import axios from "axios";

export default function PurchaseorderrequestDeleteService(id) {
  return axios({
    method: 'delete',
    url: process.env.REACT_APP_API_HOST + "/purchase_order_request/" + id,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
