import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import moment from "moment";
import {Tooltip} from "primereact/tooltip";
import {Dialog} from "primereact/dialog";
import {classNames} from "primereact/utils";
import {AutoComplete} from "primereact/autocomplete";
import {Message} from "primereact/message";
import {FileUpload} from "primereact/fileupload";
import {addLocale} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {TabView, TabPanel} from "primereact/tabview";

import EmployeeShowService from "../../services/employee/EmployeeShowService";
import IdentitydocumenttemplateListService from "../../services/identitydocumenttype/IdentitydocumenttypeListService";
import SafetyListService from "../../services/safety/SafetyListService";
import SafetyTypeListService from "../../services/safety/SafetyTypeListService";
import SafetyemployeeByemployeeListService from "../../services/safetyemployee/SafetyemployeeByemployeeService";
import EmployeeInviteService from "../../services/employee/EmployeeInviteService";
import EntrycheckFormComponent from "../entrycheck/EntrycheckFormComponent";
import IdentifierByEmployeeListService from "../../services/identifier/IdentifierByEmployeeListService";
import EmployeedocumentListService from "../../services/employeedocument/EmployeedocumentListService";
import EmployeedocumentAddService from "../../services/employeedocument/EmployeedocumentAddService";
import DocumenttemplateListService from "../../services/documenttemplate/DocumenttemplateListService";
import EmployeedocumentWildcardsService from "../../services/employeedocument/EmployeedocumentWildcardsService";
import EmployeedocumentGenerateService from "../../services/employeedocument/EmployeedocumentGenerateService";
import ProjecttypeListComponent from "../projecttype/ProjecttypeListComponent";
import SafetyemployeeFormService from "../../services/safetyemployee/SafetyemployeeFormService";
import SafetyemployeeDeleteService from "../../services/safetyemployee/SafetyemployeeDeleteService";
import {Field, Form} from "react-final-form";
import {Dropdown} from "primereact/dropdown";
import SafetyWithoutEducationListService from "../../services/safety/SafetyWithoutEducationListService";

const EmployeeFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [identitydocumenttypes, setIdentitydocumenttypes] = useState({});
  const [safeties, setSafeties] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [safetytypes, setSafetytypes] = useState([]);
  const [documentImgDialog, setDocumentImgDialog] = useState(false);
  const [documents, setDocuments] = useState([]);

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const [wildcards, setWildcards] = useState([]);
  const [wildcardsAdded, setWildcardsAdded] = useState([]);
  const [wildcardsDialog, setWildcardsDialog] = useState(false);

  const identityDocumentType = (id) => {
    if (identitydocumenttypes && identitydocumenttypes[0]) {
      let identitydocumenttype = identitydocumenttypes.filter(item => item.id === id)
      if (identitydocumenttype && identitydocumenttype[0] && identitydocumenttype[0].name) {
        return identitydocumenttype[0].name
      }
    }
  }

  // safites
  const [safetyDialog, setSafetyDialog] = useState(false);
  const [safetyEditDialog, setSafetyEditDialog] = useState(false);
  const [safetyFormData, setSafetyFormData] = useState({}); // set on dialog open!
  const [safetiesLoading, setSafetiesLoading] = useState(false);
  const [occSafeties, setOccSafeties] = useState({});

  const safetyValidate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Kötelező mező';
    // }
    // if (!data.validUntil) {
    //   errors.validUntil = 'Kötelező mező';
    // }
    if (!data.occupationalSafety) {
      errors.occupationalSafety = 'Kötelező mező';
    }
    return errors;
  };

  const safetyActionBody = (rowData, row) => {
    return (
      <React.Fragment>
        <Button type="button" icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                onClick={() => {
                  setSafetyDialog(true)
                  setSafetyFormData({...rowData, rowIndex: row.rowIndex, method: 'put'})
                }}
        />
        <Button type="button" icon="pi pi-trash" className="p-button-sm  p-button-danger"
                onClick={() => {
                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                    safetyDelete(rowData, row)
                  }
                }}
        />
      </React.Fragment>
    );
  }

  const safetyOnSubmit = (data, form) => {
    setSafetiesLoading(true)
    let _data = {}
    _data = {
      ...data,
      employee: {id: formData.id}
    }
    let method = 'post'
    if(safetyFormData.method === 'put') {
      method = 'put'
    }
    SafetyemployeeFormService(_data, method, formData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        SafetyemployeeByemployeeListService(formData.id)
          .then(response => {
            setSafeties(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        setSafetyFormData({})
        setSafetyDialog(false)
        setSafetiesLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setSafetiesLoading(false)
      })

  }

  const safetyDelete = (data, row) => {
    SafetyemployeeDeleteService(data.id)
      .then(response => {
        SafetyemployeeByemployeeListService(props.initialValues.id)
          .then(response => {
            setSafeties(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const safetyType = (id) => {
    if (safetytypes && safetytypes[0]) {
      let safetytype = safetytypes.filter(item => item.id === Number(id))
      if (safetytype && safetytype[0] && safetytype[0].value) {
        return safetytype[0].value
      }
    }
  }

  // document upload
  const documentUploader = useRef(null);
  const documentUpload = (files) => {
    let filesToUpload = []
    files.files.map(function (file, index) {
      var reader = new FileReader();
      let name = file.name
      reader.onloadend = () => {
        var base64data = reader.result;
        filesToUpload.push({
          name: name,
          originalFileName: name,
          file: base64data
        })
      }
      reader.readAsDataURL(file)
      if (files.files.length == index + 1) {
        setTimeout(function () {
          EmployeedocumentAddService(formData.id, filesToUpload)
            .then(response => {
              window.App.toastShow('success', 'Sikeres feltöltés!');
              //documentUploader.clear()
              EmployeedocumentListService('', props.initialValues.id)
                .then(response => {
                  setDocuments(response.data.items)
                })
                .catch(error => {
                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                })
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        }, 500)
      }
    })
  }

  // documenttemplate autocomplete
  const [documenttemplates, setDocumenttemplates] = useState([]);
  const [selectedDocumenttemplate, setSelectedDocumenttemplate] = useState();
  const [selectedDocumenttemplateId, setSelectedDocumenttemplateId] = useState();

  const searchDocumenttemplate = (event) => {
    setSelectedDocumenttemplate(event.query.replace('Nincs találat', '').replace(': ', ''))
    DocumenttemplateListService(JSON.stringify({
      filters: {
        name: {
          value: event.query.replace('Nincs találat', '').replace(': ', '')
        }
      }
    }))
      .then(response => {
        setDocumenttemplates(response.data.items)
        if (response.data && response.data.items && response.data.items[0]) {
          setDocumenttemplates(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedDocumenttemplate(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    if (localStorage.getItem('roles').toString().includes('role_employee_document_read')) {
      EmployeedocumentListService('', props.initialValues.id)
        .then(response => {
          setDocuments(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    SafetyWithoutEducationListService('')
      .then(response => {
        let _occSafeties = []
        Object.entries(response.data.items).map(function (safety, index) {
          _occSafeties.push({
            name: safety[1].name + " - " + safety[1].workTypeName + " - " + safety[1].entryLocationName,
            id: safety[1].id
          })
        })
        setOccSafeties(_occSafeties)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    SafetyTypeListService('')
      .then(response => {
        setSafetytypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    EmployeeShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
        SafetyemployeeByemployeeListService(props.initialValues.id)
          .then(response => {
            setSafeties(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    IdentitydocumenttemplateListService('')
      .then(response => {
        setIdentitydocumenttypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    IdentifierByEmployeeListService('', props.initialValues.id)
      .then(response => {
        setIdentifiers(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>{formData.name}</h2>
      <div className={"p-grid"}>
        {formData.companyName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Cég
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.companyName}</>
          </p>
        </div>
        }
        {formData.email &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Email
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.email}</>
          </p>
        </div>
        }
        {formData.birthDay &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Született
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{moment.utc(formData.birthDay).local().format('YYYY-MM-DD')}</>
          </p>
        </div>
        }
        {localStorage.getItem('roles').toString().includes('role_employee_write') &&
        <div className={"p-col p-text-right"}>
          <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                  onClick={() => {
                    window.App.setState({
                      sidebarEmployeeShow: false,
                      sidebarEmployeeUpdate: true,
                      props: formData
                    })
                  }}
          />
        </div>
        }
      </div>
      <hr/>
      <TabView className="tabview-header-icon menu" renderActiveOnly="true">
        <TabPanel header="Alap adatok" leftIcon="pi pi-list">
          <Panel>
            <div className={"p-grid"}>
              <div className={"p-col"}>
                <div className={"p-grid"}>
                  {formData.name &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <label htmlFor="name">Név</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.name}
                    </p>
                  </div>
                  }
                  {formData.active &&
                  <div className={"p-col-12 p-md-3 p-lg-3"}>
                    <label>&nbsp;</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.active === true ? (
                        <span className={"text-success"}><i className={"pi pi-check"}></i> Aktív</span>
                      ) : (
                        <span className={"text-danger"}><i className={"pi pi-times"}></i> Inaktív</span>
                      )}
                    </p>
                  </div>
                  }
                </div>
                <div className={"p-grid p-mt-1"}>
                  {formData.email &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <label htmlFor="email">Email</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.email}
                    </p>
                  </div>
                  }
                  {formData.phone &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <label htmlFor="email">Telefonszám</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.phone}
                    </p>
                  </div>
                  }
                </div>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-sitemap"}></i> Jogviszony
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {formData.companyName &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <label>Cég</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.companyName}
                    </p>
                  </div>
                  }
                  {formData.employeePositionName &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <label>Beosztás</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.employeePositionName}
                    </p>
                  </div>
                  }
                  {process.env.REACT_APP_EMPLOYEE_HOMEOFFICE_ENABLED === 'true' &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <label>Otthoni munkavégzés engedélyezve</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.isHomeofficeEnabled === true ? (
                        <>igen</>
                      ) : (
                        <>nem</>
                      )}
                    </p>
                  </div>
                  }
                </div>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-user"}></i> Személyes adatok
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <label>Született</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.birthPlace && <>{formData.birthPlace}, </>}{formData.birthDay && <>{moment.utc(formData.birthDay).local().format('YYYY-MM-DD')}</>}
                    </p>
                  </div>
                  {formData.employeeNationalityName &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <label>Állampolgárság</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.employeeNationalityName}
                    </p>
                  </div>
                  }
                </div>
                <div className={"p-grid"}>
                  {formData.identityDocumentType &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <label>Okmány típusa</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {identityDocumentType(formData.identityDocumentType)}
                    </p>
                  </div>
                  }
                  {formData.identityDocumentNumber &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <label>Okmány azonosító</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.identityDocumentNumber}
                    </p>
                  </div>
                  }
                  {formData.identityDocumentValidUntil &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <label>Okmány lejárat</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {moment.utc(formData.identityDocumentValidUntil).local().format('YYYY-MM-DD')}
                    </p>
                  </div>
                  }
                  {formData.taxNumber &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <label>Adóazonosító</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.taxNumber}
                    </p>
                  </div>
                  }
                  {formData.insuranceNumber &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <label>TAJ</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.insuranceNumber}
                    </p>
                  </div>
                  }
                </div>
              </div>
              <div className={"p-col p-lg-4 p-xl-4"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-image"}></i> Fotó
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col"}>
                    {formData.id &&
                    <img src={process.env.REACT_APP_API_HOST + "/employee/profileImage/" + formData.id
                    + "?token=" + localStorage.getItem("userToken") + "&date=" + new (Date)}
                         className={"w-100 p-d-block"}/>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                {localStorage.getItem('roles').toString().includes('role_employee_write') && props.initialValues.readOnly !== true &&
                formData.email &&
                <>
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      <Button type="button" label="Meghívás" icon="pi pi-envelope" className="p-button-info"
                              loading={window.App.state.loadingEmployeeInvite}
                              onClick={() => {
                                window.App.setState({loadingEmployeeInvite: true})
                                EmployeeInviteService(formData.id)
                                  .then(response => {
                                    window.App.toastShow('success', 'Sikeres meghívás!',);
                                    window.App.setState({loadingEmployeeInvite: false})
                                    EmployeeShowService(formData.id)
                                      .then(response => {
                                        setFormData(response.data)
                                      })
                                      .catch(error => {
                                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      })
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                    window.App.setState({loadingEmployeeInvite: false})
                                  })
                              }}
                      />
                    </div>
                    {formData && formData.invitedAt !== null &&
                    <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
                      Meghívó kiküldve: {moment.utc(formData.invitedAt).local().format('YYYY.MM.DD. HH:mm:ss')}
                    </div>
                    }
                    {formData && formData.invitedAt === null &&
                    <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
                      Meghívó kiküldve: nem
                    </div>
                    }
                  </div>
                </>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>

              </div>
              {formData.id && localStorage.getItem('roles').toString().includes('role_employee_write') && props.initialValues.readOnly !== true &&
              <div className={"p-col-12"}>
                <Message className={"w-100 p-d-block"} severity="warn" text="
                A rendszer automatikus meghívót küld a munkavállalónak, amennyiben létrehozáskor az e-mail cím meg van adva.
              "></Message>
              </div>
              }
            </div>
          </Panel>
        </TabPanel>
        <TabPanel header="Munkavédelmi bejegyzések" leftIcon="pi pi-list">
          <Panel>
            <DataTable value={safeties}
                       emptyMessage="Nincs találat."
                       autoLayout={true}
                       filterDisplay="row"
                       responsiveLayout="scroll"
                       stripedRows
            >
              <Column body={safetyActionBody} className={"p-py-0 p-text-nowrap"}></Column>
              {/*<Column field="name" header="Megnevezés"></Column>*/}
              {/*<Column field="entryLocationName" header="Projekt"></Column>*/}
              <Column field="occupationalSafetyName" header="Munkavédelmi előírás"></Column>
              <Column field="validUntil" header="Érvényes" body={(rowData) => {
                if (rowData.validUntil) {
                  return (
                    <>{moment.utc(rowData.validUntil).local().format('YYYY-MM-DD')}</>
                  )
                } else {
                  return (
                    <>Korlátlan</>
                  )
                }
              }}></Column>
              <Column header="Típus" body={(rowData) => {
                return (
                  <>{safetyType(rowData.type)}</>
                )
              }}></Column>
            </DataTable>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                {localStorage.getItem('roles').toString().includes('role_occ_safety_write') &&
                <Button type="button" label="Új bejegyzés hozzáadása" icon="pi pi-plus" className="p-button-success"
                        onClick={() => {
                          setSafetyDialog(true)
                          setSafetyFormData({method: 'post'})
                        }}
                />
                }
                <Dialog visible={safetyDialog}
                        style={{width: '750px'}}
                        header="Munkavédelmi bejegyzés"
                        modal
                        onHide={() => {
                          setSafetyDialog(false)
                        }}>
                  <Form onSubmit={safetyOnSubmit} initialValues={safetyFormData}
                        validate={safetyValidate} render={({handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className={"p-grid"}>
                        {/*<div className={"p-col-12 p-md-12 p-lg-12"}>*/}
                        {/*  <Field name="name" render={({input, meta}) => (*/}
                        {/*    <div className="p-field p-fluid">*/}
                        {/*      <label htmlFor="name"*/}
                        {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>*/}
                        {/*      <span className="p-input-icon-right">*/}
                        {/*        <InputText id="name" {...input}*/}
                        {/*                   onChange={(e) => {*/}
                        {/*                     setSafetyFormData({*/}
                        {/*                       ...safetyFormData,*/}
                        {/*                       name: e.target.value*/}
                        {/*                     })*/}
                        {/*                   }}*/}
                        {/*                   value={safetyFormData.name}*/}
                        {/*                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      </span>*/}
                        {/*      {getFormErrorMessage(meta)}*/}
                        {/*    </div>*/}
                        {/*  )}/>*/}
                        {/*</div>*/}
                        <div className={"p-col-12 p-md-6 p-lg-6"}>
                          <Field name="occupationalSafety" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="occupationalSafety"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavédelmi
                                előírás</label>
                              <span className="p-input-icon-right">
                      {safetyFormData.occupationalSafety && safetyFormData.occupationalSafety.id ? (
                        <Dropdown options={occSafeties}
                                  optionLabel="name"
                                  optionValue="id"
                                  id={"occupationalSafety"}
                                  emptyMessage={"Még nincs előírás..."}
                                  onChange={(e) => {
                                    setSafetyFormData({
                                      ...safetyFormData,
                                      occupationalSafety: {id: e.value}
                                    })
                                  }}
                                  value={Number(safetyFormData.occupationalSafety.id)}
                        />
                      ) : (
                        <Dropdown options={occSafeties}
                                  optionLabel="name"
                                  optionValue="id"
                                  id={"occupationalSafety"}
                                  emptyMessage={"Még nincs előírás..."}
                                  onChange={(e) => {
                                    setSafetyFormData({
                                      ...safetyFormData,
                                      occupationalSafety: {id: e.value}
                                    })
                                  }}
                        />
                      )}
                    </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-md-6 p-lg-6"}>
                          <Field name="validUntil" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="validUntil"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Érvényesség
                                vége</label>
                              <span className="p-input-icon-right">
                      {safetyFormData.validUntil && (safetyFormData.validUntil !== null || safetyFormData.validUntil !== undefined) ? (
                        <Calendar dateFormat="yy-mm-dd"
                                  monthNavigator={true}
                                  yearNavigator={true}
                                  yearRange="1900:2022"
                                  onChange={(e) => {
                                    if (e.target.value !== null) {
                                      setSafetyFormData({
                                        ...safetyFormData,
                                        validUntil: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                      })
                                    } else {
                                      setSafetyFormData({
                                        ...safetyFormData,
                                        validUntil: null
                                      })
                                    }
                                  }}
                                  value={new Date(safetyFormData.validUntil)}
                                  mask="9999-99-99"
                                  id={"validUntil"}
                                  locale={"hu"}/>
                      ) : (
                        <Calendar dateFormat="yy-mm-dd"
                                  monthNavigator={true}
                                  yearNavigator={true}
                                  yearRange="1900:2022"
                                  onChange={(e) => {
                                    if (e.target.value !== null) {
                                      setSafetyFormData({
                                        ...safetyFormData,
                                        validUntil: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                      })
                                    } else {
                                      setSafetyFormData({
                                        ...safetyFormData,
                                        validUntil: null
                                      })
                                    }
                                  }}
                                  mask="9999-99-99"
                                  id={"validUntil"}
                                  locale={"hu"}/>
                      )}
                      </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                      </div>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>

                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          <Button type="submit" label="Mentés" icon="pi pi-check"
                                  loading={safetiesLoading}/>
                        </div>
                      </div>
                    </form>
                  )}/>
                </Dialog>
              </div>
            </div>
          </Panel>
        </TabPanel>
        {localStorage.getItem('roles').toString().includes('role_employee_document_read') ? (
          <TabPanel header="Dokumentumok" leftIcon="pi pi-list">
            <Panel>
              <DataTable value={documents}
                         emptyMessage="Nincs találat."
                         autoLayout={true}
                         filterDisplay="row"
                         responsiveLayout="scroll"
                         stripedRows
              >
                <Column field="name" header="Megnevezés"></Column>
                <Column field="note" header="Megjegyzés"></Column>
                <Column field="validUntil" header="Érvényes" body={(rowData) => {
                  if (rowData.validUntil) {
                    return (
                      <>{moment.utc(rowData.validUntil).local().format('YYYY-MM-DD')}</>
                    )
                  } else {
                    return (
                      <>Korlátlan</>
                    )
                  }
                }}></Column>
                <Column field="filePath" header="Fájl" body={(rowData) => {
                  return (
                    <>
                      <a
                        href={process.env.REACT_APP_API_HOST + "/employeeDocument/" + formData.id + "/download/" + rowData.id
                        + "?token=" + localStorage.getItem('userToken') + "."
                        + localStorage.getItem("networkId")}
                        target="_blank"
                      >
                        <i className={"pi pi-file"}></i> {rowData.originalFileName}
                      </a>
                      {/*<img*/}
                      {/*  src={process.env.REACT_APP_API_HOST + "/employeeDocument/" + formData.id + "/download/" + rowData.id*/}
                      {/*  + "?token=" + localStorage.getItem('userToken') + "."*/}
                      {/*  + localStorage.getItem("networkId") + '&date=' + new Date()} className={"w-100"}*/}
                      {/*  onClick={() => {*/}
                      {/*    setDocumentImgDialog(true)*/}
                      {/*    window.App.setState({*/}
                      {/*      props: {*/}
                      {/*        employeeId: formData.id,*/}
                      {/*        imageId: rowData.id*/}
                      {/*      }*/}
                      {/*    })*/}
                      {/*  }}*/}
                      {/*  style={{width: '80px'}}*/}
                      {/*/>*/}
                      {/*<Dialog visible={documentImgDialog}*/}
                      {/*        style={{width: '750px'}}*/}
                      {/*        header="Dokumentum megtekintése"*/}
                      {/*        modal*/}
                      {/*        onHide={() => {*/}
                      {/*          setDocumentImgDialog(false)*/}
                      {/*        }}>*/}
                      {/*  <img src={process.env.REACT_APP_API_HOST + "/employeeDocument/" + window.App.state.props.employeeId*/}
                      {/*  + "/download/" + window.App.state.props.imageId*/}
                      {/*  + "?token=" + localStorage.getItem("userToken") + "." + localStorage.getItem('networkId')}*/}
                      {/*       style={{'width': '700px', 'height': 'auto'}}/>*/}
                      {/*</Dialog>*/}
                    </>
                  )
                }}></Column>
              </DataTable>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6"}>
                  {localStorage.getItem('roles').toString().includes('role_employee_document_write') &&
                  <>
                    <h4>
                      <i className={"pi pi-upload"}></i> Felöltés
                    </h4>
                    <FileUpload //mode="basic"
                      name="file"
                      id={"file"}
                      //auto="true"
                      multiple
                      ref={documentUploader}
                      customUpload
                      uploadHandler={documentUpload}
                      chooseLabel={"Fájlok"}
                      uploadLabel={"Feltöltés"}
                      cancelLabel={"Mégse"}
                    />
                  </>
                  }
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  {localStorage.getItem('roles').toString().includes('role_employee_document_generate') &&
                  <>
                    <h4>
                      <i className={"pi pi-file"}></i> Generálás sablonból
                    </h4>
                    <AutoComplete value={selectedDocumenttemplate}
                                  id={"type"}
                                  suggestions={documenttemplates}
                      // forceSelection
                                  completeMethod={searchDocumenttemplate}
                                  field={"name"}
                                  delay="500"
                                  className={"w-100"}
                                  placeholder={"Keresés gépeléssel..."}
                                  onSelect={(e) => {
                                    EmployeedocumentWildcardsService(formData.id, e.value.id)
                                      .then(response => {
                                        if (response && response.data && response.data && response.data.extrafields && response.data.extrafields[0]) {
                                          setWildcards(response.data.extrafields)
                                          setWildcardsDialog(true)
                                        }
                                      })
                                      .catch(error => {
                                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      })
                                    setSelectedDocumenttemplate(e.value.name)
                                    setSelectedDocumenttemplateId(e.value.id)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedDocumenttemplate('')
                                  }}
                    />
                    <Dialog header="Változók meghatározása" visible={wildcardsDialog} style={{width: '480px'}} modal
                            onHide={() => {
                              setWildcardsDialog(false)
                            }}>
                      <div className="grid p-fluid">
                        {wildcards && wildcards[0] &&
                        <>
                          {wildcards.map((wildcard, index) => {
                            return (
                              <>
                                <div className={"p-col-12"}>
                                  <div className="p-field p-fluid">
                                    <label>{wildcard.label}</label>
                                    <span className="p-inputgroup">
                              {wildcard.type === 'date' &&
                              <>
                                <Calendar dateFormat="yy-mm-dd"
                                          monthNavigator={true}
                                          yearNavigator={true}
                                          yearRange={"2022:2099"}
                                          keepInvalid="true"
                                          onChange={(e) => {
                                            setWildcardsAdded([
                                              ...wildcardsAdded,
                                              {
                                                key: wildcard.key,
                                                value: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                              }
                                            ])
                                          }}
                                          mask="9999-99-99"
                                          locale={"hu"}
                                />
                              </>
                              }
                            </span>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                        </>
                        }
                      </div>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>
                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          <Button type="button" label="Mentés" icon="pi pi-check"
                                  onClick={() => {
                                    EmployeedocumentGenerateService(formData.id, selectedDocumenttemplateId, wildcardsAdded)
                                      .then(response => {
                                        window.App.toastShow('success', 'Sikeres generálás!');
                                        setWildcardsDialog(false)
                                        EmployeedocumentListService('', props.initialValues.id)
                                          .then(response => {
                                            setDocuments(response.data.items)
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      })
                                      .catch(error => {
                                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      })

                                  }}
                          />
                        </div>
                      </div>
                    </Dialog>
                  </>
                  }
                </div>
              </div>
            </Panel>
          </TabPanel>
        ) : (
          <TabPanel disabled/>
        )}
        <TabPanel header="Azonosítók" leftIcon="pi pi-list">
          {props.initialValues.readOnly !== true &&
          <Panel>
            <div className={"p-grid"}>
              {identifiers && identifiers[0] ? (
                [identifiers.map((identifier, index) => {
                  return (
                    <>
                      <div className={"p-col p-fluid p-text-center"} style={{maxWidth: '120px'}}>
                        <img src={process.env.REACT_APP_API_HOST + "/identifier/qr/" + identifier.id
                        + "/?token=" + localStorage.getItem("userToken")
                        } className={"w-100"}/>
                        {localStorage.getItem('api_host').toString().includes('test') &&
                        <Button onClick={() => {
                          window.App.setState({
                            props: identifier.identifier,
                            sidebarEntrycheck: true
                          })
                        }} label={"Teszt"}/>
                        }
                        {process.env.NODE_ENV === 'development' &&
                        <>
                          <Button onClick={() => {
                            window.App.setState({
                              props: identifier.identifier,
                              sidebarEntrycheck: true
                            })
                          }} label={"Teszt"}/>
                        </>
                        }
                        <Dialog visible={window.App.state.sidebarEntrycheck}
                                style={{width: '750px'}}
                                header="Belépés tesztelése"
                                modal
                                onHide={() => {
                                  window.App.setState({sidebarEntrycheck: false})
                                }}>
                          <EntrycheckFormComponent identifier={window.App.state.props}/>
                        </Dialog>
                      </div>
                    </>
                  )
                })]
              ) : (
                <div className={"p-col-12"}>Még nincs hozzárendelve azonosító...</div>
              )}
            </div>
          </Panel>
          }
        </TabPanel>
      </TabView>
    </>
  )
}
export default withTranslation('common')(EmployeeFormComponent);
