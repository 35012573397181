import axios from "axios";

export default function CompanyLogoDeleteService(companyId) {
  return axios({
    method: "put",
    url: process.env.REACT_APP_API_HOST + "/company/remove_logo",
    data: {
      company: Number(companyId),
    },
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
