import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import ReactQuill from "react-quill";

import DocumenttemplateFormService from '../../services/documenttemplate/DocumenttemplateFormService'
import {Dropdown} from "primereact/dropdown";
import DocumenttypeListService from "../../services/documenttype/DocumenttypeListService";
import {AutoComplete} from "primereact/autocomplete";
import DocumenttemplateShowService from "../../services/documenttemplate/DocumenttemplateShowService";
import DocumenttypeShowService from "../../services/documenttype/DocumenttypeShowService";
import DocumenttemplateWildcardsService from "../../services/documenttemplate/DocumenttemplateWildcardsService";

const DocumenttemplateFormComponent = (props) => {

  const [wildcards, setWildcards] = useState([]);

  //form
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const validate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'Az megnevezés nem lehet üres';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      DocumenttemplateFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderDocumenttemplateList: true,
            sidebarDocumenttemplateUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      DocumenttemplateFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderDocumenttemplateList: true,
            sidebarDocumenttemplateCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // documenttype autocomplete
  const [documenttypes, setDocumenttypes] = useState([]);
  const [selectedDocumenttype, setSelectedDocumenttype] = useState();

  const searchDocumenttype = (event) => {
    setSelectedDocumenttype(event.query.replace('Nincs találat', '').replace(': ', ''))
    DocumenttypeListService(JSON.stringify({
      filters: {
        name: {
          value: event.query.replace('Nincs találat', '').replace(': ', '')
        }
      }
    }))
      .then(response => {
        setDocumenttypes(response.data.items)
        if (response.data && response.data.items && response.data.items[0]) {
          setDocumenttypes(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedDocumenttype(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  //editor
  const quill = React.useRef(false)

  useEffect(() => {
    if (props && props.initialValues) {
      DocumenttemplateShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          if (response && response.data && response.data.type && response.data.type.id) {
            DocumenttypeShowService(response.data.type.id)
              .then(response => {
                setSelectedDocumenttype(response.data.name)
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
            DocumenttemplateWildcardsService(response.data.entity.replace('App\\Entity\\', ''))
              .then(response => {
                setWildcards(response.data)
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })

          }
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <Field name="entity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="entity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Entitás</label>
                  <span className="p-input-icon-right">
                    {formData.id ? (
                      <Dropdown
                        showClear
                        options={[
                        {
                          name: 'Munkavállaló',
                          value: 'App\\Entity\\Employee'
                        },
                        {
                          name: 'Vállalkozás',
                          value: 'App\\Entity\\Company'
                        },
                      ]}
                                optionLabel="name"
                                optionValue="value"
                                id={"entity"}
                                emptyMessage={"Még nincs entitás..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    entity: e.value
                                  })
                                }}
                                disabled
                                value={formData.entity}
                      />
                    ) : (
                      <Dropdown
                        showClear
                        options={[
                        {
                          name: 'Munkavállaló',
                          value: 'App\\Entity\\Employee'
                        },
                        {
                          name: 'Vállalkozás',
                          value: 'App\\Entity\\Company'
                        },
                      ]}
                                optionLabel="name"
                                optionValue="value"
                                id={"entity"}
                                emptyMessage={"Még nincs entitás..."}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    entity: e.value
                                  })
                                }}
                                value={formData.entity}
                      />
                    )}
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col"}>
              <Field name="type" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="type"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                  <span className="p-input-icon-right">
                    {formData && formData.id ? (
                      <AutoComplete value={selectedDocumenttype}
                                    id={"type"}
                                    suggestions={documenttypes}
                                    disabled
                                    // forceSelection
                                    completeMethod={searchDocumenttype}
                                    field={"name"}
                                    delay="500"
                                    placeholder={"Keresés gépeléssel..."}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                    onSelect={(e) => {
                                      setFormData({
                                        ...formData,
                                        type: {id: e.value.id},
                                      })
                                      setSelectedDocumenttype(e.value.name)
                                    }}
                                    dropdown
                                    onClear={(e) => {
                                      setSelectedDocumenttype('')
                                    }}
                      />
                    ):(
                      <AutoComplete value={selectedDocumenttype}
                                    id={"type"}
                                    suggestions={documenttypes}
                                    // forceSelection
                                    completeMethod={searchDocumenttype}
                                    field={"name"}
                                    delay="500"
                                    placeholder={"Keresés gépeléssel..."}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                    onSelect={(e) => {
                                      setFormData({
                                        ...formData,
                                        type: {id: e.value.id},
                                      })
                                      setSelectedDocumenttype(e.value.name)
                                    }}
                                    dropdown
                                    onClear={(e) => {
                                      setSelectedDocumenttype('')
                                    }}
                      />
                    )}
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Név</label>
                  <span className="p-input-icon-right">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          {formData.id &&
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-8"}>
              <div className="p-field p-fluid">
                <label htmlFor="body">Tartalom</label>
                <div id={"editor-container"}>
                  <ReactQuill style={{height: '620px'}} value={formData.body} id={"body"}
                          ref={quill}
                          onTextChange={(e) => setFormData({body: e.htmlValue})}/>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-4"}>
              <div className={"p-col-12 p-lg-8"}>
                <div className="p-field p-fluid">
                  <label htmlFor="body">Változók</label>
                  <div>
                  {wildcards && wildcards[0] ? (
                    <>
                      {wildcards.map((wildcard, index) => {
                        console.log(wildcard)
                        let text = wildcard.key
                        return(
                          <>
                            <span className={"p-d-block"}
                                  onClick={() => {
                              var selection = quill.current.getEditor().getSelection(true);
                              quill.current.getEditor().insertText(selection.index, text);
                            }}>
                              {wildcard.label}
                            </span>
                          </>
                        )
                      })}
                    </>
                    ):(
                      <><i className={"pi pi-lock"}></i> A változó kiválasztása csak típus kiválasztása után lehetséges!</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          }
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(DocumenttemplateFormComponent);
