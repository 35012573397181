import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {classNames} from "primereact/utils";
import {MultiSelect} from "primereact/multiselect";
import {Form, Field} from 'react-final-form';

import PartnercontactListService from "../../services/partnercontact/PartnercontactListService";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import ReceiptSendService from "../../services/receipt/ReceiptSendService";
import PartnerShowService from "../../services/partner/PartnerShowService";

const ReceiptSendComponent = (props) => {

  const [formData, setFormData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const validate = (data) => {
    let errors = {};
    if (!data.email) {
      errors.email = 'Az email nem lehet üres';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      ...contacts,
      data
    });
    ReceiptSendService(window.App.state.props.id, formData)
      .then(response => {
        setLoading(false)
        window.App.toastShow('success', 'Sikeres küldés!');
        window.App.setState({
          dialogReceiptSend: false
        })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    PartnercontactListService('', window.App.state.props.partner.id)
      .then(response => {
        if (response && response.data && response.data.items && response.data.items[0]) {
          setContacts(response.data.items)
        } else {
          window.App.toastShow('warn', 'Figyelem', 'Ennek a partnernek nincs kapcsolattartója!');
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    PartnerShowService(window.App.state.props.partner.id)
      .then(response => {

      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <Field name="contacts" render={({input, meta}) => (
              <div className="p-field p-fluid">
                <label htmlFor="contacts"
                       className={classNames({'p-error': isFormFieldValid(meta)})}>Kapcsolattartó(k)</label>
                <span className="p-input-icon-right">
                  {contacts && contacts[0] ? (
                    <MultiSelect options={contacts}
                                 optionLabel="name"
                                 optionValue="id"
                                 emptyMessage={"Még nincs munkavállaló..."}
                                 onChange={(e) => {
                                   setSelectedContacts(e.value)
                                 }}
                                 id={"contacts"}
                                 value={selectedContacts}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}
                    />
                  ) : (
                    <MultiSelect options={contacts}
                                 disabled
                                 className={"disabled"}/>

                  )}
                </span>
                {getFormErrorMessage(meta)}
              </div>
            )}/>
          </div>
          <div className={"p-col-12"}>
            <Field name="email" render={({input, meta}) => (
              <div className="p-field p-fluid">
                <label htmlFor="email"
                       className={classNames({'p-error': isFormFieldValid(meta)})}>Email cím</label>
                <span className="p-input-icon-right">
                  <InputText id="email" {...input}
                             onChange={(e) => {
                               setFormData({
                                 ...formData,
                                 email: e.target.value
                               })
                             }}
                             value={formData.email}
                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                </span>
                {getFormErrorMessage(meta)}
              </div>
            )}/>
          </div>
        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            <Button type="submit" label="Küldés" icon={"pi pi-envelope"} loading={loading}
                    className="p-button-info"/>
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ReceiptSendComponent);
