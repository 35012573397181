import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {PickList} from "primereact/picklist";
import {AutoComplete} from "primereact/autocomplete";

import RolegroupFormService from '../../services/rolegroup/RolegroupFormService'
import RolesListService from "../../services/roles/RolesListService";
import RolegroupRolesService from "../../services/rolegroup/RolegroupRolesService";

const RolegroupFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'Az megnevezés nem lehet üres';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if ((props && props.initialValues) || formData.id) {
      RolegroupFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({rerenderRolegroupList: true})
          setFormData(response.data)
          if (formData.closeForm === true) {
            window.App.setState({
              sidebarRolegroupUpdate: false,
              sidebarRolegroupCreate: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      RolegroupFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({rerenderRolegroupList: true})
          setFormData(response.data)
          if (formData.closeForm === true) {
            setTimeout(function () {
              window.App.setState({
                sidebarRolegroupCreate: false
              })
            }, 500)
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  //roles picklists
  const [rolesSource, setRolesSource] = useState([]);
  const [rolesTarget, setRolesTarget] = useState([]);
  const [rolegroupRoles, setRolegroupRoles] = useState([]);

  const rolesItemTemplate = (item) => {
    return (
      <>
        {item.name}
      </>
    );
  }

  const onRolesChange = (event) => {
    setRolesSource(event.source);
    setRolesTarget(event.target);
    setRolegroupRoles(event.target)
  }

  useEffect(() => {
    RolesListService()
      .then(response => {
        setRolesSource(response.data)
        if (props && props.initialValues) {

          let roles = props.initialValues.roles
          let _targetRoles = []
          Object.entries(roles).map(function (role, index) {
            let _role = response.data.filter(item => item.code === role[1])
            if (_role && _role[0] && _role[0].name && _role[0].code) {
              _targetRoles.push({
                code: _role[0].code,
                name: _role[0].name
              })
            }
          })
          setRolesTarget(_targetRoles)

          let _sourceRoles = response.data.filter(function (objFromSource) {
            return !_targetRoles.find(function (objFromTarget) {
              return objFromSource.code === objFromTarget.code
            })
          })
          setRolesSource(_sourceRoles)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      setFormData(props.initialValues)
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Név</label>
                  <span className="p-input-icon-right">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"}
                      loading={loading}
                      onClick={() => {
                        setFormData({...formData, closeForm: true})
                      }}
                      className="p-button-success"/>
              <Button type="submit" label="Mentés" icon={"pi pi-check"}
                      loading={loading}
                      onClick={() => {
                        setFormData({...formData, closeForm: false})
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
        </Panel>
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-check-square"}></i> Jogosultságok kiválasztása
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          {formData.id ? (
            <>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-12 p-xl-12"}>
                  <PickList source={rolesSource} target={rolesTarget} itemTemplate={rolesItemTemplate}
                            sourceHeader="Elérhető" targetHeader="Kiválasztott"
                            showSourceControls={false}
                            showTargetControls={false}
                            sourceStyle={{height: '342px'}} targetStyle={{height: '342px'}}
                            onChange={onRolesChange}></PickList>
                </div>
              </div>
              <div className={"p-grid p-mt-3"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>

                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                  <Button type="button" label="Mentés" icon={"pi pi-check"} className="p-button-success"
                          onClick={() => {
                            RolegroupRolesService(rolegroupRoles, formData.id)
                              .then(response => {
                                window.App.toastShow('success', 'Sikeres mentés!');
                                window.App.setState({rerenderRolegroupList: true})
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              })
                          }}
                  />
                </div>
              </div>
            </>
          ) : (
            <><i className={"pi pi-lock"}></i> A jogosultságok beállítása csak mentés után lehetséges!</>
          )}
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(RolegroupFormComponent);
