import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';

import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Link} from "react-router-dom";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import UserLoginService from '../services/user/UserLoginService';
import NetworkListService from "../services/network/NetworkListService";
import ModulesListService from "../services/modules/ModulesListService";

const LoginPage = () => {

  const [formData, setFormData] = useState({});

  const validate = (data) => {
    let errors = {};
    if (!data.identifier) {
      errors.identifier = 'Az email mező nem lehet üres.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.identifier)) {
      errors.identifier = 'Mindenképpen email formátumra van szükség! Pl. example@email.com';
    }
    if (!data.password) {
      errors.password = 'A jelszó nem lehet üres';
    }
    return errors;
  };

  const onSubmit = (data, form) => {
    setFormData(data);
    UserLoginService(data)
      .then(response => {
        localStorage.setItem("userId", response.data.id);
        localStorage.setItem("userToken", response.data.token);
        localStorage.setItem("userName", data.identifier);
        localStorage.setItem("userRealName", response.data.name);
        localStorage.setItem("roles", JSON.stringify(response.data.roles).toLowerCase())
        NetworkListService()
          .then(response => {
            if (response.data && response.data[0]) {
              window.App.setState({
                networks: response.data
              })
              if (!localStorage.getItem('networkId') && !localStorage.getItem('networkName')) {
                localStorage.setItem('networkId', response.data[0].id)
                localStorage.setItem('networkName', response.data[0].name)
              }
            }
            ModulesListService()
              .then(response => {
                Object.entries(response.data).map(function (item, index) {
                  localStorage.setItem('module_' + item[1].code, item[1].name);
                  if (index == response.data.length -1) {
                    document.getElementById('body').classList.remove('outside')
                    window.App.toastShow('success', 'Sikeres belépés!');
                    //setTimeout(function () {
                    window.location.replace('/#/');
                    window.App.forceUpdate()
                    window.App.setState({loggedIn: true})
                    //window.location.reload() // TODO
                    //}, 2000)
                  }
                })
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      })
      .catch(function (error) {
        setFormData({
          identifier: data.identifier,
          password: ''
        })
        setTimeout(function() {
          document.getElementById('password').focus()
        },1500)
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    document.title = 'Bejelentkezés' + ' - ' + process.env.REACT_APP_TITLE;
    document.getElementById('body').classList.add('outside')
    setTimeout(function() {
      document.getElementById('identifier').focus()
    },500)
  }, []);

  return (
    <div className="LoginPage p-component p-p-3">
      <img src={require(".." + process.env.REACT_APP_LOGO_FILE)} className="p-d-block p-my-5 p-mx-auto"/>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-4 p-xl-6"}>
            <h3>
              <i className={"pi pi-user"}></i> Bejelentkezés
            </h3>
          </div>
        </div>
      } className={""}>
        <Form onSubmit={onSubmit}
              className="p-d-block p-mt-5 p-mx-auto"
              initialValues={formData}
              validate={validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <Field name="identifier" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="identifier"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Email</label>
                    <span className="p-input-icon-right">
                    <i className="pi pi-envelope"/>
                    <InputText id="identifier"
                               {...input}
                               tabIndex={1}
                               value={formData.identifier}
                               onChange={(e) => {
                                 setFormData({
                                   ...formData,
                                   identifier: e.target.value
                                 })
                               }}
                               className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12"}>
                <Field name="password" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="password"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Jelszó</label>
                    <Password id="password"
                              tabIndex={2}
                              {...input}
                              toggleMask
                              feedback={false}
                              value={formData.password}
                              onChange={(e) => {
                               setFormData({
                                 ...formData,
                                 password: e.target.value
                               })
                              }}
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <Link to={"/password-reset"}>Elfelejtettem a jelszavam</Link>
                {process.env.REACT_APP_REGISTRATION === 'enabled' &&
                <>
                  <br/>
                  <Link to={"/registration"}>Regisztráció</Link>
                </>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Belépés" className="p-button-success" icon={"pi pi-check"} disabled={submitting}/>
              </div>
            </div>
            <div className={"p-text-center p-mt-3"}>
              <span className={""} dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
            </div>
          </form>
        )}/>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(LoginPage);
