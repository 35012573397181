import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";

import ReceiptShowService from "../../services/receipt/ReceiptShowService";
import moment from "moment";
import currencyFormatter from "currency-formatter";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

const ReceiptShowComponent = (props) => {

  const [formData, setFormData] = useState({});

  const priceFormatter = (price) => {
    let currency
    if (formData.currency === 'HUF') {
      currency = {
        precision: 2,
        symbol: '',
        decimal: ',',
        thousand: ' ',
      }
    } else {
      currency = {
        precision: 2,
        symbol: '',
        decimal: '.',
        thousand: ' ',
      }
    }
    return (
      <>
        <span className={""}>
        {currencyFormatter.format(
          price, {
            precision: currency.precision,
            symbol: currency.symbol,
            decimal: currency.decimal,
            thousand: currency.thousand,
          }
        )
        }
          &nbsp;{formData.currency}
          </span>
      </>
    )
  }

  useEffect(() => {
    ReceiptShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <div style={{maxWidth: '21cm', minHeight: '29cm', borderRadius: '0', margin: 'auto'}}>
      <Panel className={"p-mx-auto p-shadow-5 p-p-5"} style={{maxWidth: '21cm', minHeight: '29cm', borderRadius: '0'}}>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6"}>

          </div>
          <div className={"p-col-12 p-lg-6"}>
            <h1 className={"p-m-0 p-p-0 p-text-uppercase p-text-right p-text-normal"}>
              {formData.receiptType === 'invoice' && formData.outgoing === true &&
              <>Számla</>
              }
              {formData.receiptType === 'invoice' && formData.outgoing === false &&
              <>Költségszámla</>
              }
              {formData.receiptType === 'delivery_note' &&
              <>Szállítólevél</>
              }
            </h1>
          </div>
        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col"}>
            <label>
              {formData.receiptType === 'delivery_note' ? (
                <>
                  Szállítólevél kelte
                </>
              ):(
                <>
                  Számla kelte
                </>
              )}
            </label>
            <strong className={"p-d-block"}>
              {moment.utc(formData.issueDate).local().format('YYYY.MM.DD.')}
            </strong>
          </div>
          {formData.receiptType !== 'delivery_note' &&
          <div className={"p-col"}>
            <label>
              Teljesítés
            </label>
            <strong className={"p-d-block"}>
              {formData.deliveryDate && formData.deliveryDate !== null &&
              <>
                {moment.utc(formData.deliveryDate).local().format('YYYY.MM.DD.')}
              </>
              }
            </strong>
          </div>
          }
          {formData.receiptType !== 'delivery_note' &&
          <div className={"p-col"}>
            <label>
              Fiz. határidő
            </label>
            <strong className={"p-d-block"}>
              {moment.utc(formData.paymentDate).local().format('YYYY.MM.DD.')}
            </strong>
          </div>
          }
          {formData.receiptType !== 'delivery_note' &&
          <div className={"p-col"}>
            <label>
              Fizetés módja
            </label>
            <strong className={"p-d-block"}>
              {formData.paymentMethodName}
            </strong>
          </div>
          }
          <div className={"p-col p-text-right"}>
            <label>
              {formData.receiptType === 'delivery_note' ? (
                <>
                  Szállítólevél sorszáma
                </>
              ) : (
                <>
                  Számla sorszáma
                </>
              )}
            </label>
            <strong className={"p-d-block"}>
              {formData.receiptNumber}
            </strong>
          </div>
        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col"}>
            <label>
              {formData.outgoing === true &&
              <>Szállító</>
              }
              {formData.outgoing === false &&
              <>Vevő</>
              }
            </label>
            <h3 className={"p-m-0 p-p-0"}>
              {formData.companyName}
            </h3>
            {formData.companyAddressPostalCode}&nbsp;{formData.companyAddressCity}
            <br/>
            {formData.companyAddressDetail}
            <br/>
            Adószám: {formData.companyVatNumber}
            {formData.companyCommunityVatNumber && formData.companyCommunityVatNumber !== null &&
            <>
              <br/>
              Közösségi adószám: {formData.companyCommunityVatNumber}
            </>
            }
            {formData.companyBankAccount && formData.companyBankAccount !== null &&
            <>
              <br/>
              Számlaszám:
            </>
            }
          </div>
          <div className={"p-col"}>
            <label>
              {formData.outgoing === true &&
              <>Vevő</>
              }
              {formData.outgoing === false &&
              <>Szállító</>
              }
            </label>
            <h3 className={"p-m-0 p-p-0"}>
              {formData.partnerName}
            </h3>
            {formData.partnerAddressPostalCode}&nbsp;{formData.partnerAddressCity}
            <br/>
            {formData.partnerAddressDetail}
            {formData.partnerVatNumber && formData.partnerVatNumber !== null &&
              <>
                <br/>
                Adószám: {formData.partnerVatNumber}
              </>
            }
            {formData.partnerCommunityVatNumber && formData.partnerCommunityVatNumber !== null &&
            <>
              <br/>
              Közösségi adószám: {formData.companyCommunityVatNumber}
            </>
            }
          </div>
        </div>
        <table className={"p-mt-3 w-100"} cellPadding={'5px'} cellSpacing={0}>
          <tr>
            <th className={"p-text-normal p-text-uppercase p-text-left"} style={{borderBottom: '1px solid #ccc'}}>
              Mennyiség
            </th>
            <th className={"p-text-normal p-text-uppercase"} style={{borderBottom: '1px solid #ccc'}}>
              Nettó egységár
            </th>
            <th className={"p-text-normal p-text-uppercase"} style={{borderBottom: '1px solid #ccc'}}>
              ÁFA
            </th>
            <th className={"p-text-normal p-text-uppercase"} style={{borderBottom: '1px solid #ccc'}}>
              Nettó érték
            </th>
            <th className={"p-text-normal p-text-uppercase"} style={{borderBottom: '1px solid #ccc'}}>
              ÁFA érték
            </th>
            <th className={"p-text-normal p-text-uppercase p-text-right"} style={{borderBottom: '1px solid #ccc'}}>
              Bruttó érték
            </th>
          </tr>
          {formData.items && formData.items[0] ? (
            [formData.items.map((item, index) => {
              return (
                <>
                  <tr>
                    <td colSpan={6}>
                      <strong>
                        {item.description}
                      </strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {item.quantity}&nbsp;{item.unitName}
                    </td>
                    <td className={"p-text-right"}>
                      {priceFormatter(item.unitPrice)}
                    </td>
                    <td>

                    </td>
                    <td className={"p-text-right"}>
                      {priceFormatter(item.netAmount)}
                    </td>
                    <td className={"p-text-right"}>
                      {priceFormatter(item.vatAmount)}
                    </td>
                    <td className={"p-text-right"}>
                      {priceFormatter(item.grossAmount)}
                    </td>
                  </tr>
                </>
              )
            })]
          ) : (
            <tr>
              <td colSpan={6}>
                <small>Nincs tétel rögzítve.</small>
              </td>
            </tr>
          )}
          <tr>
            <th colSpan={3} className={"p-text-left"} style={{borderTop: '1px solid #ccc'}}>
              <strong>
                Összesen
              </strong>
            </th>
            <th className={"p-text-right"} style={{borderTop: '1px solid #ccc'}}>
              <strong>
                {priceFormatter(formData.netAmount)}
              </strong>
            </th>
            <th className={"p-text-right"} style={{borderTop: '1px solid #ccc'}}>
              <strong>
                {priceFormatter(formData.vatAmount)}
              </strong>
            </th>
            <th className={"p-text-right"} style={{borderTop: '1px solid #ccc'}}>
              <strong>
                {priceFormatter(formData.grossAmount)}
              </strong>
            </th>
          </tr>
        </table>

        <div className={"p-grid p-mt-3"}>
          {formData.projectSummary && formData.projectSummary[0] &&
          <div className={"p-col"}>
            <table className={"w-100"}>
              <tr>
                <th className={"p-text-normal p-text-uppercase p-text-left"} style={{borderBottom: '1px solid #ccc'}}>
                  Projekt
                </th>
                <th className={"p-text-normal p-text-uppercase p-text-right"} style={{borderBottom: '1px solid #ccc'}}>
                  Összesen
                </th>
              </tr>
              {formData.projectSummary && formData.projectSummary[0] &&
              [formData.projectSummary.map((item, index) => {
                return (
                  <>
                    <tr>
                      <td>
                        {item.projectName}
                      </td>
                      <td className={"p-text-right p-text-nowrap"}>
                        {priceFormatter(item.totalNetAmount)}
                      </td>
                    </tr>
                  </>
                )
              })]
              }
            </table>
          </div>
          }
          {formData.costTypeSummary && formData.costTypeSummary[0] &&
          <div className={"p-col"}>
            <table className={"w-100"}>
              <tr>
                <th className={"p-text-normal p-text-uppercase p-text-left"} style={{borderBottom: '1px solid #ccc'}}>
                  Költségnem
                </th>
                <th className={"p-text-normal p-text-uppercase p-text-right"} style={{borderBottom: '1px solid #ccc'}}>
                  Összesen
                </th>
              </tr>
              {formData.costTypeSummary && formData.costTypeSummary[0] &&
                [formData.costTypeSummary.map((item, index) => {
                  return (
                    <>
                      <tr>
                        <td>
                          {item.costTypeName}
                        </td>
                        <td className={"p-text-right p-text-nowrap"}>
                          {priceFormatter(item.totalNetAmount)}
                        </td>
                      </tr>
                    </>
                    )
                })]
              }
            </table>
          </div>
          }
        </div>

        <p className={"p-mt-3"}>
          <strong>
            Megjegyzés:
          </strong>
          {formData.partnerNote}
        </p>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(ReceiptShowComponent);
