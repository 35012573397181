import axios from 'axios'

export default function ProductImportService(formData) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/product/import",
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  })
}
