import axios from "axios";

export default function SafetyWorktypeAddService(formData, safetyId) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/safety/" + safetyId + "/worktypes",
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
