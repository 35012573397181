import axios from "axios";

export default function ProjectMaterialListService(projectId) {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/project/" + projectId + "/productMaterial",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
