import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {Panel} from "primereact/panel";
import {TabView, TabPanel} from 'primereact/tabview';
import {Dialog} from "primereact/dialog";

import CompanyFormComponent from "../../components/company/CompanyFormComponent";
import CompanyListComponent from "../../components/company/CompanyListComponent";
import CosttypeFormComponent from "../../components/costtype/CosttypeFormComponent";
import CosttypeListComponent from "../../components/costtype/CosttypeListComponent";
import PaymentmethodFormComponent from "../../components/paymentmethod/PaymentmethodFormComponent";
import PaymentmethodListComponent from "../../components/paymentmethod/PaymentmethodListComponent";
import UnitFormComponent from "../../components/unit/UnitFormComponent";
import UnitListComponent from "../../components/unit/UnitListComponent";
import ReceiptgroupFormComponent from "../../components/receiptgroup/ReceiptgroupFormComponent";
import ReceiptgroupListComponent from "../../components/receiptgroup/ReceiptgroupListComponent";
import UserListComponent from "../../components/user/UserListComponent";
import UserFormComponent from "../../components/user/UserFormComponent";
import ProjectstatusFormComponent from "../../components/projectstatus/ProjectstatusFormComponent"
import ProjectstatusListComponent from "../../components/projectstatus/ProjectstatusListComponent";
import ProjecttypeFormComponent from "../../components/projecttype/ProjecttypeFormComponent";
import ProjecttypeListComponent from "../../components/projecttype/ProjecttypeListComponent";
import RolegroupListComponent from "../../components/rolegroup/RolegroupListComponent";
import RolegroupFormComponent from "../../components/rolegroup/RolegroupFormComponent";
import EntrylocationListComponent from "../../components/entrylocation/EntrylocationListComponent";
import EntrylocationFormComponent from "../../components/entrylocation/EntrylocationFormComponent";
import EntrylocationOwnerInviteComponent from "../../components/entrylocation/EntrylocationOwnerInviteComponent";
import EntrylocationShowComponent from "../../components/entrylocation/EntrylocationShowComponent";
import IdentifierListComponent from "../../components/identifier/IdentifierListComponent";
import IdentifierFormComponent from "../../components/identifier/IdentifierFormComponent";
import ManufacturerListComponent from "../../components/manufacturer/ManufacturerListComponent";
import WarehouseListComponent from "../../components/warehouse/WarehouseListComponent";
import ManufacturerFormComponent from "../../components/manufacturer/ManufacturerFormComponent";
import WarehouseFormComponent from "../../components/warehouse/WarehouseFormComponent";
import CalendartypeListComponent from "../../components/calendartype/CalendartypeListComponent";
import CalendartypeFormComponent from "../../components/calendartype/CalendartypeFormComponent";
import EmployeenationalityListComponent from "../../components/employeenationality/EmployeenationalityListComponent";
import EmployeepositionFormComponent from "../../components/employeeposition/EmployeepositionFormComponent";
import EmployeenationalityFormComponent from "../../components/employeenationality/EmployeenationalityFormComponent";
import ActivitygroupListComponent from "../../components/activitygroup/ActivitygroupListComponent";
import ActivitygroupFormComponent from "../../components/activitygroup/ActivitygroupFormComponent";
import ActivitysubgroupFormComponent from "../../components/activitysubgroup/ActivitysubgroupFormComponent";
import DocumenttypeListComponent from "../../components/documenttype/DocumenttypeListComponent";
import DocumenttypeFormComponent from "../../components/documenttype/DocumenttypeFormComponent";
import DocumenttemplateListComponent from "../../components/documenttemplate/DocumenttemplateListComponent";
import DocumenttemplateFormComponent from "../../components/documenttemplate/DocumenttemplateFormComponent";

export const SettingsDashboardPage = () => {

  useEffect(() => {
    window.App.pageHeight()
    document.title = localStorage.getItem('module_settings') + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('settings')[0]) {
        document.getElementsByClassName('settings')[0].classList.add("activeMenu")
      }
    }, 1000)
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_settings') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_entry_location_read')
                || localStorage.getItem('roles').toString().includes('role_entry_location_description') ? (
                  <TabPanel header="Projektek"
                            toggleable
                            collapsed={localStorage.getItem('panel_entrylocations')}
                            onExpand={() => {
                              localStorage.removeItem('panel_entrylocations')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_entrylocations', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <EntrylocationListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_identifier_read') ? (
                  <TabPanel header="Azonosítók"
                            toggleable
                            collapsed={localStorage.getItem('panel_identifiers')}
                            onExpand={() => {
                              localStorage.removeItem('panel_identifiers')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_identifiers', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <IdentifierListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_user_read') ? (
                  <TabPanel header="Felhasználók"
                            toggleable
                            collapsed={localStorage.getItem('panel_users')}
                            onExpand={() => {
                              localStorage.removeItem('panel_users')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_users', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <UserListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_company_read') ? (
                  <TabPanel header="Vállalkozások"
                            toggleable
                            collapsed={localStorage.getItem('panel_companies')}
                            onExpand={() => {
                              localStorage.removeItem('panel_companies')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_companies', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <CompanyListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_role_group_read') ? (
                  <TabPanel header="Szerepkörök"
                            toggleable
                            collapsed={localStorage.getItem('panel_rolegroups')}
                            onExpand={() => {
                              localStorage.removeItem('panel_rolegroups')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_rolegroups', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <RolegroupListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_cost_type_read') ? (
                  <TabPanel header="Költségnemek"
                            toggleable
                            collapsed={localStorage.getItem('panel_costtypes')}
                            onExpand={() => {
                              localStorage.removeItem('panel_costtypes')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_costtypes', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <CosttypeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_payment_method_read') ? (
                  <TabPanel header="Fizetési módok"
                            toggleable
                            collapsed={localStorage.getItem('panel_paymentmethods')}
                            onExpand={() => {
                              localStorage.removeItem('panel_paymentmethods')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_paymentmethods', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <PaymentmethodListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_unit_read') ? (
                  <TabPanel header="Mennyiségi egységek"
                            toggleable
                            collapsed={localStorage.getItem('panel_units')}
                            onExpand={() => {
                              localStorage.removeItem('panel_units')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_units', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <UnitListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_receipt_group_read') ? (
                  <TabPanel header="Számlatömbök"
                            toggleable
                            collapsed={localStorage.getItem('panel_receiptgroups')}
                            onExpand={() => {
                              localStorage.removeItem('panel_receiptgroups')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_receiptgroups', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <ReceiptgroupListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header="Projekt státuszok"
                            toggleable
                            collapsed={localStorage.getItem('panel_projectstatuses')}
                            onExpand={() => {
                              localStorage.removeItem('panel_projectstatuses')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_projectstatuses', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <ProjectstatusListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header="Projekt típusok"
                            toggleable
                            collapsed={localStorage.getItem('panel_projecttypes')}
                            onExpand={() => {
                              localStorage.removeItem('panel_projecttypes')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_projecttypes', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <ProjecttypeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_document_type_read') ? (
                  <TabPanel header="Dokumentum típusok"
                            toggleable
                            collapsed={localStorage.getItem('panel_codumenttypes')}
                            onExpand={() => {
                              localStorage.removeItem('panel_codumenttypes')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_codumenttypes', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <DocumenttypeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_document_template_read') ? (
                  <TabPanel header="Dokumentum sablonok"
                            toggleable
                            collapsed={localStorage.getItem('panel_dumenttemplates')}
                            onExpand={() => {
                              localStorage.removeItem('panel_dumenttemplates')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_dumenttemplates', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <DocumenttemplateListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_manufacturer_read') ? (
                  <TabPanel header="Gyártók"
                            toggleable
                            collapsed={localStorage.getItem('panel_manufacturers')}
                            onExpand={() => {
                              localStorage.removeItem('panel_manufacturers')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_manufacturers', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <ManufacturerListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_warehouse_read') ? (
                  <TabPanel header="Raktárak"
                            toggleable
                            collapsed={localStorage.getItem('panel_warehouse')}
                            onExpand={() => {
                              localStorage.removeItem('panel_warehouse')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_warehouse', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <WarehouseListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_event_type_read') ? (
                  <TabPanel header="Esemény típusok"
                            toggleable
                            collapsed={localStorage.getItem('panel_calendartypes')}
                            onExpand={() => {
                              localStorage.removeItem('panel_calendartypes')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_calendartypes', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <CalendartypeListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_employee_nationality_read') ? (
                  <TabPanel header="Állampolgárságok"
                            toggleable
                            collapsed={localStorage.getItem('panel_employeenationalities')}
                            onExpand={() => {
                              localStorage.removeItem('panel_employeenationalities')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_employeenationalities', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <EmployeenationalityListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
                {localStorage.getItem('roles').toString().includes('role_activity_group_read') ? (
                  <TabPanel header="Tevékenységek"
                            toggleable
                            collapsed={localStorage.getItem('panel_activitygroup')}
                            onExpand={() => {
                              localStorage.removeItem('panel_activitygroup')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_activitygroup', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <ActivitygroupListComponent/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled/>
                )}
              </TabView>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {localStorage.getItem('roles').toString().includes('role_entry_location_create') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                    <a onClick={() => window.App.setState({sidebarEntrylocationCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új projekt
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_identifier_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                    <a onClick={() => window.App.setState({sidebarIdentifierCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új azonosító
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_company_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarCompanyCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új vállalkozás
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_user_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarUserCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új felhasználó
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_role_group_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarRolegroupCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új szerepkör
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_cost_type_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarCosttypeCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új költségnem
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_payment_method_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarPaymentmethodCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új fizetési mód
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_unit_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarUnitCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új mennyiségi egység
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_receipt_group_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarReceiptgroupCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új számlatömb
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_project_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarProjectstatusCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új projekt státusz
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_project_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarProjecttypeCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új projekt típus
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_document_type_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarDocumenttypeCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új dokumentum típus
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_document_template_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarDocumenttemplateCreate: true})}
                          className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új dokumentum sablon
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_manufacturer_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarManufacturerCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új gyártó
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_warehouse_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarWarehouseCreate: true})}
                          className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új raktár
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_event_type_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarCalendartypeCreate: true})}
                          className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új esemény típus
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_employee_nationality_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                    <a onClick={() => window.App.setState({sidebarEmployeenationalityCreate: true})}
                          className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új állampolgárság
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_activity_group_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                    <a onClick={() => window.App.setState({sidebarActivitygroupCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új tevékenység
                      </Card>
                    </a>
                  </div>
                  }
                </div>
                {/*<Chart type="bar"*/}
                {/*       options={{*/}
                {/*         animation: {*/}
                {/*           duration: 0*/}
                {/*         },*/}
                {/*         plugins: {*/}
                {/*           legend: {*/}
                {/*             labels: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           }*/}
                {/*         },*/}
                {/*         scales: {*/}
                {/*           x: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*           y: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*         }*/}
                {/*       }}*/}
                {/*       data={{*/}
                {/*         labels: ['Belépett', 'Kilépett', 'Bent tartózkodik', 'Visszautasított'],*/}
                {/*         datasets: [*/}
                {/*           {*/}
                {/*             label: 'Összes azonosítás: 134',*/}
                {/*             data: [30, 50, 32, 12],*/}
                {/*             backgroundColor: [*/}
                {/*               "#42A5F5",*/}
                {/*               "#66BB6A",*/}
                {/*               "#FFA726",*/}
                {/*               "#F8f8f8"*/}
                {/*             ]*/}
                {/*           }*/}
                {/*         ]*/}
                {/*       }}/>*/}
                {/*<h3 className={"p-text-center p-text-normal"}>Riport</h3>*/}
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                <span className={"p-text-light"}
                      dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarCompanyCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCompanyCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új vállalkozás</h2>
            <CompanyFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCompanyUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCompanyUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Vállalkozás módosítása</h2>
            <CompanyFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUserCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUserCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új felhasználó</h2>
            <UserFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUserUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarUserUpdate: false,
                       useEffect: true,
                     })
                   }}>
            <h2 className={"text-primary"}>Felhasználó módosítása</h2>
            <UserFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarRolegroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarRolegroupCreate: false,
                       useEffect: true,
                     })
                   }}>
            <h2 className={"text-primary"}>Új szerepkör</h2>
            <RolegroupFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarRolegroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarRolegroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Szerepkör módosítása</h2>
            <RolegroupFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCosttypeCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCosttypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új költségnem</h2>
            <CosttypeFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEntrylocationCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEntrylocationCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új projekt</h2>
            <EntrylocationFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEntrylocationUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEntrylocationUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Projekt módosítása</h2>
            <EntrylocationFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEntrylocationShow} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEntrylocationShow: false})
                   }}>
            <h2 className={"text-primary"}>Projekt megtekintése</h2>
            <EntrylocationShowComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Dialog visible={window.App.state.sidebarEntrylocationOwnerInvite}
                  style={{width: '780px'}}
                  header="Fővállalkozó meghívása"
                  modal
                  onHide={() => {
                    window.App.setState({sidebarEntrylocationOwnerInvite: false})
                  }}>
            <EntrylocationOwnerInviteComponent/>
          </Dialog>
          <Sidebar position="left" visible={window.App.state.sidebarIdentifierCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarIdentifierCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új azonosító</h2>
            <IdentifierFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarIdentifierUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarIdentifierUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Azonosító módosítása</h2>
            <IdentifierFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCosttypeUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCosttypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Költségnem módosítása</h2>
            <CosttypeFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPaymentmethodCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarPaymentmethodCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új fizetési mód</h2>
            <PaymentmethodFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPaymentmethodUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarPaymentmethodUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Fizetési mód módosítása</h2>
            <PaymentmethodFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUnitCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUnitCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új mennyiségi egység</h2>
            <UnitFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarUnitUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarUnitUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Mennyiséi egység módosítása</h2>
            <UnitFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptgroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarReceiptgroupCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új számlatömb</h2>
            <ReceiptgroupFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptgroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarReceiptgroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Számlatömb módosítása</h2>
            <ReceiptgroupFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectstatusCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjectstatusCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új projekt státusz</h2>
            <ProjectstatusFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectstatusUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjectstatusUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Projekt státusz módosítása</h2>
            <ProjectstatusFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjecttypeCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjecttypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új projekt típus</h2>
            <ProjecttypeFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjecttypeUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjecttypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Projekt típus módosítása</h2>
            <ProjecttypeFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarDocumenttypeCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarDocumenttypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új dokumentum típus</h2>
            <DocumenttypeFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarDocumenttypeUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarDocumenttypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Dokumentum típus módosítása</h2>
            <DocumenttypeFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarDocumenttemplateCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({sidebarDocumenttemplateCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új dokumentum sablon</h2>
            <DocumenttemplateFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarDocumenttemplateUpdate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({sidebarDocumenttemplateUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Dokumentum sablon módosítása</h2>
            <DocumenttemplateFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarManufacturerCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarManufacturerCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új gyártó</h2>
            <ManufacturerFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarManufacturerUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarManufacturerUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Gyártó módosítása</h2>
            <ManufacturerFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWarehouseCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarWarehouseCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új raktár</h2>
            <WarehouseFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWarehouseUpdate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({sidebarWarehouseUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Raktár módosítása</h2>
            <WarehouseFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCalendartypeCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCalendartypeCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új esemény típus</h2>
            <CalendartypeFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCalendartypeUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarCalendartypeUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Esemény típus módosítása</h2>
            <CalendartypeFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmployeenationalityCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmployeenationalityCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új állampolgárság</h2>
            <EmployeenationalityFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEmployeenationalityUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEmployeenationalityUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Állampolgárság módosítása</h2>
            <EmployeenationalityFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarActivitygroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarActivitygroupCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új tevékenység</h2>
            <ActivitygroupFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarActivitygroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarActivitygroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Tevékenység módosítása</h2>
            <ActivitygroupFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarActivitysubgroupCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarActivitysubgroupCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új altevékenység</h2>
            <ActivitysubgroupFormComponent groupId={window.App.state.props} method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarActivitysubgroupUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarActivitysubgroupUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Altevékenység modosítása</h2>
            <ActivitysubgroupFormComponent groupId={window.App.state.propsGroupId}
                                           initialValues={window.App.state.props} method="post"/>
          </Sidebar>
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          {/*<i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>*/}
          {/*<span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>*/}
          {/*Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.*/}
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(SettingsDashboardPage);
