import axios from "axios";

export default function ProjectmilestoneCreateService(formData, method, projectId) {
  let _formData = {
    ...formData,
    project: {
      id: projectId
    }
  }
  return axios({
    method: method,
    url: process.env.REACT_APP_API_HOST + "/project_milestone/" + projectId,
    data: _formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
