import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import download from "js-file-download";
import moment from "moment";

import TrackListService from "../../services/track/TrackListService";
import TrackExportService from "../../services/track/TrackExportService";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";

const TrackListComponent = (props) => {

  const [track, setTrack] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderTrackList: false})
      TrackListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setTrack(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const deleteTrack = (rowData) => {
    // TrackDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {/*<Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"*/}
        {/*        tooltip="Megtekintés"*/}
        {/*        onClick={() => {*/}
        {/*          window.App.setState({*/}
        {/*            sidebarTrackShow: true,*/}
        {/*            props: rowData*/}
        {/*          })*/}
        {/*        }}/>*/}
        {/*{localStorage.getItem('roles').toString().includes('role_track_write') &&*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger p-mr-2"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteTrack(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
        {/*{localStorage.getItem('roles').toString().includes('role_track_write') &&*/}
        {/*<Button icon="pi pi-envelope" className=" p-button-success p-mr-2"*/}
        {/*        tooltip="Meghívás"*/}
        {/*        onClick={() => {*/}
        {/*          TrackInviteService(rowData.id)*/}
        {/*            .then(response => {*/}
        {/*              window.App.toastShow('success', 'Sikeres meghívás!',);*/}
        {/*            })*/}
        {/*            .catch(error => {*/}
        {/*              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);*/}
        {/*            })*/}
        {/*        }}/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  const exportTacks = () => {
    window.App.setState({loadingEntrylogExport: true})
    TrackExportService(JSON.stringify(lazyParams))
      .then(response => {
        window.App.toastShow('success', 'Sikeres export készítés!', '')
        download(response.data, localStorage.getItem('networkName')
          + '_'
          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
          + '.xlsx')
        window.App.setState({loadingEntrylogExport: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.response)
        window.App.setState({loadingEntrylogExport: false})
      })
  }

  const [dateFilterSelected, setDateFilterSelected] = useState();
  const createdAtFilterElement = <>
    <Calendar dateFormat="yy-mm-dd" locale={"hu"} selectionMode="range" value={dateFilterSelected}
              onChange={e => {
                if (!e.value) {
                  return
                }
                setDateFilterSelected(e.value);
                dt.current.filter(moment.utc(e.value[0]).local().format('YYYY-MM-DD'), 'createdAtFrom');
                if (e.value[1]) {
                  dt.current.filter(moment.utc(e.value[1]).local().format('YYYY-MM-DD'), 'createdAtUntil');
                }
              }}/>
    </>


  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderTrackList === true])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={track} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        {/*<Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>*/}
        <Column field="projectName" header="Projekt" sortable filter filterPlaceholder="Projekt"></Column>
        <Column field="employeeName" header="Munkavállaló" sortable filter filterPlaceholder="Munkavállaló"></Column>
        <Column field="createdAt" header="Időpont" sortable
                body={(rowData) => {
                  return (
                    <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD hh:mm')}</>
                  )
                }}
                filter
                filterElement={createdAtFilterElement}></Column>
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          <Button type="button" label="Export XLS" icon={"pi pi-download"}
                  onClick={exportTacks}
                  className="p-button-secondary p-ml-2"/>
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(TrackListComponent);
