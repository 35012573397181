import axios from "axios";

export default function ReceiptDeleteService(receiptId) {
  return axios({
    method: 'delete',
    url: process.env.REACT_APP_API_HOST + "/receipt/" + receiptId + "/delete",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
