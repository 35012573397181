import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import {Card} from "primereact/card";
import {Chart} from "primereact/chart";
import {Button} from "primereact/button";
import EntrylogCountByZoneService from "../../services/entrylog/EntrylogCountByZoneService";

export const _ReportDashboardPage_Entryfish = (props) => {

  // chart
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [totCount, setTotCount] = useState(0);


  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Kimutatások' + ' - ' + process.env.REACT_APP_TITLE;
    if (document.getElementsByClassName('activeMenu')[0]) {
      document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
    }
    if (document.getElementsByClassName('report')[0]) {
      document.getElementsByClassName('report')[0].classList.add("activeMenu")
    }

    if (localStorage.getItem('networkId')) {
      EntrylogCountByZoneService()
        .then(response => {
          let chartData = []
          let chartLabels = []
          let tot = 0
          Object.entries(response.data).map(function (data, index) {
            if (data && data[1] && data[1].count && data[1].name) {
              tot = tot + Number(data[1].count)
              chartData.push(data[1].count)
              chartLabels.push(data[1].name)
            }
          })
          setChartData(chartData)
          setChartLabels(chartLabels)
          setTotCount(tot)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }
  }, []);

  return (
    <>
      <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
        <div className={"p-grid p-d-flex p-flex-wrap"}>
          <div className={"p-col-12 p-lg-8 p-xl-8 p-p-5 left p-position-relative"}>

            <div className={"p-text-center p-my-5 p-p-5 right text-light"}
                 style={{maxWidth: '950px', margin: '0 auto', borderRadius: '3px', backgroundColor: 'white'}}>
              <div className={"p-grid p-d-flex p-flex-wrap"}>
                <div className={"p-col p-d-flex"}>
                  <a className={"w-100 text-light"}>
                    <Card className={"p-text-center w-100 shadow-none text-light"} style={{backgroundColor: '#11d8d8'}}>
                      <i className="pi pi-id-card p-d-block p-mb-2"
                         style={{'fontSize': '2em'}}></i>
                      <strong>Belépettek</strong> letöltése
                    </Card>
                  </a>
                </div>
                <div className={"p-col p-d-flex"}>
                  <a className={"w-100 text-light"}>
                    <Card className={"p-text-center w-100 shadow-none text-light"} style={{backgroundColor: '#11d8d8'}}>
                      <i className="pi pi-shield p-d-block p-mb-2"
                         style={{'fontSize': '2em'}}></i>
                      <strong>Munkavédelmi bejegyzések</strong> letöltése
                    </Card>
                  </a>
                </div>
                <div className={"p-col p-d-flex"}>
                  <a className={"w-100"}>
                    <Card className={"p-text-center w-100 shadow-none text-light"} style={{backgroundColor: '#11d8d8'}}>
                      <i className="pi pi-clone p-d-block p-mb-2"
                         style={{'fontSize': '2em'}}></i>
                      <strong>Projekt kimutatás</strong> leöltése
                    </Card>
                  </a>
                </div>
                <div className={"p-col p-d-flex"}>
                  <a className={"w-100"}>
                    <Card className={"p-text-center w-100 shadow-none text-light"} style={{backgroundColor: '#19386e'}}>
                      <i className="pi pi-user p-d-block p-mb-2"
                         style={{'fontSize': '2em'}}></i>
                      <strong>Munkaidő</strong>
                    </Card>
                  </a>
                </div>
                <div className={"p-col p-d-flex"}>
                  <a className={"w-100"}>
                    <Card className={"p-text-center w-100 shadow-none text-light"} style={{backgroundColor: '#19386e'}}>
                      <i className="pi pi-chart-line p-d-block p-mb-2"
                         style={{'fontSize': '2em'}}></i>
                      <strong>Belépési napló</strong>
                    </Card>
                  </a>
                </div>
              </div>
            </div>

          </div>
          <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>

            <div className={"p-text-center p-pt-5 w-75"}>
              {localStorage.getItem('roles').toString().includes('role_entry_log_live') &&
              <div className={"p-text-center"}>
                {chartData && chartData[0] &&
                <>
                  <Chart type="doughnut"
                         options={{
                           animation: {
                             duration: 0
                           },
                           plugins: {
                             legend: {
                               labels: {
                                 color: '#FFF'
                               }
                             }
                           }
                         }}
                         data={{
                           labels: chartLabels,
                           datasets: [
                             {
                               data: chartData,
                               backgroundColor: [
                                 "#11D8D8",
                                 "#D9C309",
                                 "#64748A",
                                 "#F3F4F5",
                                 "#11D8D8",
                                 "#D9C309",
                                 "#64748A",
                                 "#F3F4F5",
                                 "#11D8D8",
                                 "#D9C309",
                                 "#64748A",
                                 "#F3F4F5",
                                 "#11D8D8",
                                 "#D9C309",
                                 "#64748A",
                                 "#F3F4F5",
                                 "#11D8D8",
                                 "#D9C309",
                                 "#64748A",
                                 "#F3F4F5",
                                 "#11D8D8",
                                 "#D9C309",
                                 "#64748A",
                                 "#F3F4F5",
                                 "#11D8D8",
                                 "#D9C309",
                                 "#64748A",
                                 "#F3F4F5",
                                 "#11D8D8",
                                 "#D9C309",
                                 "#64748A",
                                 "#F3F4F5",
                               ]
                             }
                           ]
                         }} className={"p-mt-5"}/>
                </>
                }
                <h3 className={"p-text-center p-text-normal p-mt-4 p-mb-0"}>Területen tartozkódok
                  száma: {totCount} fő</h3>
                <Button className={"p-button-secondary p-button-sm p-mt-2 p-text-light"}
                        loading={window.App.state.loadingEntrylogCountByZone}
                        icon={"pi pi-refresh"}
                        label={"Frissítés"}
                        onClick={() => {
                          window.App.setState({loadingEntrylogCountByZone: true})
                          EntrylogCountByZoneService()
                            .then(response => {
                              let chartData = []
                              let chartLabels = []
                              let tot = 0
                              Object.entries(response.data).map(function (data, index) {
                                if (data && data[1] && data[1].count && data[1].name) {
                                  tot = tot + Number(data[1].count)
                                  chartData.push(data[1].count)
                                  chartLabels.push(data[1].name)
                                }
                              })
                              setChartData(chartData)
                              setChartLabels(chartLabels)
                              setTotCount(tot)
                              window.App.setState({loadingEntrylogCountByZone: false})
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString());
                              window.App.setState({loadingEntrylogCountByZone: false})
                            })
                        }
                        } />
              </div>
              }
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(_ReportDashboardPage_Entryfish);
