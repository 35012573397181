import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import GuestListService from "../../services/guest/GuestListService";
import GuestDeleteService from "../../services/guest/GuestDeleteService";

const GuestListComponent = (props) => {

  const [guest, setGuest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderGuestList: false})
      GuestListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setGuest(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteGuest = (rowData) => {
    GuestDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_guest_write') &&
        <>
          {rowData.allowed === false ? (
            <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                    tooltip="Módosítás"
                    onClick={() => {
                      window.App.setState({
                        sidebarGuestUpdate: true,
                        props: rowData
                      })
                    }}/>
          ) : (
            <Button icon="pi pi-pencil" className="disabled p-button-sm  p-button-warning p-mr-2"
                    tooltip="Módosítás"
            />
          )}
        </>
        }
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarGuestShow: true,
                    props: rowData
                  })
                }}/>
        {/*{localStorage.getItem('roles').toString().includes('role_guest_write') &&*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger p-mr-2"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteGuest(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  const [allowedFilterSelected, setAllowedFilterSelected] = useState();
  const allowedFilterOptions = [
    {label: 'Jóváhagyva', value: true},
    {label: 'Tiltva', value: false},
  ];
  const allowedFilterOnChange = (event) => {
    dt.current.filter(event.value, 'allowed', 'equals');
    setAllowedFilterSelected(event.value);
  }
  const allowedFilterElementTemplate = <Dropdown
    value={allowedFilterSelected}
    options={allowedFilterOptions}
    onChange={allowedFilterOnChange}
    className="p-column-filter allowed-column-filter"
    showClear
  />
  const allowedBodyTemplate = (data) => {
    if (data.allowed === true) return <span>Engedélyezve</span>;
    else return <span>Tiltva</span>;
  }

  const [documentValidFilterSelected, setDocumentValidFilterSelected] = useState();
  const documentValidFilterOptions = [
    {label: 'Igen', value: true},
    {label: 'Nem', value: false},
  ];
  const documentValidFilterOnChange = (event) => {
    dt.current.filter(event.value, 'documentValid', 'equals');
    setDocumentValidFilterSelected(event.value);
  }
  const documentValidFilterElementTemplate = <Dropdown
    value={documentValidFilterSelected}
    options={documentValidFilterOptions}
    onChange={documentValidFilterOnChange}
    className="p-column-filter documentValid-column-filter"
    showClear
  />
  const documentValidBodyTemplate = (data) => {
    if (data.documentValid === true) return <span>Igen</span>;
    else return <span>Nem</span>;
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderGuestList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={guest} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      ref={dt}
      filterDisplay="row"
responsiveLayout="scroll"
      stripedRows
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
      <Column field="companyName" header="Cég" sortable filter filterPlaceholder="Cég"></Column>
      {/*<Column field="organisation" header="Szervezet" sortable filter filterPlaceholder="Szervezet"></Column>*/}
      {/*<Column field="entryLocationName" header="Projekt" sortable*/}
      {/*        //filter filterPlaceholder="Projekt"*/}
      {/*></Column>*/}
      <Column field="allowed" header="Jóváhagyva" body={allowedBodyTemplate} sortable
        //filter filterElement={allowedFilterElementTemplate}
              className={"p-text-nowrap"}></Column>
      <Column field="documentValid" header="Dokumentum ellenőrizve" body={documentValidBodyTemplate} sortable
        //filter filterElement={documentValidFilterElementTemplate}
              className={"p-text-nowrap"}></Column>
      <Column field="active" header="Felhasznált" sortable body={(rowData) => {
        return (
          <>
            {rowData.active === true ? (
              <>Nem</>
            ) : (
              <>Igen</>
            )}
          </>
        )
      }}></Column>
    </DataTable>

  )
}
export default withTranslation('common')(GuestListComponent);
