import axios from "axios";

export default function ReceiptFileUploadService(file, receiptId) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/receipt/" + receiptId + "/uploadExternalFile",
    data: {file: file},
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
