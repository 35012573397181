import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';

export const ReportDashboardPage = (props) => {


  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Kimutatások' + ' - ' + process.env.REACT_APP_TITLE;
    if (document.getElementsByClassName('activeMenu')[0]) {
      document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
    }
    if (document.getElementsByClassName('report')[0]) {
      document.getElementsByClassName('report')[0].classList.add("activeMenu")
    }
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
    REPORT
    </div>
  )
}
export default withTranslation('common')(ReportDashboardPage);
