import axios from "axios";

export default function CompanyLogoUploadService(companyId, file) {
  return axios({
    method: "put",
    url: process.env.REACT_APP_API_HOST + "/company/upload_logo",
    data: {
      company: Number(companyId),
      logo: file
    },
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
