import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import {Dropdown} from "primereact/dropdown";
import ReceiptDeliverynoteInvoiceService from "../../services/receipt/ReceiptDeliverynoteInvoiceService";

const ReceiptDeliverynoteInvoiceComponent = (props) => {

  //form
  const [formData, setFormData] = useState({});
  const [receiptgroups, setReceiptgroups] = useState([]);
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    if (!data.receiptGroup) {
      errors.receiptGroup = 'Számlatömb megadása kötelező';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    ReceiptDeliverynoteInvoiceService(window.App.state.props.id, data)
      .then(response => {
        window.App.toastShow('success', 'Sikeres számla létrehozás!');
        setLoading(false)
        window.App.setState({
          sidebarReceiptUpdate: true,
          dialogReceiptDeliverynoteInvoiceCreate: false,
          props: {
            id: response.data.receiptId,
            external: true,
          },
        })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    ReceiptgroupListService(JSON.stringify({
      filters: {
        receiptType: {
          value: 'invoice'
        },
        outgoing: {
          value: false
        },
        external: {
          value: true
        }
      }
    }))
      .then(response => {
        setReceiptgroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <Field name="receiptGroup" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="receiptGroup"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Célszámlatömb</label>
                  <span className="p-input-icon-right">
                  {formData.receiptGroup && formData.receiptGroup.id ? (
                    <Dropdown {...input}
                              value={formData.receiptGroup.id}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  receiptGroup: {id: e.value}
                                })
                              }}
                              options={receiptgroups}
                              optionLabel="name"
                              optionValue="id"
                              id={"receiptGroup"}
                              emptyMessage={"Még nincs szállítólevél típusú számlatömb..."}
                              className={classNames({'p-error': isFormFieldValid(meta)})}
                              showClear
                    />
                  ):(
                    <Dropdown {...input}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  receiptGroup: {id: e.value}
                                })
                              }}
                              options={receiptgroups}
                              optionLabel="name"
                              optionValue="id"
                              id={"receiptGroup"}
                              emptyMessage={"Még nincs szállítólevél típusú számlatömb..."}
                              className={classNames({'p-error': isFormFieldValid(meta)})}
                              showClear
                    />
                  )}
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Tovább a költségszámlára" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ReceiptDeliverynoteInvoiceComponent);
