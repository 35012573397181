import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {InputSwitch} from "primereact/inputswitch";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import {BlockUI} from "primereact/blockui";
import {Editor} from "primereact/editor";

import EntrylocationFormService from '../../services/entrylocation/EntrylocationFormService'
import CompanyListService from "../../services/company/CompanyListService";
import EntrylocationAddCompanyService from "../../services/entrylocation/EntrylocationAddCompanyService";
import EntrylocationListCompanyService from "../../services/entrylocation/EntrylocationListCompanyService";
import EntrylocationInviteCompanyService from "../../services/entrylocation/EntrylocationInviteCompanyService";
import EntrylocationOwnerInviteComponent from "./EntrylocationOwnerInviteComponent";
import {AutoComplete} from "primereact/autocomplete";
import EntrylocationSubcontractorInviteComponent from "./EntrylocationSubcontractorInviteComponent";

const EntrylocationFormComponent = (props) => {

  const [formData, setFormData] = useState({
    active: true,
    company: {
      id: Number(localStorage.getItem('defaultCompanyId'))
    }
  });
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const validate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'Az megnevezés nem lehet üres';
    }
    if (!data.company || !data.company.id || data.company.id === null) {
      errors.company = 'Vállalkozás kiválasztása kötelező!';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues || formData.id) {
      EntrylocationFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({rerenderEntrylocationList: true})
          if (formData.closeForm === true) {
            window.App.setState({
              sidebarEntrylocationUpdate: false,
              sidebarEntrylocationCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      EntrylocationFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({rerenderEntrylocationList: true})
          setFormData(response.data)
          if (formData.closeForm === true) {
            window.App.setState({
              sidebarEntrylocationCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const [companyFormData, setCompanyFormData] = useState({}); // set on dialog open!

  const companyActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_entry_location_subcontractor_invite') &&
        <Button icon="pi pi-envelope" className="p-button-sm  p-button-info p-mr-2"
                type="button"
                onClick={() => {
                  window.App.setState({dialogEntrylocationSubcontractor: true})
                  setCompanyFormData({
                    ...companyFormData,
                    companyId: rowData.id
                  })
                  window.App.setState({props: formData.id})
                }}/>
        }
      </React.Fragment>
    );
  }

  // company autocomplete
  const [companiesAC, setCompaniescompaniesAC] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchEntryLocation = (event) => {
    setSelectedCompany(event.query.replace('Nincs találat', '').replace(': ', ''))
    CompanyListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCompaniescompaniesAC(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedCompany(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // pager
  const [rows, setRows] = useState(10);
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    if (props && props.initialValues) {
      setFormData(props.initialValues)
      setSelectedCompany(props.initialValues.companyName)
    }
    CompanyListService()
      .then(response => {
        setCompanies(response.data.items)
        if (props && props.initialValues) {
          EntrylocationListCompanyService(props.initialValues.id, JSON.stringify({
            first: 0,
            rows: 9999,
            page: 0,
          }))
            .then(response => {
              window.App.setState({dataEntrylocationSubcontractors: response.data.items})
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            {localStorage.getItem('roles').toString().includes('role_entry_location_create') &&
            <>
              <div className={"p-col-12 p-md-6 p-lg-6"}>
                <Field name="name" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="name"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                    <span className="p-input-icon-right">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-md-4 p-lg-4"}>
                <Field name="company" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="company"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>
                    <span className="p-input-icon-right">
                    {localStorage.getItem('roles').toString().includes('role_company_browse')
                    || localStorage.getItem('roles').toString().includes('role_company_read') ? (
                      <AutoComplete value={selectedCompany}
                                    id={"company"}
                                    suggestions={companiesAC}
                                    // forceSelection
                                    completeMethod={searchEntryLocation}
                                    field={"name"}
                                    delay="500"
                                    placeholder={"Keresés gépeléssel..."}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                    onSelect={(e) => {
                                      setFormData({
                                        ...formData,
                                        company: {id: e.value.id}
                                      })
                                      setSelectedCompany(e.value.name)
                                      localStorage.setItem('defaultCompanyId', e.value.id)
                                      localStorage.setItem('defaultCompanyName', e.value.name)
                                    }}
                                    dropdown
                                    onClear={(e) => {
                                      setSelectedCompany('')
                                    }}
                      />
                    ) : (
                      <Dropdown disabled className={"disabled"} id={"company"}/>
                    )}

                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-md-2 p-lg-2"}>
                <Field name="active" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="active"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>
                    <span className="p-input-icon-right">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.active}
                      value={formData.active}
                      onChange={e => setFormData({...formData, active: e.target.value})}/>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </>
            }
            {localStorage.getItem('roles').toString().includes('role_entry_location_description') &&
            <>
              <div className={"p-col-12"}>
                <Field name="description" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="description"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Leírás</label>
                    <span className="p-input-icon-right">
                    <Editor style={{height: '320px'}} value={formData.description}
                            onTextChange={(e) => setFormData({...formData, description: e.htmlValue})}/>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </>
            }
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              {(props && props.initialValues || formData.id) && localStorage.getItem('roles').toString().includes('role_entry_location_invite') &&
              <Button type="button" label="Meghívás" icon={"pi pi-envelope"}
                      className="p-button-info"
                      onClick={() => {
                        window.App.setState({props: props.initialValues.id})
                        window.App.setState({dialogEntrylocationOwner: true})
                      }}
              />
              }
              {formData.id &&
              <Button type="button" label="Megtekintés" icon={"pi pi-eye"}
                      onClick={() => {
                        window.App.setState({
                          props: formData,
                          sidebarEntrylocationUpdate: false,
                          sidebarEntrylocationCreate: false,
                          sidebarEntrylocationShow: true,
                        })
                      }}
                      className="p-button p-ml-2"/>
              }
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"}
                      loading={loading}
                      onClick={() => {
                        setFormData({...formData, closeForm: true})
                      }}
                      className="p-button-success"/>
              <Button type="submit" label="Mentés" icon={"pi pi-check"}
                      loading={loading}
                      onClick={() => {
                        setFormData({...formData, closeForm: false})
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
        </Panel>
        {/*<BlockUI blocked={Boolean(!formData.id)} template={<i className="pi pi-lock" style={{'fontSize': '3rem'}}/>}>*/}
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Közreműködők
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          {(formData && formData.id) || (props.initialValues && props.initialValues.id) ? (
            <>
              {window.App.state.dataEntrylocationSubcontractors && window.App.state.dataEntrylocationSubcontractors[0] &&
              <DataTable value={window.App.state.dataEntrylocationSubcontractors}
                         emptyMessage="Nincs találat."
                         autoLayout={true}
                         filterDisplay="row"
responsiveLayout="scroll"
                         stripedRows
                         className={"p-mb-3"}
                         paginator
                         paginatorTemplate={pager}
                         rows={rows}
              >
                {/*<Column body={companyActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>*/}
                <Column field="companyName" header="Név"></Column>
              </DataTable>
              }
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
                  {window.App.state.dataEntrylocationSubcontractors && !window.App.state.dataEntrylocationSubcontractors[0] &&
                  <>Nincs közreműködő.</>
                  }
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                  {localStorage.getItem('roles').toString().includes('role_entry_location_subcontractor_invite') &&
                  <Button type="button" label="Meghívás" icon="pi pi-envelope" className="p-button-info"
                          onClick={() => {
                            window.App.setState({dialogEntrylocationSubcontractor: true})
                            setCompanyFormData({
                              ...companyFormData,
                              companyId: formData.id
                            })
                            window.App.setState({props: formData.id})
                          }}

                  />
                  }
                </div>
              </div>
            </>
          ) : (
            <><i className={"pi pi-lock"}></i> A közreműködők megtekintése csak mentés után lehetséges!</>
          )}
        </Panel>
        {/*</BlockUI>*/}
        <Dialog visible={window.App.state.dialogEntrylocationOwner}
                style={{width: '750px'}}
                header="Fővállalkozó meghívása"
                modal
                onHide={() => {
                  window.App.setState({dialogEntrylocationOwner: false})
                }}>
          <EntrylocationOwnerInviteComponent/>
        </Dialog>
        <Dialog visible={window.App.state.dialogEntrylocationSubcontractor}
                style={{width: '750px'}}
                header="Közreműködő meghívása"
                modal
                onHide={() => {
                  window.App.setState({dialogEntrylocationSubcontractor: false})
                  EntrylocationListCompanyService(formData.id)
                    .then(response => {
                      window.App.setState({dataEntrylocationSubcontractors: response.data.items})
                    })
                    .catch(error => {
                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                    })
                }}>
          <EntrylocationSubcontractorInviteComponent/>
        </Dialog>
      </form>
    )}/>
  )
}
export default withTranslation('common')(EntrylocationFormComponent);
