import axios from "axios";

export default function CompanycontactFormService(formData, method, companyId, contactId) {
  let url = process.env.REACT_APP_API_HOST + "/companyContact/" + companyId
  if(contactId) url = process.env.REACT_APP_API_HOST + "/companyContact/" + companyId + "/" + contactId
  return axios({
    method: method,
    url: url,
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
