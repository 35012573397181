import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Calendar} from "primereact/calendar";
import {addLocale} from 'primereact/api';
import {AutoComplete} from "primereact/autocomplete";
import moment from "moment";
import download from 'js-file-download';

import EntryzoneListService from "../../services/entryzone/EntryzoneListService";
import EntrylogExportService from "../../services/entrylog/EntrylogExportService";
import EntrylogWorktimereportDownloadService from "../../services/entrylog/EntrylogWorktimereportDownloadService";

const EntrylogWorktimereportFormComponent = (props) => {

  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.warehouseFrom) {
    //   errors.warehouseFrom = 'A raktár nem lehet üres';
    // }
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    let _createdAtFrom
    if (data.createdAtFrom) {
      _createdAtFrom = data.createdAtFrom
    }
    let _createdAtUntil
    if (data.createdAtUntil) {
      _createdAtUntil = data.createdAtUntil
    }
    let _entryZoneName
    if (data.entryZoneName) {
      _entryZoneName = data.entryZoneName
    }
    EntrylogWorktimereportDownloadService(JSON.stringify({
      filters: {
        createdAtFrom: {
          value: _createdAtFrom
        },
        createdAtUntil: {
          value: _createdAtUntil
        },
        entryZoneName: {
          value: _entryZoneName
        }
      }
    }))
      .then(response => {
        window.App.toastShow('success', 'Sikeres letöltés!', '')
        download(response.data, localStorage.getItem('networkName')
          + '_'
          +
          'munkaido_kimutatas'
          + '_'
          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
          + '.xlsx')
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!');
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // entryZone autocomplete
  const [entryZones, setEntryZones] = useState([]);
  const [selectedEntryZone, setSelectedEntryZone] = useState();

  const searchEntryZone = (event) => {
    setSelectedEntryZone(event.query.replace('Nincs találat', '').replace(': ', ''))
    EntryzoneListService(JSON.stringify({
      filters: {
        name: {
          value: event.query.replace('Nincs találat', '').replace(': ', '')
        }
      }
    }))
      .then(response => {
        setEntryZones(response.data.items)
        if (response.data && response.data.items && response.data.items[0]) {
          setEntryZones(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedEntryZone(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit}>
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={"w-100"}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="createdAtFrom" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="createdAtFrom"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Időpont kezdete</label>
                  <span className="p-input-icon-right">
                        {formData && formData.createdAtFrom && (formData.createdAtFrom !== null || formData.createdAtFrom !== undefined) ? (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    showTime
                                    hideOnDateTimeSelect="true"
                                    hourFormat="24"
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        setFormData({
                                          ...formData,
                                          createdAtFrom: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                                    value={new Date(formData.createdAtFrom)}
                            //mask="9999-99-99"
                                    id={"createdAtFrom"}
                                    locale={"hu"}/>
                        ) : (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    hideOnDateTimeSelect="true"
                                    showTime
                                    hourFormat="24"
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        setFormData({
                                          ...formData,
                                          createdAtFrom: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                                    viewDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                    id={"createdAtFrom"}
                                    locale={"hu"}/>
                        )}
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="createdAtUntil" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="createdAtUntil"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Időpont vége</label>
                  <span className="p-input-icon-right">
                        {formData && formData.createdAtUntil && (formData.createdAtUntil !== null || formData.createdAtUntil !== undefined) ? (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    showTime
                                    hideOnDateTimeSelect="true"
                                    hourFormat="24"
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        setFormData({
                                          ...formData,
                                          createdAtUntil: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                                    value={new Date(formData.createdAtUntil)}
                            //mask="9999-99-99"
                                    id={"createdAtUntil"}
                                    locale={"hu"}/>
                        ) : (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    hideOnDateTimeSelect="true"
                                    showTime
                                    hourFormat="24"
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        setFormData({
                                          ...formData,
                                          createdAtUntil: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                                    viewDate={new Date(new Date().setHours(0, 0, 0, 0))}
                                    id={"createdAtUntil"}
                                    locale={"hu"}/>
                        )}
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="entryZone" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="entryZone"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Zóna</label>
                  <span className="p-input-icon-right">
                              <AutoComplete value={selectedEntryZone}
                                            id={"entryZone"}
                                            suggestions={entryZones}
                                            // forceSelection
                                            completeMethod={searchEntryZone}
                                            field={"name"}
                                            delay="500"
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                entryZoneName: e.value.name,
                                              })
                                              setSelectedEntryZone(e.value.name)
                                            }}
                                            dropdown
                                            onClear={(e) => {
                                              setSelectedEntryZone('')
                                            }}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Letöltés" icon="pi pi-download" loading={loading}
                      className="p-button-secondary"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(EntrylogWorktimereportFormComponent);
