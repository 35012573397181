import axios from "axios";

export default function ReceiptBatchService(formData) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/receipt/batch",
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
