import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import moment from "moment";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

import IdentitydocumenttypeListService from "../../services/identitydocumenttype/IdentitydocumenttypeListService";
import GuestAllowService from "../../services/guest/GuestAllowService";
import GuestDocumentValidService from "../../services/guest/GuestDocumentValidService";
import GuestShowService from "../../services/guest/GuestShowService";
import EntrycheckFormComponent from "../entrycheck/EntrycheckFormComponent";
import EmployeeInviteService from "../../services/employee/EmployeeInviteService";
import IdentifierByGuestListService from "../../services/identifier/IdentifierByGuestListService";

const GuestShowComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [identifiers, setIdentifiers] = useState([]);
  const [loadingInvite, setLoadingInvite] = useState(false);
  const [loadingAllow, setLoadingAllow] = useState(false);
  const [loadingValidate, setLoadingValidate] = useState(false);

  const [identitydocumenttypes, setIdentitydocumenttypes] = useState([]);
  const identityDocumentType = (id) => {
    if (identitydocumenttypes && identitydocumenttypes[0]) {
      let identitydocumenttype = identitydocumenttypes.filter(item => item.id === id)
      if(identitydocumenttype && identitydocumenttype[0] && identitydocumenttype[0].name) {
        return identitydocumenttype[0].name
      }
    }
  }

  useEffect(() => {
    IdentitydocumenttypeListService()
      .then(response => {
        setIdentitydocumenttypes(response.data)
      })
    GuestShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    IdentifierByGuestListService('', props.initialValues.id)
      .then(response => {
        setIdentifiers(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Alapadatok
            </h3>
          </div>
        </div>
      } className={""}>
        <div className={"p-grid"}>
          {formData.name &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Név</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.name}
            </p>
          </div>
          }
          {/*{formData.organisation &&*/}
          {/*<div className={"p-col-12 p-lg-4 p-xl-4"}>*/}
          {/*  <label>Szervezet</label>*/}
          {/*  <p className={"p-mt-1 p-mb-0 p-text-bold"}>*/}
          {/*    {formData.organisation}*/}
          {/*  </p>*/}
          {/*</div>*/}
          {/*}*/}
          {formData.email &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Email</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.email}
            </p>
          </div>
          }
          {formData.note &&
          <div className={"p-col-12 p-lg-8 p-xl-8"}>
            <label>Megjegyzés</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.note}
            </p>
          </div>
          }
        </div>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-id-card"}></i> Azonosító
            </h3>
          </div>
        </div>
        <div className={"p-grid"}>
          {formData.identityDocumentType &&
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            <label>Azonosító dokumentum</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {identityDocumentType(formData.identityDocumentType)}: {formData.identityDocumentNumber && <>{formData.identityDocumentNumber}</>}
            </p>
          </div>
          }
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            <label>Dokumentum ellenőrizve</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.documentValid === true ? (
                <>Igen</>
              ) : (
                <>Nem</>
              )}
            </p>
          </div>
        </div>
        <div className={"p-grid p-mt-1"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-sitemap"}></i> Vendéglátó
            </h3>
          </div>
        </div>
        <div className={"p-grid"}>
          {formData.companyName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Cég</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.companyName}
            </p>
          </div>
          }
          {formData.entryLocationName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Projekt</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.entryLocationName}
            </p>
          </div>
          }
          {formData.active &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label htmlFor="email">Felhasznált</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.active === true ? (
                <>Nem</>
              ) : (
                <>Igen</>
              )}
            </p>
          </div>
          }
        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-8 p-xl-8"}>
            {/*{loadingInvite === true ?(*/}
            {/*  <Button type="button" label="Meghívás" loading loadingIcon="pi pi-spin pi-spinner" className="p-button-success p-mr-2"/>*/}
            {/*):(*/}
            {/*  <Button type="button" label="Meghívás" icon="pi pi-envelope" className="p-button-info p-mr-2"*/}
            {/*          onClick={() => {*/}
            {/*            EmployeeInviteService(formData.id)*/}
            {/*              .then(response => {*/}
            {/*                setLoadingInvite(false)*/}
            {/*                window.App.toastShow('success', 'Sikeres meghívás!',);*/}
            {/*              })*/}
            {/*              .catch(error => {*/}
            {/*                setLoadingInvite(false)*/}
            {/*                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);*/}
            {/*              })*/}
            {/*          }}*/}
            {/*  />*/}
            {/*)}*/}
            {localStorage.getItem('roles').toString().includes('role_guest_allow') &&
            <>
              {formData.allowed === true || loadingAllow === true ? (
                <Button icon="pi pi-qrcode" className="p-button-success p-mr-2 disabled"
                        label="Jóváhagyás"/>
              ) : (
                <Button icon="pi pi-qrcode" className="p-button-success p-mr-2"
                        label="Jóváhagyás"
                        onClick={() => {
                          setLoadingAllow(true)
                          GuestAllowService(formData.id)
                            .then(response => {
                              window.App.toastShow('success', 'Sikeres engedélyezés!');
                              window.App.setState({
                                rerenderGuestList: true
                              })
                              GuestShowService(formData.id)
                                .then(response => {
                                  setFormData(response.data)
                                  setLoadingAllow(false)
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                })
                            })
                            .catch(error => {
                              setLoadingAllow(false)
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        }}
                />
              )}
            </>
            }
            {localStorage.getItem('roles').toString().includes('role_guest_document_valid') &&
            <>
              {formData.documentValid === true || loadingValidate === true || formData.allowed === false ? (
                <Button type="button" icon="pi pi-id-card" className="p-button-success p-mr-2 disabled"
                        label="Ellenőrzés"
                />
              ) : (
                <Button type="button" icon="pi pi-id-card" className="p-button-success p-mr-2"
                        label="Ellenőrzés"
                        onClick={() => {
                          setLoadingValidate(true)
                          GuestDocumentValidService(formData.id)
                            .then(response => {
                              window.App.toastShow('success', 'Sikeres dokumentum ellőrizés!',);
                              window.App.setState({
                                rerenderGuestList: true
                              })
                              GuestShowService(formData.id)
                                .then(response => {
                                  setFormData(response.data)
                                  setLoadingValidate(false)
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                })
                            })
                            .catch(error => {
                              setLoadingValidate(false)
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            })
                        }}
                />
              )}
            </>
            }
          </div>
          <div className={"p-col-12 p-lg-4 p-xl-4 p-text-right"}>
            {localStorage.getItem('roles').toString().includes('role_guest_write') &&
            <>
              {formData.id && formData.allowed === false ? (
                <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                        onClick={() => {
                          window.App.setState({
                            sidebarGuestShow: false,
                            sidebarGuestUpdate: true,
                            props: formData
                          })
                        }}
                />
              ) : (
                <Button type="button" label="Módosítás" icon="pi pi-pencil" className="disabled p-button-warning"/>
              )}
            </>
            }
          </div>
        </div>
      </Panel>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Azonosítók
            </h3>
          </div>
        </div>
      } className={"p-mt-3"}>
        <div className={"p-grid"}>
          {identifiers && identifiers[0] ? (
            [identifiers.map((identifier, index) => {
              return (
                <div className={"p-col p-fluid p-text-center"} style={{maxWidth: '120px'}}>
                  <img src={process.env.REACT_APP_API_HOST + "/identifier/qr/" + identifier.id + "?token="
                  + localStorage.getItem("userToken")} className={"w-100"}/>
                  {localStorage.getItem('api_host').toString().includes('test') &&
                  <Button onClick={() => {
                    window.App.setState({
                      props: identifier.identifier,
                      sidebarEntrycheck: true
                    })
                  }} label={"Teszt"}/>
                  }
                  {process.env.NODE_ENV === 'development' &&
                  <Button onClick={() => {
                    window.App.setState({
                      props: identifier.identifier,
                      sidebarEntrycheck: true
                    })
                  }} label={"Teszt"}/>
                  }
                </div>
              )
            })]
          ) : (
            <div className={"p-col-12"}>Még nincs hozzárendelve azonosító...</div>
          )}
        </div>
      </Panel>
      <Dialog visible={window.App.state.sidebarEntrycheck}
              style={{width: '750px'}}
              header="Belépés tesztelése"
              modal
              onHide={() => {
                window.App.setState({sidebarEntrycheck: false})
              }}>
        <EntrycheckFormComponent identifier={window.App.state.props}/>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(GuestShowComponent);
