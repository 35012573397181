import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import ProjectitemFormService from '../../services/projectitem/ProjectitemFormService'
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import UnitListService from "../../services/unit/UnitListService";

const ProjectitemFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [units, setUnits] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.projectActionName) {
    //   errors.projectActionName = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (window.App.state.projectitemFormMethod && window.App.state.projectitemFormMethod === 'update') {
      ProjectitemFormService(data, 'put', props.projectId)
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderProjectitemList: true,
            sidebarProjectitemUpdate: false,
            dialogProjectitem: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ProjectitemFormService(data, 'post', props.projectId)
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderProjectitemList: true,
            sidebarProjectitemCreate: false,
            dialogProjectitem: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    UnitListService()
      .then(response => {
        setUnits(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      setFormData(
        props.initialValues
      )
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-10"}>
              <Field name="projectActionName" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="projectActionName"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-input-icon-right">
                      <InputText id="projectActionName" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     projectActionName: e.target.value
                                   })
                                 }}
                                 value={formData.projectActionName}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2"}>
              <Field name="serial" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="serial"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Sorszám</label>
                  <span className="p-input-icon-right">
                      <InputText id="serial" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     serial: e.target.value
                                   })
                                 }}
                                 value={formData.serial}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <Field name="unit" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unit"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Egység</label>
                  <span className="p-input-icon-right">
                              {formData && formData.unit && formData.unit.id ? (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          value={formData.unit.id}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          showClear
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setFormData({
                                              ...formData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          showClear
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />)}
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <Field name="quantity" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="quantity"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség</label>
                  <span className="p-input-icon-right">
                              <InputNumber id="quantity" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={formData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4"}>
              <Field name="unitPrice" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="unitPrice"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Egységár</label>
                  <span className="p-input-icon-right">
                              <InputNumber id="unitPrice" {...input}
                                           locale="hu-HU"
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               unitPrice: e.value
                                             })
                                           }}
                                           value={formData.unitPrice}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4"}>
              <Field name="materialUnitPrice" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="materialUnitPrice"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Anyag esgységár</label>
                  <span className="p-input-icon-right">
                              <InputNumber id="materialUnitPrice" {...input}
                                           locale="hu-HU"
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           onValueChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               materialUnitPrice: e.value
                                             })
                                           }}
                                           value={formData.materialUnitPrice}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProjectitemFormComponent);
