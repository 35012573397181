import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import ActivitygroupListService from "../../services/activitygroup/ActivitygroupListService";
import ScheduleListService from "../../services/schedule/ScheduleListService";
import ReceiptListComponent from "../receipt/ReceiptListComponent";
import ScheduleListComponent from "../schedule/ScheduleListComponent";
import ActivitysubgroupListComponent from "../activitysubgroup/ActivitysubgroupListComponent";
// import ActivitygroupDeleteService from "../../services/activitygroup/ActivitygroupDeleteService";

const ActivitygroupListComponent = (props) => {

  const [activitygroup, setActivitygroup] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  const [expandedRows, setExpandedRows] = useState(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderActivitygroupList: false})
      ActivitygroupListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setActivitygroup(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteActivitygroup = (rowData) => {
    // ActivitygroupDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_activity_group_write') &&
        <>
          <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                  tooltip="Módosítás"
                  onClick={() => {
                    window.App.setState({
                      sidebarActivitygroupUpdate: true,
                      props: rowData
                    })
                  }}/>
          <Button icon="pi pi-plus" className="p-button-sm  p-button-success p-mr-2"
                  tooltip="Új altevékenység"
                  onClick={() => {
                    window.App.setState({
                      sidebarActivitysubgroupCreate: true,
                      props: rowData.id
                    })
                  }}/>

        </>
        }
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteActivitygroup(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }

  const rowExpansionTemplate = (data) => {
    return (
      <>
        <div className="p-ml-3">
          <ActivitysubgroupListComponent groupId={data.id}/>
          {/*<DataTable value={scheduleData}*/}
          {/*           emptyMessage="Nincs találat."*/}
          {/*           autoLayout={true}*/}
          {/*           filterDisplay="row"
responsiveLayout="scroll"*/}
          {/*           stripedRows*/}
          {/*           className={"p-mb-3"}*/}
          {/*>*/}
          {/*  <Column field="name" header="Név"></Column>*/}
          {/*</DataTable>*/}
        </div>
      </>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderActivitygroupList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={activitygroup} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
responsiveLayout="scroll"
      stripedRows
      dataKey="id"
      rowExpansionTemplate={rowExpansionTemplate}
      expandedRows={expandedRows}
      onRowExpand={(e) => {
        //setScheduleData([])
        let id = e.data.id
        setExpandedRows({[id]: true})
        // ScheduleListService(e.data.id)
        //   .then(response => {
        //     setScheduleData(response.data.items)
        //   })
        //   .catch(error => {
        //     window.App.toastShow('error', 'Hiba történt!', error.toString());
        //   })
      }}
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
      <Column expander style={{width: '3em'}}/>
    </DataTable>

  )
}
export default withTranslation('common')(ActivitygroupListComponent);
