import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';

import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Link} from "react-router-dom";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import UserPasswordSetService from "../services/user/UserPasswordSetService";

const PasswordSetPage = () => {

  const [formData, setFormData] = useState({});
  const validate = (data) => {
    let errors = {};
    if (!data.newPassword) {
      errors.newPassword = 'Új jelszót meg kell adni';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setFormData(data);
    UserPasswordSetService(data)
      .then(response => {
        window.App.toastShow('success', 'Sikeres jelszó beállítás!', 'Az új jelszóval be kell jelentkezni.');
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    document.title = 'Új jelszó beállítása' + ' - ' + process.env.REACT_APP_TITLE;
    document.getElementById('body').classList.add('outside')
    setFormData({
      ...formData,
      passwordReminderHash: window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0]
    })
  }, [formData.passwordReminderHash]);

  return (
    <div className="LoginPage p-component p-p-3">
      <img src={require(".." + process.env.REACT_APP_LOGO_FILE)} className="p-d-block p-my-5 p-mx-auto"/>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-12 p-xl-12"}>
            <h3>
              <i className={"pi pi-user"}></i> Új jelszó beállítása
            </h3>
          </div>
        </div>
      } className={""}>
        <Form onSubmit={onSubmit}
              className="p-d-block p-mt-5 p-mx-auto"
              initialValues={formData}
              validate={validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <Field name="newPassword" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="newPassword"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Új jelszó</label>
                    <span className="p-input-icon-right">
                    <i className="pi pi-envelope"/>
                    <Password id="newPassword" {...input}
                              toggleMask
                              promptLabel={"Kérlek kezd el gépelni a jelszót"}
                              weakLabel={"Gyenge"}
                              mediumLabel={"Közepes"}
                              strongLabel={"Erős"}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  newPassword: e.target.value
                                })
                              }}
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <Link to={"/login"}>Bejelentkezés</Link>
                {process.env.REACT_APP_REGISTRATION === 'enabled' &&
                <>
                  <br/>
                  <Link to={"/registration"}>Regisztráció</Link>
                </>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Jelszó beállítása" className="p-button-success" icon={"pi pi-check"} disabled={submitting}/>
              </div>
            </div>
            <div className={"p-text-center p-mt-3"}>
              <span className={""} dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
            </div>
          </form>
        )}/>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(PasswordSetPage);
