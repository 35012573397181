import axios from "axios";

export default function ProjectmilestoneReceiptItemAddService(items, projectId, receiptItemId) {
  return axios({
    method: 'put',
    url: process.env.REACT_APP_API_HOST + "/project_milestone/" + projectId + "/" + receiptItemId + "/add-receipt",
    data: {receiptItems: items},
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
