import React, {Component, useRef} from 'react';
import {HashRouter as Router, Switch, Route, Link} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {Toast} from 'primereact/toast';
import {Menubar} from 'primereact/menubar';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import Favicon from 'react-favicon';
import {Sidebar} from "primereact/sidebar";

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/bootstrap4-light-purple/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.scss';
import './assets/css/app.css';

import LoginPage from './pages/LoginPage';
import PasswordResetPage from "./pages/PasswordResetPage";
import PasswordSetPage from "./pages/PasswordSetPage";
import DashboardPage from './pages/DashboardPage';
import DashboardEntryfishPage from './pages/_DashboardPage_Entryfish';
import ReportDashboardPage from './pages/report/ReportDashboardPage';
import ReportDashboardEntryfishPage from './pages/report/_ReportDashboardPage_Entryfish';
import PartnerDashboardPage from "./pages/partner/PartnerDashboardPage";
import ProjectDashboardPage from "./pages/project/ProjectDashboardPage";
import EntryDashboardPage from "./pages/entry/EntryDashboardPage";
import FinanceDashboardPage from "./pages/finance/FinanceDashboardPage";
import HrDashboardPage from "./pages/hr/HrDashboardPage";
import PurchaseDashboardPage from "./pages/purchase/PurchaseDashboardPage";
import InventoryDashboardPage from "./pages/inventory/InventoryDashboardPage";
import SettingsDashboardPage from "./pages/settings/SettingsDashboardPage";
import SafetyDashboardPage from "./pages/safety/SafetyDashboardPage";
import ProfileFormComponent from "./components/profile/ProfileFormComponent";
import ManufacturingDashboardPage from "./pages/manufacturing/ManufacturingDashboardPage";

import UserLogoutService from './services/user/UserLogoutService';
import NetworkListService from "./services/network/NetworkListService";
import ModulesListService from "./services/modules/ModulesListService";

if (process.env.REACT_APP_BRAND === 'lbuild') {
  require('./assets/css/lbuild.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}
if (process.env.REACT_APP_BRAND === 'entryfish') {
  require('./assets/css/entryfish.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}
if (process.env.REACT_APP_BRAND === 'brw') {
  require('./assets/css/brw.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}
if (process.env.REACT_APP_BRAND === 'opuseu') {
  require('./assets/css/opuseu.css');
  require('primereact/resources/themes/saga-blue/theme.css');
}

class App extends Component {

  constructor(props) {
    super(props);
    this.toastShow = this.toastShow.bind(this);

    window.App = this;

    this.state = {}

    this.menu = [];
  };

  componentDidMount() {
    this.networkSelect()
    if (window.innerWidth < 1680) {
      localStorage.setItem('scale', 'small')
      document.getElementById('app').classList.add('scale-small')
    } else {
      document.getElementById('app').classList.add('scale-normal')
    }
    localStorage.setItem('api_host', process.env.REACT_APP_API_HOST)
    setTimeout(function () {
      document.getElementById('root').style.display = "block"
    }, 500)
  }

  componentDidUpdate() {
    this.menu = []
    if (localStorage.getItem('module_partner')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_partner'),
          icon: 'pi pi-fw pi-briefcase',
          url: '/#/partner',
          className: 'partner'
        }
      )
    }
    if (localStorage.getItem('module_project')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_project'),
          icon: 'pi pi-fw pi-clone',
          url: '/#/project',
          className: 'project'
        }
      )
    }
    if (localStorage.getItem('module_finance')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_finance'),
          icon: 'pi pi-fw pi-wallet',
          url: '/#/finance',
          className: 'finance'
        }
      )
    }
    if (localStorage.getItem('module_purchase')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_purchase'),
          icon: 'pi pi-database',
          url: '/#/purchase',
          className: 'purchase'
        }
      )
    }
    if (localStorage.getItem('module_inventory')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_inventory'),
          icon: 'pi pi-th-large',
          url: '/#/inventory',
          className: 'inventory'
        }
      )
    }
    if (localStorage.getItem('module_entry')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_entry'),
          icon: 'pi pi-fw pi-id-card',
          url: '/#/entry',
          className: 'entry'
        }
      )
    }
    if (localStorage.getItem('module_occupational_safety')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_occupational_safety'),
          icon: 'pi pi-fw pi-shield',
          url: '/#/safety',
          className: 'safety'
        }
      )
    }
    if (localStorage.getItem('module_hr')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_hr'),
          icon: 'pi pi-fw pi-users',
          url: '/#/hr',
          className: 'hr'
        }
      )
    }
    if (localStorage.getItem('module_maintenance')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_maintenance'),
          icon: 'pi pi-fw pi-tag',
          url: '/#/maintenance',
          className: 'maintenance'
        }
      )
    }
    if (localStorage.getItem('module_manufacturing')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_manufacturing'),
          icon: 'pi pi-fw pi-plus',
          url: '/#/manufacturing',
          className: 'manufacturing'
        }
      )
    }
    if (localStorage.getItem('module_settings')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_settings'),
          icon: 'pi pi-fw pi-cog',
          url: '/#/settings',
          className: 'settings'
        }
      )
    }
    if (localStorage.getItem('module_report')) {
    this.menu.push(
      {
        label: localStorage.getItem('module_report'),
        icon: 'pi pi-fw pi-chart-pie',
        url: '/#/report',
        className: 'report'
      }
    )
    }
    if (localStorage.getItem('module_support')) {
      this.menu.push(
        {
          label: localStorage.getItem('module_support'),
          icon: 'pi pi-fw pi-question-circle',
          url: '/#/support',
          className: 'support'
        }
      )
    }
  }

  pageHeight = () => {
    //tabview sroll
    setTimeout(function () {
      if (document.getElementById('tabScroll')) {
        if (document.getElementById('tabScroll').scrollWidth > document.getElementById('tabScroll').parentElement.offsetWidth) {
          window.App.setState({tabScroll: true})
        } else {
          window.App.setState({tabScroll: false})
        }
      }
    }, 500)

    //page height
    if (window.innerWidth > 1200) {
      let headerHight = 52
      let windowHeight = window.innerHeight
      let pageHeight = windowHeight - headerHight
      if (document.getElementById("page")) {
        document.getElementById("page").getElementsByClassName("DashboardPage")[0].style.cssText
          = "height:" + pageHeight + "px !important";
      }
    }
    window.addEventListener('resize', function (event) {
      if (window.innerWidth > 1200) {
        let headerHight = 52
        let windowHeight = window.innerHeight
        let pageHeight = windowHeight - headerHight
        if (document.getElementById("page")) {
          document.getElementById("page").getElementsByClassName("DashboardPage")[0].style.cssText
            = "height:" + pageHeight + "px !important";
        }
      }
    })
  }

  networkSelect = () => {
    if (localStorage.getItem('userToken')) {
      NetworkListService()
        .then(response => {
          if (response.data && response.data[0]) {
            window.App.setState({
              networks: response.data
            })
            if (!localStorage.getItem('networkId') && !localStorage.getItem('networkName')) {
              localStorage.setItem('networkId', response.data[0].id)
              localStorage.setItem('networkName', response.data[0].name)
            }
          }
          ModulesListService()
            .then(response => {
              Object.keys(localStorage)
                .filter(key => key.startsWith("module_"))
                .forEach(key => localStorage
                  .removeItem((key)))
              Object.entries(response.data).map(function (item, index) {
                localStorage.setItem('module_' + item[1].code, item[1].name);
              })
              window.App.forceUpdate()
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString());
            })
          window.App.forceUpdate()
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }
    window.App.forceUpdate()
  }

  toastShow(severity, summary, detail, life, response) {
    if (response && response.data) {
      if (response.status === 400) {
        var elementInvalid = document.getElementsByClassName("p-invalid");
        var elementError = document.getElementsByClassName("p-error");
        while (elementInvalid.length > 0) {
          elementInvalid[0].classList.remove("p-invalid");
          elementError[0].remove();
        }
        const data = response.data
        let detail = ''
        let summary = 'A kijelölt mezők helyes kitöltése kötelező!'
        if (Object.values(response.data)[0]) {
          summary = Object.values(response.data)[0]
        }
        severity = "warn"
        for (const key in data) {
          if (document.getElementById(key)) {
            document.getElementById(key).classList.add("p-invalid")
            document.getElementById(key).parentElement.insertAdjacentHTML("afterend", "<small class=\"p-error\">" + data[key] + "</small>")
            document.getElementById(key).addEventListener('click', function handleClick() {
              document.getElementById(key).classList.remove("p-invalid");
              if (document.getElementById(key).parentElement.parentElement.querySelector('.p-error')) {
                document.getElementById(key).parentElement.parentElement.querySelector('.p-error').remove();
              }
            });
          }
          //detail = detail + ' ' + data[key] // nem jön mindenhol értelmes üzenet!
        }
        if (summary = 'Nem található') {
          summary = 'A kijelölt mezők helyes kitöltése kötelező!'
        }
        window.App.toast.show({
          severity: severity,
          summary: summary,
          detail: detail,
          life: 2500
        });
      } else if (response.status === 401) {
        if (localStorage.getItem('userToken') == null) {
          detail = "Hibás adatok! A bejelentkezéshez valós emailcím és jelszó megadása szükséges."
          window.App.toast.show({severity: severity, summary: summary, detail: detail, life: 2500});
        } else {
          detail = "Lejárt munkamenet miatt a rendszer kilépett."
          severity = "warn"
          summary = "A továbblépéshez be kell jelentkezni!"
          UserLogoutService()
          window.App.toast.show({severity: severity, summary: summary, detail: detail, life: 2500});
        }
      } else if (response.status === 404) {
        summary = "Nem található!"
        detail = response.data.detail
        window.App.toast.show({severity: severity, summary: summary, detail: detail, life: 2500});
      } else if (response.status === 403) {
        summary = "Jogosultság nem megfelelő!"
        severity = "warn"
        detail = 'Az adatok betöltése sikertelen.'
        window.App.toast.show({severity: severity, summary: summary, detail: detail, life: 2500});
      } else if (response.status === 409 || response.status === 500) {
        summary = "Hoppá! Váratlan hiba történt!"
        detail = response.data.detail
        window.App.toast.show({severity: severity, summary: summary, detail: detail, life: 2500});
      } else {
        window.App.toast.show({severity: severity, summary: summary, detail: detail, life: 2500});
      }
    } else if (detail && detail.toString().includes("401") || detail && detail.toString().includes("Authentication")) {
      detail = "Lejárt munkamenet miatt a rendszer kilépett."
      severity = "warn"
      summary = "A továbblépéshez be kell jelentkezni!"
      UserLogoutService()
      window.App.toast.show({severity: severity, summary: summary, detail: detail, life: 2500});
    } else {
      window.App.toast.show({severity: severity, summary: summary, detail: detail, life: 2500});
    }
    /*
    if (response && response.data) {
      detail = '';
      var elements = document.getElementsByClassName("p-invalid");
      while (elements.length > 0) {
        elements[0].classList.remove("p-invalid");
      }
      if (document.getElementById(Object.keys(response.data)[0])) {
        document.getElementById(Object.keys(response.data)[0]).classList.add("p-invalid")
      }
      detail += ' ' + Object.values(response.data)[0] + ' '
      if (!Object.values(response.data)[0]) {
        detail = 'Váratlan szerver hiba!'
      }
      if (Object.values(response.data)[0] && Object.values(response.data)[0].toString().includes('tools.ietf.org')) {
        detail = 'Váratlan szerver hiba!'
      }
    }
    if (!life) {
      life = 2500
    }
    if (detail && detail.toString().includes("500")) {
      detail = 'Váratlan szerver hiba!'
    }
    if (detail && detail.toString().includes("401") || detail && detail.toString().includes("Authentication")) {
      detail = "Lejárt munkamenet miatt a rendszer kilépett."
      severity = "warn"
      summary = "A továbblépéshez be kell jelentkezni!"
      UserLogoutService()
    }
    if (severity === 'error') {
      if (detail.toString().includes('object')) {
        detail = 'Váratlan szervehiba!'
      }
      life = 5000
      summary = detail
      detail = "Ügyfélszolgálatunk a(z) " + process.env.REACT_APP_DEVOPS_CONTACT + " címen örömmel segít megoldani minden problémát."
    }
    if ((severity === 'error' || severity === 'warn') && document.getElementsByClassName("p-toast-detail")[0]) {
      let displayed = document.getElementsByClassName("p-toast-detail")
      Object.entries(displayed).map(function (toast, index) {
        if (toast[1].innerText.includes(detail)) {

        } else {
          window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
        }
      })
    } else {
      window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
    }
   */
  }

  urlVars = () => {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  render() {
    return (
      <Router>
        <div className={process.env.REACT_APP_BRAND} id="app">
          <Favicon url={require("." + process.env.REACT_APP_FAVICON_FILE)}/>
          <Toast ref={(el) => this.toast = el} position="bottom-right"/>
          {process.env.REACT_APP_ACCESS === 'disabled' ? (
            <>
              <div className="p-component p-p-3 p-text-center">
                <img src={require("." + process.env.REACT_APP_LOGO_FILE)} className="p-d-block p-my-5 p-mx-auto"/>
                <br/>
                <p className={"p-pb-3"}>Karbantartás folyamatban...</p>
              </div>
            </>
          ) : (
            <>
              {localStorage.getItem('userToken') == null ? (
                <Switch>
                  <Route path="/" component={LoginPage} exact/>
                  <Route path="/login/" component={LoginPage}/>
                  {/*{process.env.REACT_APP_REGISTRATION == 'enabled' &&*/}
                  {/*<Route path="/registration/" component={RegistrationPage}/>*/}
                  {/*}*/}
                  <Route path="/password-reset/" component={PasswordResetPage}/>
                  <Route path="/password-set/" component={PasswordSetPage}/>
                </Switch>
              ) : (
                <>
                  {localStorage.getItem('scale') === 'small' &&
                  <style>
                    {"\
                  html{\
                    font-size:12px !important;\
                    transition: 0.2s !important;\
                  }\
                "}
                  </style>
                  }
                  {localStorage.getItem('scale') === 'normal' &&
                  <style>
                    {"\
                  html{\
                    font-size:16px !important;\
                    transition: 0.2s !important;\
                  }\
                "}
                  </style>
                  }
                  {this.state.loading === true ? (
                    <div id="header" className={"p-menubar p-component p-py-0"} style={{minHeight: '52px'}}>
                      <Link to={"/"}>
                        <img src={require("." + process.env.REACT_APP_LOGO_FILE)}
                             width={"120"}/>
                      </Link>
                      <i className="pi pi-spin pi-spinner p-mt-0 p-pt-0 p-ml-4" style={{fontSize: '1.5em'}}></i>
                    </div>
                  ) : (
                    <div id="header">
                      <nav>
                        <>
                          <Menubar
                            style={{minHeight: '52px'}}
                            start={
                              <>
                                <Link to={"/"}>
                                  <img src={require("." + process.env.REACT_APP_LOGO_FILE)}
                                       width={"120"}/>
                                </Link>
                              </>
                            }
                            model={this.menu}
                            className={"p-py-0"}
                            end={
                              <>
                                <div className="p-inputgroup">
                                  {localStorage.getItem('scale') === 'small' &&
                                  <Button icon="pi pi-search-plus"
                                          type="button"
                                          onClick={() => {
                                            localStorage.setItem('scale', 'normal')
                                            document.getElementById('app').classList.add('scale-normal')
                                            document.getElementById('app').classList.remove('scale-small')
                                            window.App.forceUpdate();
                                          }}
                                  />
                                  }
                                  {localStorage.getItem('scale') === 'normal' &&
                                  <Button icon="pi pi-search-minus"
                                          type="button"
                                          onClick={() => {
                                            localStorage.setItem('scale', 'small')
                                            document.getElementById('app').classList.remove('scale-normal')
                                            document.getElementById('app').classList.add('scale-small')
                                            window.App.forceUpdate();
                                          }}
                                  />
                                  }
                                  {!localStorage.getItem('scale') &&
                                  <Button icon="pi pi-search-minus"
                                          type="button"
                                          onClick={() => {
                                            localStorage.setItem('scale', 'small')
                                            window.App.forceUpdate();
                                          }}
                                  />
                                  }
                                  {localStorage.getItem('networkId') &&
                                  <>
                                    <Dropdown options={window.App.state.networks}
                                              optionLabel="name"
                                              optionValue="id"
                                              id={"networkName"}
                                              emptyMessage={"Még nincs céged..."}
                                              onMouseDown={() => {
                                                NetworkListService()
                                                  .then(response => {
                                                    if (response.data && response.data[0]) {
                                                      window.App.setState({
                                                        networks: response.data
                                                      })
                                                    }
                                                  })
                                              }}
                                              onChange={(e) => {
                                                let network = window.App.state.networks.filter(item => item.id === e.value)
                                                localStorage.setItem('networkId', e.value)
                                                localStorage.setItem('networkName', network[0].name)
                                                localStorage.removeItem('defaultCompanyId')
                                                localStorage.removeItem('defaultCompanyName')
                                                localStorage.removeItem('defaultPaymentMethodId')
                                                localStorage.removeItem('defaultPaymentMethodName')
                                                localStorage.removeItem('defaultReceiptGroupId')
                                                localStorage.removeItem('defaultReceiptGroupName')
                                                this.networkSelect();
                                                window.location.replace('/#/');
                                                window.App.forceUpdate()
                                                window.App.setState({
                                                  loading: true
                                                })
                                                setTimeout(function () {
                                                  window.location.reload() // TODO
                                                }, 2500)
                                              }}
                                              value={Number(localStorage.getItem('networkId'))}
                                    />
                                  </>
                                  }
                                  {process.env.REACT_APP_BRAND === 'entryfish' &&
                                  <Button icon="pi pi-info-circle"
                                          type="button"
                                          className={"p-button-highlighted"}
                                          onClick={() => {
                                            window.location.href = require('./assets/files/entryfish/entryfish_felhasznaloi_kezikonyv.pdf');
                                          }}
                                  />
                                  }
                                  {localStorage.getItem("userName") &&
                                  <>
                                    <Button className={'p-button-warning p-text-uppercase'}
                                            icon="pi pi-user"
                                            onClick={() => {
                                              window.App.setState({sidebarProfileUpdate: true})
                                            }}/>
                                    <Button icon="pi pi-fw pi-power-off"
                                            className={'p-button-danger'}
                                            onClick={() => {
                                              if (window.confirm('A kilépés megerősítésére van szükség!')) {
                                                UserLogoutService()
                                              }
                                            }}/>
                                  </>
                                  }
                                </div>
                              </>
                            }
                          />
                        </>
                      </nav>
                    </div>
                  )}
                  <div id="page">
                    {process.env.REACT_APP_BRAND === 'lbuild' &&
                    <Route path="/" component={FinanceDashboardPage} exact/>
                    }
                    {process.env.REACT_APP_BRAND === 'entryfish' &&
                    <Route path="/" component={DashboardEntryfishPage} exact/>
                    //<Route path="/" component={EntryDashboardPage} exact/>
                    }
                    {process.env.REACT_APP_BRAND === 'brw' &&
                    <Route path="/" component={ManufacturingDashboardPage} exact/>
                    }
                    {process.env.REACT_APP_BRAND === 'trackie' &&
                    <Route path="/" component={DashboardPage} exact/>
                    }
                    <Route path="/partner" component={PartnerDashboardPage}/>
                    <Route path="/settings" component={SettingsDashboardPage}/>
                    {process.env.REACT_APP_BRAND === 'entryfish' &&
                    <Route path="/report" component={ReportDashboardEntryfishPage}/>
                    }
                    {process.env.REACT_APP_BRAND !== 'entryfish' &&
                      <Route path="/report" component={ReportDashboardPage}/>
                    }
                    <Route path="/finance" component={FinanceDashboardPage}/>
                    <Route path="/project" component={ProjectDashboardPage}/>
                    <Route path="/entry" component={EntryDashboardPage}/>
                    <Route path="/hr" component={HrDashboardPage}/>
                    <Route path="/safety" component={SafetyDashboardPage}/>
                    <Route path="/purchase" component={PurchaseDashboardPage}/>
                    <Route path="/inventory" component={InventoryDashboardPage}/>
                    <Route path="/manufacturing" component={ManufacturingDashboardPage}/>
                    <Sidebar position="right" visible={window.App.state.sidebarProfileUpdate} closeOnEscape={true}
                             style={{width: '780px'}}
                             onHide={() => {
                               window.App.setState({sidebarProfileUpdate: false})
                             }}>
                      <h2 className={"text-primary"}>Profil módosítása</h2>
                      <ProfileFormComponent/>
                    </Sidebar>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Router>
    );
  }
}

export default withTranslation('common')(App);

