import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from 'primereact/column';
import {InputSwitch} from "primereact/inputswitch";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import {addLocale} from 'primereact/api';
import {FileUpload} from 'primereact/fileupload';
import {Tooltip} from "primereact/tooltip";
import moment from "moment";
import {InputNumber} from "primereact/inputnumber";
import currencyFormatter from "currency-formatter";

import ReceiptFormService from '../../services/receipt/ReceiptFormService'
import ReceiptitemFormService from "../../services/receiptitem/ReceiptitemFormService";
import CompanyListService from "../../services/company/CompanyListService";
import PartnerListService from "../../services/partner/PartnerListService";
import ProductListService from "../../services/product/ProductListService";
import CurrencyListService from "../../services/currency/CurrencyListService";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import PaymentmethodListService from "../../services/paymentmethod/PaymentmethodListService";
import VatListService from "../../services/vat/VatListService";
import UnitListService from "../../services/unit/UnitListService";
import CosttypeListService from "../../services/costtype/CosttypeListService";
import ProjectListService from "../../services/project/ProjectListService";
import PartnerFormComponent from "../partner/PartnerFormComponent";
import ReceiptShowService from "../../services/receipt/ReceiptShowService";
import ReceiptitemDeleteService from "../../services/receiptitem/ReceiptitemDeleteService";
import PaymentmethodShowService from "../../services/paymentmethod/PaymentmethodShowService";
import {Sidebar} from "primereact/sidebar";
import ReceiptStornoService from "../../services/receipt/ReceiptStornoService";
import ReceiptFileUploadService from "../../services/receipt/ReceiptFileUploadService";
import ReceiptFileGetService from "../../services/receipt/ReceiptFileGetService";
import ReceiptFileDeleteService from "../../services/receipt/ReceiptFileDeleteService";
import ReceiptDeleteService from "../../services/receipt/ReceiptDeleteService";

const ReceiptFormComponent = (props) => {

  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false);
  const [paymentMethodIsPaid, setPaymentMethodIsPaid] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [receiptgroups, setReceiptgroups] = useState([]);
  const [vats, setVats] = useState([]);
  const [units, setUnits] = useState([]);
  const [netAmount, setNetAmount] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [grossAmount, setGrossAmount] = useState(0);
  const [totVat, setTotVat] = useState({
    percent: 27,
    name: '27%',
    id: 10
  });
  const [file, setFile] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.company) {
    //   errors.company = 'Kötelező mező';
    // }
    // if (!data.partner) {
    //   errors.partner = 'Kötelező mező';
    // }
    // if (!data.receiptGroup || data.receiptGroup && !data.receiptGroup.id || data.receiptGroup.id === 0) {
    //   errors.receiptGroup = 'Kötelező mező';
    // }
    // if (!data.deliveryDate) {
    //   errors.deliveryDate = 'Kötelező mező';
    // }
    // if (!data.paymentDate) {
    //   errors.paymentDate = 'Kötelező mező';
    // }
    // if (!data.paymentMethod || data.paymentMethod.id === 0) {
    //   errors.paymentMethod = 'Kötelező mező';
    // }
    // if (props.type === 'cost') {
    //   if (!data.issueDate) {
    //     errors.issueDate = 'Kötelező mező';
    //   }
    //   if (!data.receiptNumber) {
    //     errors.receiptNumber = 'Kötelező mező';
    //   }
    //   if (data.receiptNumber && data.receiptNumber.length > 30) {
    //     errors.receiptNumber = 'A sorszám max 30 karakterből állhat';
    //   }
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    if (!data.items || (data.items && !data.items[0])) {
      window.App.toastShow('warn', 'Tételek megadása kötelező', 'Legalább egy tételt szükséges rögzíteni a bizonylaton!', '');
      return false
    }
    setLoading(true)
    setFormData({
      ...formData,
      data,
    });
    delete data['items']

    if ((props && props.initialValues) || formData.id) {
      ReceiptFormService(data, 'put', formData.receiptGroup.id)
        .then(response => {
          if (file !== false) {
            ReceiptFileUploadService(file, formData.id)
              .then(response => {
                console.log('file upload success')
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderReceiptList: true,
            sidebarReceiptUpdate: false,
            newPartnerId: null,
            newPartnerName: null,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ReceiptFormService(data, 'post', formData.receiptGroup.id)
        .then(response => {
          if (file !== false) {
            ReceiptFileUploadService(file, formData.id)
              .then(response => {
                console.log('file upload success')
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderReceiptList: true,
            sidebarReceiptInvoiceCreate: false,
            sidebarReceiptCostCreate: false,
            newPartnerId: null,
            newPartnerName: null,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // items
  const itemValidate = (data) => {
    let errors = {};
    // if (!data.description) {
    //   errors.description = 'Kötelező mező';
    // }
    // if (!data.quantity) {
    //   errors.quantity = 'Kötelező mező';
    // }
    // if (!data.unit) {
    //   errors.unit = 'Kötelező mező';
    // }
    // if (!data.unitPrice) {
    //   errors.unitPrice = 'Kötelező mező';
    // }
    // if (!data.unitPrice) {
    //   errors.unitPrice = 'Kötelező mező';
    // }
    // if (!data.vat) {
    //   errors.vat = 'Kötelező mező';
    // }
    return errors;
  };
  const itemVatBody = (rowData) => {
    if (vats && vats[0]) {
      const vat = vats.filter(vat => vat.id === rowData.vat);
      if (vat && vat[0]) {
        return (
          <>
            {vat[0].name}
          </>
        )
      }
    }
  }
  const unitPriceBody = (rowData) => {
    let currency
    if (formData.currency === 'HUF') {
      currency = {
        precision: 2,
        symbol: '',
        decimal: ',',
        thousand: ' ',
      }
    } else {
      currency = {
        precision: 2,
        symbol: '',
        decimal: '.',
        thousand: ' ',
      }
    }
    return (
      <>
        {currencyFormatter.format(
          rowData.unitPrice, {
            precision: currency.precision,
            symbol: currency.symbol,
            decimal: currency.decimal,
            thousand: currency.thousand,
          }
        )
        }
        &nbsp;{formData.currency}
      </>
    )
  }
  const itemActionBody = (rowData, row) => {
    return (
      <React.Fragment>
        <Button type="button" icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  setItemDialog(true)
                  setItemFormData({...rowData, rowIndex: row.rowIndex})
                  setItemFormMethod('update')
                  setSelectedItemProject(rowData.projectName)
                  setSelectedItemCosttype(rowData.costTypeName)
                }}
        />
        <Button type="button" icon="pi pi-trash" className="p-button-sm  p-button-danger"
                tooltip="Törlés"
                onClick={() => {
                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                    ReceiptitemDeleteService(formData.id, rowData.id)
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres törlés!');
                        ReceiptShowService(formData.id)
                          .then(response => {
                            setFormData(response.data)
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })
                  }
                }}
        />
      </React.Fragment>
    );
  }

  // items
  const [itemDialog, setItemDialog] = useState(false);
  const [itemFormData, setItemFormData] = useState({}); // set on dialog open!
  const [isSummaryOverwritten, setIsSummaryOverwritten] = useState(false);
  const [itemFormMethod, setItemFormMethod] = useState('');

  const itemOnSubmit = (data, form) => {
    setLoading(true)
    let _data = {
      isSummaryOverwritten: isSummaryOverwritten,
      ...data
    }
    let method = ''
    if (itemFormMethod === 'create') {
      method = 'post'
    } else {
      method = 'put'
    }
    ReceiptitemFormService(_data, formData.id, method, itemFormData.id)
      .then(response => {
        ReceiptShowService(formData.id)
          .then(response => {
            setFormData(response.data)
            setLoading(false)
            setItemDialog(false)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            setLoading(false)
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })

  }

  // products autocomplete
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  const searchProducts = (event) => {
    setSelectedProduct(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProductListService(
      {lazyEvent: JSON.stringify(event.name)}
    ).then(response => {
      setProducts(response.data.items)
    });
  }

  // partners autocomplete
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');

  const searchPartners = (event) => {
    setSelectedPartner(event.query.replace('Nincs találat', '').replace(': ', ''))
    PartnerListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}})).then(response => {
      if (response.data && response.data.items && response.data.items[0]) {
        setPartners(response.data.items)
      } else {
        let string
        if (event.query) {
          string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        } else {
          string = 'Nincs találat'
        }
        setSelectedPartner(string)
      }
    });
  }

  // image for cost
  const imageUpload = (files) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      var base64data = reader.result;
      // setFormData({
      //   ...formData,
      //   file: base64data
      // })
      setFile(base64data)
    }
    let file = reader.readAsDataURL(files.files[0]);
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  //if cost
  const [selectedCompany, setSelectedCompany] = useState('');

  const serachCompany = (event) => {
    setSelectedCompany(event.query.replace('Nincs találat', '').replace(': ', ''))
    CompanyListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCompanies(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedCompany(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // paymentMethod autocomplete
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const searchPaymentMethod = (event) => {
    setSelectedPaymentMethod(event.query.replace('Nincs találat', '').replace(': ', ''))
    PaymentmethodListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setPaymentMethods(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedPaymentMethod(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // costtype autocomplete
  const [costtypes, setCosttypes] = useState([]);
  const [selectedCosttype, setSelectedCosttype] = useState('');
  const [selectedItemCosttype, setSelectedItemCosttype] = useState('');

  const searchCosttype = (event) => {
    setSelectedCosttype(event.query.replace('Nincs találat', '').replace(': ', ''))
    CosttypeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCosttypes(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedCosttype(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // project autocomplete
  const [projects, setProjects] = useState([]);
  const [selectedItemProject, setSelectedItemProject] = useState();

  const searchItemProject = (event) => {
    setSelectedItemProject(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProjectListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          let _projects = []
          Object.entries(response.data.items).map(function (project, index) {
            _projects.push({
              name: project[1].projectNumber + " (" + project[1].name + ")",
              id: project[1].id
            })
          })
          setProjects(_projects)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedItemProject(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    setFormData({
      ...formData,
      partner: {id: Number(window.App.state.newPartnerId)}
    })
    setSelectedPartner(window.App.state.newPartnerName)
  }, [window.App.state.newPartnerId])


  useEffect(() => {
    ReceiptgroupListService()
      .then(response => {
        setReceiptgroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    CurrencyListService()
      .then(response => {
        setCurrencies(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    VatListService()
      .then(response => {
        setVats(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    UnitListService()
      .then(response => {
        setUnits(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      ReceiptShowService(props.initialValues.id)
        .then(response => {
          ReceiptFileGetService(props.initialValues.id)
            .then(response => {
              if (response.data && response.data.file && response.data.file !== null) {
                setFile(response.data.file)
              }
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          setSelectedPartner(response.data.partnerName)
          setSelectedCompany(response.data.companyName)
          //setSelectedProject(response.data.projectName)
          setSelectedPaymentMethod(response.data.paymentMethodName)
          setSelectedCosttype(response.data.costTypeName)
          if (props.initialValues.vat) {
            let vatId = response.data.vat
            VatListService()
              .then(response => {
                let vatPercent = response.data.filter(vat => vat.id === vatId);
                setTotVat({
                  percent: Number(vatPercent[0].percentage),
                  name: vatPercent[0].name,
                  id: vatId
                })
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }
          if (props.initialValues.paymentMethod && props.initialValues.paymentMethod.id) {
            PaymentmethodShowService(props.initialValues.paymentMethod.id)
              .then(response => {
                if (response.data.paid === true) {
                  setPaymentMethodIsPaid(true)
                }
              })
              .catch(error => {
                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
              })
          }
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    } else {
      let _formData = {}
      _formData = {
        ..._formData,
        company: {id: Number(localStorage.getItem('defaultCompanyId'))},
        paymentMethod: {id: Number(localStorage.getItem('defaultPaymentMethodId'))},
      }
      // setFormData({
      //   ...formData,
      //   company: {id: Number(localStorage.getItem('defaultCompanyId'))},
      //   paymentMethod: {id: Number(localStorage.getItem('defaultPaymentMethodId'))},
      // })
      setSelectedCompany(localStorage.getItem('defaultCompanyName'))
      setSelectedPaymentMethod(localStorage.getItem('defaultPaymentMethodName'))
      // if (window.App.state.props && window.App.state.props.type === 'cost' && localStorage.getItem('defaultReceiptCostGroupId')) {
      //   _formData = {
      //     ..._formData,
      //     receiptGroup: {id: Number(localStorage.getItem('defaultReceiptCostGroupId'))},
      //   }
      //   // setFormData({
      //   //   ...formData,
      //   //   receiptGroup: {id: Number(localStorage.getItem('defaultReceiptCostGroupId'))},
      //   // })
      // }
      // if (localStorage.getItem('defaultProjectName') && localStorage.getItem('defaultProjectId')) {
      //   setSelectedProject(localStorage.getItem('defaultProjectName'))
      //   _formData = {
      //     ..._formData,
      //     project: {
      //       id: Number(localStorage.getItem('defaultProjectId'))
      //     }
      //   }
      //   // setFormData({
      //   //   ...formData,
      //   //   project: {
      //   //     id: Number(localStorage.getItem('defaultProjectId'))
      //   //   }
      //   // })
      // }
      if (localStorage.getItem('defaultCurrencyCode')) {
        _formData = {
          ..._formData,
          currency: localStorage.getItem('defaultCurrencyCode')
        }
      }
      if (window.App.state.props && window.App.state.props.receiptgroupId) {
        _formData = {
          ..._formData,
          receiptGroup: {
            id: window.App.state.props.receiptgroupId
          }
        }
      }
      setFormData({
        ..._formData,
        currency: "HUF",
        missingContract: false,
        vat: 10
      })
    }
  }, [])

  return (
    <>
      {window.App.state.props && window.App.state.props.name &&
      <h2 className={"text-primary p-mb-0"}>{window.App.state.props.name} {window.App.state.props.operation}</h2>
      }
      {window.App.state.props && window.App.state.props.receiptNumber &&
      <h2 className={"text-primary p-mb-0"}>{window.App.state.props.receiptNumber}</h2>
      }
      <div className={"p-grid p-mb-2"}>
        {window.App.state.props && window.App.state.props.receiptGroupName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Bizonylat tömb
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{window.App.state.props.receiptGroupName}</>
          </p>
        </div>
        }
        {window.App.state.props && window.App.state.props.name &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Bizonylat tömb
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{window.App.state.props.name}</>
          </p>
        </div>
        }
        {window.App.state.props && window.App.state.props.type &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Típus
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            {window.App.state.props.type === 'invoice' && window.App.state.props.outgoing === true &&
            <>Számla</>
            }
            {window.App.state.props.type === 'cost' && window.App.state.props.outgoing === false &&
            <>Költségszámla</>
            }
            {window.App.state.props.type === 'fee_request' &&
            <>Díjbekérő</>
            }
            {window.App.state.props.type === 'delivery_note' &&
            <>Szállítólevél</>
            }
          </p>
        </div>
        }
      </div>
      <Form onSubmit={onSubmit} initialValues={formData}
            validate={validate} render={({handleSubmit, submitting}) => (
        <form onSubmit={handleSubmit} className="">
          <Panel
            //   headerTemplate={
            //   <div className={"p-grid"}>
            //     <div className={"p-col-12"}>
            //       <h3>
            //         <i className={"pi pi-list"}></i> Fej adatok
            //       </h3>
            //     </div>
            //   </div>
            // }
            className={""}>
            <div className={"p-grid"}>
              {((formData && formData.external === true) || (window.App.state.props && window.App.state.props.external === true)) &&
              <div className={"p-col-12 p-lg-2 p-xl-2"}>
                <Field name="receiptNumber" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="costType"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Sorszám</label>
                    <span className="p-input-icon-right">
                        <InputText id="receiptNumber" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       receiptNumber: e.target.value
                                     })
                                   }}
                                   value={formData.receiptNumber}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
              <div className={"p-col"}>
                <Field name="company" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="company"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>
                      {window.App.state.props && window.App.state.props.outgoing === false ? (
                        <>Vevő</>
                      ) : (
                        <>Szállító</>
                      )}
                    </label>
                    <span className="p-input-icon-right">
                      <AutoComplete value={selectedCompany}
                                    id={"company"}
                                    suggestions={companies}
                                    // forceSelection
                                    completeMethod={serachCompany}
                                    field={"name"}
                                    delay="500"
                                    placeholder={"Keresés gépeléssel..."}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                    onSelect={(e) => {
                                      setFormData({
                                        ...formData,
                                        company: {id: e.value.id}
                                      })
                                      setSelectedCompany(e.value.name)
                                      localStorage.setItem('defaultCompanyId', e.value.id)
                                      localStorage.setItem('defaultCompanyName', e.value.name)
                                    }}
                                    dropdown
                                    onClear={(e) => {
                                      setSelectedCompany('')
                                    }}
                      />
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col"}>
                <Field name="partner" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="partner"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>
                      {window.App.state.props && window.App.state.props.outgoing === false ? (
                        <>Szállító</>
                      ) : (
                        <>Vevő</>
                      )}
                    </label>
                    <span className="p-inputgroup">
                              <AutoComplete value={selectedPartner}
                                            id={"partner"}
                                            suggestions={partners}
                                            // forceSelection
                                            completeMethod={searchPartners}
                                            field={"name"}
                                            delay="500"
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                partner: {id: e.value.id}
                                              })
                                              setSelectedPartner(e.value.name)
                                            }}
                                            onClear={(e) => {
                                              setSelectedPartner('')
                                            }}
                              />
                    <Button type="button" className={"p-button"}
                            onClick={() => {
                              window.App.setState({sidebarPartnerCreate: true})
                            }}>
                      <i className={"pi pi-window-maximize"}></i>
                    </Button>
                    <Sidebar position="left" visible={window.App.state.sidebarPartnerCreate}
                             closeOnEscape={true}
                             style={{width: '780px'}}
                             onHide={() => {
                               window.App.setState({sidebarPartnerCreate: false})
                             }}>
                      <h2 className={"text-primary"}>Új partner</h2>
                      <PartnerFormComponent method="post"/>
                    </Sidebar>
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              {window.App.state.props && (window.App.state.props.type === 'invoice' || window.App.state.props.type === 'cost' || window.App.state.props.type === 'fee_request') &&
              <div className={"p-col-12 p-lg-1 p-xl-1"}>
                <Field name="deliveryDate" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="deliveryDate"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Teljesítés</label>
                    <span className="p-input-icon-right">
                    {formData.deliveryDate && (formData.deliveryDate !== null || formData.deliveryDate !== undefined) ? (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                    setFormData({
                                      ...formData,
                                      deliveryDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  } else {
                                    setFormData({
                                      ...formData,
                                      deliveryDate: null
                                    })
                                  }
                                }}
                                value={new Date(formData.deliveryDate)}
                                mask="9999-99-99"
                                id={"deliveryDate"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    ) : (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                    setFormData({
                                      ...formData,
                                      deliveryDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  } else {
                                    setFormData({
                                      ...formData,
                                      deliveryDate: null
                                    })
                                  }
                                }}
                                mask="9999-99-99"
                                id={"deliveryDate"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    )}
                      </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
              {window.App.state.props && window.App.state.props.external === true &&
              <div className={"p-col-12 p-lg-1 p-xl-1"}>
                <Field name="issueDate" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="issueDate"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Kiállítva</label>
                    <span className="p-input-icon-right">
                    {formData.issueDate && (formData.issueDate !== null || formData.issueDate !== undefined) ? (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                    setFormData({
                                      ...formData,
                                      issueDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  } else {
                                    setFormData({
                                      ...formData,
                                      issueDate: null
                                    })
                                  }
                                }}
                                value={new Date(formData.issueDate)}
                                mask="9999-99-99"
                                id={"issueDate"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    ) : (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                    setFormData({
                                      ...formData,
                                      issueDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  } else {
                                    setFormData({
                                      ...formData,
                                      issueDate: null
                                    })
                                  }
                                }}
                                mask="9999-99-99"
                                id={"issueDate"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    )}
                      </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
              {window.App.state.props && (window.App.state.props.type === 'invoice'
                || window.App.state.props.type === 'cost'
                || window.App.state.props.type === 'fee_request') &&
              <div className={"p-col-12 p-lg-1 p-xl-1"}>
                <Field name="paymentDate" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="paymentDate"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Fiz. határidő</label>
                    <span className="p-input-icon-right">
                    {formData.paymentDate && (formData.paymentDate !== null || formData.paymentDate !== undefined) ? (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                    setFormData({
                                      ...formData,
                                      paymentDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  } else {
                                    setFormData({
                                      ...formData,
                                      paymentDate: null
                                    })
                                  }
                                }}
                                value={new Date(formData.paymentDate)}
                                mask="9999-99-99"
                                id={"paymentDate"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    ) : (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                    setFormData({
                                      ...formData,
                                      paymentDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  } else {
                                    setFormData({
                                      ...formData,
                                      paymentDate: null
                                    })
                                  }
                                }}
                                mask="9999-99-99"
                                id={"paymentDate"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    )}
                      </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
              {window.App.state.props && (window.App.state.props.type === 'invoice'
                || window.App.state.props.type === 'cost'
                || window.App.state.props.type === 'fee_request') &&
              <div className={"p-col"}>
                <Field name="currency" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="currency"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Pénznem</label>
                    <span className="p-input-icon-right">
                              <Dropdown {...input}
                                        onChange={(e) => {
                                          setFormData({
                                            ...formData,
                                            currency: e.target.value
                                          })
                                          localStorage.setItem('defaultCurrencyCode', e.target.value)
                                        }}
                                        value={formData.currency}
                                        options={currencies}
                                        optionLabel="code"
                                        optionValue="code"
                                        id={"currency"}
                                        emptyMessage={"Még nincs pénznem..."}
                                        className={classNames({'p-error': isFormFieldValid(meta)})}
                              />
                            </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
              {(formData.external === true || window.App.state.props.external === true) && formData.currency !== 'HUF' &&
              // (window.App.state.props.type === 'invoice'
              //   || window.App.state.props.type === 'cost'
              //   || window.App.state.props.type === 'fee_request') &&
              <div className={"p-col"}>
                <Field name="exchangeRate" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="costType"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Árfolyam</label>
                    <span className="p-input-icon-right">
                        <InputNumber id="exchangeRate" {...input}
                                     minFractionDigits={0}
                                     maxFractionDigits={9}
                                     locale="hu-HU"
                                     onChange={(e) => {
                                       setFormData({
                                         ...formData,
                                         exchangeRate: Number(e.value)
                                       })
                                     }}
                                     value={formData.exchangeRate}
                                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
              {/*<div className={"p-col"}>*/}
              {/*  <Field name="receiptGroup" render={({input, meta}) => (*/}
              {/*    <div className="p-field p-fluid">*/}
              {/*      <label htmlFor="receiptGroup"*/}
              {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Számlatömb</label>*/}
              {/*      <span className="p-input-icon-right">*/}
              {/*        <Dropdown {...input}*/}
              {/*                  disabled*/}
              {/*                  value={window.App.state.props.receiptgroupId}*/}
              {/*                  options={receiptgroups}*/}
              {/*                  optionLabel="name"*/}
              {/*                  optionValue="id"*/}
              {/*                  id={"receiptGroup"}*/}
              {/*                  emptyMessage={"Még nincs számlatömb..."}*/}
              {/*                  className={classNames({'p-error': isFormFieldValid(meta)})}*/}
              {/*        />*/}
              {/*      </span>*/}
              {/*      {getFormErrorMessage(meta)}*/}
              {/*    </div>*/}
              {/*  )}/>*/}
              {/*</div>*/}
              {window.App.state.props && (window.App.state.props.type === 'invoice' || window.App.state.props.type === 'cost' || window.App.state.props.type === 'fee_request') &&
              <div className={"p-col"}>
                <Field name="paymentMethod" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="paymentMethod"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Fizetési mód</label>
                    <span className="p-input-icon-right">
                    <AutoComplete value={selectedPaymentMethod}
                                  id={"paymentMethod"}
                                  suggestions={paymentMethods}
                                  // forceSelection
                                  completeMethod={searchPaymentMethod}
                                  field={"name"}
                                  delay="500"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      paymentMethod: {id: e.value.id}
                                    })
                                    setSelectedPaymentMethod(e.value.name)
                                    if (e.value.paid === true) {
                                      setPaymentMethodIsPaid(true)
                                    }
                                    localStorage.setItem('defaultPaymentMethodId', e.value.id)
                                    localStorage.setItem('defaultPaymentMethodName', e.value.name)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedPaymentMethod('')
                                  }}
                    />
                  </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
            </div>
          </Panel>
          <div className={"p-grid"}>
            <div className={"p-col"}>
              <Panel headerTemplate={
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-list"}></i> Tételek
                    </h3>
                  </div>
                </div>
              } className={"p-mt-3"}>
                {formData.items && formData.items[0] &&
                <DataTable value={formData.items}
                           emptyMessage="Nincs találat."
                           autoLayout={true}
                           filterDisplay="row"
responsiveLayout="scroll"
                           stripedRows
                           className={"p-mb-3"}
                >
                  <Column field="description" header="Leírás"></Column>
                  <Column field="quantity" header="Mennyiség" className={"p-text-right"} body={(rowData) => {
                    return (
                      <>
                        {rowData.quantity} {rowData.unitName}
                      </>
                    )
                  }}></Column>
                  <Column field="unitPrice" header="Egységár" className={"p-text-right p-text-nowrap"}
                          body={unitPriceBody}></Column>
                  <Column field="netAmount" header="Nettó érték" className={"p-text-right p-text-nowrap"}
                          body={(rowData) => {
                            let currency
                            if (formData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  rowData.netAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )
                                }
                                &nbsp;{formData.currency}
                              </>
                            )
                          }}></Column>
                  <Column field="vatAmount" header="ÁFA" body={itemVatBody}></Column>
                  <Column field="grossAmount" header="Bruttó érték" className={"p-text-right p-text-nowrap"}
                          body={(rowData) => {
                            let currency
                            if (formData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  rowData.grossAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )
                                }
                                &nbsp;{formData.currency}
                              </>
                            )
                          }}></Column>
                  <Column body={itemActionBody} className={"p-text-right p-text-nowrap"}></Column>
                </DataTable>
                }
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  </div>
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                    <Button type="button" label="Új tétel hozzáadása" icon="pi pi-plus" className="p-button-success"
                            loading={loading}
                            onClick={() => {
                              setLoading(true)
                              if (localStorage.getItem("defaultIsUnitPriceBr") === null) {
                                localStorage.setItem('defaultIsUnitPriceBr', false)
                              }
                              // let errors = validate(formData)
                              // if (errors && Object.keys(errors)[0]) {
                              //   document.getElementById("submit").click()
                              // } else {
                              setItemFormData({
                                description: '',
                                product: null,
                                unit: {id: Number(localStorage.getItem("defaultUnitId"))},
                                vat: Number(localStorage.getItem("defaultVatId")),
                                isUnitPriceGr: JSON.parse(localStorage.getItem("defaultIsUnitPriceBr")),
                              })
                              setSelectedItemCosttype('')
                              setSelectedItemProject('')
                              if (!formData.id && formData.receiptGroup && formData.receiptGroup.id) {
                                ReceiptFormService(formData, 'post', formData.receiptGroup.id)
                                  .then(response => {
                                    if (file !== false) {
                                      ReceiptFileUploadService(file, response.data.id)
                                        .then(response => {
                                          setLoading(false)
                                        })
                                        .catch(error => {
                                          setLoading(false)
                                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                        })
                                    }
                                    setItemDialog(true)
                                    setItemFormMethod('create')
                                    setFormData(response.data)
                                  })
                                  .catch(error => {
                                    setLoading(false)
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  })
                              } else {
                                delete formData['items']
                                ReceiptFormService(formData, 'put', formData.receiptGroup.id)
                                  .then(response => {
                                    if (file !== false) {
                                      ReceiptFileUploadService(file, response.data.id)
                                        .then(response => {
                                          setLoading(false)
                                        })
                                        .catch(error => {
                                          setLoading(false)
                                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                        })
                                    }
                                    setItemDialog(true)
                                    setItemFormMethod('create')
                                    setFormData(response.data)
                                  })
                                  .catch(error => {
                                    setLoading(false)
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                  })
                              }
                              // }
                            }}
                    />
                  </div>
                </div>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col"}>
                  </div>
                  <div className={"p-col p-text-right"}>
                    {window.App.state.props && window.App.state.props.external === true ? (
                      <>
                        <table align="right">
                          <tr>
                            <td className={"p-text-nowrap p-text-right"}>Nettó végösszeg:</td>
                            <td>
                              {formData && formData.currency ? (
                                <InputNumber id="netAmount"
                                             minFractionDigits={0}
                                             maxFractionDigits={9}
                                             locale="hu-HU"
                                             value={formData.netAmount}
                                             onChange={(e) => {
                                               setFormData({
                                                 ...formData,
                                                 netAmount: Number(e.value),
                                               })
                                               setIsSummaryOverwritten(true)
                                             }}
                                />
                              ) : (
                                <InputNumber id="netAmount"
                                             locale="hu-HU"
                                             minFractionDigits={0}
                                             maxFractionDigits={9}
                                             onChange={(e) => {
                                               setFormData({
                                                 ...formData,
                                                 netAmount: Number(e.value)
                                               })
                                               setIsSummaryOverwritten(true)
                                             }}
                                             value={formData.netAmount}
                                />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className={"p-text-nowrap p-text-right"}>ÁFA tartalom:</td>
                            <td>
                              {formData && formData.currency ? (
                                <InputNumber id="vatAmount"
                                             minFractionDigits={0}
                                             maxFractionDigits={9}
                                             locale="hu-HU"
                                             value={formData.vatAmount}
                                             onChange={(e) => {
                                               setFormData({
                                                 ...formData,
                                                 vatAmount: e.value,
                                               })
                                               setItemFormData({
                                                 ...itemFormData,
                                                 isSummaryOverwritten: true,
                                               })
                                             }}
                                />
                              ) : (
                                <InputNumber id="vatAmount"
                                             locale="hu-HU"
                                             minFractionDigits={0}
                                             maxFractionDigits={9}
                                             onChange={(e) => {
                                               setFormData({
                                                 ...formData,
                                                 vatAmount: e.value
                                               })
                                               setItemFormData({
                                                 ...itemFormData,
                                                 isSummaryOverwritten: true,
                                               })
                                             }}
                                             value={formData.vatAmount}
                                />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className={"p-text-nowrap p-text-right"}>Bruttó végösszeg:</td>
                            <td>
                              {formData && formData.currency ? (
                                <InputNumber id="grossAmount"
                                             minFractionDigits={0}
                                             maxFractionDigits={9}
                                             locale="hu-HU"
                                             value={formData.grossAmount}
                                             onChange={(e) => {
                                               setFormData({
                                                 ...formData,
                                                 grossAmount: Number(e.value),
                                               })
                                               setIsSummaryOverwritten(true)
                                             }}
                                />
                              ) : (
                                <InputNumber id="grossAmount"
                                             locale="hu-HU"
                                             minFractionDigits={0}
                                             maxFractionDigits={9}
                                             onChange={(e) => {
                                               setFormData({
                                                 ...formData,
                                                 grossAmount: Number(e.value),
                                               })
                                               setIsSummaryOverwritten(true)
                                             }}
                                             value={formData.grossAmount}
                                />
                              )}
                            </td>
                          </tr>
                        </table>
                      </>
                    ) : (
                      <>
                        <table align="right">
                          <tr>
                            <td>Nettó végösszeg:</td>
                            <td className="p-text-right  p-text-nowrap"><span className="p-text-bold">
                          {(() => {
                            let currency
                            if (formData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  formData.netAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )}&nbsp;{formData.currency}
                              </>
                            )
                          })()}
                        </span></td>
                          </tr>
                          <tr>
                            <td>ÁFA tartalom:</td>
                            <td className="p-text-right  p-text-nowrap"><span className="p-text-bold">
                          {(() => {
                            let currency
                            if (formData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  formData.vatAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )}&nbsp;{formData.currency}
                              </>
                            )
                          })()}
                        </span>
                            </td>
                          </tr>
                          <tr>
                            <td>Bruttó végösszeg:</td>
                            <td className="p-text-right  p-text-nowrap"><span className="p-text-bold">
                          {(() => {
                            let currency
                            if (formData.currency === 'HUF') {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: ',',
                                thousand: ' ',
                              }
                            } else {
                              currency = {
                                precision: 2,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            }
                            return (
                              <>
                                {currencyFormatter.format(
                                  formData.grossAmount, {
                                    precision: currency.precision,
                                    symbol: currency.symbol,
                                    decimal: currency.decimal,
                                    thousand: currency.thousand,
                                  }
                                )}&nbsp;{formData.currency}
                              </>
                            )
                          })()}
                        </span>
                            </td>
                          </tr>
                        </table>
                      </>
                    )}
                  </div>
                </div>
              </Panel>

              {(formData && (formData.costTypeSummary && formData.costTypeSummary[0]) || (formData.projectSummary && formData.projectSummary[0])) &&
              <>
                <Panel headerTemplate={
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Összesítések
                      </h3>
                    </div>
                  </div>
                } className={"p-mt-3"}>
                  <div className={"p-grid"}>
                    {formData.projectSummary && formData.projectSummary[0] &&
                    <div className={"p-col"}>
                      <DataTable value={formData.projectSummary}
                                 emptyMessage="Nincs találat."
                                 autoLayout={true}
                                 filterDisplay="row"
responsiveLayout="scroll"
                                 stripedRows
                                 className={"p-mb-3"}
                      >
                        <Column field="projectName" header="Projekt"></Column>
                        <Column field="totalNetAmount" header="Összesen"
                                className={"p-text-right p-text-nowrap"}
                                body={(rowData) => {
                                  let currency
                                  if (formData.currency === 'HUF') {
                                    currency = {
                                      precision: 2,
                                      symbol: '',
                                      decimal: ',',
                                      thousand: ' ',
                                    }
                                  } else {
                                    currency = {
                                      precision: 2,
                                      symbol: '',
                                      decimal: '.',
                                      thousand: ' ',
                                    }
                                  }
                                  return (
                                    <>
                                      {currencyFormatter.format(
                                        rowData.totalNetAmount, {
                                          precision: currency.precision,
                                          symbol: currency.symbol,
                                          decimal: currency.decimal,
                                          thousand: currency.thousand,
                                        }
                                      )
                                      }
                                      &nbsp;{formData.currency}
                                    </>
                                  )
                                }}
                        ></Column>
                      </DataTable>
                    </div>
                    }
                    {formData.costTypeSummary && formData.costTypeSummary[0] &&
                    <div className={"p-col"}>
                      <DataTable value={formData.costTypeSummary}
                                 emptyMessage="Nincs találat."
                                 autoLayout={true}
                                 filterDisplay="row"
responsiveLayout="scroll"
                                 stripedRows
                                 className={"p-mb-3"}
                      >
                        <Column field="costTypeName" header="Költségnem"></Column>
                        <Column field="totalNetAmount" header="Összesen"
                                className={"p-text-right p-text-nowrap"}
                                body={(rowData) => {
                                  let currency
                                  if (formData.currency === 'HUF') {
                                    currency = {
                                      precision: 2,
                                      symbol: '',
                                      decimal: ',',
                                      thousand: ' ',
                                    }
                                  } else {
                                    currency = {
                                      precision: 2,
                                      symbol: '',
                                      decimal: '.',
                                      thousand: ' ',
                                    }
                                  }
                                  return (
                                    <>
                                      {currencyFormatter.format(
                                        rowData.totalNetAmount, {
                                          precision: currency.precision,
                                          symbol: currency.symbol,
                                          decimal: currency.decimal,
                                          thousand: currency.thousand,
                                        }
                                      )
                                      }
                                      &nbsp;{formData.currency}
                                    </>
                                  )
                                }}
                        ></Column>
                      </DataTable>
                    </div>
                    }
                  </div>
                </Panel>
              </>
              }

              {window.App.state.props && (window.App.state.props.type === 'invoice'
                || window.App.state.props.type === 'cost'
                || window.App.state.props.type === 'fee_request') &&
              <>
                <Panel headerTemplate={
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Pénzügyi teljesítési adatok
                      </h3>
                    </div>
                  </div>
                } className={"p-mt-3"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-8"}>
                      <div className={"p-grid"}>
                        <div className={"p-col-12 p-lg-6"}>
                          <Field name="paidAmount" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="paidAmount"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Kifizetett
                                összeg</label>
                              <span className="p-input-icon-right">
                            {paymentMethodIsPaid === true ? (
                              <>
                                <InputNumber
                                  id="paidAmount"
                                  disabled
                                  className={"disabled"}/>
                              </>
                            ) : (
                              <>
                                <InputNumber
                                  id="paidAmount" {...input}
                                  minFractionDigits={0}
                                  maxFractionDigits={9}
                                  locale="hu-HU"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      paidAmount: Number(e.value)
                                    })
                                  }}
                                  value={formData.paidAmount}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                              </>
                            )}
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-lg-6"}>
                          <Field name="paidDate" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="paidDate"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Kifizetés ideje</label>
                              <span className="p-input-icon-right">
                            {paymentMethodIsPaid === true ? (
                              <>
                                <Calendar dateFormat="yy-mm-dd" className={"disabled"} disabled id={"paidDate"}/>
                              </>
                            ) : (
                              <>
                                {formData.paidDate && (formData.paidDate !== null || formData.paidDate !== undefined) ? (
                                  <Calendar dateFormat="yy-mm-dd"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            yearRange={"2022:2099"}
                                            keepInvalid="true"
                                            onChange={(e) => {
                                              if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                                setFormData({
                                                  ...formData,
                                                  paidDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                                })
                                              } else {
                                                setFormData({
                                                  ...formData,
                                                  paidDate: null
                                                })
                                              }
                                            }}
                                            value={new Date(formData.paidDate)}
                                            mask="9999-99-99"
                                            id={"paidDate"}
                                            locale={"hu"}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  />
                                ) : (
                                  <Calendar dateFormat="yy-mm-dd"
                                            monthNavigator={true}
                                            yearNavigator={true}
                                            yearRange={"2022:2099"}
                                            keepInvalid="true"
                                            onChange={(e) => {
                                              if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                                setFormData({
                                                  ...formData,
                                                  paidDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                                })
                                              } else {
                                                setFormData({
                                                  ...formData,
                                                  paidDate: null
                                                })
                                              }
                                            }}
                                            mask="9999-99-99"
                                            id={"paidDate"}
                                            locale={"hu"}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  />
                                )}
                              </>
                            )}
                      </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                      </div>
                      <div className={"p-grid"}>
                        {window.App.state.props && window.App.state.props.outgoing === false &&
                        <div className={"p-col-12 p-lg-6"}>
                          <Field name="recordingDate" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="recordingDate"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Bankba rögzítve</label>
                              <span className="p-input-icon-right">
                        {paymentMethodIsPaid === true ? (
                          <>
                            <Calendar dateFormat="yy-mm-dd" className={"disabled"} disabled id={"recordingDate"}/>
                          </>
                        ) : (
                          <>
                            {formData.recordingDate && (formData.recordingDate !== null || formData.recordingDate !== undefined) ? (
                              <Calendar dateFormat="yy-mm-dd"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange={"2022:2099"}
                                        keepInvalid="true"
                                        onChange={(e) => {
                                          if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                            setFormData({
                                              ...formData,
                                              recordingDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                            })
                                          } else {
                                            setFormData({
                                              ...formData,
                                              recordingDate: null
                                            })
                                          }
                                        }}
                                        value={new Date(formData.recordingDate)}
                                        mask="9999-99-99"
                                        id={"recordingDate"}
                                        locale={"hu"}
                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}
                              />
                            ) : (
                              <Calendar dateFormat="yy-mm-dd"
                                        keepInvalid="true"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange={"2022:2099"}
                                        onChange={(e) => {
                                          if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                            setFormData({
                                              ...formData,
                                              recordingDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                            })
                                          } else {
                                            setFormData({
                                              ...formData,
                                              recordingDate: null
                                            })
                                          }
                                        }}
                                        mask="9999-99-99"
                                        id={"recordingDate"}
                                        locale={"hu"}
                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}
                              />
                            )}
                          </>
                        )}
                      </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        }
                        <div className={"p-col-12 p-lg-6"}>
                          <Field name="accountancyDate" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="accountancyDate"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Könyvelésre
                                leadva</label>
                              <span className="p-input-icon-right">
                        {formData.accountancyDate && (formData.accountancyDate !== null || formData.accountancyDate !== undefined) ? (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    onChange={(e) => {
                                      if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                        setFormData({
                                          ...formData,
                                          accountancyDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                        })
                                      } else {
                                        setFormData({
                                          ...formData,
                                          accountancyDate: null
                                        })
                                      }
                                    }}
                                    value={new Date(formData.accountancyDate)}
                                    mask="9999-99-99"
                                    id={"accountancyDate"}
                                    locale={"hu"}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                          />
                        ) : (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    onChange={(e) => {
                                      if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                        setFormData({
                                          ...formData,
                                          accountancyDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                        })
                                      } else {
                                        setFormData({
                                          ...formData,
                                          accountancyDate: null
                                        })
                                      }
                                    }}
                                    mask="9999-99-99"
                                    id={"accountancyDate"}
                                    locale={"hu"}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                          />
                        )}
                      </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                      </div>
                      {window.App.state.props && window.App.state.props.type !== 'fee_request' &&
                      <div className={"p-grid"}>
                        <div className={"p-col-12 p-lg-6"}>
                          <Field name="retentionNetAmount" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="retentionNetAmount"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Visszatartás
                                összege</label>
                              <span className="p-input-icon-right">
                                <InputNumber
                                  id="retentionNetAmount" {...input}
                                  minFractionDigits={0}
                                  maxFractionDigits={9}
                                  locale="hu-HU"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      retentionNetAmount: Number(e.value)
                                    })
                                  }}
                                  value={formData.retentionNetAmount}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-lg-6"}>
                          <Field name="retentionPaymentDate" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="retentionPaymentDate"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Visszatartás
                                határideje</label>
                              <span className="p-input-icon-right">
                            {formData.retentionPaymentDate && (formData.retentionPaymentDate !== null || formData.retentionPaymentDate !== undefined) ? (
                              <Calendar dateFormat="yy-mm-dd"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange={"2022:2099"}
                                        keepInvalid="true"
                                        onChange={(e) => {
                                          if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                            setFormData({
                                              ...formData,
                                              retentionPaymentDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                            })
                                          } else {
                                            setFormData({
                                              ...formData,
                                              retentionPaymentDate: null
                                            })
                                          }
                                        }}
                                        value={new Date(formData.retentionPaymentDate)}
                                        mask="9999-99-99"
                                        id={"retentionPaymentDate"}
                                        locale={"hu"}
                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}
                              />
                            ) : (
                              <Calendar dateFormat="yy-mm-dd"
                                        monthNavigator={true}
                                        yearNavigator={true}
                                        yearRange={"2022:2099"}
                                        keepInvalid="true"
                                        onChange={(e) => {
                                          if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                            setFormData({
                                              ...formData,
                                              retentionPaymentDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                            })
                                          } else {
                                            setFormData({
                                              ...formData,
                                              retentionPaymentDate: null
                                            })
                                          }
                                        }}
                                        mask="9999-99-99"
                                        id={"retentionPaymentDate"}
                                        locale={"hu"}
                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}
                              />
                            )}
                      </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                      </div>
                      }
                    </div>
                    <div className={"p-col-12 p-lg-4"}>
                      <div className={"p-grid"}>
                        <div className={"p-col-12"}>
                          <Field name="isPaid" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="isPaid"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Pénzügyileg
                                teljesült</label>
                              <span className="p-input-icon-right">
                                                    {paymentMethodIsPaid === true ? (
                                                      <>
                                                        <InputSwitch
                                                          className={"p-mt-1 disabled"}
                                                          disabled/>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <InputSwitch
                                                          className={"p-mt-1"}
                                                          checked={formData.isPaid}
                                                          value={formData.isPaid}
                                                          onChange={e => setFormData({
                                                            ...formData,
                                                            isPaid: e.target.value
                                                          })}/>
                                                      </>
                                                    )}
                      </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        {window.App.state.props && window.App.state.props.type !== 'fee_request' &&
                        <div className={"p-col-12"}>
                          <Field name="missingContract" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="missingContract"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Hiányzó
                                szerződés</label>
                              <span className="p-input-icon-right">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={formData.missingContract}
                          value={formData.missingContract}
                          onChange={e => setFormData({...formData, missingContract: e.target.value})}/>
                      </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        }
                      </div>
                    </div>
                  </div>
                </Panel>
              </>
              }

              <Panel headerTemplate={
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-list"}></i> Megjegyzés
                    </h3>
                  </div>
                </div>
              } className={"p-mt-3"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-12 p-xl-12"}>
                    <Field name="partnerNote" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                      <span className="p-input-icon-right">
                        <InputText id="partnerNote" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       partnerNote: e.target.value
                                     })
                                   }}
                                   value={formData.partnerNote}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                </div>
              </Panel>
            </div>
            {((window.App.state.props && window.App.state.props.external === true) || formData.external === true) &&
            <div className={"p-col-12 p-lg-5"}>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-12"}>
                  <h3 className={"p-mb-2"}>
                    <i className={"pi pi-file"}></i> Számlakép
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <Field name="image" render={({input, meta}) => (
                    <div className="p-field">
                      {file === false ? (
                        <>
                          <label htmlFor="file"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Kép feltöltése</label>
                          <span className="p-d-block">
                              <FileUpload mode="basic"
                                          name="file"
                                          id={"file"}
                                          auto="true"
                                          maxFileSize={1000000}
                                          customUpload
                                          uploadHandler={imageUpload}
                                          chooseLabel={"Fájl kiválasztása"}
                                          uploadLabel={"Feltöltés"}
                              />
                            </span>
                        </>
                      ) : (
                        <>
                          {file && file.toString().includes('data:application/') &&
                            <>
                              {navigator.userAgent.indexOf("Safari") != -1 ? (
                                <iframe
                                  src={file}
                                  width='100%'
                                  height='987px'
                                  className={"p-d-block p-mb-4"}
                                />
                              ):(
                                <object
                                  data={file + "#toolbar=0&navpanes=0&scrollbar=0"}
                                  type='application/pdf'
                                  width='100%'
                                  height='987px'
                                  className={"p-d-block p-mb-4"}
                                />
                              )}
                            </>
                          }
                          {file && file.toString().includes('data:image/') &&
                          <img src={file} className={"w-100 p-d-block p-mb-4"}/>
                          }
                          <label htmlFor="file"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Feltöltött kép
                            törlése</label>
                          <span className="p-d-block">
                              <Button type="button"
                                      label={"Törlés"}
                                      icon={"pi pi-trash"}
                                      loading={loading}
                                      className="p-button p-button-danger"
                                      onClick={() => {
                                        if (window.confirm('A törlés megerősítésére van szükség!')) {
                                          setLoading(true)
                                          if (formData && formData.id) {
                                            ReceiptFileDeleteService(formData.id)
                                              .then(response => {
                                                setFile(false)
                                                setLoading(false)
                                              })
                                              .catch(error => {
                                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                                setLoading(false)
                                              })
                                          }
                                        }
                                      }}
                              />
                            </span>
                        </>
                      )}
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </div>
            }
            {/*{((window.App.state.props && window.App.state.props.external === true) || formData.external === true) && formData.file &&*/}
            {/*<div className={"p-col"}>*/}
            {/*  <div className={"p-grid p-mt-1"}>*/}
            {/*    <div className={"p-col-12"}>*/}
            {/*      <h3 className={"p-mb-2"}>*/}
            {/*        <i className={"pi pi-list"}></i> Számlakép*/}
            {/*      </h3>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className={"p-grid"}>*/}
            {/*    <div className={"p-col-12 p-text-center"}>*/}
            {/*      {formData.file && formData.file.toString().includes('data:application/') &&*/}
            {/*      <object*/}
            {/*        data={formData.file + "#toolbar=0&navpanes=0&scrollbar=0"}*/}
            {/*        type='application/pdf'*/}
            {/*        width='700px'*/}
            {/*        height='987px'*/}
            {/*      />*/}
            {/*      }*/}
            {/*      {formData.file && formData.file.toString().includes('data:image/') &&*/}
            {/*      <img src={formData.file} className={"w-100"}/>*/}
            {/*      }*/}
            {/*      {formData.file && formData.file.toString().length == 26 &&*/}
            {/*      <img src={process.env.REACT_APP_API_HOST + "/upload/" + formData.file} className={"w-100"}/>*/}
            {/*      }*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div className={"p-grid"}>*/}
            {/*    <div className={"p-col-12"}>*/}
            {/*      <label htmlFor="file"*/}
            {/*      >Feltöltött kép*/}
            {/*        törlése</label>*/}
            {/*      <span className="p-d-block">*/}
            {/*                  <Button type="button"*/}
            {/*                          className="p-button p-button-danger"*/}
            {/*                          onClick={() => setFormData({...formData, file: null})}*/}
            {/*                  >*/}
            {/*                     <i className={"pi pi-trash"}></i>&nbsp;Törlés*/}
            {/*                  </Button>*/}
            {/*                </span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*}*/}
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right p-col-align-center"}>
              {formData.draft === true ? (
                <>
                  {formData.id &&
                  <Button type="button" label="Törlés"
                          onClick={() => {
                            setLoading(true)
                            ReceiptDeleteService(formData.id)
                              .then(response => {
                                window.App.toastShow('success', 'Sikeres törlés!');
                                window.App.setState({
                                  rerenderReceiptList: true,
                                  sidebarReceiptUpdate: false,
                                  sidebarReceiptCreate: false,
                                })
                                setLoading(false)
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                setLoading(false)
                              })
                          }}
                          loading={loading}
                          icon={"pi pi-trash"}
                          className="p-button-danger p-ml-2"/>
                  }
                </>
              ) : (
                <>
                  {window.App.state.props.external === true ? (
                    <>
                      {formData.id &&
                      <Button type="button" label="Törlés"
                              onClick={() => {
                                setLoading(true)
                                ReceiptDeleteService(formData.id)
                                  .then(response => {
                                    window.App.toastShow('success', 'Sikeres törlés!');
                                    window.App.setState({
                                      rerenderReceiptList: true,
                                      sidebarReceiptUpdate: false,
                                      sidebarReceiptCreate: false,
                                    })
                                    setLoading(false)
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                    setLoading(false)
                                  })
                              }}
                              loading={loading}
                              icon={"pi pi-trash"}
                              className="p-button-danger p-ml-2"/>
                      }
                    </>
                  ) : (
                    <>
                      {formData.id &&
                      <Button type="button" label="Sztornó"
                              onClick={() => {
                                setLoading(true)
                                ReceiptStornoService(formData.id)
                                  .then(response => {
                                    window.App.toastShow('success', 'Sikeres sztornózás!');
                                    window.App.setState({
                                      rerenderReceiptList: true,
                                      sidebarReceiptUpdate: false,
                                      sidebarReceiptCreate: false,
                                    })
                                    setLoading(false)
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                    setLoading(false)
                                  })
                              }}
                              loading={loading}
                              icon={"pi pi-trash"}
                              className="p-button-danger p-ml-2"/>
                      }
                    </>
                  )}
                </>
              )}
              <Button type="submit" label="Mentés" id="submit"
                      loading={loading}
                      icon={"pi pi-check"}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
          <Dialog visible={itemDialog}
                  style={{width: '750px'}}
                  header="Tétel hozzáadása"
                  modal
                  onHide={() => {
                    setItemDialog(false)
                    setLoading(false)
                  }}>
            <Form onSubmit={itemOnSubmit}
                  initialValues={itemFormData}
                  validate={itemValidate}
                  render={({handleSubmit}) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className={"p-grid"}>
                        <div className={"p-col-12 p-lg-3 p-xl-3"}>
                          <Field name="product" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="product"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Cikktörzs</label>
                              <span className="p-inputgroup">
                              <AutoComplete value={selectedProduct}
                                            id={"product"}
                                            suggestions={products}
                                            // forceSelection
                                            completeMethod={searchProducts}
                                            field={"name"}
                                            delay="500"
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setItemFormData({
                                                ...itemFormData,
                                                product: {id: e.value.id},
                                                description: e.value.name
                                              })
                                              setSelectedProduct(e.value.name)
                                            }}
                                            onClear={(e) => {
                                              setSelectedProduct('')
                                            }}
                              />
                                {/*<Button type="button" className={"p-button"}*/}
                                {/*        onClick={() => setPartnerDialog(true)}>*/}
                                {/*  <i className={"pi pi-window-maximize"}></i>*/}
                                {/*</Button>*/}
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-lg-9 p-xl-9"}>
                          <Field name="description" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="description"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                              <span className="p-input-icon-right">
                              <InputText id="description" {...input}
                                         onChange={(e) => {
                                           setItemFormData({
                                             ...itemFormData,
                                             description: e.target.value
                                           })
                                         }}
                                         value={itemFormData.description}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-lg-2 p-xl-2"}>
                          <Field name="quantity" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="quantity"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség</label>
                              <span className="p-input-icon-right">
                              <InputNumber id="quantity" {...input}
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           locale="hu-HU"
                                           onValueChange={(e) => {
                                             setItemFormData({
                                               ...itemFormData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={itemFormData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-lg-2 p-xl-2"}>
                          <Field name="unit" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="unit"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Egység</label>
                              <span className="p-input-icon-right">
                              {itemFormData && itemFormData.unit && itemFormData.unit.id ? (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setItemFormData({
                                              ...itemFormData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          value={itemFormData.unit.id}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setItemFormData({
                                              ...itemFormData,
                                              unit: {id: e.target.value}
                                            })
                                            localStorage.setItem("defaultUnitId", e.target.value)
                                          }}
                                          options={units}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"unit"}
                                          emptyMessage={"Még nincs egység..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />)}
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        {window.App.state.props && (window.App.state.props.type === 'invoice'
                          || window.App.state.props.type === 'cost'
                          || window.App.state.props.type === 'fee_request') &&
                        <div className={"p-col-12 p-lg-4 p-xl-4"}>
                          <Field name="unitPrice" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="unitPrice"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Egységár</label>
                              <span className="p-input-icon-right">
                              <InputNumber id="unitPrice" {...input}
                                           locale="hu-HU"
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           onValueChange={(e) => {
                                             setItemFormData({
                                               ...itemFormData,
                                               unitPrice: e.value
                                             })
                                           }}
                                           value={itemFormData.unitPrice}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        }
                        <div className={"p-col-12 p-lg-1 p-xl-1"}>
                          <div className="p-field">
                            <label>&nbsp;</label>
                            <span className="p-d-block p-pt-2">
                             {formData.currency}
                          </span>
                          </div>
                        </div>
                        <div className={"p-col-12 p-lg-1 p-xl-1"}>
                          <Field name="isUnitPriceGr" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="itemFormData.isUnitPriceGr"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Bruttó</label>
                              <span className="p-d-block">
                              <InputSwitch {...input}
                                           value={itemFormData.isUnitPriceGr}
                                           checked={itemFormData.isUnitPriceGr}
                                           onChange={(e) => {
                                             setItemFormData({
                                               ...itemFormData,
                                               isUnitPriceGr: e.value
                                             })
                                             localStorage.setItem("defaultIsUnitPriceBr", e.value)
                                           }}
                                           className={"p-mt-1"}
                              />
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        {window.App.state.props && (window.App.state.props.type === 'invoice'
                          || window.App.state.props.type === 'cost'
                          || window.App.state.props.type === 'fee_request') &&
                        <div className={"p-col-12 p-lg-2 p-xl-2"}>
                          <Field name="vat" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="vat"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>ÁFA</label>
                              <span className="p-input-icon-right">
                                <Dropdown {...input}
                                          onChange={(e) => {
                                            setItemFormData({
                                              ...itemFormData,
                                              vat: e.target.value
                                            })
                                            localStorage.setItem("defaultVatId", e.target.value)
                                          }}
                                          value={itemFormData.vat}
                                          options={vats}
                                          optionLabel="name"
                                          optionValue="id"
                                          id={"vat"}
                                          emptyMessage={"Még nincs ÁFA..."}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        }
                        {window.App.state.props.external === true &&
                        <>
                          <div className={"p-col-12 p-lg-6 p-xl-6"}>
                            <Field name="costType" render={({input, meta}) => (
                              <div className="p-field p-fluid">
                                <label htmlFor="costType"
                                       className={classNames({'p-error': isFormFieldValid(meta)})}>Költségnem</label>
                                <span className="p-input-icon-right">
                                <AutoComplete value={selectedItemCosttype}
                                              id={"costType"}
                                              suggestions={costtypes}
                                              // forceSelection
                                              completeMethod={searchCosttype}
                                              field={"name"}
                                              delay="500"
                                              placeholder={"Keresés gépeléssel..."}
                                              className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                              onSelect={(e) => {
                                                setItemFormData({
                                                  ...itemFormData,
                                                  costType: {id: e.value.id}
                                                })
                                                setSelectedItemCosttype(e.value.name)
                                              }}
                                              dropdown
                                              onClear={(e) => {
                                                setSelectedItemCosttype('')
                                              }}
                                />
                                  {/*{itemFormData && itemFormData.costType && itemFormData.costType.id ? (*/}
                                  {/*  <Dropdown {...input}*/}
                                  {/*            onChange={(e) => {*/}
                                  {/*              setItemFormData({*/}
                                  {/*                ...itemFormData,*/}
                                  {/*                costType: {id: e.target.value}*/}
                                  {/*              })*/}
                                  {/*            }}*/}
                                  {/*            value={itemFormData.costType.id}*/}
                                  {/*            options={costtypes}*/}
                                  {/*            optionLabel="name"*/}
                                  {/*            optionValue="id"*/}
                                  {/*            id={"costType"}*/}
                                  {/*            emptyMessage={"Még nincs költségnem..."}*/}
                                  {/*            className={classNames({'p-error': isFormFieldValid(meta)})}*/}
                                  {/*  />*/}
                                  {/*) : (*/}
                                  {/*  <Dropdown {...input}*/}
                                  {/*            onChange={(e) => {*/}
                                  {/*              setItemFormData({*/}
                                  {/*                ...itemFormData,*/}
                                  {/*                costType: {id: e.target.value}*/}
                                  {/*              })*/}
                                  {/*            }}*/}
                                  {/*            options={costtypes}*/}
                                  {/*            optionLabel="name"*/}
                                  {/*            optionValue="id"*/}
                                  {/*            id={"costType"}*/}
                                  {/*            emptyMessage={"Még nincs költségnem..."}*/}
                                  {/*            className={classNames({'p-error': isFormFieldValid(meta)})}*/}
                                  {/*  />*/}
                                  {/*)}*/}
                            </span>
                                {getFormErrorMessage(meta)}
                              </div>
                            )}/>
                          </div>
                          <div className={"p-col-12 p-lg-6 p-xl-6"}>
                            <Field name="project" render={({input, meta}) => (
                              <div className="p-field p-fluid">
                                <label htmlFor="project"
                                       className={classNames({'p-error': isFormFieldValid(meta)})}>Projekt</label>
                                <span className="p-input-icon-right">
                                <AutoComplete value={selectedItemProject}
                                              id={"project"}
                                              suggestions={projects}
                                              // forceSelection
                                              completeMethod={searchItemProject}
                                              field={"name"}
                                              delay="500"
                                              dropdown
                                              placeholder={"Keresés gépeléssel..."}
                                              className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                              onSelect={(e) => {
                                                setItemFormData({
                                                  ...itemFormData,
                                                  project: {id: e.value.id}
                                                })
                                                setSelectedItemProject(e.value.name)
                                                // localStorage.setItem("defaultProjectId", e.value.id)
                                                // localStorage.setItem("defaultProjectName", e.value.name)
                                              }}
                                              onClear={(e) => {
                                                setSelectedItemProject('')
                                              }}
                                />
                                  {/*{itemFormData && itemFormData.project && itemFormData.project.id ? (*/}
                                  {/*  <Dropdown {...input}*/}
                                  {/*            onChange={(e) => {*/}
                                  {/*              setItemFormData({*/}
                                  {/*                ...itemFormData,*/}
                                  {/*                project: {id: e.target.value}*/}
                                  {/*              })*/}
                                  {/*            }}*/}
                                  {/*            value={itemFormData.project.id}*/}
                                  {/*            options={projects}*/}
                                  {/*            optionLabel="name"*/}
                                  {/*            optionValue="id"*/}
                                  {/*            id={"project"}*/}
                                  {/*            emptyMessage={"Még nincs projekt..."}*/}
                                  {/*            className={classNames({'p-error': isFormFieldValid(meta)})}*/}
                                  {/*  />*/}
                                  {/*) : (*/}
                                  {/*  <Dropdown {...input}*/}
                                  {/*            onChange={(e) => {*/}
                                  {/*              setItemFormData({*/}
                                  {/*                ...itemFormData,*/}
                                  {/*                project: {id: e.target.value}*/}
                                  {/*              })*/}
                                  {/*            }}*/}
                                  {/*            options={projects}*/}
                                  {/*            optionLabel="name"*/}
                                  {/*            optionValue="id"*/}
                                  {/*            id={"project"}*/}
                                  {/*            emptyMessage={"Még nincs projekt..."}*/}
                                  {/*            className={classNames({'p-error': isFormFieldValid(meta)})}*/}
                                  {/*  />*/}
                                  {/*)}*/}
                              </span>
                                {getFormErrorMessage(meta)}
                              </div>
                            )}/>
                          </div>
                        </>
                        }
                      </div>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>
                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          {itemFormMethod === 'create' &&
                          <Button type="submit" label="Tétel hozzáadása" icon={"pi pi-plus"}/>
                          }
                          {itemFormMethod === 'update' &&
                          <Button type="submit" label="Tétel módosítása" icon={"pi pi-check"}/>
                          }
                        </div>
                      </div>
                    </form>
                  )}/>
          </Dialog>

        </form>
      )}/>
    </>
  )
}
export default withTranslation('common')(ReceiptFormComponent);
