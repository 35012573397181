import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";
import {Dialog} from "primereact/dialog";
import download from 'js-file-download';

import EntrylogListService from "../../services/entrylog/EntrylogListService";
import EntrylogActionListService from "../../services/entrylog/EntrylogActionListService";
import EntrylogStatusListService from "../../services/entrylog/EntrylogStatusListService";
import {Panel} from "primereact/panel";
import {MultiSelect} from "primereact/multiselect";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import EntrylogExportService from "../../services/entrylog/EntrylogExportService";
import EntrylogWorktimereportListService from "../../services/entrylog/EntrylogWorktimereportListService";
import EntrylogWorktimereportDownloadService from "../../services/entrylog/EntrylogWorktimereportDownloadService";

const EntrylogWorktimereportListComponent = (props) => {

  const [entrylog, setEntrylog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerender: false})
      EntrylogWorktimereportListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setEntrylog(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const [entrylogActions, setEntrylogActions] = useState([]);
  const [entrylogStatuses, setEntrylogStatuses] = useState([]);
  const entrylogStatus = (rowData) => {
    if (entrylogStatuses && entrylogStatuses[0]) {
      let entrylogStatus = entrylogStatuses.filter(item => item.id === rowData.status)
      if(entrylogStatus[0] && entrylogStatus[0].name) {
        return entrylogStatus[0].name
      }
    }
  }
  const entrylogAction = (rowData) => {
    if (entrylogActions && entrylogActions[0]) {
      let entrylogAction = entrylogActions.filter(item => item.id === rowData.action)
      if(entrylogAction[0] && entrylogAction[0].name) {
        return entrylogAction[0].name
      }
    }
  }

  const exportEntryLogs = () => {
    window.App.setState({loadingEntrylogExport: true})
    EntrylogExportService(JSON.stringify(lazyParams))
      .then(response => {
        window.App.toastShow('success', 'Sikeres export készítés!', '')
        download(response.data, localStorage.getItem('networkName')
          + '_'
          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
          + '.xlsx')
        window.App.setState({loadingEntrylogExport: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.response)
        window.App.setState({loadingEntrylogExport: false})
      })
  }

  const actionFilterElement = <Dropdown options={entrylogActions}
                                        id={"entrylogActions"}
                                        optionValue={"id"}
                                        optionLabel={"name"}
                                        showClear
                                        emptyMessage={"Még nincs irány..."}
                                        onChange={(e) => {
                                          dt.current.filter(e.value, 'action', '');
                                          window.App.setState({
                                            valueName: e.value
                                          })
                                        }}
                                        value={window.App.state.valueName}
  />

  const statusFilterElement = <Dropdown options={entrylogStatuses}
                                        id={"entrylogStatuses"}
                                        optionValue={"id"}
                                        optionLabel={"name"}
                                        showClear
                                        emptyMessage={"Még nincs státusz..."}
                                        onChange={(e) => {
                                          dt.current.filter(e.value, 'status', '');
                                          window.App.setState({
                                            valueEntrylogStatuses: e.value
                                          })
                                        }}
                                        value={window.App.state.valueEntrylogStatuses}
  />

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  //dateFilter
  const [dateFilterSelectedFrom, setDateFilterSelectedFrom] = useState(null);
  const [dateFilterSelectedTo, setDateFilterSelectedTo] = useState(null);
  const dateFilterElementTemplate =
    <div className={"p-grid"} style={{maxWidth: '290px'}}>
      <div className={"p-col-5"}>
                    <Calendar dateFormat="yy-mm-dd"
                              className={"w-100"}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setDateFilterSelectedFrom(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'createdAtFrom', '');
                                }, 500)
                              }}
                              value={dateFilterSelectedFrom}
                              mask="9999-99-99"
                              id={"createdAtFrom"}
                              locale={"hu"}
                    />
      </div>
      <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
        <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
      </div>
      <div className={"p-col-5"}>
                    <Calendar dateFormat="yy-mm-dd"
                              className={"w-100"}
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setDateFilterSelectedTo(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'createdAtUntil', '');
                                }, 500)
                              }}
                              value={dateFilterSelectedTo}
                              mask="9999-99-99"
                              id={"createdAtUntil"}
                              locale={"hu"}
                    />
      </div>
    </div>
    // <span className={"date-column-filter text-nowrap"}>
    //   <Calendar dateFormat="yy-mm-dd" locale={"hu"} selectionMode="range" value={dateFilterSelected} showTime
    //             hideOnDateTimeSelect="true"
    //             onChange={e => {
    //               if (!e.value) {
    //                 return
    //               }
    //               setDateFilterSelected(e.value);
    //               dt.current.filter(moment.utc(e.value[0]).local().format('YYYY-MM-DD HH:mm'), 'createdAtFrom');
    //               if (e.value[1]) {
    //                 dt.current.filter(moment.utc(e.value[1]).local().format('YYYY-MM-DD HH:mm'), 'createdAtUntil');
    //               }
    //             }}/>
    // </span>

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderEntrylogWorktimereportList === true])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={entrylog} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        ref={dt}
        filterDisplay="row"
responsiveLayout="scroll"
        stripedRows
      >
        <Column field="companyName" header="Vállalkozás" sortable filter filterPlaceholder="Vállalkozás"></Column>
        <Column field="employeeName" header="Munkavállaló" sortable filter filterPlaceholder="Munkavállaló"></Column>
        <Column field="entryZoneName" header="Zóna" sortable filter filterPlaceholder="Zóna"></Column>
        <Column field="createdAt" header="Időpont" sortable filter filterElement={dateFilterElementTemplate} body={(rowData) => {
          return (
            <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
          )
        }}></Column>

      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          <Button type="button" label="Letöltés" icon={"pi pi-download"}
                  onClick={() => {
                    EntrylogWorktimereportDownloadService(JSON.stringify(lazyParams))
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres letöltés!', '')
                        download(response.data, localStorage.getItem('networkName')
                          + '_'
                          +
                          'munkaido_kimutatas'
                          + '_'
                          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                          + '.xlsx')
                        setLoading(false)
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!');
                        setLoading(false)
                      })
                  }}
                  className={"p-button p-text-light"}
                  />
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(EntrylogWorktimereportListComponent);
