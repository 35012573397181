import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {Panel} from "primereact/panel";
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {TabView, TabPanel} from 'primereact/tabview';

import EntrylogListComponent from "../../components/entrylog/EntrylogListComponent";
import EntrylogLiveListComponent from "../../components/entrylog/EntrylogLiveListComponent";
import EntryzoneListComponent from "../../components/entryzone/EntryzoneListComponent";
import EntryzoneFormComponent from "../../components/entryzone/EntryzoneFormComponent";
import EntryterminalListComponent from "../../components/entryterminal/EntryterminalListComponent";
import EntryterminalFormComponent from "../../components/entryterminal/EntryterminalFormComponent";
import EntryterminalShowComponent from "../../components/entryterminal/EntryterminalShowComponent";
import GuestListComponent from "../../components/guest/GuestListComponent";
import GuestFormComponent from "../../components/guest/GuestFormComponent";
import GuestShowComponent from "../../components/guest/GuestShowComponent";
import EntrylogCountByZoneService from "../../services/entrylog/EntrylogCountByZoneService";
import EntrylocationDownloadReportService from "../../services/entrylocation/EntrylocationDownloadReportService";
import download from "js-file-download";
import moment from "moment";
import EntrylogDownloadReportService from "../../services/entrylog/EntrylogDownloadReportService";

export const EntryDashboardPage = () => {

  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [totCount, setTotCount] = useState(0);

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Beléptetés' + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('entry')[0]) {
        document.getElementsByClassName('entry')[0].classList.add("activeMenu")
      }
    }, 1000)
    if (localStorage.getItem('networkId')) {
      EntrylogCountByZoneService()
        .then(response => {
          let chartData = []
          let chartLabels = []
          let tot = 0
          Object.entries(response.data).map(function (data, index) {
            if (data && data[1] && data[1].count && data[1].name) {
              tot = tot + Number(data[1].count)
              chartData.push(data[1].count)
              chartLabels.push(data[1].name)
            }
          })
          setChartData(chartData)
          setChartLabels(chartLabels)
          setTotCount(tot)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }
  }, [localStorage.getItem('networkId')]);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3">
      {localStorage.getItem('module_entry') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_entry_log_read') ? (
                  <TabPanel header="Belépési napló"
                            toggleable
                            collapsed={localStorage.getItem('panel_entrylogs')}
                            onExpand={() => {
                              localStorage.removeItem('panel_entrylogs')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_entrylogs', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      {localStorage.getItem('roles').toString().includes('role_entry_log_extended') &&
                      <Button className={"p-button-outlined p-button-sm fixed"}
                              label="Kiterjesztett nézet" icon="pi pi-window-maximize"
                              onClick={() => {
                                window.App.setState({sidebarEntrylogList: true})
                              }}
                      />
                      }
                      <EntrylogListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_entry_zone_read') ? (
                  <TabPanel header="Zónák"
                            toggleable
                            collapsed={localStorage.getItem('panel_entryzones')}
                            onExpand={() => {
                              localStorage.removeItem('panel_entryzones')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_entryzones', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <EntryzoneListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_entry_terminal_read') ? (
                  <TabPanel header="Terminálok"
                            toggleable
                            collapsed={localStorage.getItem('panel_entryterminals')}
                            onExpand={() => {
                              localStorage.removeItem('panel_entryterminals')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_entryterminals', 'collapse')
                            }}
                  >
                    <div className={"p-p-5"}>
                      <EntryterminalListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_guest_read') ? (
                  <TabPanel header="Vendégek"
                            toggleable
                            collapsed={localStorage.getItem('panel_guests')}
                            onExpand={() => {
                              localStorage.removeItem('panel_guests')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_guests', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <GuestListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
              </TabView>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {localStorage.getItem('roles').toString().includes('role_entry_zone_create') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                    <a onClick={() => window.App.setState({sidebarEntryzoneCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új zóna
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_entry_terminal_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                    <a onClick={() => window.App.setState({sidebarEntryterminalCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új terminál
                      </Card>
                    </a>
                  </div>
                  }
                  {localStorage.getItem('roles').toString().includes('role_guest_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                    <a onClick={() => window.App.setState({sidebarGuestCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új vendég
                      </Card>
                    </a>
                  </div>
                  }
                </div>
                <div className={"p-text-center p-pt-5"}>
                  {localStorage.getItem('roles').toString().includes('role_entry_log_live') &&
                  <Button className={"p-button-secondary p-button-sm p-ml-2 p-mt-2 p-text-light"}
                          onClick={() => window.App.setState({sidebarCheckinLiveShow: true})}>
                    <i className={"pi pi-list"}></i>&nbsp;Élő nézet
                  </Button>
                  }
                  {localStorage.getItem('roles').toString().includes('role_entry_log_live') &&
                  <Button className="p-button-sm p-button-secondary p-button-highlighted p-ml-2"
                          value={"Projekt kimutatás letöltése"}
                          type="button"
                          label={"Belépettek kimutatása"}
                          icon={"pi pi-download"}
                          loading={window.App.state.loadingEntrylogDownloadReport}
                          onClick={() => {
                            window.App.setState({loadingEntrylogDownloadReport: true})
                            EntrylogDownloadReportService()
                              .then(response => {
                                download(response.data, localStorage.getItem('networkName')
                                  + '_'
                                  + 'belepettek-kimutatasa'
                                  + '_'
                                  + moment.utc(new Date()).local().format('YYYY-MM-DD hh:mm:ss')
                                  + '.xlsx')
                                window.App.setState({loadingEntrylogDownloadReport: false})
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString());
                                window.App.setState({loadingEntrylogDownloadReport: false})
                              })
                          }}/>
                  }
                </div>
                {localStorage.getItem('roles').toString().includes('role_entry_log_live') &&
                <div className={"p-text-center"}>
                  {chartData && chartData[0] &&
                  <>
                    <Chart type="doughnut"
                           options={{
                             animation: {
                               duration: 0
                             },
                             plugins: {
                               legend: {
                                 labels: {
                                   color: '#FFF'
                                 }
                               }
                             }
                           }}
                           data={{
                             labels: chartLabels,
                             datasets: [
                               {
                                 data: chartData,
                                 backgroundColor: [
                                   "#11D8D8",
                                   "#D9C309",
                                   "#64748A",
                                   "#F3F4F5",
                                   "#11D8D8",
                                   "#D9C309",
                                   "#64748A",
                                   "#F3F4F5",
                                   "#11D8D8",
                                   "#D9C309",
                                   "#64748A",
                                   "#F3F4F5",
                                   "#11D8D8",
                                   "#D9C309",
                                   "#64748A",
                                   "#F3F4F5",
                                   "#11D8D8",
                                   "#D9C309",
                                   "#64748A",
                                   "#F3F4F5",
                                   "#11D8D8",
                                   "#D9C309",
                                   "#64748A",
                                   "#F3F4F5",
                                   "#11D8D8",
                                   "#D9C309",
                                   "#64748A",
                                   "#F3F4F5",
                                   "#11D8D8",
                                   "#D9C309",
                                   "#64748A",
                                   "#F3F4F5",
                                 ]
                               }
                             ]
                           }} className={"p-mt-5"}/>
                  </>
                  }
                  <h3 className={"p-text-center p-text-normal p-mt-4 p-mb-0"}>Területen tartozkódok
                    száma: {totCount} fő</h3>
                  <Button className={"p-button-secondary p-button-sm p-mt-2 p-text-light"}
                          loading={window.App.state.loadingEntrylogCountByZone}
                          label={"Frissítés"}
                          icon={"pi pi-refresh"}
                          onClick={() => {
                            window.App.setState({loadingEntrylogCountByZone: true})
                            EntrylogCountByZoneService()
                              .then(response => {
                                let chartData = []
                                let chartLabels = []
                                let tot = 0
                                Object.entries(response.data).map(function (data, index) {
                                  if (data && data[1] && data[1].count && data[1].name) {
                                    tot = tot + Number(data[1].count)
                                    chartData.push(data[1].count)
                                    chartLabels.push(data[1].name)
                                  }
                                })
                                setChartData(chartData)
                                setChartLabels(chartLabels)
                                setTotCount(tot)
                                window.App.setState({loadingEntrylogCountByZone: false})
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString());
                                window.App.setState({loadingEntrylogCountByZone: false})
                              })
                          }
                          }/>
                </div>
                }
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                  <span className={"p-text-light"}
                        dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear()).replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarEntrylogList} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({sidebarEntrylogList: false})
                   }}>
            <h2 className={"text-primary"}>Belépési napló</h2>
            <EntrylogListComponent view="advanced"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEntryzoneCreate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({sidebarEntryzoneCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új zóna</h2>
            <EntryzoneFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEntryzoneUpdate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({sidebarEntryzoneUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Zóna módosítása</h2>
            <EntryzoneFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEntryterminalCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEntryterminalCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új terminál</h2>
            <EntryterminalFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEntryterminalUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEntryterminalUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Terminál módosítása</h2>
            <EntryterminalFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarEntryterminalShow} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarEntryterminalShow: false})
                   }}>
            <h2 className={"text-primary"}>Terminál megtekintése</h2>
            <EntryterminalShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarCheckinLiveShow} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     clearInterval(window.liveInterval)
                     window.App.setState({sidebarCheckinLiveShow: false})
                   }}>
            <h2 className={"text-primary"}>Élő nézet</h2>
            <EntrylogLiveListComponent/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarGuestCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarGuestCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új vendég</h2>
            <GuestFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarGuestUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarGuestUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Vendég módosítása</h2>
            <GuestFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarGuestShow} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarGuestShow: false})
                   }}>
            <h2 className={"text-primary"}>Vendég megtekintése</h2>
            <GuestShowComponent initialValues={window.App.state.props}/>
          </Sidebar>

        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          {/*<i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>*/}
          {/*<span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>*/}
          {/*Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.*/}
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(EntryDashboardPage);
