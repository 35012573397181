import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {TabView, TabPanel} from 'primereact/tabview';

import ReceiptFormComponent from "../../components/receipt/ReceiptFormComponent";
import ReceiptListComponent from "../../components/receipt/ReceiptListComponent";
import ReceiptCostImportComponent from "../../components/receipt/ReceiptCostImportComponent";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import ReceiptShowComponent from "../../components/receipt/ReceiptShowComponent";
import ReceiptSzamlazzhuImportComponent from "../../components/receipt/ReceiptSzamlazzhuImportComponent";

export const FinanceDashboardPage = () => {

  const [receiptgroups, setReceiptgroups] = useState([]);

  useEffect(() => {
    window.App.pageHeight()
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('finance')[0]) {
        document.getElementsByClassName('finance')[0].classList.add("activeMenu")
      }
    },1000)
    document.title = 'Pénzügy' + ' - ' + process.env.REACT_APP_TITLE;
    ReceiptgroupListService(
      JSON.stringify( {
        first: 0,
        rows: 9999,
        page: 0,
      })
    )
      .then(response => {
        setReceiptgroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString());
      })
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_finance') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              {localStorage.getItem('roles').toString().includes('role_receipt_read') &&
              <>
                <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                  <TabPanel header="Összes bizonylat listája">
                    <div className={"p-px-5 p-pb-5"}>
                      <ReceiptListComponent view="simple"/>
                    </div>
                  </TabPanel>
                  {receiptgroups.map((receiptgroup, index) => {
                    if (receiptgroup.active && receiptgroup.active === true) {
                      return (
                        <TabPanel header={receiptgroup.name}>
                          <div className={"p-px-5 p-pb-5"}>
                            <ReceiptListComponent receiptGroup={receiptgroup.id} receiptType={receiptgroup.receiptType} view="simple"/>
                          </div>
                        </TabPanel>
                      )
                    } else {
                      return (
                        <TabPanel disabled></TabPanel>
                      )
                    }
                  })}
                </TabView>
              </>
              }
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {localStorage.getItem('roles').toString().includes('role_receipt_write') &&
                  <>
                    {receiptgroups.map((receiptgroup, index) => {
                      if (receiptgroup.active !== false) {
                        let icon = 'pi-plus-circle'
                        let operation = 'létrehozása'
                        let external = false
                        let outgoing = false
                        if (receiptgroup.external === true) {
                          icon = 'pi-plus'
                          operation = 'rögzítése'
                          external = true
                        }
                        return (
                          <>
                            <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                              <a onClick={() => {
                                if (receiptgroup.receiptType === 'invoice' && receiptgroup.outgoing === true) {
                                  window.App.setState({
                                    sidebarReceiptInvoiceCreate: true,
                                    props: {
                                      type: 'invoice',
                                      receiptgroupId: receiptgroup.id,
                                      external: receiptgroup.external,
                                      outgoing: receiptgroup.outgoing,
                                      name: receiptgroup.name,
                                      operation: operation
                                    }
                                  })
                                }
                                else if (receiptgroup.receiptType === 'invoice' && receiptgroup.outgoing === false) {
                                  window.App.setState({
                                    sidebarReceiptCostCreate: true,
                                    props: {
                                      type: 'cost',
                                      receiptgroupId: receiptgroup.id,
                                      external: receiptgroup.external,
                                      outgoing: receiptgroup.outgoing,
                                      name: receiptgroup.name,
                                      operation: operation
                                    }
                                  })
                                }
                                else if (receiptgroup.receiptType === 'delivery_note') {
                                  window.App.setState({
                                    sidebarReceiptDeliveryNoteCreate: true,
                                    props: {
                                      type: 'delivery_note',
                                      receiptgroupId: receiptgroup.id,
                                      external: receiptgroup.external,
                                      outgoing: receiptgroup.outgoing,
                                      name: receiptgroup.name,
                                      operation: operation
                                    }
                                  })
                                }
                                else if (receiptgroup.receiptType === 'fee_request') {
                                  window.App.setState({
                                    sidebarReceiptFeeRequestCreate: true,
                                    props: {
                                      type: 'fee_request',
                                      receiptgroupId: receiptgroup.id,
                                      external: receiptgroup.external,
                                      outgoing: receiptgroup.outgoing,
                                      name: receiptgroup.name,
                                      operation: operation
                                    }
                                  })
                                }
                              }}
                                    className={"w-100"}>
                                <Card className={"p-text-center w-100 shadow-none"}>
                                  <i className={"pi " + icon + " bg-white p-d-block p-mb-2"}
                                     style={{'fontSize': '2em'}}></i>
                                  Új <span style={{textTransform: "lowercase"}}>{receiptgroup.name}</span> {operation}
                                </Card>
                              </a>
                            </div>
                          </>
                        )
                      }
                    })}
                  </>
                  }
                  {localStorage.getItem('roles').toString().includes('role_receipt_write') &&
                  <>
                    {receiptgroups.map((receiptgroup, index) => {
                      if (receiptgroup.external === true && receiptgroup.active !== false
                        && receiptgroup.receiptType === 'invoice' && receiptgroup.outgoing === false) {
                        return (
                          <>
                            <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                              <a onClick={() => window.App.setState({
                                sidebarReceiptCostImport: true,
                                props: {
                                  receiptgroupId: receiptgroup.id,
                                }
                              })} className={"w-100"}>
                                <Card className={"p-text-center w-100 shadow-none"}>
                                  <i className="pi pi-cloud-upload bg-white p-d-block p-mb-2"
                                     style={{'fontSize': '2em'}}></i>
                                  {receiptgroup.name} importálása
                                </Card>
                              </a>
                            </div>
                          </>
                        )
                      }
                    })}
                  </>
                  }
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex "}>
                    <a onClick={() => window.App.setState({
                      sidebarReceiptSzamlazzhuImport: true,
                    })} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-cloud-upload bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Számlázz.hu importálás
                      </Card>
                    </a>
                  </div>
                </div>
                <div className={"p-text-center"}>
                  <h3 className={"p-text-center p-text-normal"}>Gyorsmenü</h3>
                  <Button className={"p-button-outlined p-button-sm p-ml-2 p-mt-2 p-text-light"}
                          onClick={() => window.App.setState({
                            lazyparamsReceiptList: {
                              first: 0,
                              rows: 10,
                              page: 1,
                              sortField: 'createDate',
                              sortOrder: -1,
                              filters: {
                                type: {
                                  value: "invoice"
                                },
                                outgoing: {
                                  value: false
                                },
                                paid: {
                                  value: false
                                }
                              }
                            },
                            sidebarReceiptList: true
                          })}
                  >
                    <i className="pi pi-check"></i>&nbsp;Nyitott költségszámlák
                  </Button>
                  <Button className={"p-button-outlined p-button-sm p-ml-2 p-mt-2 p-text-light"}
                          onClick={() => window.App.setState({
                            lazyparamsReceiptList: {
                              first: 0,
                              rows: 10,
                              page: 1,
                              sortField: 'createDate',
                              sortOrder: -1,
                              filters: {
                                type: {
                                  value: "invoice"
                                },
                                outgoing: {
                                  value: true
                                },
                                paid: {
                                  value: false
                                }
                              }
                            },
                            sidebarReceiptList: true
                          })}
                  >
                    <i className="pi pi-check"></i>&nbsp;Nyitott bevételi számlák
                  </Button>
                  <Button className={"p-button-outlined p-button-sm p-ml-2 p-mt-2 p-text-light"}
                          onClick={() => window.App.setState({
                            lazyparamsReceiptList: {
                              first: 0,
                              rows: 10,
                              page: 1,
                              sortField: 'createDate',
                              sortOrder: -1,
                              filters: {
                                type: {
                                  value: "invoice"
                                },
                                outgoing: {
                                  value: false
                                },
                                allowed: {
                                  value: false
                                }
                              }
                            },
                            sidebarReceiptList: true
                          })}
                  >
                    <i className="pi pi-check"></i>&nbsp;Számla jóváhagyás
                  </Button>
                </div>
                <div className={"p-py-3"}>
                  <hr className={"p-my-5 w-50"}/>
                </div>
                {/*<Chart type="bar"*/}
                {/*       options={{*/}
                {/*         animation: {*/}
                {/*           duration: 0*/}
                {/*         },*/}
                {/*         plugins: {*/}
                {/*           legend: {*/}
                {/*             labels: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           }*/}
                {/*         },*/}
                {/*         scales: {*/}
                {/*           x: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*           y: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*         }*/}
                {/*       }}*/}
                {/*       data={{*/}
                {/*         labels: ['Január', 'December', 'November', 'Október'],*/}
                {/*         datasets: [*/}
                {/*           {*/}
                {/*             label: '',*/}
                {/*             data: [30, 50, 32, 12],*/}
                {/*             backgroundColor: [*/}
                {/*               "#42A5F5",*/}
                {/*               "#66BB6A",*/}
                {/*               "#FFA726",*/}
                {/*               "#F8f8f8"*/}
                {/*             ]*/}
                {/*           }*/}
                {/*         ]*/}
                {/*       }}/>*/}
                {/*<h3 className={"p-text-center p-text-normal"}>Bevétel</h3>*/}
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                    <span className={"p-text-light"}
                          dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptList} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => window.App.setState({
                     sidebarReceiptList: false,
                     lazyparamsReceiptList: null,
                   })}>
            <h2 className={"text-primary"}>Bizonylatok listája</h2>
            <ReceiptListComponent view="advanced"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptCostCreate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptCostCreate: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <ReceiptFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptUpdate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptUpdate: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <ReceiptFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptInvoiceCreate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({
                       rerenderReceiptList: true,
                       sidebarReceiptInvoiceCreate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <ReceiptFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptDeliveryNoteCreate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({
                       rerenderReceiptList: true,
                       sidebarReceiptDeliveryNoteCreate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <ReceiptFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptFeeRequestCreate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({
                       rerenderReceiptList: true,
                       sidebarReceiptFeeRequestCreate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <ReceiptFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptCostImport} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarReceiptCostImport: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <h2 className={"text-primary"}>Költségszámlák importálása</h2>
            <ReceiptCostImportComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptSzamlazzhuImport} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarReceiptSzamlazzhuImport: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <h2 className={"text-primary"}>Számlázz.hu importálás</h2>
            <ReceiptSzamlazzhuImportComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptShow} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptShow: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <h2 className={"text-primary"}>Bizonylat megtekintése</h2>
            <ReceiptShowComponent initialValues={window.App.state.props}/>
          </Sidebar>

        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          {/*<i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>*/}
          {/*<span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>*/}
          {/*Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.*/}
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(FinanceDashboardPage);
