import axios from "axios";

export default function ProjectMaterialDeleteService(projectId, materialId) {
  return axios({
    method: 'delete',
    url: process.env.REACT_APP_API_HOST + "/project/" + projectId + "/productMaterial/" + materialId,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
