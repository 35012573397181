import axios from "axios";

export default function ReceiptitemDeleteService(receiptId, itemId) {
  return axios({
    method: 'delete',
    url: process.env.REACT_APP_API_HOST + "/receipt/" + receiptId + "/item/" + itemId,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
