import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import download from 'js-file-download';
import moment from "moment";

import EntrylocationCurrentEmployeesListService from "../../services/entrylocation/EntrylocationCurrentEmployeesListService";
import WorktypeAttachableEmployeeListService from "../../services/worktype/WorktypeAttachableEmployeeListService";

const EntrylocationCurrentEmployeesComponent = (props) => {

  const [currentEmployees, setCurrentEmployees] = useState([])

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'name',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      EntrylocationCurrentEmployeesListService(JSON.stringify(lazyParams))
        .then(response => {
          setCurrentEmployees(response.data.items);
          setTotalRecords(response.data.total_item_count);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
      // WorktypeAttachableEmployeeListService(JSON.stringify(lazyParams), worktypeId)
      //   .then(response => {
      //     setTotalRecords(response.data.total_item_count);
      //     set(response.data.items);
      //     setLoading(false);
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString());
      //   })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadLazyData();
    // EntrylocationCurrentEmployeesListService()
    //   .then(response => {
    //     setCurrentEmployees(response.data.items);
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString());
    //   })
  }, [lazyParams])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={currentEmployees}
      autoLayout={true}
      filterDisplay="row"
      responsiveLayout="scroll"
      stripedRows
      paginator first={lazyParams.first}
      totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField}
      sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters}
      loading={loading} lazy
      paginatorTemplate={pager} rows={rows}
    >
      <Column body={(rowData) => {
        return (
          <React.Fragment>
            <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                    tooltip="Megtekintés"
                    onClick={() => {
                        let _rowData = {
                          ...rowData,
                          readOnly: true
                        }
                        window.App.setState({
                          sidebarEmployeeShow: true,
                          props: _rowData,
                        })
                    }}/>
            </React.Fragment>
        )
      }}></Column>
      <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
      <Column field="companyName" header="Cég" sortable filter filterPlaceholder="Cég"></Column>
      <Column field="employeePositionName" header="Beosztás" sortable filter filterPlaceholder="Beosztás"></Column>
    </DataTable>

  )
}
export default withTranslation('common')(EntrylocationCurrentEmployeesComponent);
