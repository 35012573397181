import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {addLocale} from 'primereact/api';
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dropdown} from "primereact/dropdown";

import ScheduleFormService from '../../services/schedule/ScheduleFormService'
import WorktypeAttachableEmployeeListService from "../../services/worktype/WorktypeAttachableEmployeeListService";
import WorktypeEmployeeAddService from "../../services/worktype/WorktypeEmployeeAddService";
import WorktypeEmployeeListService from "../../services/worktype/WorktypeEmployeeListService";
import WorktypeEmployeeRemoveService from "../../services/worktype/WorktypeEmployeeRemoveService";
import EmployeeListService from "../../services/employee/EmployeeListService";

const ScheduleFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    let employeeIds = []
    employeesSource.map(function(item, index) {
      employeeIds.push(item.id)
    })
    let _data = {
      ...data,
      employeeIds: employeeIds
    }
    setLoading(true)
    setFormData({
      ...formData,
      _data
    });
    ScheduleFormService(_data, 'post', props.initialValues.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        window.App.setState({
          rerenderScheduleList: true,
          sidebarScheduleCreate: false
        })
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  // employees assign
  const [employeesSource, setEmployeesSource] = useState([]);
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesTarget, setEmployeesTarget] = useState([]);
  const [employeesToDelete, setEmployeesToDelete] = useState([]);

  const [loadingEmployeesSource, setLoadingEmployeesSource] = useState(false);
  const [totalRecordsEmployeesSource, setTotalRecordsEmployeesSource] = useState(0);
  const [rowsEmployeesSource, setRowsEmployeesSource] = useState(10);
  const [lazyParamsEmployeesSource, setLazyParamsEmployeesSource] = useState({
    first: 0,
    rows: rowsEmployeesSource,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutEmployeesSource = null;
  const loadLazyDataEmployeesSource = (worktypeId) => {
    setLoadingEmployeesSource(true);
    if (loadLazyTimeoutEmployeesSource) {
      clearTimeout(loadLazyTimeoutEmployeesSource);
    }
    loadLazyTimeoutEmployeesSource = setTimeout(() => {
      EmployeeListService(JSON.stringify(lazyParamsEmployeesSource))
        .then(response => {
          setTotalRecordsEmployeesSource(response.data.total_item_count);
          setEmployeesSource(response.data.items);
          setLoadingEmployeesSource(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const onSortEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const onFilterEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const pagerEmployeesSource = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsEmployeesSource(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadLazyDataEmployeesSource();
  }, [lazyParamsEmployeesSource])

  return (
    <>
      <Panel>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit} className="">
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-4 p-xl-4"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-list"}></i> Alapadatok
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-6"}>
                    <Field name="dayFrom" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="dayFrom"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Dátumtól</label>
                        <span className="p-input-icon-right">
                      {formData.dayFrom && (formData.dayFrom !== null || formData.dayFrom !== undefined) ? (
                        <Calendar dateFormat="yy-mm-dd"
                                  monthNavigator={true}
                                  yearNavigator={true}
                                  yearRange={"2022:2099"}
                                  keepInvalid="true"
                                  onChange={(e) => {
                                    if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                      setFormData({
                                        ...formData,
                                        dayFrom: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                      })
                                    } else {
                                      setFormData({
                                        ...formData,
                                        dayFrom: null
                                      })
                                    }
                                  }}
                                  value={new Date(formData.dayFrom)}
                                  mask="9999-99-99"
                                  id={"dayFrom"}
                                  locale={"hu"}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                        />
                      ) : (
                        <Calendar dateFormat="yy-mm-dd"
                                  monthNavigator={true}
                                  yearNavigator={true}
                                  yearRange={"2022:2099"}
                                  keepInvalid="true"
                                  onChange={(e) => {
                                    if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                      setFormData({
                                        ...formData,
                                        dayFrom: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                      })
                                    } else {
                                      setFormData({
                                        ...formData,
                                        dayFrom: null
                                      })
                                    }
                                  }}
                                  mask="9999-99-99"
                                  id={"dayFrom"}
                                  locale={"hu"}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                        />
                      )}
                    </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                  <div className={"p-col-12 p-lg-6"}>
                    <Field name="dayUntil" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="dayUntil"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Dátumig</label>
                        <span className="p-input-icon-right">
                      {formData.dayUntil && (formData.dayUntil !== null || formData.dayUntil !== undefined) ? (
                        <Calendar dateFormat="yy-mm-dd"
                                  monthNavigator={true}
                                  yearNavigator={true}
                                  yearRange={"2022:2099"}
                                  keepInvalid="true"
                                  onChange={(e) => {
                                    if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                      setFormData({
                                        ...formData,
                                        dayUntil: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                      })
                                    } else {
                                      setFormData({
                                        ...formData,
                                        dayUntil: null
                                      })
                                    }
                                  }}
                                  value={new Date(formData.dayUntil)}
                                  mask="9999-99-99"
                                  id={"dayUntil"}
                                  locale={"hu"}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                        />
                      ) : (
                        <Calendar dateFormat="yy-mm-dd"
                                  monthNavigator={true}
                                  yearNavigator={true}
                                  yearRange={"2022:2099"}
                                  keepInvalid="true"
                                  onChange={(e) => {
                                    if (e.target.value && (e.target.value.length >= 10 || e.target.value.length === undefined)) {
                                      setFormData({
                                        ...formData,
                                        dayUntil: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                      })
                                    } else {
                                      setFormData({
                                        ...formData,
                                        dayUntil: null
                                      })
                                    }
                                  }}
                                  mask="9999-99-99"
                                  id={"dayUntil"}
                                  locale={"hu"}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                        />
                      )}
                    </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                  <div className={"p-col-12 p-lg-6"}>
                    <Field name="timeFrom" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="timeFrom"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Időponttól</label>
                        <span className="p-input-icon-right">
                      {formData.timeFrom && (formData.timeFrom !== null || formData.timeFrom !== undefined) ? (
                        <Calendar timeOnly
                                  hourFormat="24"
                                  keepInvalid="true"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      timeFrom: moment.utc(e.target.value).local().format('HH:mm')
                                    })
                                  }}
                                  value={formData.timeFrom}
                                  mask="99:99"
                                  id={"timeFrom"}
                                  locale={"hu"}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                        />
                      ) : (
                        <Calendar timeOnly
                                  hourFormat="24"
                                  keepInvalid="true"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      timeFrom: moment.utc(e.target.value).local().format('HH:mm')
                                    })
                                  }}
                                  mask="99:99"
                                  id={"timeFrom"}
                                  locale={"hu"}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                        />
                      )}
                    </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                  <div className={"p-col-12 p-lg-6"}>
                    <Field name="timeUntil" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="timeUntil"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Időpontig</label>
                        <span className="p-input-icon-right">
                      {formData.timeUntil && (formData.timeUntil !== null || formData.timeUntil !== undefined) ? (
                        <Calendar timeOnly
                                  hourFormat="24"
                                  keepInvalid="true"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      timeUntil: moment.utc(e.target.value).local().format('HH:mm')
                                    })
                                  }}
                                  value={formData.timeUntil}
                                  mask="99:99"
                                  id={"timeUntil"}
                                  locale={"hu"}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                        />
                      ) : (
                        <Calendar timeOnly
                                  hourFormat="24"
                                  keepInvalid="true"
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      timeUntil: moment.utc(e.target.value).local().format('HH:mm')
                                    })
                                  }}
                                  mask="99:99"
                                  id={"timeUntil"}
                                  locale={"hu"}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                        />
                      )}
                    </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                </div>
              </div>
              <div className={"p-col-12 p-lg-8 p-xl-8"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3 className={"p-mt-0 p-pt-0"}>
                      <i className={"pi pi-list"}></i> Kiválasztható munkavállalók
                    </h3>
                  </div>
                </div>
                <DataTable
                  emptyMessage="Nincs találat."
                  value={employeesSource} paginator first={lazyParamsEmployeesSource.first}
                  totalRecords={totalRecordsEmployeesSource} onPage={onPageEmployeesSource}
                  onSort={onSortEmployeesSource} sortField={lazyParamsEmployeesSource.sortField}
                  sortOrder={lazyParamsEmployeesSource.sortOrder}
                  onFilter={onFilterEmployeesSource} filters={lazyParamsEmployeesSource.filters}
                  loading={loadingEmployeesSource} lazy
                  paginatorTemplate={pagerEmployeesSource} rows={rowsEmployeesSource}
                  emptyMessage="Nincs találat."
                  autoLayout={true}
                  selection={employeesToAdd} onSelectionChange={e => {
                  setEmployeesToAdd(e.value)
                }} dataKey="id"
                  filterDisplay="row"
responsiveLayout="scroll"
                  stripedRows
                  selectionMode="checkbox"
                >
                  <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                  <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
                </DataTable>
              </div>
            </div>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                        className="p-button-success"/>
              </div>
            </div>
          </form>
        )}/>
      </Panel>
    </>
  )
}
export default withTranslation('common')(ScheduleFormComponent);
