import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";

import EntrylocationInviteSubcontractorService
  from "../../services/entrylocation/EntrylocationInviteSubcontractorService";
import EntrylocationInviteOwnerService from "../../services/entrylocation/EntrylocationInviteOwnerService";
import CompanyListService from "../../services/company/CompanyListService";
import EntrylocationListCompanyService from "../../services/entrylocation/EntrylocationListCompanyService";
import {Dropdown} from "primereact/dropdown";

const EntrylocationSubcontractorInviteComponent = (props) => {

  const [formData, setFormData] = useState({
    company: {
      id: Number(localStorage.getItem('defaultCompanyId'))
    },
    entryLocation: {'id': Number(localStorage.getItem('networkId'))}
  });
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const validate = (data) => {
    let errors = {};
    if (!data.company) {
      errors.company = 'Kötelező mező';
    }
    if (!data.name) {
      errors.name = 'Kötelező mező';
    }
    if (!data.email) {
      errors.email = 'Az email mező nem lehet üres.';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = 'Mindenképpen email formátumot adj meg! Pl. example@email.com';
    }
    return errors;
  };


  const onSubmit = (data, form) => {
    setLoading(true)
    EntrylocationInviteSubcontractorService(data, window.App.state.props)
      .then(response => {
        window.App.toastShow('success', 'Sikeres meghívó küldés!');
        window.App.setState({dialogEntrylocationSubcontractor: false})
        EntrylocationListCompanyService(response.data.id)
          .then(response => {
            window.App.setState({dataEntrylocationSubcontractors: response.data.items})
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const searchCompany = (event) => {
    setSelectedCompany(event.query.replace('Nincs találat', '').replace(': ', ''))
    CompanyListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCompanies(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedCompany(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    CompanyListService()
      .then(response => {
        setCompanies(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <Form onSubmit={onSubmit}
          initialValues={formData}
          validate={validate}
          render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} className="">
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-12 p-lg-12"}>
                  <h4 className={"p-mt-0 p-mb-1 p-p-0"}>Meghívót küldő vállalkozás</h4>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="company" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="company"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>
                      <span className="p-input-icon-right">
                                            {localStorage.getItem('roles').toString().includes('role_company_browse')
                                            || localStorage.getItem('roles').toString().includes('role_company_read') ? (

                                              <AutoComplete value={selectedCompany}
                                                            id={"company"}
                                                            suggestions={companies}
                                                            // forceSelection
                                                            completeMethod={searchCompany}
                                                            field={"name"}
                                                            delay="500"
                                                            placeholder={"Keresés gépeléssel..."}
                                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                                            onSelect={(e) => {
                                                              setFormData({
                                                                ...formData,
                                                                company: {id: e.value.id}
                                                              })
                                                              setSelectedCompany(e.value.name)
                                                              localStorage.setItem('defaultCompanyId', e.value.id)
                                                              localStorage.setItem('defaultCompanyName', e.value.name)
                                                            }}
                                                            dropdown
                                                            onClear={(e) => {
                                                              setSelectedCompany('')
                                                            }}
                                              />
                                            ) : (
                                              <Dropdown disabled className={"disabled"} id={"company"}/>
                                            )}
                  </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <div className={"p-grid p-pt-3"}>
                <div className={"p-col-12 p-md-12 p-lg-12"}>
                  <h4 className={"p-mt-0 p-mb-1 p-p-0"}>Meghívott vállalkozás</h4>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Meghívott vállalkozás
                        neve</label>
                      <span className="p-input-icon-right">
                              <InputText id="name" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             name: e.target.value
                                           })
                                         }}
                                         value={formData.name}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="vatNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="vatNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Meghívott vállalkozás
                        adószáma</label>
                      <span className="p-input-icon-right">
                              <InputText id="vatNumber" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             vatNumber: e.target.value
                                           })
                                         }}
                                         value={formData.vatNumber}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="contactLastName" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="contactLastName"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Kapcsolatartó
                        vezetéknév</label>
                      <span className="p-input-icon-right">
                              <InputText id="contactLastName" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             contactLastName: e.target.value
                                           })
                                         }}
                                         value={formData.contactLastName}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="contactFirstName" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="contactFirstName"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Kapcsolatartó
                        keresztnév</label>
                      <span className="p-input-icon-right">
                              <InputText id="contactFirstName" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             contactFirstName: e.target.value
                                           })
                                         }}
                                         value={formData.contactFirstName}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-4 p-lg-4"}>
                  <Field name="email" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="email"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Kapcsolatartó email</label>
                      <span className="p-input-icon-right">
                              <InputText id="email" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             email: e.target.value
                                           })
                                         }}
                                         value={formData.email}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              <div className={"p-grid p-mt-3"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                  <Button type="submit" label="Meghívó küldése" icon="pi pi-check" loading={loading}/>
                </div>
              </div>
            </form>
          )}/>
  )
}
export default withTranslation('common')(EntrylocationSubcontractorInviteComponent);
