import axios from "axios";

function UserLogoutService() {
  axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/logout",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  })
    .then(function (response) {

    })
    .catch(function (error) {

    })
  localStorage.removeItem('userToken');
  localStorage.removeItem('userId');
  localStorage.removeItem('userName');
  localStorage.removeItem('networkId');
  localStorage.removeItem('networkName');
  localStorage.removeItem('roles');
  Object.keys(localStorage)
    .filter(key => key.startsWith("module_"))
    .forEach(key => localStorage
      .removeItem((key)))
  window.location.replace('/#/');
  window.App.forceUpdate()
}
export default UserLogoutService;
