import axios from "axios";

export default function EntrycheckService(terminal, identifier, direction, date) {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/entryCheck/" + terminal + "/" + identifier + "/" + direction + "?isTest=true&checkDate=" + date,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
