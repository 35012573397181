import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import Kalend, {CalendarView} from 'kalend'
import 'kalend/dist/styles/index.css';
import {Panel} from "primereact/panel";
import hu from "../../translations/hu/kalend.json"
import {MultiSelect} from "primereact/multiselect";
import {Dropdown} from "primereact/dropdown";

import EmployeeListService from "../../services/employee/EmployeeListService";
import ProjectactionListService from "../../services/projectaction/ProjectactionListService";
import MachineListService from "../../services/machine/MachineListService";


const ProjectactionListComponent = (props) => {

  const [events, setEvents] = useState([]);
  const [filterEmployees, setFilterEmployees] = useState();
  const [employees, setEmployees] = useState([]);
  const [filterMachines, setFilterMachines] = useState();
  const [machines, setMachines] = useState([]);
  const [filterType, setFilterType] = useState('hasScheduled');

  useEffect(() => {
      ProjectactionListService(
        JSON.stringify({
          filters: {
            employeeIds: {value: "" + filterEmployees},
            machineIds: {value: "" + filterMachines},
            [filterType]: {value: true}
          }
        })
      )
        .then(response => {
          let _events = []
          response.data.items.map(function (action, index) {
            if (action && action.scheduledFrom) {
              _events.push({
                id: action.id,
                startAt: action.scheduledFrom,
                endAt: action.scheduledUntil,
                summary: action.projectName + ' / ' + action.employeeName + ' / ' + action.machineName,
                // color: 'blue',
                // calendarID: 'work'
              })
            }
          })
          setEvents(_events)
          window.App.setState({rerenderManufacturingcalendarList: false})
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, [window.App.state.rerenderManufacturingcalendarList === true]
  )

  useEffect(() => {
    ProjectactionListService()
      .then(response => {
        let _events = []
        response.data.items.map(function (action, index) {
          if (action && action.scheduledFrom) {
            _events.push({
              id: action.id,
              startAt: action.scheduledFrom,
              endAt: action.scheduledUntil,
              summary: action.projectName + ' / ' + action.employeeName + ' / ' + action.machineName,
              // color: 'blue',
              // calendarID: 'work'
            })
          }
        })
        setEvents(_events)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    EmployeeListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setEmployees(response.data.items)
        let filter = []
        response.data.items.map(function (item, index) {
          filter.push(item.id)
        })
        setFilterEmployees(filter)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    MachineListService(JSON.stringify({
      first: 0,
      rows: 9999,
      page: 0,
    }))
      .then(response => {
        setMachines(response.data.items)
        let filter = []
        response.data.items.map(function (item, index) {
          filter.push(item.id)
        })
        setFilterMachines(filter)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <>
      <Panel className={"p-pb-2"}
             id={"filters"}
      >
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <div className="p-field p-fluid">
              <label>Munkavállaló</label>
              <span className="p-input-icon-right">
                <MultiSelect options={employees}
                             optionLabel="name"
                             optionValue="id"
                             emptyMessage={"Még nincs munkavállaló..."}
                             filter
                             onChange={(e) => {
                               setFilterEmployees(e.value)
                               setTimeout(function () {
                                 window.App.setState({rerenderManufacturingcalendarList: true})
                               }, 200)
                             }}
                             value={filterEmployees}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <div className="p-field p-fluid">
              <label>Gép</label>
              <span className="p-input-icon-right">
                <MultiSelect options={machines}
                             optionLabel="name"
                             optionValue="id"
                             emptyMessage={"Még nincs gép..."}
                             filter
                             onChange={(e) => {
                               setFilterMachines(e.value)
                               setTimeout(function () {
                                 window.App.setState({rerenderManufacturingcalendarList: true})
                               }, 200)
                             }}
                             value={filterMachines}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <div className="p-field p-fluid">
              <label>Típus</label>
              <span className="p-input-icon-right">
                <Dropdown
                  showClear
                  options={
                  [
                    {label: 'Terv', value: 'hasScheduled'},
                    {label: 'Tény', value: 'hasExecuted'},
                  ]
                }
                          onChange={(e) => {
                            setFilterType(e.value)
                            setTimeout(function () {
                              window.App.setState({rerenderManufacturingcalendarList: true})
                            }, 200)
                          }}
                          value={filterType}
                />
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <div style={{height: '665px'}}>
        <Kalend
          customLanguage={hu}
          onEventClick={(e) => {
            window.App.setState({
              sidebarManufacturingcalendarUpdate: true,
              props: e
            })
          }}
          onNewEventClick={(e) => {
            window.App.setState({
              sidebarProjectactionCreate: true,
              props: {
                initialValues: {
                  startAt: e.startAt
                }
              }
          })
          }}
        events={events}
        initialDate={new Date().toISOString()}
        hourHeight={20}
        initialView={CalendarView.DAY}
        disabledViews={[CalendarView.THREE_DAYS]}
        timeFormat={'24'}
        weekDayStart={'Monday'}
        language={'en'}
        />
      </div>
    </>
  )
}
export default withTranslation('common')(ProjectactionListComponent);
