import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";
import {Dialog} from "primereact/dialog";
import download from 'js-file-download';

import EntrylogListService from "../../services/entrylog/EntrylogListService";
import EntrylogActionListService from "../../services/entrylog/EntrylogActionListService";
import EntrylogStatusListService from "../../services/entrylog/EntrylogStatusListService";
import {Panel} from "primereact/panel";
import {MultiSelect} from "primereact/multiselect";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import EntrylogExportService from "../../services/entrylog/EntrylogExportService";

const EntrylogListComponent = (props) => {

  const [entrylog, setEntrylog] = useState([]);
  const [imgDialog, setImgDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: 1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerender: false})
      EntrylogListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setEntrylog(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const [entrylogActions, setEntrylogActions] = useState([]);
  const [entrylogStatuses, setEntrylogStatuses] = useState([]);
  const entrylogStatus = (rowData) => {
    if (entrylogStatuses && entrylogStatuses[0]) {
      let entrylogStatus = entrylogStatuses.filter(item => item.id === rowData.status)
      if(entrylogStatus[0] && entrylogStatus[0].name) {
        return entrylogStatus[0].name
      }
    }
  }
  const entrylogAction = (rowData) => {
    if (entrylogActions && entrylogActions[0]) {
      let entrylogAction = entrylogActions.filter(item => item.id === rowData.action)
      if(entrylogAction[0] && entrylogAction[0].name) {
        return entrylogAction[0].name
      }
    }
  }

  const exportEntryLogs = () => {
    window.App.setState({loadingEntrylogExport: true})
    EntrylogExportService(JSON.stringify(lazyParams))
      .then(response => {
        window.App.toastShow('success', 'Sikeres export készítés!', '')
        download(response.data, localStorage.getItem('networkName')
          + '_'
          + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
          + '.xlsx')
        window.App.setState({loadingEntrylogExport: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.response)
        window.App.setState({loadingEntrylogExport: false})
      })
  }

  const actionFilterElement = <Dropdown options={entrylogActions}
                                        id={"entrylogActions"}
                                        optionValue={"id"}
                                        optionLabel={"name"}
                                        showClear
                                        emptyMessage={"Még nincs irány..."}
                                        onChange={(e) => {
                                          dt.current.filter(e.value, 'action', '');
                                          window.App.setState({
                                            valueName: e.value
                                          })
                                        }}
                                        value={window.App.state.valueName}
  />

  const statusFilterElement = <Dropdown options={entrylogStatuses}
                                        id={"entrylogStatuses"}
                                        optionValue={"id"}
                                        optionLabel={"name"}
                                        showClear
                                        emptyMessage={"Még nincs státusz..."}
                                        onChange={(e) => {
                                          dt.current.filter(e.value, 'status', '');
                                          window.App.setState({
                                            valueEntrylogStatuses: e.value
                                          })
                                        }}
                                        value={window.App.state.valueEntrylogStatuses}
  />

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    loadLazyData();
    if (!lazyParams.filters) {
      EntrylogActionListService('')
        .then(response => {
          setEntrylogActions(response.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
      EntrylogStatusListService('')
        .then(response => {
          setEntrylogStatuses(response.data);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }
  }, [lazyParams, window.App.state.rerender === true])

  return (
    <>
      {props && props.view === 'advanced' &&
      <Panel>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Időpont</label>
              <div className={"p-grid"}>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'createdAtFrom', '');
                                }, 500)
                              }}
                              mask="9999-99-99"
                              id={"createdAtFrom"}
                              locale={"hu"}
                    />
                  </span>
                </div>
                <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                  <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                </div>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'createdAtUntil', '');
                                }, 500)

                              }}
                              mask="9999-99-99"
                              id={"createdAtUntil"}
                              locale={"hu"}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"p-col-12 p-lg-1 p-xl-1"}>
            <div className="p-field p-fluid">
              <label>Irány</label>
              <span className="p-input-icon-right">
                {actionFilterElement}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-1 p-xl-1"}>
            <div className="p-field p-fluid">
              <label>Státusz</label>
              <span className="p-input-icon-right">
                {statusFilterElement}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Munkavállaló neve</label>
              <span className="p-input-icon-right">
                <InputText id="name"
                           onChange={(e) => {
                             dt.current.filter(e.target.value, 'name', '');
                           }}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Vállalkozás</label>
              <span className="p-input-icon-right">
                <InputText id="companyName"
                           onChange={(e) => {
                             dt.current.filter(e.target.value, 'companyName', '');
                           }}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Terminál</label>
              <span className="p-input-icon-right">
                <InputText id="terminalName"
                           onChange={(e) => {
                             dt.current.filter(e.target.value, 'entryTerminalName', '');
                           }}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Zóna</label>
              <span className="p-input-icon-right">
                <InputText id="zoneName"
                           onChange={(e) => {
                             dt.current.filter(e.target.value, 'entryZoneName', '');
                           }}
                />
              </span>
            </div>
          </div>
          {localStorage.getItem('roles').toString().includes('role_entry_log_extended_download') &&
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Export</label>
              <Button label={"XLS letöltés"}
                      icon={"pi pi-download"}
                      loading={window.App.state.loadingEntrylogExport}
                      onClick={exportEntryLogs}
              />
            </div>
          </div>
          }
        </div>
      </Panel>
      }
      <DataTable
        emptyMessage="Nincs találat."
        value={entrylog} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        ref={dt}
        filterDisplay="row"
responsiveLayout="scroll"
        stripedRows
      >
        <Column field="createdAt" header="Időpont" sortable body={(rowData) => {
          return (
            <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
          )
        }}></Column>
        {props.view === 'advanced' ? (
          <Column field="action" header="Irány" body={entrylogAction} sortable></Column>
        ) : (
          <Column field="action" header="Irány" body={entrylogAction} sortable filter
                  filterElement={actionFilterElement}></Column>
        )}
        {props.view === 'advanced' ? (
          <Column field="status" header="Státusz" sortable body={entrylogStatus}></Column>
        ) : (
          <Column field="status" header="Státusz" sortable filter body={entrylogStatus}
                  filterElement={statusFilterElement}></Column>
        )}
        {props.view === 'advanced' ? (
          <Column field="name" header="Név" sortable
                  body={(rowData) => {
                    if (rowData.employee !== null && rowData.employee.id) {
                      return (
                        <>{rowData.name} (Munkavállaló)</>
                      )
                    } else if (rowData.guest !== null && rowData.guest.id) {
                      return (
                        <>{rowData.name} (Vendég)</>
                      )
                    } else {
                      return (
                        <>{rowData.name}</>
                      )
                    }
                  }}
          ></Column>
        ) : (
          <Column field="name" header="Név" sortable sortable filter filterPlaceholder="Név"
                  body={(rowData) => {
                    if (rowData.employee !== null && rowData.employee.id) {
                      return (
                        <>{rowData.name} (Munkavállaló)</>
                      )
                    } else if (rowData.guest !== null && rowData.guest.id) {
                      return (
                        <>{rowData.name} (Vendég)</>
                      )
                    } else {
                      return (
                        <>{rowData.name}</>
                      )
                    }
                  }}
          ></Column>
        )}
        {props.view === 'advanced' ? (
          <Column field="companyName" header="Vállalkozás" sortable></Column>
        ) : (
          <Column field="companyName" header="Vállalkozás" sortable filter filterPlaceholder="Vállalkozás"></Column>
        )}
        <Column field="" header="Kép"
                body={(rowData) => {
                  return (
                    <>
                      <img src={process.env.REACT_APP_API_HOST + "/entry_log/image/" + rowData.id}
                           style={{'width': '50px', 'height': 'auto'}}
                           onClick={() => {
                             setImgDialog(true)
                             window.App.setState({props: rowData.id})
                           }}
                      />
                    </>
                  )
                }}
        ></Column>
        {props && props.view === 'advanced' &&
        <Column field="entryTerminalName" header="Terminál" sortable></Column>
        }
        {props && props.view === 'advanced' &&
        <Column field="entryZoneName" header="Zóna" sortable></Column>
        }
        {props && props.view === 'advanced' &&
        <Column field="note" header="Megjegyzés" sortable></Column>
        }
      </DataTable>
      <Dialog visible={imgDialog}
              style={{width: '750px'}}
              header="Személy megtekintése"
              modal
              onHide={() => {
                setImgDialog(false)
              }}>
        <img src={process.env.REACT_APP_API_HOST + "/entry_log/image/" + window.App.state.props}
             style={{'width': '700px', 'height': 'auto'}}/>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(EntrylogListComponent);
