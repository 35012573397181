import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import {Calendar} from "primereact/calendar";
import {addLocale} from 'primereact/api';
import {MultiSelect} from "primereact/multiselect";
import {Panel} from "primereact/panel";

import WorksheetListService from "../../services/worksheet/WorksheetListService";
import {InputText} from "primereact/inputtext";
import moment from "moment";
//import WorksheetDeleteService from "../../services/worksheet/XXXDeleteService";

const WorksheetListComponent = (props) => {

  const dt = useRef(null);
  const [worksheets, setWorksheets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderWorkseetList: false})
      WorksheetListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setWorksheets(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  // const deleteWorksheet = (rowData) => {
  //   WorksheetDeleteService(rowData.id)
  //     .then(response => {
  //       window.App.toastShow('success', 'Sikeres törlés!',);
  //       loadLazyData();
  //     })
  //     .catch(error => {
  //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
  //     })
  // }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {rowData.result === 'draft' ? (
          <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                  tooltip="Módosítás"
                  onClick={() => {
                    window.App.setState({
                      sidebarWorksheetUpdate: true,
                      props: rowData
                    })
                  }}/>
        ) : (
          <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2 disabled"
                  tooltip="Módosítás"
          />
        )}
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarWorksheetShow: true,
                    props: rowData
                  })
                }}/>
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteWorksheet(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  //dateFilter
  const [dateFilterSelected, setDateFilterSelected] = useState(null);
  const dateFilterElementTemplate =
    <span className={"date-column-filter text-nowrap"}>
      <Calendar dateFormat="yy-mm-dd" locale={"hu"} selectionMode="range" value={dateFilterSelected} showTime
                hideOnDateTimeSelect="true"
                onChange={e => {
                  if (!e.value) {
                    return
                  }
                  setDateFilterSelected(e.value);
                  dt.current.filter(moment.utc(e.value[0]).local().format('YYYY-MM-DD HH:mm'), 'workTimeFrom');
                  if (e.value[1]) {
                    dt.current.filter(moment.utc(e.value[1]).local().format('YYYY-MM-DD HH:mm'), 'workTimeTo');
                  }
                }}/>
    </span>

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderWorksheetList === true])

  return (
    <>
      {props && props.view === 'advanced' &&

      <Panel className={"advanced-search"}>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Munka kezdete</label>
              <span className="p-input-icon-right">
                <Calendar dateFormat="yy-mm-dd"
                          monthNavigator={true}
                          yearNavigator={true}
                          yearRange={"2022:2099"}
                          keepInvalid="true"
                          showTime
                          hideOnDateTimeSelect="true"
                          onChange={(e) => {
                            dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD HH:mm'), 'workTimeFrom', '');
                          }}
                          //mask="9999-99-99"
                          id={"workTimeFrom"}
                          locale={"hu"}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Munka vége</label>
              <span className="p-input-icon-right">
                <Calendar dateFormat="yy-mm-dd"
                          monthNavigator={true}
                          yearNavigator={true}
                          yearRange={"2022:2099"}
                          keepInvalid="true"
                          showTime
                          hideOnDateTimeSelect="true"
                          onChange={(e) => {
                            dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD HH:mm'), 'workTimeUntil', '');
                          }}
                          //mask="9999-99-99"
                          id={"workTimeUntil"}
                          locale={"hu"}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Munkavállaló neve</label>
              <span className="p-input-icon-right">
                <InputText id="employeeName"
                           onChange={(e) => {
                             dt.current.filter(e.target.value, 'employeeName', '');
                           }}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <div className="p-field p-fluid">
              <label>Projekt neve</label>
              <span className="p-input-icon-right">
                <InputText id="projectName"
                           onChange={(e) => {
                             dt.current.filter(e.target.value, 'projectName', '');
                           }}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Megnevezés</label>
              <span className="p-input-icon-right">
                <InputText id="title"
                           onChange={(e) => {
                             dt.current.filter(e.target.value, 'title', '');
                           }}
                />
              </span>
            </div>
          </div>
        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            <Button type="button" label="Export XLS" icon={"pi pi-download"}
                    onClick={() => {
                      alert('download')
                    }}
                    className="p-button-secondary p-ml-2"/>
          </div>
        </div>
      </Panel>
      }
      <DataTable
        emptyMessage="Nincs találat."
        value={worksheets} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        <Column field="workFrom" header="Munka kezdete" sortable body={(rowData) => {
          return (
            <>{moment.utc(rowData.workFrom).local().format('YYYY-MM-DD HH:mm')}</>
          )
        }}></Column>
        <Column field="workUntil" header="Munka vége" sortable body={(rowData) => {
          return (
            <>{moment.utc(rowData.workUntil).local().format('YYYY-MM-DD HH:mm')}</>
          )
        }}></Column>
        <Column field="employeeName" header="Munkavállaló" sortable filter filterPlaceholder="Munkavállaló"></Column>
        <Column field="projectName" header="Projekt" sortable filter filterPlaceholder="Projekt"></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(WorksheetListComponent);
