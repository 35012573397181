import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";
import download from 'js-file-download';

import EntrylocationListService from "../../services/entrylocation/EntrylocationListService";
import EntrylocationDeleteService from "../../services/entrylocation/EntrylocationDeleteService";
import EntrylocationDownloadReportService from "../../services/entrylocation/EntrylocationDownloadReportService";
import moment from "moment";

const EntrylocationListComponent = (props) => {

  const [entrylocation, setEntrylocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderEntrylocationList: false})
      EntrylocationListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setEntrylocation(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteEntrylocation = (rowData) => {
    EntrylocationDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_entry_location_update') &&
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarEntrylocationUpdate: true,
                    props: rowData
                  })
                }}/>
        }
        {localStorage.getItem('roles').toString().includes('role_entry_location_read') &&
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarEntrylocationShow: true,
                    props: rowData
                  })
                }}/>
        }
        {/*{localStorage.getItem('roles').toString().includes('role_entry_location_create') &&*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger p-mr-2"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteEntrylocation(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
        {localStorage.getItem('roles').toString().includes('role_entry_location_invite') &&
        <Button icon="pi pi-envelope" className="p-button-sm p-button-info p-button-highlighted p-mr-2"
                tooltip="Meghívás"
                type="button"
                onClick={() => {
                  window.App.setState({
                    props: rowData.id,
                    sidebarEntrylocationOwnerInvite: true
                  })
                }}/>
        }
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderEntrylocationList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={entrylocation} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
responsiveLayout="scroll"
      stripedRows
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
      <Column field="companyName" header="Cég" sortable filter filterPlaceholder="Cég"></Column>
      <Column field="active" header="Aktív" sortable
              body={(rowData) => {
                return (
                  <>
                    {rowData.active === true ? (
                      <>Igen</>
                    ) : (
                      <>Nem</>
                    )}
                  </>
                )
              }}
      ></Column>
      {localStorage.getItem('roles').toString().includes('role_entry_location_report') &&
      <Column body={(rowData) => {
        return (
          <>
            <Button className="p-button-sm p-button-secondary p-button-highlighted p-mr-2"
                    type="button"
                    label={"Projekt kimutatás"}
                    icon={"pi pi-download"}
                    loading={window.App.state.loadingEntrylocationDownloadReport}
                    onClick={() => {
                      window.App.setState({loadingEntrylocationDownloadReport: true})
                      EntrylocationDownloadReportService(rowData.id)
                        .then(response => {
                          download(response.data, localStorage.getItem('networkName')
                            + '_'
                            + 'projekt-kimutatas'
                            + '_'
                            + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                            + '.xlsx')
                          window.App.setState({loadingEntrylocationDownloadReport: false})
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString());
                          window.App.setState({loadingEntrylocationDownloadReport: false})
                        })
                    }} />
          </>
        )
      }}></Column>
      }
    </DataTable>

  )
}
export default withTranslation('common')(EntrylocationListComponent);
