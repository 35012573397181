import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {FileUpload} from "primereact/fileupload";

import ReceiptCostImportService from "../../services/receipt/ReceiptCostImportService";
import PartnerListService from "../../services/partner/PartnerListService";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import CompanyListService from "../../services/company/CompanyListService";

const ReceiptCostImportComponent = (props) => {

  //default form
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [receiptgroups, setReceiptgroups] = useState([]);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    ReceiptCostImportService(formData)
      .then(response => {
        window.App.toastShow('success', 'Sikeres importálás!', '');
        setFormData({...formData, xls: null})
        window.App.setState({
          sidebarReceiptCostImport: false,
          rerenderReceiptList: true,
        })
        setLoading(false)
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const fileUpload = (files) => {
    var reader = new FileReader();
    reader.onloadend = () => {
      let base64data = reader.result;
      setFormData({
        ...formData,
        file: base64data.toString().replace(/^data:(.*,)?/, '')
      })
    }
    reader.readAsDataURL(files.files[0])
  }

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const searchCompany = (event) => {
    setSelectedCompany(event.query.replace('Nincs találat', '').replace(': ', ''))
    CompanyListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCompanies(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedCompany(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }


  useEffect(() => {
    let _formData = {}
    ReceiptgroupListService()
      .then(response => {
        setReceiptgroups(response.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if(localStorage.getItem('defaultCompanyId')) {
      _formData = {
        ..._formData,
        company: {
          id: Number(localStorage.getItem('defaultCompanyId'))
        }
      }
    }
    if(window.App.state.props && window.App.state.props.receiptgroupId) {
      _formData = {
        ..._formData,
        receiptGroup: window.App.state.props.receiptgroupId
      }
    }
    setFormData(_formData)
  }, [])

  return (
      <Form onSubmit={onSubmit} initialValues={formData}
            validate={validate} render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} className="">

              <Panel headerTemplate={
                <div className={"p-grid"}>
                  <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>
                    <h3>
                      <i className={"pi pi-upload"}></i> Fájl feltöltése
                    </h3>
                  </div>
                </div>
              } className={""}>
                <div className={"p-grid"}>
                  <div className={"p-col"}>
                    <Field name="company" render={({input, meta}) => (
                      <div className="p-field p-fluid">
                        <label htmlFor="company"
                               className={classNames({'p-error': isFormFieldValid(meta)})}>Vevő</label>
                        <span className="p-input-icon-right">
                            <AutoComplete value={selectedCompany}
                                          id={"company"}
                                          suggestions={companies}
                                          // forceSelection
                                          completeMethod={searchCompany}
                                          field={"name"}
                                          delay="500"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              company: {id: e.value.id}
                                            })
                                            setSelectedCompany(e.value.name)
                                            localStorage.setItem('defaultCompanyId', e.value.id)
                                            localStorage.setItem('defaultCompanyName', e.value.name)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedCompany('')
                                          }}
                            />
                          </span>
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                  {/*<div className={"p-col-12 p-lg-4 p-xl-4"}>*/}
                  {/*  <Field name="receiptGroup" render={({input, meta}) => (*/}
                  {/*    <div className="p-field p-fluid">*/}
                  {/*      <label htmlFor="receiptGroup"*/}
                  {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Számlatömb</label>*/}
                  {/*      <span className="p-input-icon-right">*/}
                  {/*            <Dropdown {...input}*/}
                  {/*                      disabled*/}
                  {/*                      value={Number(window.App.state.props.receiptgroupId)}*/}
                  {/*                      options={receiptgroups}*/}
                  {/*                      optionLabel="name"*/}
                  {/*                      optionValue="id"*/}
                  {/*                      id={"receiptGroup"}*/}
                  {/*                      emptyMessage={"Még nincs számlatömb..."}*/}
                  {/*                      className={classNames({'p-error': isFormFieldValid(meta)})}*/}
                  {/*            />*/}
                  {/*          </span>*/}
                  {/*      {getFormErrorMessage(meta)}*/}
                  {/*    </div>*/}
                  {/*  )}/>*/}
                  {/*</div>*/}
                  <div className={"p-col"}>
                    <Field name="file" render={({input, meta}) => (
                      <div className="p-field">
                        {!formData.file ? (
                          <>
                            <label htmlFor="image"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>XLS kiválasztása</label>
                            <span className="">
                               <FileUpload mode="basic"
                                           name="file"
                                           id={"file"}
                                           accept=""
                                           auto="true"
                                           //maxFileSize={1000000}
                                           customUpload
                                           uploadHandler={fileUpload}
                                           chooseLabel={"Fájl kiválasztása"}
                                           uploadLabel={"Feltöltés"}
                               />
                            </span>
                          </>
                        ) : (
                          <>
                            <label htmlFor="image"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Feltöltött XLS
                              törlése</label>
                            <span className="p-d-block">
                              <Button type="button"
                                      className="p-button p-button-danger"
                                      icon="pi pi-trash"
                                      onClick={() => setFormData({...formData, file: null})}
                              >Törlés</Button>
                            </span>
                          </>
                        )}
                        {getFormErrorMessage(meta)}
                      </div>
                    )}/>
                  </div>
                </div>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col-12 p-lg-6 p-xl-6"}>

                  </div>
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                    {formData.file ? (
                      <Button type="submit" label="Import indítása"  icon={"pi pi-check"} loading={loading}
                              className="p-button-success"/>
                    ) : (
                      <Button type="button" label="Import indítása"
                              icon={"pi pi-check"}
                              className="p-button-success"
                              onClick={() => {
                                alert('Tölts fel egy XLS fájlt!')
                              }}
                      />
                    )}
                  </div>
                </div>
              </Panel>
        </form>
      )}/>
  )
}
export default withTranslation('common')(ReceiptCostImportComponent);
