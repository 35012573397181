import axios from "axios";

export default function IdentifierByGuestListService(query, guestId) {
    if(query && query.toString().includes('"multiSortMeta":[],')) {
    query = query.replace('"multiSortMeta":[],', '')
  }

  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/identifier/guest/" + guestId + "?" + query,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
