import axios from "axios";

export default function EmployeeMassInviteService(selectedEmployees) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/employee/invite",
    params: {},
    data: selectedEmployees,
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
