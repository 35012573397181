import axios from "axios";

export default function ProjectworktypeUpdateService(formData, method, worktypeId) {
  let _formData = {
    ...formData,
    id: worktypeId
  }
  return axios({
    method: method,
    url: process.env.REACT_APP_API_HOST + "/project_worktype/" + worktypeId,
    data: _formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
