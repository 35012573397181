import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import moment from "moment";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

import WorksheetShowService from "../../services/worksheet/WorksheetShowService";
import WorksheetResultsService from "../../services/worksheet/WorksheetResultsService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import EmployeeShowComponent from "../employee/EmployeeShowComponent";
import {Sidebar} from "primereact/sidebar";

const WorksheetShowComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [results, setResults] = useState([]);
  const [imgDialog, setImgDialog] = useState(false);
  const [signatureDialog, setSignatureDialog] = useState(false);

  const result = (id) => {
    if (results && results[0]) {
      let resultName = results.filter(item => item.id === id)
      if(resultName && resultName[0] && resultName[0].value) {
        return resultName[0].value
      }
    }
  }

  useEffect(() => {
    WorksheetShowService(props.initialValues.id)
      .then(response => {
        setFormData(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    WorksheetResultsService()
      .then(response => {
        setResults(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-list"}></i> Alapadatok
            </h3>
          </div>
        </div>
      } className={""}>
        <div className={"p-grid"}>
          {formData.projectName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Projekt</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.projectName}
            </p>
          </div>
          }
          {formData.employeeName &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Munkavállaló</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              <a onClick={() => {
                window.App.setState({
                  sidebarEmployeeShow: true,
                  props: formData
                })
              }}>{formData.employeeName}</a>
            </p>
          </div>
          }
          {formData.title &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Megnevezés</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.title}
            </p>
          </div>
          }
          {formData.instruction &&
          <div className={"p-col-12"}>
            <label>Feladat leírása</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.instruction}
            </p>
          </div>
          }
        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-8 p-xl-8"}>

          </div>
          <div className={"p-col-12 p-lg-4 p-xl-4 p-text-right"}>
            {formData.id && formData.result === 'draft' ? (
              <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                      onClick={() => {
                        window.App.setState({
                          sidebarWorksheetShow: false,
                          sidebarWorksheetUpdate: true,
                          props: formData
                        })
                      }}
              />
            ) : (
              <Button type="button" label="Módosítás" icon="pi pi-pencil" className="disabled p-button-warning"/>
            )}
          </div>
        </div>
      </Panel>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-link"}></i> Csatolmányok
            </h3>
          </div>
        </div>
      } className={"p-mt-3"}>
        <DataTable value={formData.attachments}
                   emptyMessage="Nincs találat."
                   autoLayout={true}
                   filterDisplay="row"
responsiveLayout="scroll"
                   stripedRows
        >
          <Column field="note" header="Megjegyzés"></Column>
          <Column field="filePath" header="Fájl" body={(rowData) => {
            if (rowData.type && rowData.type === 'image') {
              return (
                <>
                  <img src={process.env.REACT_APP_API_HOST + "/worksheet/" + formData.id + "/attachment/" + rowData.id
                  + "?token=" + localStorage.getItem('userToken') + "."
                  + localStorage.getItem("networkId")} width={"100%"} style={{maxWidth: '120px'}}
                  onClick={() => {setImgDialog(true)}}
                  />
                  <Dialog visible={imgDialog}
                          style={{width: '750px'}}
                          header="Csatolmány"
                          modal
                          onHide={() => {
                            setImgDialog(false)
                          }}>
                    <img src={process.env.REACT_APP_API_HOST + "/worksheet/" + formData.id + "/attachment/" + rowData.id
                    + "?token=" + localStorage.getItem('userToken') + "."
                    + localStorage.getItem("networkId")}
                         style={{'width': '700px', 'height': 'auto'}}/>
                  </Dialog>
                </>
              )
            } else {
              return (
                <>
                  <a
                    href={process.env.REACT_APP_API_HOST + "/worksheet/" + formData.id + "/attachment/" + rowData.id
                    + "?token=" + localStorage.getItem('userToken') + "."
                    + localStorage.getItem("networkId")}
                    target="_blank"
                  >
                    <i className={"pi pi-download"}></i>&nbsp;Letöltés
                  </a>
                </>
              )
            }
          }}></Column>
        </DataTable>
      </Panel>
      <Panel headerTemplate={
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <h3>
              <i className={"pi pi-chart-bar"}></i> Eredmények
            </h3>
          </div>
        </div>
      } className={"p-mt-3"}>
        <div className={"p-grid"}>
          {formData.result &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Munkavégzés eredménye</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {result(formData.result)}
            </p>
          </div>
          }
          {formData.workFrom &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Munka kezdete</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {moment.utc(formData.workFrom).local().format('YYYY-MM-DD HH:mm')}
            </p>
          </div>
          }
          {formData.workUntil &&
          <div className={"p-col-12 p-lg-4 p-xl-4"}>
            <label>Munka vége</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {moment.utc(formData.workUntil).local().format('YYYY-MM-DD HH:mm')}
            </p>
          </div>
          }
          {formData.note &&
          <div className={"p-col-12 p-lg-12 p-xl-12"}>
            <label>Munkavállaló megjegyzése</label>
            <p className={"p-mt-1 p-mb-0 p-text-bold"}>
              {formData.note}
            </p>
          </div>
          }
          {formData.signature &&
          <>
            <div className={"p-col-12 p-lg-3 p-text-center p-lg-offset-9"}
                 style={{border: '1px dashed #ccc', borderRadius: '3px'}}>
              <img src={formData.signature}
                   onClick={() => {
                     setSignatureDialog(true)
                   }}
                   className={"p-d-inline-block"} style={{maxWidth: '150px'}}/>
              {/*<div*/}
              {/*  dangerouslySetInnerHTML={{__html: formData.signature.replace('height="347"', 'height="auto"').replace('width="738"', 'width="100%"')}}*/}
              {/*  style={{maxWidth: '180px', overflowX: 'hidden', display: 'inline-block'}}></div>*/}
              <p className={"p-text-bold p-m-0 p-p-0"}>{formData.signatureName}</p>
            </div>
            <Dialog visible={signatureDialog}
                    style={{width: '750px'}}
                    header={formData.signatureName}
                    modal
                    onHide={() => {
                      setSignatureDialog(false)
                    }}>
              <img src={formData.signature}
                   style={{'width': '700px', 'height': 'auto'}}/>
            </Dialog>
          </>
          }
        </div>
      </Panel>
      <Sidebar position="left" visible={window.App.state.sidebarEmployeeShow} closeOnEscape={true}
               style={{width: '980px'}}
               onHide={() => {
                 window.App.setState({sidebarEmployeeShow: false})
               }}>
        <h2 className={"text-primary"}>Munkavállaló megtekintése</h2>
        <EmployeeShowComponent initialValues={window.App.state.props}/>
      </Sidebar>
    </>
  )
}
export default withTranslation('common')(WorksheetShowComponent);
