import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Timeline} from "primereact/timeline";
import {Card} from "primereact/card";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {GMap} from "primereact/gmap";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {InputNumber} from "primereact/inputnumber";
import {TabView, TabPanel} from 'primereact/tabview';
import {ProgressBar} from 'primereact/progressbar';
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import currencyFormatter from "currency-formatter";
import {AutoComplete} from "primereact/autocomplete";
import {Dropdown} from "primereact/dropdown";
import {InputSwitch} from "primereact/inputswitch";

import ProjectmilestoneListService from "../../services/projectmilestone/ProjectmilestoneListService";
import ProjectReceiptItemsService from "../../services/project/ProjectReceiptItemsService";
import ProjectShowService from "../../services/project/ProjectShowService";
import IdentifierByProjectListService from "../../services/identifier/IdentifierByProjectListService";
import ProjectSummaryService from "../../services/project/ProjectSummaryService";
import ProjectMaterialListService from "../../services/project/ProjectMaterialListService";
import ReceiptitemFormService from "../../services/receiptitem/ReceiptitemFormService";
import ReceiptShowService from "../../services/receipt/ReceiptShowService";
import ProductListService from "../../services/product/ProductListService";
import UnitListService from "../../services/unit/UnitListService";
import ProjectMaterialFormService from "../../services/project/ProjectMaterialFormService";
import ReceiptitemDeleteService from "../../services/receiptitem/ReceiptitemDeleteService";
import ProjectMaterialDeleteService from "../../services/project/ProjectMaterialDeleteService";
import VatListService from "../../services/vat/VatListService";
import {Calendar} from "primereact/calendar";
import ProjectmilestoneCreateService from "../../services/projectmilestone/ProjectmilestoneCreateService";
import ProjectmilestoneUpdateService from "../../services/projectmilestone/ProjectmilestoneUpdateService";
import {addLocale} from "primereact/api";
import ProjectitemListService from "../../services/projectitem/ProjectitemListService";
import ProjectitemListComponent from "../projectitem/ProjectitemListComponent";
import ProjectitemFormComponent from "../projectitem/ProjectitemFormComponent";
import ProjectitemImportComponent from "../projectitem/ProjectitemImportComponent";
import EntrylogListService from "../../services/entrylog/EntrylogListService";
import EntrylogCountService from "../../services/entrylog/EntrylogCountService";
import {MultiSelect} from "primereact/multiselect";
import ProjectmilestoneReceiptItemAddService
  from "../../services/projectmilestone/ProjectmilestoneReceiptItemAddService";
import ProjectmilestoneReceiptItemListService
  from "../../services/projectmilestone/ProjectmilestoneReceiptItemListService";
import PartnerListService from "../../services/partner/PartnerListService";
import ScheduleListComponent from "../schedule/ScheduleListComponent";
import ProjectmilestoneitemListComponent from "../projectmilstoneitem/ProjectmilestoneitemListComponent";
import ReceiptListService from "../../services/receipt/ReceiptListService";
import ReceiptShowComponent from "../receipt/ReceiptShowComponent";
import {Sidebar} from "primereact/sidebar";

const ProjectShowComponent = (props) => {

  const [formData, setFormData] = useState({
    lng: null,
    lat: null,
  });
  const [loading, setLoading] = useState(false);

  const [summary, setSummary] = useState({});
  const [receiptItems, setReceiptItems] = useState([]);
  const [selectedReceiptItems, setSelectedReceiptItems] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [vats, setVats] = useState([]);

  // gmpap
  const google = window.google
  const loadGoogleMaps = (callback) => {
    const existingScript = document.getElementById('googleMaps');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://maps.google.com/maps/api/js?key=' + process.env.REACT_APP_GMAP_KEY;
      script.id = 'googleMaps';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  // const removeGoogleMaps = () => {
  //   const mapScript = document.getElementById('googleMaps');
  //
  //   if (mapScript) {
  //     mapScript.parentNode.removeChild(mapScript);
  //
  //     const head = document.getElementsByTagName('head')[0];
  //     const scripts = head.getElementsByTagName('script');
  //     for (let i = 0; i < scripts.length; i++) {
  //       let script = scripts[i];
  //       let src = script.src;
  //
  //       if (src.startsWith('https://maps.google.com/maps')) {
  //         head.removeChild(script);
  //       }
  //     }
  //   }
  // };

  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [mapDialogVisible, setMapDialogVisible] = useState(false);
  const [mapOverlays, setMapOverlays] = useState(null);
  const [mapSelectedPosition, setMapSelectedPosition] = useState(null);
  const toast = useRef(null);

  //const toast = useRef(null);
  const infoWindow = useRef(null);

  const onMapClick = (event) => {
    setMapDialogVisible(true);
    setMapSelectedPosition(event.latLng)
  }

  const onOverlayClick = (event) => {
    let isMarker = event.overlay.getTitle !== undefined;

    if (isMarker) {
      let title = event.overlay.getTitle();
      infoWindow.current = infoWindow.current || new google.maps.InfoWindow();
      infoWindow.setContent('<div>' + title + '</div>');
      infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());

      toast.current.show({severity: 'info', summary: 'Marker Selected', detail: title});
    } else {
      toast.current.show({severity: 'info', summary: 'Shape Selected', detail: ''});
    }
  }

  const addMarker = () => {
    let newMarker = new google.maps.Marker({
      position: {
        lat: mapSelectedPosition.lat(),
        lng: mapSelectedPosition.lng()
      },
    });

    setMapOverlays([newMarker]);
    setMapDialogVisible(false);
    setFormData({
      ...formData,
      lng: mapSelectedPosition.lng(),
      lat: mapSelectedPosition.lat()
    })
  }

  const onMapReady = (event) => {
    if (props && props.initialValues && props.initialValues.lat && props.initialValues.lng) {
      setMapOverlays(
        [
          new google.maps.Marker({position: {lat: props.initialValues.lat, lng: props.initialValues.lng}, title: ''}),
        ]
      );
    } else {
      setMapOverlays(
        []
      );
    }
  }

  const onMapHide = (event) => {
    setMapDialogVisible(false);
  }

  const mapOptions = {
    center: {lat: 47.49982405077617, lng: 19.064711250233742},
    zoom: 10
  };

  const mapFooter = <div>
    <Button label="Mentés" icon="pi pi-check" className={"p-button-success"} onClick={addMarker}/>
    <Button label="Mégse" className={"p-button-secondary"} onClick={onMapHide}/>
  </div>;

  // charts

  const boxItemsTot = (items) => {
    let tot = 0
    Object.keys(items).forEach((key) => {
      tot = tot + items[key]
    });
    return tot
  }

  // timeline

  const timelineMarker = (item) => {
    return (
      <span className={"custom-marker shadow-1 p-p-1 p-mt-3 p-mb-2"}>
        <i className={item.icon} style={{fontSize: '1.5em'}}></i>
      </span>
    );
  };

  const timelineConent = (item) => {
    return (
      <Card className={"p-mt-3 p-p-1"}>
        <small>{moment.utc(item.date).local().format('YYYY-MM-DD HH:mm:ss')}</small>
        <h3 className={"p-m-0 p-p-0 p-text-secondary"}>
          <span className={"text-" + item.color}>{item.status}</span>
        </h3>
        <p className={"p-mb-0"}>{item.description}</p>
      </Card>
    );
  };

  const timelineData = (items) => {
    let data = []
    items.map((item, index) => {
      data.push({
        status: item.title,
        date: item.date,
        icon: "pi pi-" + item.icon,
        description: item.description,
        color: item.theme,
      })
    })
    return data
  }

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // // receipt items
  //
  // const itemVatBody = (rowData) => {
  //   if (vats && vats[0]) {
  //     const vat = vats.filter(vat => vat.id === rowData.vat);
  //     return (
  //       <>
  //         {vat[0].name}
  //       </>
  //     )
  //   }
  // }
  //
  // const grossAmountPriceBody = (rowData) => {
  //   let currency
  //   if (rowData.currency === 'HUF') {
  //     currency = {
  //       precision: 2,
  //       symbol: '',
  //       decimal: ',',
  //       thousand: ' ',
  //     }
  //   } else {
  //     currency = {
  //       precision: 2,
  //       symbol: '',
  //       decimal: '.',
  //       thousand: ' ',
  //     }
  //   }
  //   return (
  //     <>
  //       <span className={"p-text-bold"}>
  //       {currencyFormatter.format(
  //         rowData.grossAmount, {
  //           precision: currency.precision,
  //           symbol: currency.symbol,
  //           decimal: currency.decimal,
  //           thousand: currency.thousand,
  //         }
  //       )
  //       }
  //         &nbsp;{rowData.currency}
  //         </span>
  //     </>
  //   )
  // }
  //
  // const unitPriceBody = (rowData) => {
  //   let currency
  //   if (rowData.currency === 'HUF') {
  //     currency = {
  //       precision: 2,
  //       symbol: '',
  //       decimal: ',',
  //       thousand: ' ',
  //     }
  //   } else {
  //     currency = {
  //       precision: 2,
  //       symbol: '',
  //       decimal: '.',
  //       thousand: ' ',
  //     }
  //   }
  //   return (
  //     <>
  //       <span className={""}>
  //       {currencyFormatter.format(
  //         rowData.unitPrice, {
  //           precision: currency.precision,
  //           symbol: currency.symbol,
  //           decimal: currency.decimal,
  //           thousand: currency.thousand,
  //         }
  //       )
  //       }
  //         &nbsp;{rowData.currency}
  //         </span>
  //     </>
  //   )
  // }
  //
  // const netAmountPriceBody = (rowData) => {
  //   let currency
  //   if (rowData.currency === 'HUF') {
  //     currency = {
  //       precision: 2,
  //       symbol: '',
  //       decimal: ',',
  //       thousand: ' ',
  //     }
  //   } else {
  //     currency = {
  //       precision: 2,
  //       symbol: '',
  //       decimal: '.',
  //       thousand: ' ',
  //     }
  //   }
  //   return (
  //     <>
  //       <span className={""}>
  //       {currencyFormatter.format(
  //         rowData.netAmount, {
  //           precision: currency.precision,
  //           symbol: currency.symbol,
  //           decimal: currency.decimal,
  //           thousand: currency.thousand,
  //         }
  //       )
  //       }
  //         &nbsp;{rowData.currency}
  //         </span>
  //     </>
  //   )
  // }
  //
  // const vatAmountPriceBody = (rowData) => {
  //   let currency
  //   if (rowData.currency === 'HUF') {
  //     currency = {
  //       precision: 2,
  //       symbol: '',
  //       decimal: ',',
  //       thousand: ' ',
  //     }
  //   } else {
  //     currency = {
  //       precision: 2,
  //       symbol: '',
  //       decimal: '.',
  //       thousand: ' ',
  //     }
  //   }
  //   let net = '(nettó) '
  //   if (rowData.isUnitPriceGr === true) {
  //     net = '(bruttó) '
  //   }
  //   return (
  //     <>
  //       {net}
  //       <span className={""}>
  //       {currencyFormatter.format(
  //         rowData.vatAmount, {
  //           precision: currency.precision,
  //           symbol: currency.symbol,
  //           decimal: currency.decimal,
  //           thousand: currency.thousand,
  //         }
  //       )
  //       }
  //         &nbsp;{rowData.currency}
  //         </span>
  //     </>
  //   )
  // }

  // outgoing receipts

  const [outgoingReceiptsLoading, setOutgoingReceiptsLoading] = useState(false);
  const [outgoingReceiptsTotalRecords, setOutgoingReceiptsTotalRecords] = useState(0);
  const [outgoingReceiptsRows, setOutgoingReceiptsRows] = useState(10);
  const [outgoingReceipts, setOutgoingReceipts] = useState([]);

  let _outgoingReceiptsLazyParams = {
    filters: {
      type: {value: 'invoice'},
      outgoing: {value: true},
      projectName: {value: props.initialValues.name},
    },
    first: 0,
    rows: outgoingReceiptsRows,
    page: 0,
    sortField: 'id',
    sortOrder: 1,
  }
  const [outgoingReceiptsLazyParams, setOutgoingReceiptsLazyParams] = useState(_outgoingReceiptsLazyParams);

  const outgoingReceiptsDt = useRef(null);

  let outgoingReceiptsLoadLazyTimeout = null;
  const outgoingReceiptsLoadLazyData = () => {
    setOutgoingReceiptsLoading(true);
    if (outgoingReceiptsLoadLazyTimeout) {
      clearTimeout(outgoingReceiptsLoadLazyTimeout);
    }
    outgoingReceiptsLoadLazyTimeout = setTimeout(() => {
      //window.App.setState({rerenderReceiptList: false})
      ReceiptListService(JSON.stringify(outgoingReceiptsLazyParams))
        .then(response => {
          setOutgoingReceiptsTotalRecords(response.data.total_item_count);
          setOutgoingReceipts(response.data.items);
          setOutgoingReceiptsLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setOutgoingReceiptsLoading(false);
        })
    }, Math.random() * 1000 + 250);
  }
  const outgoingReceiptsOnPage = (event) => {
    let _lazyParams = {...outgoingReceiptsLazyParams, ...event};
    setOutgoingReceiptsLazyParams(_lazyParams);
  }
  const outgoingReceiptsOnSort = (event) => {
    let _lazyParams = {...outgoingReceiptsLazyParams, ...event};
    setOutgoingReceiptsLazyParams(_outgoingReceiptsLazyParams);
  }
  const outgoingReceiptsOnFilter = (event) => {
    let _outgoingReceiptsLazyParams = {...outgoingReceiptsLazyParams, ...event};
    _outgoingReceiptsLazyParams['first'] = 0;
    _outgoingReceiptsLazyParams['page'] = 0;
    setOutgoingReceiptsLazyParams(_outgoingReceiptsLazyParams);
  }
  const outgoingReceiptsPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];
      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setOutgoingReceiptsRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const outgoingReceiptsActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarReceiptShow: true,
                    props: rowData
                  })
                }}/>
      </React.Fragment>
    );
  }

  // incoming receipts

  const [incomingReceiptsLoading, setIncomingReceiptsLoading] = useState(false);
  const [incomingReceiptsTotalRecords, setIncomingReceiptsTotalRecords] = useState(0);
  const [incomingReceiptsRows, setIncomingReceiptsRows] = useState(10);
  const [incomingReceipts, setIncomingReceipts] = useState([]);

  let _incomingReceiptsLazyParams = {
    filters: {
      type: {value: 'invoice'},
      outgoing: {value: false},
      projectName: {value: props.initialValues.name},
    },
    first: 0,
    rows: incomingReceiptsRows,
    page: 0,
    sortField: 'id',
    sortOrder: 1,
  }
  const [incomingReceiptsLazyParams, setIncomingReceiptsLazyParams] = useState(_incomingReceiptsLazyParams);

  const incomingReceiptsDt = useRef(null);

  let incomingReceiptsLoadLazyTimeout = null;
  const incomingReceiptsLoadLazyData = () => {
    setIncomingReceiptsLoading(true);
    if (incomingReceiptsLoadLazyTimeout) {
      clearTimeout(incomingReceiptsLoadLazyTimeout);
    }
    incomingReceiptsLoadLazyTimeout = setTimeout(() => {
      //window.App.setState({rerenderReceiptList: false})
      ReceiptListService(JSON.stringify(incomingReceiptsLazyParams))
        .then(response => {
          setIncomingReceiptsTotalRecords(response.data.total_item_count);
          setIncomingReceipts(response.data.items);
          setIncomingReceiptsLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setIncomingReceiptsLoading(false);
        })
    }, Math.random() * 1000 + 250);
  }
  const incomingReceiptsOnPage = (event) => {
    let _lazyParams = {...incomingReceiptsLazyParams, ...event};
    setIncomingReceiptsLazyParams(_lazyParams);
  }
  const incomingReceiptsOnSort = (event) => {
    let _lazyParams = {...incomingReceiptsLazyParams, ...event};
    setIncomingReceiptsLazyParams(_incomingReceiptsLazyParams);
  }
  const incomingReceiptsOnFilter = (event) => {
    let _incomingReceiptsLazyParams = {...incomingReceiptsLazyParams, ...event};
    _incomingReceiptsLazyParams['first'] = 0;
    _incomingReceiptsLazyParams['page'] = 0;
    setIncomingReceiptsLazyParams(_incomingReceiptsLazyParams);
  }
  const incomingReceiptsPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];
      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setIncomingReceiptsRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const incomingReceiptsActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarReceiptShow: true,
                    props: rowData
                  })
                }}/>
      </React.Fragment>
    );
  }

  // material form

  const [materialDialog, setMaterialDialog] = useState(false);
  const [materialFormData, setMaterialFormData] = useState({}); // set on dialog open!
  const [materialFormMethod, setMaterialFormMethod] = useState('');

  const materialValidate = (data) => {
    let errors = {};
    if (!data.product) {
      errors.product = 'Kötelező mező';
    }
    return errors;
  };

  const materialOnSubmit = (data, form) => {
    let method = ''
    if (materialFormMethod === 'create') {
      method = 'post'
    } else {
      method = 'put'
    }
    ProjectMaterialFormService(data, props.initialValues.id, method)
      .then(response => {
        ProjectMaterialListService(props.initialValues.id)
          .then(response => {
            setMaterials(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    setMaterialDialog(false)
  }

  // products autocomplete
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  const searchProducts = (event) => {
    setSelectedProduct(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProductListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setProducts(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedProduct(string)
        }
      });
  }

  // unit

  const [units, setUnits] = useState([]);

  // material item

  const materialItemActionBody = (rowData, row) => {
    return (
      <React.Fragment>
        {rowData.usedQuantity === null && rowData.orderedQuantity === null ? (
          <>
            <Button type="button" icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                    tooltip="Módosítás"
                    onClick={() => {
                      setMaterialDialog(true)
                      setMaterialFormData({...rowData, rowIndex: row.rowIndex})
                      setSelectedProduct(rowData.productName)
                      setMaterialFormMethod('update')
                    }}
            />
            <Button type="button" icon="pi pi-trash" className="p-button-sm  p-button-danger"
                    tooltip="Törlés"
                    onClick={() => {
                      if (window.confirm('A törlés megerősítésére van szükség!')) {
                        ProjectMaterialDeleteService(formData.id, rowData.id)
                          .then(response => {
                            window.App.toastShow('success', 'Sikeres törlés!');
                            ProjectMaterialListService(props.initialValues.id)
                              .then(response => {
                                setMaterials(response.data.items)
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              })
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      }
                    }}
            />
          </>
        ) : (
          <>
            <Button type="button" icon="pi pi-pencil" className="p-button-sm p-button-warning p-mr-2 disabled"
                    tooltip="Módosítás" disabled
            />
            <Button type="button" icon="pi pi-trash" className="p-button-sm p-button-danger disabled"
                    tooltip="Törlés"
                    disabled
            />
          </>
        )}
      </React.Fragment>
    );
  }

  // milestones
  const [milestones, setMilestones] = useState([]);
  const [milestonesLoading, setMilestonesLoading] = useState(false);
  const [milestoneFormMethod, setMilestoneFormMethod] = useState('create');
  const [milestoneExpandedRows, setMilestoneExpandedRows] = useState(null);

  const milestonesMarker = (item, index) => {
    return (
      <span className="custom-marker" style={{color: process.env.REACT_APP_COLOR2}}>
          <i className="pi pi-circle-fill"></i>
      </span>
    );
  };

  const milestonesContent = (item) => {
    return (
      <Card className={"p-mb-4  p-p-1"}>
        <table className={"p-m-0 p-p-0 w-100"}>
          <tr>
            <td>Nettó szolgáltatási díj:</td>
            <th className={"p-text-right"} style={{color: process.env.REACT_APP_COLOR2}}>
              {item.netAmountMaterial} {formData.currency}
            </th>
          </tr>
          <tr>
            <td>Nettó anyag díj:</td>
            <th className={"p-text-right"} style={{color: process.env.REACT_APP_COLOR2}}>
              {item.netAmountService} {formData.currency}
            </th>
          </tr>
          <tr>
            <td>ÁFA:</td>
            <th className={"p-text-right"} style={{color: process.env.REACT_APP_COLOR2}}>
              {item.vat}
            </th>
          </tr>
          <tr>
            <td>Visszatartás összege:</td>
            <th className={"p-text-right"} style={{color: process.env.REACT_APP_COLOR2}}>
              {item.retentionAmount} {formData.currency}
            </th>
          </tr>
          <tr>
            <td>Szolgáltatás összege:</td>
            <th className={"p-text-right"} style={{color: process.env.REACT_APP_COLOR2}}>
              {item.partnerServicesAmount} {formData.currency}
            </th>
          </tr>
          <tr>
            <td>Levonás összege:</td>
            <th className={"p-text-right"} style={{color: process.env.REACT_APP_COLOR2}}>
              {item.otherDeductionAmount} {formData.currency}
            </th>
          </tr>
          <tr>
            <td>Tervezett számlázás dátum:</td>
            <th className={"p-text-right"} style={{color: process.env.REACT_APP_COLOR2}}>
              {item.plannedInvoicingAt}
            </th>
          </tr>
        </table>
      </Card>
    );
  };

  const milestoneValidate = (data) => {
    let errors = {};
    // if (!data.title) {
    //   errors.title = 'Kötelező mező';
    // }
    return errors;
  };

  const milestoneActionBody = (rowData, row) => {
    return (
      <React.Fragment>
        <Button type="button" icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  setMilestoneFormMethod('update')
                  setMilestoneDialog(true)
                  setMilestoneFormData({...rowData, rowIndex: row.rowIndex})
                  ProjectmilestoneReceiptItemListService(formData.id, rowData.id)
                    .then(response => {
                      setReceiptItems(response.data)
                    })
                    .catch(error => {
                      window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                    })
                }}
        />
        {/*<Button type="button" icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            milestoneDelete(rowData, row)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }
  const [milestoneDialog, setMilestoneDialog] = useState(false);
  const [milestoneFormData, setMilestoneFormData] = useState({}); // set on dialog open!

  const milestoneOnSubmit = (data, form) => {
    setMilestonesLoading(true)
    if (milestoneFormMethod === 'create') {
      ProjectmilestoneCreateService(data, 'post', formData.id)
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          ProjectmilestoneListService(formData.id)
            .then(response => {
              setMilestones(response.data.items)
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          setMilestoneDialog(false)
          setMilestonesLoading(false)
          setMilestoneFormData({})
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setMilestonesLoading(false)
        })
    }
    if (milestoneFormMethod === 'update') {
      ProjectmilestoneUpdateService(data, 'put', data.id)
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          ProjectmilestoneListService(formData.id)
            .then(response => {
              setMilestones(response.data.items)
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          setMilestoneDialog(false)
          setMilestonesLoading(false)
          setMilestoneFormData({})
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setMilestonesLoading(false)
        })
    }
  }

  const milestoneDelete = (data, row) => {
    let newMilestones = milestones.filter((milestone, index) => index !== row.rowIndex)
    setMilestones(newMilestones)
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  //receipt items receiptItemsPager
  const [receiptItemTotalRecords, setReceiptItemTotalRecords] = useState(0);
  const [receiptItemRows, setReceiptItemRows] = useState(10);
  const [receiptItemsLazyParams, setReceiptItemsLazyParams] = useState({
    first: 0,
    receiptItemRows: receiptItemRows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let receiptItemsLoadLazyTimeout = null;
  const receiptItemsLoadLazyData = () => {
    setLoading(true);
    if (receiptItemsLoadLazyTimeout) {
      clearTimeout(receiptItemsLoadLazyTimeout);
    }
    receiptItemsLoadLazyTimeout = setTimeout(() => {
      ProjectReceiptItemsService(props.initialValues.id, JSON.stringify(receiptItemsLazyParams))
        .then(response => {
          setReceiptItemTotalRecords(response.data.total_item_count);
          setReceiptItems(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const receiptItemsOnPage = (event) => {
    let _receiptItemsLazyParams = {...receiptItemsLazyParams, ...event};
    setReceiptItemsLazyParams(_receiptItemsLazyParams);
  }
  const receiptItemsOnSort = (event) => {
    let _receiptItemsLazyParams = {...receiptItemsLazyParams, ...event};
    setReceiptItemsLazyParams(_receiptItemsLazyParams);
  }
  const receiptItemsOnFilter = (event) => {
    let _receiptItemsLazyParams = {...receiptItemsLazyParams, ...event};
    _receiptItemsLazyParams['first'] = 0;
    _receiptItemsLazyParams['page'] = 0;
    setReceiptItemsLazyParams(_receiptItemsLazyParams);
  }
  const receiptItemsPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setReceiptItemRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.receiptItemTotalRecords}
        </span>
      )
    }
  };

  //milestones pager
  const [milestoneTotalRecords, setMilestoneTotalRecords] = useState(0);
  const [milestoneRows, setMilestoneRows] = useState(10);
  const [milestonesLazyParams, setMilestonesLazyParams] = useState({
    first: 0,
    milestoneRows: milestoneRows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let milestonesLoadLazyTimeout = null;
  const milestonesLoadLazyData = () => {
    setLoading(true);
    if (milestonesLoadLazyTimeout) {
      clearTimeout(milestonesLoadLazyTimeout);
    }
    milestonesLoadLazyTimeout = setTimeout(() => {
      ProjectmilestoneListService(props.initialValues.id, JSON.stringify(milestonesLazyParams))
        .then(response => {
          setMilestoneTotalRecords(response.data.total_item_count);
          setMilestones(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const milestonesOnPage = (event) => {
    let _milestonesLazyParams = {...milestonesLazyParams, ...event};
    setMilestonesLazyParams(_milestonesLazyParams);
  }
  const milestonesOnSort = (event) => {
    let _milestonesLazyParams = {...milestonesLazyParams, ...event};
    setMilestonesLazyParams(_milestonesLazyParams);
  }
  const milestonesOnFilter = (event) => {
    let _milestonesLazyParams = {...milestonesLazyParams, ...event};
    _milestonesLazyParams['first'] = 0;
    _milestonesLazyParams['page'] = 0;
    setMilestonesLazyParams(_milestonesLazyParams);
  }
  const milestonesPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setMilestoneRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.milestoneTotalRecords}
        </span>
      )
    }
  };

  //materials pager
  const [materialTotalRecords, setMaterialTotalRecords] = useState(0);
  const [materialRows, setMaterialRows] = useState(10);
  const [materialsLazyParams, setMaterialsLazyParams] = useState({
    first: 0,
    materialRows: materialRows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let materialsLoadLazyTimeout = null;
  const materialsLoadLazyData = () => {
    setLoading(true);
    if (materialsLoadLazyTimeout) {
      clearTimeout(materialsLoadLazyTimeout);
    }
    materialsLoadLazyTimeout = setTimeout(() => {
      ProjectMaterialListService(props.initialValues.id, JSON.stringify(materialsLazyParams))
        .then(response => {
          setMaterialTotalRecords(response.data.total_item_count);
          setMaterials(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const materialsOnPage = (event) => {
    let _materialsLazyParams = {...materialsLazyParams, ...event};
    setMaterialsLazyParams(_materialsLazyParams);
  }
  const materialsOnSort = (event) => {
    let _materialsLazyParams = {...materialsLazyParams, ...event};
    setMaterialsLazyParams(_materialsLazyParams);
  }
  const materialsOnFilter = (event) => {
    let _materialsLazyParams = {...materialsLazyParams, ...event};
    _materialsLazyParams['first'] = 0;
    _materialsLazyParams['page'] = 0;
    setMaterialsLazyParams(_materialsLazyParams);
  }
  const materialsPager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setMaterialRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.materialTotalRecords}
        </span>
      )
    }
  };

  useEffect(() => {
    loadGoogleMaps(() => {
      setGoogleMapsReady(true);
    });
    if (props && props.initialValues) {
      ProjectShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          ProjectSummaryService(props.initialValues.id)
            .then(response => {
              setSummary(response.data)
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      // ProjectmilestoneListService(props.initialValues.id)
      //   .then(response => {
      //     setMilestones(response.data.items)
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //   })
      // ProjectReceiptItemsService(props.initialValues.id)
      //   .then(response => {
      //     setReceiptItems(response.data.items)
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //   })
      // ProjectMaterialListService(props.initialValues.id)
      //   .then(response => {
      //     setMaterials(response.data.items)
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //   })
      IdentifierByProjectListService('', props.initialValues.id)
        .then(response => {
          setIdentifiers(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      UnitListService()
        .then(response => {
          setUnits(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      VatListService()
        .then(response => {
          setVats(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    milestonesLoadLazyData();
    materialsLoadLazyData();
    window.App.setState({
      valueOutgonigIsPaid: null,
      valueIncomingIsPaid: null
    })
  }, [milestonesLazyParams, materialsLazyParams])

  useEffect(() => {
    outgoingReceiptsLoadLazyData();
  }, [outgoingReceiptsLazyParams])

  useEffect(() => {
    incomingReceiptsLoadLazyData();
  }, [incomingReceiptsLazyParams])

  return (
    <>
      <h2 className={"text-primary p-mb-0"}>{formData.name}</h2>
      <div className={"p-grid"}>
        {formData.projectStatus && formData.projectStatus.name &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Státusz
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.projectStatus.name}</>
          </p>
        </div>
        }
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Helyszín
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            Budapest
          </p>
        </div>
        {formData.partner && formData.partnerName &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Partner
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.partnerName}</>
          </p>
        </div>
        }
        {formData.projectNumber &&
        <div className={"p-col"}>
          <small className={"p-text-uppercase"}>
            Projektszám
          </small>
          <p className={"p-mt-0 p-mb-0 p-text-bold"}>
            <>{formData.projectNumber}</>
          </p>
        </div>
        }
      </div>
      <hr/>
      <TabView className="tabview-header-icon menu">
        <TabPanel header="Összefoglaló nézet" leftIcon="pi pi-chart-bar">
          <div className={"p-grid"}>
            {summary && summary.kpi && summary.kpi[0] &&
            [summary.kpi.map((kpi, index) => {
              return (
                <div className={"p-col"}>
                  <Card className={"p-p-1"}>
                    <div className={"p-grid"}>
                      <div className={"p-col-9 p-col-align-center"}>
                        <small className={"p-text-uppercase"}>{kpi.title}</small>
                        <h3 className={"p-m-0 p-p-0 p-text-secondary"}> {kpi.count}</h3>
                      </div>
                      <div className={"p-col-3 p-col-align-center p-text-right"}>
                        <i className={"pi pi-" + kpi.icon} style={{'fontSize': '1.5em'}}></i>
                      </div>
                      <div className={"p-col-12"}>
                          <span className={"text-" + kpi.subtitle_theme}>
                            <span className={"p-text-bold"}>
                              {kpi.subtitle_theme === 'success' &&
                              <i className={"pi pi-arrow-up"}></i>
                              }
                              {kpi.subtitle_theme === 'danger' &&
                              <i className={"pi pi-arrow-down"}></i>
                              }
                              {kpi.subtitle_theme === 'warning' &&
                              <i className={"pi pi-exclamation-triangle"}></i>
                              }
                              &nbsp;{kpi.subtitle}
                            </span> {kpi.description}
                          </span>
                      </div>
                    </div>
                  </Card>
                </div>
              )
            })]
            }
          </div>
          <div className={"p-grid p-mt-2"}>
            <div className={"p-col-12 p-lg-6"}>
              <div className={"p-grid"}>
                {summary && summary.categoryBoxes && summary.categoryBoxes[0] &&
                [summary.categoryBoxes.map((box, boxindex) => {
                  var palettes = [
                    process.env.REACT_APP_COLOR1,
                    process.env.REACT_APP_COLOR2,
                    process.env.REACT_APP_COLOR3,
                    process.env.REACT_APP_COLOR4,
                    process.env.REACT_APP_COLOR5,
                  ];
                  let chartData = []
                  let colorIndexStarter = -1
                  Object.entries(box.items).forEach((item, itemindex) => {
                    const colorIndex = ++colorIndexStarter % palettes.length
                    chartData.push(
                      {
                        type: 'bar',
                        label: item[0] + " (" +
                          currencyFormatter.format(
                            item[1], {
                              precision: 0,
                              symbol: '',
                              decimal: ',',
                              thousand: ' ',
                            }
                          ) + ")",
                        backgroundColor: palettes[colorIndex],
                        data: [item[1]]
                      }
                    )
                  });
                  return (
                    <>
                      <div className={"p-col-12"}>
                        <Card className={"p-p-1"}>
                          <div className={"p-grid"}>
                            <div className={"p-col-10 p-col-align-center"}>
                              <small className={"p-text-uppercase"}>{box.title}</small>
                              <h3 className={"p-m-0 p-p-0 p-text-secondary"}> {box.count}
                                &nbsp;
                                (
                                {currencyFormatter.format(
                                  boxItemsTot(box.items), {
                                    precision: 0,
                                    symbol: '',
                                    decimal: ',',
                                    thousand: ' ',
                                  }
                                )}
                                &nbsp;
                                {box.unit}
                                )
                              </h3>
                            </div>
                            <div className={"p-col-2 p-col-align-center p-text-right"}>
                              <i className={"pi pi-" + box.icon} style={{'fontSize': '1.5em'}}></i>
                            </div>
                            <div className={"p-col-12"}>
                              <Chart type="bar"
                                     data={
                                       {
                                         labels: ['',],
                                         datasets: chartData,
                                       }
                                     }
                                     options={
                                       {
                                         indexAxis: 'y',
                                         maintainAspectRatio: false,
                                         aspectRatio: 3,
                                         plugins: {
                                           legend: {
                                             labels: {
                                               color: '#000'
                                             }
                                           }
                                         },
                                         scales: {
                                           x: {
                                             stacked: true,
                                             ticks: {
                                               color: '#e5e5e5'
                                             },
                                             grid: {
                                               color: '#fff'
                                             }
                                           },
                                           y: {
                                             stacked: true,
                                             ticks: {
                                               color: '#fff'
                                             },
                                             grid: {
                                               color: '#fff'
                                             }
                                           }
                                         }
                                       }
                                     }/>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </>
                  )
                })]
                }
              </div>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <h3 className={"p-mt-0 p-mb-0 p-text-secondary"}>
                <i className={"pi pi-clock"}></i> Előzmények
              </h3>
              {summary.timeline && summary.timeline[0] ? (
                <>
                  <Timeline value={timelineData(summary.timeline)} layout="vertical" align={"left"}
                            marker={timelineMarker} content={timelineConent} className={"no-alternate-column"}/>
                </>
              ) : (
                <>Még nincs előzmény...</>
              )}
            </div>
          </div>
          {/*<div className={"p-grid p-mt-1"}>*/}
          {/*  <div className={"p-col-12 p-lg-12"}>*/}
          {/*    <h3 className={"p-mt-0 p-mb-0 p-text-secondary"}>*/}
          {/*      <i className={"pi pi-clock"}></i> Előzmények*/}
          {/*    </h3>*/}
          {/*    {summary.timeline && summary.timeline[0] ? (*/}
          {/*      <>*/}
          {/*        <Timeline value={timelineData(summary.timeline)} layout="vertical" align={"left"}*/}
          {/*                  marker={timelineMarker} content={timelineConent} className={"no-alternate-column"}/>*/}
          {/*      </>*/}
          {/*    ) : (*/}
          {/*      <>Még nincs előzmény...</>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</div>*/}
        </TabPanel>
        <TabPanel header="Alap adatok" leftIcon="pi pi-list">
          <Panel>
            <div className={"p-grid"}>
              {formData.name &&
              <div className={"p-col-12 p-md-6 p-lg-6"}>
                <small className={"p-text-uppercase"}>Megnevezés</small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  {formData.name}
                </p>
              </div>
              }
              {formData.partner && formData.partnerName &&
              <div className={"p-col-12 p-md-6 p-lg-6"}>
                <small className={"p-text-uppercase"}>Vevő</small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  {formData.partnerName}
                </p>
              </div>
              }
              {formData.description &&
              <div className={"p-col-12 p-lg-12"}>
                <small className={"p-text-uppercase"}>Leírás</small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  {formData.description}
                </p>
              </div>
              }
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-money-bill"}></i> Pénzügy
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {formData.netAmount &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Nettó összeg</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.netAmount} {formData.currency}
                    </p>
                  </div>
                  }
                  {formData.extraWorkAmount &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Pótmunka összege</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.extraWorkAmount} {formData.currency}
                    </p>
                  </div>
                  }
                </div>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-info-circle"}></i> Tulajdonságok
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {!formData.projectType && (!formData.projectStatus || !formData.projectStatus.name) &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <span className={"p-mt-1"}>Nincs adat.</span>
                  </div>
                  }
                  {formData.projectType && formData.projectType.name &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Típus</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.projectType.name}
                    </p>
                  </div>
                  }
                  {formData.projectStatus && formData.projectStatus.name &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Státusz</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.projectStatus.name}
                    </p>
                  </div>
                  }
                </div>
              </div>
            </div>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-file"}></i> Szerződés
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {!formData.contractNumber && !formData.projectNumber &&
                  <div className={"p-col-12"}>
                    <span className={"p-mt-1"}>Nincs adat.</span>
                  </div>
                  }
                  {formData.contractNumber &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Szerződésszám</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.contractNumber}
                    </p>
                  </div>
                  }
                  {formData.projectNumber &&
                  <div className={"p-col-12 p-md-6 p-lg-6"}>
                    <small className={"p-text-uppercase"}>Projektszám</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {formData.projectNumber}
                    </p>
                  </div>
                  }
                </div>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
                <div className={"p-grid p-mt-1"}>
                  <div className={"p-col-12"}>
                    <h3 className="p-text-secondary">
                      <i className={"pi pi-calendar"}></i> Határidők
                    </h3>
                  </div>
                </div>
                <div className={"p-grid"}>
                  {!formData.startedAt && !formData.guaranteeEndAt &&
                  <div className={"p-col-12"}>
                    <span className={"p-mt-1"}>Nincs adat.</span>
                  </div>
                  }
                  {formData.startedAt &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <small className={"p-text-uppercase"}>Munkakezdés</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {moment.utc(formData.startedAt).local().format('YYYY-MM-DD')}
                    </p>
                  </div>
                  }
                  {formData.guaranteeEndAt &&
                  <div className={"p-col-12 p-md-4 p-lg-4"}>
                    <small className={"p-text-uppercase"}>Garancia lejárat</small>
                    <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                      {moment.utc(formData.guaranteeEndAt).local().format('YYYY-MM-DD')}
                    </p>
                  </div>
                  }
                </div>
              </div>
              {formData.lng !== null && formData.lat !== null &&
              <>
                <div className={"p-col-12 p-lg-12 p-xl-12"}>
                  <div className={"p-grid p-mt-1"}>
                    <div className={"p-col-12"}>
                      <h3 className="p-text-secondary">
                        <i className={"pi pi-map"}></i> Lokáció
                      </h3>
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      {
                        googleMapsReady && (
                          <div className="card">
                            <GMap overlays={mapOverlays} options={mapOptions}
                                  style={{width: '100%', minHeight: '320px'}}
                                  onMapReady={onMapReady}
                                  onMapClick={onMapClick} onOverlayClick={onOverlayClick}/>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </>
              }
            </div>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                        onClick={() => {
                          window.App.setState({
                            sidebarProjectShow: false,
                            sidebarProjectUpdate: true,
                            props: formData
                          })
                        }}
                />
              </div>
            </div>
          </Panel>
          <Panel className={"p-mt-3"} headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3 className={"p-text-secondary p-mt-0"}>
                  <i className={"pi pi-qrcode"}></i> Azonosítók
                </h3>
              </div>
            </div>
          }>
            <div className={"p-grid"}>
              {identifiers && identifiers[0] ? (
                [identifiers.map((identifier, index) => {
                  return (
                    <div className={"p-col p-fluid p-text-center"} style={{maxWidth: '120px'}}>
                      <img src={process.env.REACT_APP_API_HOST + "/identifier/qr/" + identifier.id + "?token="
                      + localStorage.getItem("userToken")} className={"w-100"}/>
                    </div>
                  )
                })]
              ) : (
                <>Még nincs hozzárendelve azonosító...</>
              )}
            </div>
          </Panel>
        </TabPanel>
        <TabPanel header="Projekt tételek" leftIcon="pi pi-list">
          <Panel>
            <ProjectitemListComponent projectId={props.initialValues.id}/>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Tételek importálása" icon="pi pi-upload"
                        className="p-button-secondary p-ml-2"
                        onClick={() => {
                          window.App.setState({
                            dialogProjectitemImport: true,
                          })
                        }}
                />
                <Button type="button" label="Tétel hozzáadása" icon="pi pi-plus" className="p-button-success p-ml-2"
                        onClick={() => {
                          window.App.setState({
                            dialogProjectitem: true,
                            projectitemFormMethod: 'create',
                          })
                        }}
                />
              </div>
            </div>
          </Panel>
          <Dialog visible={window.App.state.dialogProjectitem}
                  style={{width: '750px'}}
                  header="Tétel hozzáadása"
                  modal
                  onHide={() => {
                    window.App.setState({
                      dialogProjectitem: false
                    })
                  }}>
            <ProjectitemFormComponent projectId={props.initialValues.id} initialValues={window.App.state.props}/>
          </Dialog>
          <Dialog visible={window.App.state.dialogProjectitemImport}
                  style={{width: '750px'}}
                  header="Tételek importálása"
                  modal
                  onHide={() => {
                    window.App.setState({
                      dialogProjectitemImport: false
                    })
                  }}>
            <ProjectitemImportComponent projectId={props.initialValues.id} initialValues={window.App.state.props}/>
          </Dialog>
        </TabPanel>
        <TabPanel header="Mérföldkövek" leftIcon="pi pi-list">
          <Panel>
            {milestones && milestones[0] ? (
              <DataTable
                paginator first={milestonesLazyParams.first} totalRecords={milestoneTotalRecords}
                onPage={milestonesOnPage}
                onSort={milestonesOnSort} sortField={milestonesLazyParams.sortField}
                sortOrder={milestonesLazyParams.sortOrder}
                onFilter={milestonesOnFilter} filters={milestonesLazyParams.filters} loading={loading} lazy
                paginatorTemplate={milestonesPager} rows={milestoneRows}
                value={milestones}
                emptyMessage="Nincs találat."
                autoLayout={true}
                filterDisplay="row"
responsiveLayout="scroll"
                stripedRows
                dataKey="id"
                rowExpansionTemplate={(rowData) => {
                  return (
                    <>
                      <div className="p-ml-3 ">
                        <h3 className={"p-mt-0 p-pt-0"}>Tételek</h3>
                        <ProjectmilestoneitemListComponent initialValues={rowData} projectId={formData.id}/>
                      </div>
                    </>
                  );
                }}
                expandedRows={milestoneExpandedRows}
                onRowExpand={(e) => {
                  let id = e.data.id
                  setMilestoneExpandedRows({[id]: true})
                }}
              >
                <Column body={milestoneActionBody} className={"p-py-0 p-text-nowrap"}></Column>
                <Column field="title" header="Megnevezés"></Column>
                <Column field="startDate" header="Időszak"
                        body={(rowData) => {
                          return (
                            <>{moment.utc(rowData.startDate).local().format('YYYY-MM-DD')}</>
                          )
                        }}
                ></Column>
                <Column field="endDate"
                        body={(rowData) => {
                          return (
                            <>{moment.utc(rowData.endDate).local().format('YYYY-MM-DD')}</>
                          )
                        }}
                ></Column>
                <Column field="sumCosts" header="Összköltség" className={"p-text-right"}></Column>
                <Column field="sumIncome" header="Számlázott összeg" className={"p-text-right"}></Column>
                <Column field="isAdditional" header="Pótmunka"
                        body={(rowData) => {
                          return (
                            <>
                              {rowData.isAdditional === true ? (
                                <>Igen</>
                              ) : (
                                <>Nem</>
                              )}
                            </>
                          )
                        }}></Column>
                <Column expander style={{width: '3em'}}/>
              </DataTable>
              // <Timeline value={milestones} align="left" className="customized-timeline no-alternate-column"
              //           marker={milestonesMarker}
              //           content={milestonesContent}/>
            ) : (
              <>Még nincs mérfölkő...</>
            )}
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Mérföldkő hozzáadása" icon="pi pi-plus"
                        onClick={() => {
                          if (milestones && milestones[0]) {
                            let lastMilestoneIndex = milestones.length - 1
                            console.log(moment.utc(milestones[lastMilestoneIndex].endDate).local().format('YYYY-MM-DD'))
                            setMilestoneFormData({
                              ...milestoneFormData,
                              startDate: moment.utc(milestones[lastMilestoneIndex].endDate).local().format('YYYY-MM-DD')
                            })
                          }
                          setMilestoneDialog(true)
                        }}
                        className="p-button-success"/>
              </div>
            </div>
          </Panel>
          <Dialog visible={milestoneDialog}
                  style={{width: '750px'}}
                  header="Mérföldkő hozzáadása"
                  modal
                  onHide={() => {
                    setMilestoneDialog(false)
                  }}>
            <Form onSubmit={milestoneOnSubmit}
                  initialValues={milestoneFormData}
                  validate={milestoneValidate}
                  render={({handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className={"p-grid"}>
                        <div className={"p-col-12 p-md-4 p-lg-4"}>
                          <Field name="title" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="title"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                              <span className="p-input-icon-right">
                              <InputText id="title" {...input}
                                         onChange={(e) => {
                                           setMilestoneFormData({
                                             ...milestoneFormData,
                                             title: e.target.value
                                           })
                                         }}
                                         value={milestoneFormData.title}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        {/*<div className={"p-col-12 p-md-3 p-lg-3"}>*/}
                        {/*  <Field name="netAmountService" render={({input, meta}) => (*/}
                        {/*    <div className="p-field p-fluid">*/}
                        {/*      <label htmlFor="netAmountService"*/}
                        {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Nettó szolgáltatási*/}
                        {/*        díj</label>*/}
                        {/*      <span className="p-input-icon-right">*/}
                        {/*      {formData && formData.currency ? (*/}
                        {/*        <InputNumber id="netAmountService" {...input}*/}
                        {/*          // mode="currency"*/}
                        {/*          // currency={formData.currency}*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         netAmountService: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      ) : (*/}
                        {/*        <InputNumber id="netAmountService" {...input}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         netAmountService: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      )}*/}
                        {/*    </span>*/}
                        {/*      {getFormErrorMessage(meta)}*/}
                        {/*    </div>*/}
                        {/*  )}/>*/}
                        {/*</div>*/}
                        {/*<div className={"p-col-12 p-md-3 p-lg-3"}>*/}
                        {/*  <Field name="netAmountMaterial" render={({input, meta}) => (*/}
                        {/*    <div className="p-field p-fluid">*/}
                        {/*      <label htmlFor="netAmountMaterial"*/}
                        {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Nettó anyag díj</label>*/}
                        {/*      <span className="p-input-icon-right">*/}
                        {/*      {formData && formData.currency ? (*/}
                        {/*        <InputNumber id="netAmountMaterial" {...input}*/}
                        {/*          // mode="currency"*/}
                        {/*          // currency={formData.currency}*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         netAmountMaterial: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      ) : (*/}
                        {/*        <InputNumber id="netAmountMaterial" {...input}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         netAmountMaterial: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      )}*/}
                        {/*    </span>*/}
                        {/*      {getFormErrorMessage(meta)}*/}
                        {/*    </div>*/}
                        {/*  )}/>*/}
                        {/*</div>*/}
                        {/*<div className={"p-col-12 p-lg-2 p-xl-2"}>*/}
                        {/*  <Field name="vat" render={({input, meta}) => (*/}
                        {/*    <div className="p-field">*/}
                        {/*      <label htmlFor="vat"*/}
                        {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>ÁFA</label>*/}
                        {/*      <span className="p-input-icon-right">*/}
                        {/*      <Dropdown {...input}*/}
                        {/*                onChange={(e) => {*/}
                        {/*                  setMilestoneFormData({*/}
                        {/*                    ...milestoneFormData,*/}
                        {/*                    vat: e.target.value*/}
                        {/*                  })*/}
                        {/*                  localStorage.setItem("defaultVatId", e.target.value)*/}
                        {/*                }}*/}
                        {/*                options={vats}*/}
                        {/*                optionLabel="name"*/}
                        {/*                optionValue="id"*/}
                        {/*                id={"vat"}*/}
                        {/*                emptyMessage={"Még nincs ÁFA..."}*/}
                        {/*                className={classNames({'p-error': isFormFieldValid(meta)})}*/}
                        {/*      />*/}
                        {/*    </span>*/}
                        {/*      {getFormErrorMessage(meta)}*/}
                        {/*    </div>*/}
                        {/*  )}/>*/}
                        {/*</div>*/}
                        {/*<div className={"p-col-12 p-md-3 p-lg-3"}>*/}
                        {/*  <Field name="retentionAmount" render={({input, meta}) => (*/}
                        {/*    <div className="p-field p-fluid">*/}
                        {/*      <label htmlFor="retentionAmount"*/}
                        {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Visszatartás*/}
                        {/*        összege</label>*/}
                        {/*      <span className="p-input-icon-right">*/}
                        {/*      {formData && formData.currency ? (*/}
                        {/*        <InputNumber id="retentionAmount" {...input}*/}
                        {/*          // mode="currency"*/}
                        {/*          // currency={formData.currency}*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         retentionAmount: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      ) : (*/}
                        {/*        <InputNumber id="retentionAmount" {...input}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         retentionAmount: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      )}*/}
                        {/*    </span>*/}
                        {/*      {getFormErrorMessage(meta)}*/}
                        {/*    </div>*/}
                        {/*  )}/>*/}
                        {/*</div>*/}
                        {/*<div className={"p-col-12 p-md-3 p-lg-3"}>*/}
                        {/*  <Field name="partnerServicesAmount" render={({input, meta}) => (*/}
                        {/*    <div className="p-field p-fluid">*/}
                        {/*      <label htmlFor="partnerServicesAmount"*/}
                        {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Szolgáltatás*/}
                        {/*        összege</label>*/}
                        {/*      <span className="p-input-icon-right">*/}
                        {/*      {formData && formData.currency ? (*/}
                        {/*        <InputNumber id="partnerServicesAmount" {...input}*/}
                        {/*          // mode="currency"*/}
                        {/*          // currency={formData.currency}*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         partnerServicesAmount: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      ) : (*/}
                        {/*        <InputNumber id="partnerServicesAmount" {...input}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         partnerServicesAmount: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      )}*/}
                        {/*    </span>*/}
                        {/*      {getFormErrorMessage(meta)}*/}
                        {/*    </div>*/}
                        {/*  )}/>*/}
                        {/*</div>*/}
                        {/*<div className={"p-col-12 p-md-3 p-lg-3"}>*/}
                        {/*  <Field name="otherDeductionAmount" render={({input, meta}) => (*/}
                        {/*    <div className="p-field p-fluid">*/}
                        {/*      <label htmlFor="otherDeductionAmount"*/}
                        {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Egyéb levonás</label>*/}
                        {/*      <span className="p-input-icon-right">*/}
                        {/*      {formData && formData.currency ? (*/}
                        {/*        <InputNumber id="otherDeductionAmount" {...input}*/}
                        {/*          // mode="currency"*/}
                        {/*          // currency={formData.currency}*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         otherDeductionAmount: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      ) : (*/}
                        {/*        <InputNumber id="otherDeductionAmount" {...input}*/}
                        {/*                     locale="hu-HU"*/}
                        {/*                     minFractionDigits={0}*/}
                        {/*                     maxFractionDigits={9}*/}
                        {/*                     onChange={(e) => {*/}
                        {/*                       setMilestoneFormData({*/}
                        {/*                         ...milestoneFormData,*/}
                        {/*                         otherDeductionAmount: e.value*/}
                        {/*                       })*/}
                        {/*                     }}*/}
                        {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                        {/*      )}*/}
                        {/*    </span>*/}
                        {/*      {getFormErrorMessage(meta)}*/}
                        {/*    </div>*/}
                        {/*  )}/>*/}
                        {/*</div>*/}
                        <div className={"p-col-12 p-md-3 p-lg-3"}>
                          <Field name="startDate" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="startDate"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Időszak</label>
                              <span className="p-input-icon-right">
                              {milestoneFormData.startDate && (milestoneFormData.startDate !== null || milestoneFormData.startDate !== undefined) ? (
                                <Calendar dateFormat="yy-mm-dd"
                                          monthNavigator={true}
                                          yearNavigator={true}
                                          yearRange={"2022:2099"}
                                          keepInvalid="true"
                                          onChange={(e) => {
                                            if (e.target.value) {
                                              setMilestoneFormData({
                                                ...milestoneFormData,
                                                startDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                              })
                                            }
                                          }}
                                          value={new Date(milestoneFormData.startDate)}
                                          mask="9999-99-99"
                                          id={"startDate"}
                                          locale={"hu"}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Calendar dateFormat="yy-mm-dd"
                                          monthNavigator={true}
                                          yearNavigator={true}
                                          yearRange={"2022:2099"}
                                          keepInvalid="true"
                                          onChange={(e) => {
                                            if (e.target.value) {
                                              setMilestoneFormData({
                                                ...milestoneFormData,
                                                startDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                              })
                                            }
                                          }}
                                          mask="9999-99-99"
                                          id={"startDate"}
                                          locale={"hu"}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                />
                              )}
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-md-3 p-lg-3"}>
                          <Field name="endDate" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="endDate"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>&nbsp;</label>
                              <span className="p-input-icon-right">
                              {milestoneFormData.endDate && (milestoneFormData.endDate !== null || milestoneFormData.endDate !== undefined) ? (
                                <Calendar dateFormat="yy-mm-dd"
                                          monthNavigator={true}
                                          yearNavigator={true}
                                          yearRange={"2022:2099"}
                                          keepInvalid="true"
                                          onChange={(e) => {
                                            if (e.target.value) {
                                              setMilestoneFormData({
                                                ...milestoneFormData,
                                                endDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                              })
                                            }
                                          }}
                                          value={new Date(milestoneFormData.endDate)}
                                          mask="9999-99-99"
                                          id={"endDate"}
                                          locale={"hu"}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                />
                              ) : (
                                <Calendar dateFormat="yy-mm-dd"
                                          monthNavigator={true}
                                          yearNavigator={true}
                                          yearRange={"2022:2099"}
                                          keepInvalid="true"
                                          onChange={(e) => {
                                            if (e.target.value) {
                                              setMilestoneFormData({
                                                ...milestoneFormData,
                                                endDate: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                              })
                                            }
                                          }}
                                          mask="9999-99-99"
                                          id={"endDate"}
                                          locale={"hu"}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                />
                              )}
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-md-2 p-lg-2"}>
                          <Field name="isAdditional" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="isAdditional"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Pótmunka</label>
                              <span className="p-input-icon-right">
                                <InputSwitch
                                  className={"p-mt-1"}
                                  checked={milestoneFormData.isAdditional}
                                  value={milestoneFormData.isAdditional}
                                  onChange={e => setMilestoneFormData({
                                    ...milestoneFormData,
                                    isAdditional: e.target.value
                                  })}/>
                              </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-12 p-lg-6"}>
                          <Field name="receiptItems" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="receiptItems"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Számla tételek</label>
                              <span className="p-input-icon-right">
                                {milestoneFormData && milestoneFormData.id ? (
                                  <MultiSelect options={receiptItems}
                                               optionLabel="description"
                                               optionValue="id"
                                               id={"receiptItems"}
                                               emptyMessage={"Még nincs számla tétel..."}
                                               onChange={(e) => {
                                                 setSelectedReceiptItems(e.value)
                                                 ProjectmilestoneReceiptItemAddService(e.value, formData.id, milestoneFormData.id)
                                                   .then(response => {
                                                     window.App.toastShow('success', 'Sikeres mentés!');
                                                   })
                                                   .catch(error => {
                                                     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                                   })
                                               }}
                                               value={selectedReceiptItems}
                                  />
                                ) : (
                                  <><i className={"pi pi-lock"}></i> Hozzáadás csak mentés után lehetséges!</>
                                )}
                              </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                      </div>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>

                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          {milestoneFormMethod === 'create' &&
                          <Button type="submit" label="Mentés" icon={"pi pi-plus"}
                                  loading={milestonesLoading}/>
                          }
                          {milestoneFormMethod === 'update' &&
                          <Button type="submit" label="Módosítás" icon="pi pi-check"
                                  loading={milestonesLoading}/>
                          }
                        </div>
                      </div>
                    </form>
                  )}/>
          </Dialog>
        </TabPanel>
        <TabPanel header="Számlák" leftIcon="pi pi-list">
          <Panel>
            <h3 className={"p-mt-0 p-pt-0"}>Bevételi számlák</h3>
            <DataTable
              emptyMessage="Nincs találat."
              value={outgoingReceipts} paginator first={outgoingReceiptsLazyParams.first}
              totalRecords={outgoingReceiptsTotalRecords} onPage={outgoingReceiptsOnPage}
              onSort={outgoingReceiptsOnSort} sortField={outgoingReceiptsLazyParams.sortField}
              sortOrder={outgoingReceiptsLazyParams.sortOrder}
              onFilter={outgoingReceiptsOnFilter} filters={outgoingReceiptsLazyParams.filters}
              loading={outgoingReceiptsLoading} lazy
              paginatorTemplate={outgoingReceiptsPager} rows={outgoingReceiptsRows} emptyMessage="Nincs találat."
              autoLayout={true}
              ref={outgoingReceiptsDt}
              filterDisplay="row"
responsiveLayout="scroll"
              stripedRows
              dataKey="id"
            >
              <Column body={outgoingReceiptsActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
              <Column field="receiptNumber" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
              <Column field="isPaid" header="Fizetve" sortable body={(rowData) => {
                return (
                  <>
                    {rowData.isPaid === true ? (
                      <>Igen<br/>{moment.utc(rowData.paidDate).local().format('YYYY-MM-DD')}</>
                    ) : (
                      <>Nem</>
                    )}
                  </>
                )
              }}
                      filter
                      filterElement={
                        <Dropdown
                          options={[
                            {
                              value: "true",
                              name: "Igen"
                            },
                            {
                              value: "false",
                              name: "Nem"
                            },
                          ]}
                          optionValue={"value"}
                          optionLabel={"name"}
                          showClear
                          emptyMessage={"Még nincs státusz..."}
                          onChange={(e) => {
                            outgoingReceiptsDt.current.filter(e.value, 'paid', '');
                            window.App.setState({
                              valueOutgonigIsPaid: e.value
                            })
                          }}
                          value={window.App.state.valueOutgonigIsPaid}
                        />
                      }
              ></Column>
              <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
              <Column field="issueDate" header="Kelt" sortable className={"p-text-nowrap"} body={(rowData) => {
                return (
                  <>
                    {rowData.issueDate && rowData.issueDate !== null ? (
                      <>{moment.utc(rowData.issueDate).local().format('YYYY-MM-DD')}</>
                    ) : (
                      <></>
                    )
                    }
                  </>
                )
              }}></Column>
              <Column field="deliveryDate" header="Teljesítés" sortable className={"p-text-nowrap"}
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.deliveryDate && rowData.deliveryDate !== null ? (
                              <>{moment.utc(rowData.deliveryDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <></>
                            )
                            }
                          </>
                        )
                      }}></Column>
              <Column field="paymentDate" header="Fiz. határidő" sortable className={"p-text-nowrap"}
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.paymentDate && rowData.paymentDate !== null ? (
                              <>{moment.utc(rowData.paymentDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <></>
                            )
                            }
                          </>
                        )
                      }}></Column>
              <Column field="netAmount" header="Nettó összeg" sortable className={"p-text-right p-text-nowrap"}
                      body={(rowData) => {
                        let currency
                        if (rowData.currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              rowData.netAmount, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}&nbsp;{rowData.currency}
                          </>
                        )
                      }}></Column>
              <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                      body={(rowData) => {
                        let currency
                        if (rowData.currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              rowData.grossAmount, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}&nbsp;{rowData.currency}
                          </>
                        )
                      }}></Column>
            </DataTable>
            <h3 className={"p-mt-3"}>Költség számlák</h3>
            <DataTable
              emptyMessage="Nincs találat."
              value={incomingReceipts} paginator first={incomingReceiptsLazyParams.first}
              totalRecords={incomingReceiptsTotalRecords} onPage={incomingReceiptsOnPage}
              onSort={incomingReceiptsOnSort} sortField={incomingReceiptsLazyParams.sortField}
              sortOrder={incomingReceiptsLazyParams.sortOrder}
              onFilter={incomingReceiptsOnFilter} filters={incomingReceiptsLazyParams.filters}
              loading={incomingReceiptsLoading} lazy
              paginatorTemplate={incomingReceiptsPager} rows={incomingReceiptsRows} emptyMessage="Nincs találat."
              autoLayout={true}
              ref={incomingReceiptsDt}
              filterDisplay="row"
responsiveLayout="scroll"
              stripedRows
              dataKey="id"
            >
              <Column body={incomingReceiptsActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
              <Column field="receiptNumber" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
              <Column field="isPaid" header="Fizetve" sortable
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.isPaid === true ? (
                              <>Igen<br/>{moment.utc(rowData.paidDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <>Nem</>
                            )}
                          </>
                        )
                      }}
                      filter
                      filterElement={
                        <Dropdown
                          options={[
                            {
                              value: "true",
                              name: "Igen"
                            },
                            {
                              value: "false",
                              name: "Nem"
                            },
                          ]}
                          optionValue={"value"}
                          optionLabel={"name"}
                          showClear
                          emptyMessage={"Még nincs státusz..."}
                          onChange={(e) => {
                            incomingReceiptsDt.current.filter(e.value, 'paid', '');
                            window.App.setState({
                              valueIncomingIsPaid: e.value
                            })
                          }}
                          value={window.App.state.valueIncomingIsPaid}
                        />
                      }
              ></Column>
              <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
              <Column field="issueDate" header="Kelt" sortable className={"p-text-nowrap"} body={(rowData) => {
                return (
                  <>
                    {rowData.issueDate && rowData.issueDate !== null ? (
                      <>{moment.utc(rowData.issueDate).local().format('YYYY-MM-DD')}</>
                    ) : (
                      <></>
                    )
                    }
                  </>
                )
              }}></Column>
              <Column field="deliveryDate" header="Teljesítés" sortable className={"p-text-nowrap"}
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.deliveryDate && rowData.deliveryDate !== null ? (
                              <>{moment.utc(rowData.deliveryDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <></>
                            )
                            }
                          </>
                        )
                      }}></Column>
              <Column field="paymentDate" header="Fiz. határidő" sortable className={"p-text-nowrap"}
                      body={(rowData) => {
                        return (
                          <>
                            {rowData.paymentDate && rowData.paymentDate !== null ? (
                              <>{moment.utc(rowData.paymentDate).local().format('YYYY-MM-DD')}</>
                            ) : (
                              <></>
                            )
                            }
                          </>
                        )
                      }}></Column>
              <Column field="netAmount" header="Nettó összeg" sortable className={"p-text-right p-text-nowrap"}
                      body={(rowData) => {
                        let currency
                        if (rowData.currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              rowData.netAmount, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}&nbsp;{rowData.currency}
                          </>
                        )
                      }}></Column>
              <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                      body={(rowData) => {
                        let currency
                        if (rowData.currency === 'HUF') {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: ',',
                            thousand: ' ',
                          }
                        } else {
                          currency = {
                            precision: 2,
                            symbol: '',
                            decimal: '.',
                            thousand: ' ',
                          }
                        }
                        return (
                          <>
                            {currencyFormatter.format(
                              rowData.grossAmount, {
                                precision: currency.precision,
                                symbol: currency.symbol,
                                decimal: currency.decimal,
                                thousand: currency.thousand,
                              }
                            )}&nbsp;{rowData.currency}
                          </>
                        )
                      }}></Column>
            </DataTable>
            <Sidebar position="left" visible={window.App.state.sidebarReceiptShow} closeOnEscape={true}
                     style={{width: '95%'}}
                     onHide={() => window.App.setState({
                       rerenderReceiptList: true,
                       sidebarReceiptShow: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })}>
              <h2 className={"text-primary"}>Bizonylat megtekintése</h2>
              <ReceiptShowComponent initialValues={window.App.state.props}/>
            </Sidebar>
            {/*<DataTable*/}
            {/*  paginator first={receiptItemsLazyParams.first} totalRecords={receiptItemTotalRecords}*/}
            {/*  onPage={receiptItemsOnPage}*/}
            {/*  onSort={receiptItemsOnSort} sortField={receiptItemsLazyParams.sortField}*/}
            {/*  sortOrder={receiptItemsLazyParams.sortOrder}*/}
            {/*  onFilter={receiptItemsOnFilter} filters={receiptItemsLazyParams.filters} loading={loading} lazy*/}
            {/*  paginatorTemplate={receiptItemsPager} rows={receiptItemRows}*/}
            {/*  value={receiptItems}*/}
            {/*  emptyMessage="Nincs találat."*/}
            {/*  autoLayout={true}*/}
            {/*  filterDisplay="row"
responsiveLayout="scroll"*/}
            {/*  stripedRows*/}
            {/*>*/}
            {/*  <Column field="description" header="Megnevezés"></Column>*/}
            {/*  <Column field="quantity" header="Mennyiség" className={"p-text-right"}></Column>*/}
            {/*  <Column field="unitName" header=""></Column>*/}
            {/*  <Column field="unitPrice" header="Egységár" className={"p-text-right"} body={unitPriceBody}></Column>*/}
            {/*  <Column field="netAmount" header="Nettó érték" className={"p-text-right"}*/}
            {/*          body={netAmountPriceBody}></Column>*/}
            {/*  <Column field="vat" header="ÁFA" body={itemVatBody}></Column>*/}
            {/*  <Column field="vatAmount" header="ÁFA érték" className={"p-text-right"}*/}
            {/*          body={vatAmountPriceBody}></Column>*/}
            {/*  <Column field="grossAmount" header="Bruttó érték" className={"p-text-right"}*/}
            {/*          body={grossAmountPriceBody}></Column>*/}
            {/*  <Column field="costTypeName" header="Költségnem"></Column>*/}
            {/*  /!*<Column body={itemActionBody} className={"p-py-0 p-text-nowrap"}></Column>*!/*/}
            {/*</DataTable>*/}
          </Panel>
        </TabPanel>
        <TabPanel header="Alapanyagok" leftIcon="pi pi-list">
          <Panel>
            <DataTable
              paginator first={materialsLazyParams.first} totalRecords={materialTotalRecords}
              onPage={materialsOnPage}
              onSort={materialsOnSort} sortField={materialsLazyParams.sortField}
              sortOrder={materialsLazyParams.sortOrder}
              onFilter={materialsOnFilter} filters={materialsLazyParams.filters} loading={loading} lazy
              paginatorTemplate={materialsPager} rows={materialRows}
              value={materials}
              emptyMessage="Nincs találat."
              autoLayout={true}
              filterDisplay="row"
responsiveLayout="scroll"
              stripedRows
            >
              <Column body={materialItemActionBody} className={"p-py-0 p-text-nowrap"}></Column>
              <Column field="productName" header="Termék"></Column>
              <Column field="quantity" header="Tervezett Me."></Column>
              <Column field="orderedQuantity" header="Rendelt Me."></Column>
              <Column field="usedQuantity" header="Felhasznált Me."></Column>
              <Column field="unitName" header="Egység"></Column>
            </DataTable>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="button" label="Anyagigény hozzáadása" icon="pi pi-plus" className="p-button-success"
                        onClick={() => {
                          setMaterialDialog(true)
                          setMaterialFormMethod('create')
                        }}
                />
              </div>
            </div>
          </Panel>
        </TabPanel>
      </TabView>
      <Dialog visible={materialDialog}
              style={{width: '750px'}}
              header="Anyagigény"
              modal
              onHide={() => {
                setMaterialDialog(false)
              }}>
        <Form onSubmit={materialOnSubmit}
              initialValues={materialFormData}
              validate={materialValidate}
              render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} className="">
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-9"}>
                      <Field name="product" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="product"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Termék</label>
                          <span className="p-inputgroup">
                        <AutoComplete value={selectedProduct}
                                      id={"product"}
                                      suggestions={products}
                          // forceSelection
                                      completeMethod={searchProducts}
                                      field={"name"}
                                      delay="500"
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setMaterialFormData({
                                          ...materialFormData,
                                          product: {id: e.value.id},
                                        })
                                        setSelectedProduct(e.value.name)
                                      }}
                                      onClear={(e) => {
                                        setSelectedProduct('')
                                      }}
                        />
                        </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    <div className={"p-col"}>
                      <Field name="quantity" render={({input, meta}) => (
                        <div className="p-field p-fluid">
                          <label htmlFor="quantity"
                                 className={classNames({'p-error': isFormFieldValid(meta)})}>Tervezett mennyiség</label>
                          <span className="p-input-icon-right">
                        <InputNumber id="quantity" {...input}
                                     minFractionDigits={0}
                                     maxFractionDigits={9}
                                     locale="hu-HU"
                                     onValueChange={(e) => {
                                       setMaterialFormData({
                                         ...materialFormData,
                                         quantity: e.value
                                       })
                                     }}
                                     value={materialFormData.quantity}
                                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                        </span>
                          {getFormErrorMessage(meta)}
                        </div>
                      )}/>
                    </div>
                    {/*<div className={"p-col"}>*/}
                    {/*  <Field name="usedQuantity" render={({input, meta}) => (*/}
                    {/*    <div className="p-field p-fluid">*/}
                    {/*      <label htmlFor="usedQuantity"*/}
                    {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Felhasznált mennyiség</label>*/}
                    {/*      <span className="p-input-icon-right">*/}
                    {/*          <InputNumber id="usedQuantity" {...input}*/}
                    {/*                       minFractionDigits={0}*/}
                    {/*                       maxFractionDigits={9}*/}
                    {/*                       locale="hu-HU"*/}
                    {/*                       onValueChange={(e) => {*/}
                    {/*                         setMaterialFormData({*/}
                    {/*                           ...materialFormData,*/}
                    {/*                           usedQuantity: e.value*/}
                    {/*                         })*/}
                    {/*                       }}*/}
                    {/*                       value={materialFormData.usedQuantity}*/}
                    {/*                       className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                    {/*        </span>*/}
                    {/*      {getFormErrorMessage(meta)}*/}
                    {/*    </div>*/}
                    {/*  )}/>*/}
                    {/*</div>*/}
                    {/*<div className={"p-col"}>*/}
                    {/*  <Field name="orderedQuantity" render={({input, meta}) => (*/}
                    {/*    <div className="p-field p-fluid">*/}
                    {/*      <label htmlFor="orderedQuantity"*/}
                    {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Megrendelt mennyiség</label>*/}
                    {/*      <span className="p-input-icon-right">*/}
                    {/*          <InputNumber id="orderedQuantity" {...input}*/}
                    {/*                       minFractionDigits={0}*/}
                    {/*                       maxFractionDigits={9}*/}
                    {/*                       locale="hu-HU"*/}
                    {/*                       onValueChange={(e) => {*/}
                    {/*                         setMaterialFormData({*/}
                    {/*                           ...materialFormData,*/}
                    {/*                           orderedQuantity: e.value*/}
                    {/*                         })*/}
                    {/*                       }}*/}
                    {/*                       value={materialFormData.orderedQuantity}*/}
                    {/*                       className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                    {/*        </span>*/}
                    {/*      {getFormErrorMessage(meta)}*/}
                    {/*    </div>*/}
                    {/*  )}/>*/}
                    {/*</div>*/}
                    {/*<div className={"p-col-12 p-lg-2 p-xl-2"}>*/}
                    {/*  <Field name="unit" render={({input, meta}) => (*/}
                    {/*    <div className="p-field p-fluid">*/}
                    {/*      <label htmlFor="unit"*/}
                    {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Egység</label>*/}
                    {/*      <span className="p-input-icon-right">*/}
                    {/*          {materialFormData && materialFormData.unit && materialFormData.unit.id ? (*/}
                    {/*            <Dropdown {...input}*/}
                    {/*                      onChange={(e) => {*/}
                    {/*                        setMaterialFormData({*/}
                    {/*                          ...materialFormData,*/}
                    {/*                          unit: {id: e.target.value}*/}
                    {/*                        })*/}
                    {/*                        localStorage.setItem("defaultUnitId", e.target.value)*/}
                    {/*                      }}*/}
                    {/*                      value={materialFormData.unit.id}*/}
                    {/*                      options={units}*/}
                    {/*                      optionLabel="name"*/}
                    {/*                      optionValue="id"*/}
                    {/*                      id={"unit"}*/}
                    {/*                      emptyMessage={"Még nincs egység..."}*/}
                    {/*                      className={classNames({'p-error': isFormFieldValid(meta)})}*/}
                    {/*            />*/}
                    {/*          ) : (*/}
                    {/*            <Dropdown {...input}*/}
                    {/*                      onChange={(e) => {*/}
                    {/*                        setMaterialFormData({*/}
                    {/*                          ...materialFormData,*/}
                    {/*                          unit: {id: e.target.value}*/}
                    {/*                        })*/}
                    {/*                        localStorage.setItem("defaultUnitId", e.target.value)*/}
                    {/*                      }}*/}
                    {/*                      options={units}*/}
                    {/*                      optionLabel="name"*/}
                    {/*                      optionValue="id"*/}
                    {/*                      id={"unit"}*/}
                    {/*                      emptyMessage={"Még nincs egység..."}*/}
                    {/*                      className={classNames({'p-error': isFormFieldValid(meta)})}*/}
                    {/*            />)}*/}
                    {/*        </span>*/}
                    {/*      {getFormErrorMessage(meta)}*/}
                    {/*    </div>*/}
                    {/*  )}/>*/}
                    {/*</div>*/}
                  </div>
                  <div className={"p-grid p-mt-3"}>
                    <div className={"p-col-12 p-lg-6 p-xl-6"}>
                    </div>
                    <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                      {materialFormMethod === 'create' &&
                      <Button type="submit" label="Anyagigény hozzáadása" icon={"pi pi-plus"}/>
                      }
                      {materialFormMethod === 'update' &&
                      <Button type="submit" label="Anyagigény módosítása" icon={"pi pi-check"}/>
                      }
                    </div>
                  </div>
                </form>
              )}/>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(ProjectShowComponent);
