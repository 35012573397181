import axios from "axios";

function UserLoginService(formData) {
  return axios.post(process.env.REACT_APP_API_HOST + "/auth", {
      identifier: formData.identifier,
      password: formData.password
    }, {}
  )
}

export default UserLoginService;
