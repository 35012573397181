import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {SelectButton} from 'primereact/selectbutton';
import {AutoComplete} from "primereact/autocomplete";

import IdentifierFormService from '../../services/identifier/IdentifierFormService'
import EmployeeListService from "../../services/employee/EmployeeListService";
import GuestListService from "../../services/guest/GuestListService";
import CompanyListService from "../../services/company/CompanyListService";
import IdentitytypeListService from "../../services/identitytype/IdentitytypeListService";
import {Dropdown} from "primereact/dropdown";
import ProjectListService from "../../services/project/ProjectListService";
import IdentifierShowService from "../../services/identifier/IdentifierShowService";

const IdentifierFormComponent = (props) => {

  const [formData, setFormData] = useState({
    type: 'employee',
    company: {
      id: Number(localStorage.getItem('defaultCompanyId'))
    }
  });
  const [identityTypes, setIdentityTypes] = useState([])
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    if (!data.identifier) {
      errors.identifier = 'Az azonosító nem lehet üres';
    }
    if (!data.company || (data.company && !data.company.id)) {
      errors.company = 'A cég nem lehet üres';
    }
    // if (!data.employee || (data.employee && !data.employee.id)) {
    //   errors.employee = 'A munkavállaló nem lehet üres';
    // }
    // if (!data.quest || (data.guest && !data.guest.id)) {
    //   errors.guest = 'A vendég nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      IdentifierFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderIdentifierList: true,
            sidebarIdentifierUpdate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      IdentifierFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderIdentifierList: true,
            sidebarIdentifierCreate: false
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(localStorage.getItem('defaultCompanyName'));

  const serachCompany = (event) => {
    setSelectedCompany(event.query.replace('Nincs találat', '').replace(': ', ''))
    CompanyListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCompanies(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedCompany(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // employees autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const searchEmployees = (event) => {
    setSelectedEmployee(event.query.replace('Nincs találat', '').replace(': ', ''))
    EmployeeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setEmployees(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedEmployee(string)
        }
      });
  }

  // guests autocomplete
  const [guests, setGuests] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState('');

  const searchGuests = (event) => {
    setSelectedGuest(event.query.replace('Nincs találat', '').replace(': ', ''))
    GuestListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}})).then(response => {
      if (response.data && response.data.items && response.data.items[0]) {
        setGuests(response.data.items)
      } else {
        let string
        if (event.query) {
          string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        } else {
          string = 'Nincs találat'
        }
        setSelectedGuest(string)
      }
    });
  }

  // project autocomplete
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(localStorage.getItem('defaultProjectName'));

  const searchProject = (event) => {
    setSelectedProject(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProjectListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setProjects(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedProject(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    IdentitytypeListService()
      .then(response => {
        setIdentityTypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    if (props && props.initialValues) {
      IdentifierShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
          if (response.data.employee && response.data.employee.id && response.data.employee.id !== null) {
            setFormData({
              ...response.data,
              type: 'employee',
            })
            setSelectedEmployee(response.data.employeeName)
          }
          if (response.data.guest && response.data.guest.id && response.data.guest.id !== null) {
            setFormData({
              ...response.data,
              type: 'guest',
            })
            setSelectedGuest(response.data.guestName)
          }
          if (response.data.project && response.data.project.id && response.data.project.id !== null) {
            setFormData({
              ...response.data,
              type: 'project',
            })
            setSelectedProject(response.data.projectName)
          }
          setSelectedCompany(response.data.companyName)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="identifier" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="identifier"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Azonosító</label>
                  <span className="p-input-icon-right">
                      <InputText id="identifier" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     identifier: e.target.value
                                   })
                                 }}
                                 value={formData.identifier}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="company" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="company"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Cég</label>
                  <span className="">
                    {localStorage.getItem('roles').toString().includes('role_company_browse')
                    || localStorage.getItem('roles').toString().includes('role_company_read') ? (
                      <AutoComplete value={selectedCompany}
                                    id={"company"}
                                    suggestions={companies}
                                    // forceSelection
                                    completeMethod={serachCompany}
                                    field={"name"}
                                    delay="500"
                                    placeholder={"Keresés gépeléssel..."}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                    onSelect={(e) => {
                                      setFormData({
                                        ...formData,
                                        company: {id: e.value.id}
                                      })
                                      setSelectedCompany(e.value.name)
                                      localStorage.setItem('defaultCompanyId', e.value.id)
                                      localStorage.setItem('defaultCompanyName', e.value.name)
                                    }}
                                    dropdown
                                    onClear={(e) => {
                                      setSelectedCompany('')
                                    }}
                      />
                    ) : (
                      <Dropdown disabled className={"disabled"} id={"company"} showClear/>
                    )}
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="type" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="type"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                  <span className="">
                    <Dropdown options={identityTypes}
                              optionLabel="name"
                              optionValue="id"
                              id={"type"}
                              emptyMessage={"Még nincs típus..."}
                              onChange={(e) => setFormData({...formData, type: e.value})}
                              value={formData.type}
                              showClear
                    />
                    {/*<SelectButton optionLabel="name" optionValue="value" value={formData.type} options={[*/}
                    {/*  {name: 'Munkavállaló', value: 'employee'},*/}
                    {/*  {name: 'Vendég', value: 'guest'},*/}
                    {/*]}*/}
                    {/*              id={"type"}*/}
                    {/*              onChange={(e) => setFormData({...formData, type: e.value})}*/}
                    {/*/>*/}
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {formData.type === 'employee' &&
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="employee" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="employee"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>
                  <span className="">
                              <AutoComplete value={selectedEmployee}
                                            id={"employee"}
                                            suggestions={employees}
                                            // forceSelection
                                            completeMethod={searchEmployees}
                                            field={"name"}
                                            delay="500"
                                            dropdown
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                employee: {id: e.value.id},
                                                guest: null,
                                                project: null,
                                              })
                                              setSelectedEmployee(e.value.name)
                                            }}
                                            onClear={(e) => {
                                              setSelectedEmployee('')
                                            }}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            }
            {formData.type === 'guest' &&
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="guest" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="guest"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Vendég</label>
                  <span className="">
                              <AutoComplete value={selectedGuest}
                                            id={"guest"}
                                            suggestions={guests}
                                            // forceSelection
                                            completeMethod={searchGuests}
                                            field={"name"}
                                            delay="500"
                                            dropdown
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                guest: {id: e.value.id},
                                                employee: null,
                                                project: null
                                              })
                                              setSelectedGuest(e.value.name)
                                            }}
                                            onClear={(e) => {
                                              setSelectedGuest('')
                                            }}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            }
            {formData.type === 'project' &&
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="project" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="project"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Projekt</label>
                  <span className="p-input-icon-right">
                    <AutoComplete value={selectedProject}
                                  id={"project"}
                                  suggestions={projects}
                                  // forceSelection
                                  completeMethod={searchProject}
                                  field={"name"}
                                  delay="500"
                                  dropdown
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      project: {id: e.value.id},
                                      guest: null,
                                      employee: null,
                                    })
                                    setSelectedProject(e.value.name)
                                    localStorage.setItem("defaultProjectId", e.value.id)
                                    localStorage.setItem("defaultProjectName", e.value.name)
                                  }}
                                  onClear={(e) => {
                                    setSelectedProject('')
                                  }}
                    />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            }
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(IdentifierFormComponent);
