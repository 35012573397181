import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Link} from "react-router-dom";
import {Password} from "primereact/password";
import {Sidebar} from "primereact/sidebar";

import ProfileShowService from "../../services/profile/ProfileShowService";
import ProfileFormService from "../../services/profile/ProfileFormService";
import ProfilePasswordService from "../../services/profile/ProfilePasswordService";
import EmployeeShowComponent from "../employee/EmployeeShowComponent";

const PartnerFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'Az megnevezés nem lehet üres';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    ProfileFormService(data,)
      .then(response => {
        window.App.toastShow('success', 'Sikeres módosítás!');
        window.App.setState({
          sidebarProfileUpdate: false
        })
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };

  const [passwordFormData, setPasswordFormData] = useState({});

  const passwordValidate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onPasswordSubmit = (data, form) => {
    setLoading(true)
    setPasswordFormData({
      ...passwordFormData,
      data
    });
    ProfilePasswordService(data)
      .then(response => {
        window.App.toastShow('success', 'Sikeres módosítás!');
        window.App.setState({
          sidebarProfileUpdate: false
        })
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };

  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };


  useEffect(() => {
    ProfileShowService()
      .then(response => {
        setFormData(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, [])

  return (
    <>
      <Form onSubmit={onSubmit} initialValues={formData}
            validate={validate} render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} className="">
          <Panel headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-list"}></i> Alapadatok
                </h3>
              </div>
            </div>
          } className={""}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12"}>
                <Field name="name" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="name"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Név</label>
                    <span className="p-input-icon-right">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            <div className={"p-grid"}>
              {formData.identifier &&
              <div className={"p-col-12 p-lg-6"}>
                <small className={"p-text-uppercase"}>
                  Azonosító
                </small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  <>{formData.identifier}</>
                </p>
              </div>
              }
              {formData.email &&
              <div className={"p-col-12 p-lg-6"}>
                <small className={"p-text-uppercase"}>
                  Email cím
                </small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  <>{formData.email}</>
                </p>
              </div>
              }
              {formData.employeeName &&
              <div className={"p-col-12 p-lg-6"}>
                <small className={"p-text-uppercase"}>
                  Munkavállaló
                </small>
                <p className={"p-mt-0 p-mb-0 p-text-bold"}>
                  <a onClick={() => {
                    window.App.setState({
                      sidebarEmployeeShow: true,
                      props: {id: formData.employee.id}
                    })
                  }}>{formData.employeeName}</a>
                </p>
              </div>
              }
            </div>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                        className="p-button-success"/>
              </div>
            </div>
          </Panel>
        </form>
      )}/>
      <Form onSubmit={onPasswordSubmit} initialValues={passwordFormData}
            validate={passwordValidate} render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} className="">
          <Panel headerTemplate={
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <h3>
                  <i className={"pi pi-lock"}></i> Jelszó módosítás
                </h3>
              </div>
            </div>
          } className={"p-mt-3"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="oldPassword" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="oldPassword"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Jelenlegi jelszó</label>
                    <span className="p-input-icon-right">
                      <Password id="oldPassword" {...input}
                                feedback={false}
                                toggleMask={true}
                                onChange={(e) => {
                                  setPasswordFormData({
                                    ...passwordFormData,
                                    oldPassword: e.target.value
                                  })
                                }}
                                value={passwordFormData.oldPassword}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              <div className={"p-col-12 p-lg-6"}>
                <Field name="newPassword" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="newPassword"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>Új jelszó</label>
                    <span className="p-input-icon-right">
                      <Password id="newPassword" {...input}
                                feedback={false}
                                weakLabel={"Gyenge jelszó"}
                                mediumLabel={"Közepes jelszó"}
                                strongLabel={"Erős jelszó"}
                                toggleMask={true}
                                onChange={(e) => {
                                  setPasswordFormData({
                                    ...passwordFormData,
                                    newPassword: e.target.value
                                  })
                                }}
                                value={passwordFormData.newPassword}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
            </div>
            <div className={"p-grid p-mt-3"}>
              <div className={"p-col-12 p-lg-6 p-xl-6"}>

              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                        className="p-button-success"/>
              </div>
            </div>
          </Panel>
        </form>
      )}/>
      <Sidebar position="left" visible={window.App.state.sidebarEmployeeShow} closeOnEscape={true}
               style={{width: '980px'}}
               onHide={() => {
                 window.App.setState({sidebarEmployeeShow: false})
               }}>
        <h2 className={"text-primary"}>Munkavállaló megtekintése</h2>
        <EmployeeShowComponent initialValues={window.App.state.props}/>
      </Sidebar>
    </>
  )
}
export default withTranslation('common')(PartnerFormComponent);
