import axios from "axios";

export default function UserFormService(formData, method) {
  let _formData
  if (method === 'post') {
    _formData = formData
  } else if (formData.employee && formData.employee.id) {
    let roleGroupsId
    if (formData.roleGroups && formData.roleGroups.id) {
      roleGroupsId = formData.roleGroups.id
    }
    _formData = {
      employee: {
        id: formData.employee.id,
      },
      identifier: formData.identifier,
      name: formData.name,
      active: formData.active,
      roleGroups: {
        id: roleGroupsId
      },
    }
  } else {
    let roleGroupsId
    if (formData.roleGroups && formData.roleGroups.id) {
      roleGroupsId = formData.roleGroups.id
    }
    _formData = {
      identifier: formData.identifier,
      name: formData.name,
      active: formData.active,
      roleGroups: {
        id: roleGroupsId
      },
    }
  }

  let id
  let url
  if (formData && formData.id) {
    id = formData.id
    url = process.env.REACT_APP_API_HOST + "/user/" + id
  } else {
    url = process.env.REACT_APP_API_HOST + "/user"
  }
  return axios({
    method: method,
    url: url,
    data: _formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
