import axios from "axios";

export default function ProjectmilestoneReceiptItemListService(projectId, receiptItemId) {
  return axios({
    method: 'get',
    url: process.env.REACT_APP_API_HOST + "/project_milestone/" + projectId + "/" + receiptItemId + "/receipt-items",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
