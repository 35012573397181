import axios from "axios";

export default function PartnercontactFormService(formData, method, partnerId, contactId) {
  let url = process.env.REACT_APP_API_HOST + "/partnerContact/" + partnerId
  if(contactId) url = process.env.REACT_APP_API_HOST + "/partnerContact/" + partnerId + "/" + contactId
  return axios({
    method: method,
    url: url,
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
