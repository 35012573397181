import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Chart} from "primereact/chart";

import ManufacturingsummaryListService from "../../services/manufacturingsummary/ManufacturingsummaryListService";


const HercalendarListComponent = (props) => {

  const [ECapacity, setECapacity] = useState([]);
  const [MCapacity, setMCapacity] = useState([]);
  useEffect(() => {

    ManufacturingsummaryListService()
      .then(response => {
        var palettes = [
          process.env.REACT_APP_COLOR1,
          process.env.REACT_APP_COLOR2,
          process.env.REACT_APP_COLOR3,
          process.env.REACT_APP_COLOR4,
          process.env.REACT_APP_COLOR5,
        ];
        let EChartData = []
        let MChartData = []
        let colorIndexStarter = -1
        Object.entries(response.data).forEach((item, itemindex) => {
          if (item[0] === 'totalFreeHoursByEmployee') {
            item[1].map(function (_item, index) {
              const colorIndex = ++colorIndexStarter % palettes.length
              EChartData.push({
                type: 'bar',
                label: _item.name + " (" + _item.totalHoursFree + ")",
                backgroundColor: palettes[colorIndex],
                data: [_item.totalHoursFree]

              })
            })
          }
          setECapacity(EChartData)
          if (item[0] === 'totalFreeHoursByMachine') {
            item[1].map(function (_item, index) {
              const colorIndex = ++colorIndexStarter % palettes.length;
              MChartData.push({
                type: 'bar',
                label: _item.name + " (" + _item.totalHoursFree + ")",
                backgroundColor: palettes[colorIndex],
                data: [_item.totalHoursFree]
              })
            })
          }
          setMCapacity(MChartData)
        });
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }, []);

  return (
    <>
      <div className={"p-grid"}>
        <div className={"p-col-12 p-lg-6"}>
          <small className={"p-text-uppercase"}>
            Munkavállalói szabad kapacitás
          </small>
          <h3 className={"p-m-0 p-p-0 p-text-secondary"}>
            X óra
          </h3>
          <Chart type="bar"
                 data={
                   {
                     labels: ['',],
                     datasets: ECapacity,
                   }
                 }
                 options={
                   {
                     indexAxis: 'y',
                     maintainAspectRatio: false,
                     aspectRatio: 3,
                     plugins: {
                       legend: {
                         labels: {
                           color: '#000'
                         }
                       }
                     },
                     scales: {
                       x: {
                         stacked: true,
                         ticks: {
                           color: '#e5e5e5'
                         },
                         grid: {
                           color: '#fff'
                         }
                       },
                       y: {
                         stacked: true,
                         ticks: {
                           color: '#fff'
                         },
                         grid: {
                           color: '#fff'
                         }
                       }
                     }
                   }
                 }/>
        </div>
        <div className={"p-col-12 p-lg-6"}>
          <small className={"p-text-uppercase"}>
            Gép szabad kapacitás
          </small>
          <h3 className={"p-m-0 p-p-0 p-text-secondary"}>
            X óra
          </h3>

          <Chart type="bar"
                 data={
                   {
                     labels: ['',],
                     datasets: MCapacity,
                   }
                 }
                 options={
                   {
                     indexAxis: 'y',
                     maintainAspectRatio: false,
                     aspectRatio: 3,
                     plugins: {
                       legend: {
                         labels: {
                           color: '#000'
                         }
                       }
                     },
                     scales: {
                       x: {
                         stacked: true,
                         ticks: {
                           color: '#e5e5e5'
                         },
                         grid: {
                           color: '#fff'
                         }
                       },
                       y: {
                         stacked: true,
                         ticks: {
                           color: '#fff'
                         },
                         grid: {
                           color: '#fff'
                         }
                       }
                     }
                   }
                 }/>
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(HercalendarListComponent);
