import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";

import ProjectactionFormService from '../../services/projectaction/ProjectactionFormService'
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {addLocale} from "primereact/api";
import ProjectitemListComponent from "../projectitem/ProjectitemListComponent";
import ProjectactionListService from "../../services/projectaction/ProjectactionListService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import ProjectitemListService from "../../services/projectitem/ProjectitemListService";
import {Dropdown} from "primereact/dropdown";

const ProjectactionFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProjectitemList: false})
      ProjectitemListService(JSON.stringify(lazyParams), props.projectId)
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setActions(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const onRowReorder = (e) => {
    setSelectedActions(e.value);
  }

  const validate = (data) => {
    let errors = {};
    // if (!data.projectActionName) {
    //   errors.projectActionName = 'Az megnevezés nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    console.log(selectedActions)
    //setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      // ProjectactionFormService(data, 'put', props.projectId)
      //   .then(response => {
      //     window.App.toastShow('success', 'Sikeres módosítás!');
      //     window.App.setState({
      //       rerenderProjectactionList: true,
      //       sidebarProjectactionUpdate: false,
      //       dialogProjectaction: false,
      //     })
      //     setLoading(false)
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //     setLoading(false)
      //   })
    } else {
      // ProjectactionFormService(data, 'post', props.projectId)
      //   .then(response => {
      //     window.App.toastShow('success', 'Sikeres mentés!');
      //     window.App.setState({
      //       rerenderProjectactionList: true,
      //       sidebarProjectactionCreate: false,
      //       dialogProjectaction: false,
      //     })
      //     setLoading(false)
      //   })
      //   .catch(error => {
      //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      //     setLoading(false)
      //   })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    if (props && props.initialValues) {
      setFormData(
        props.initialValues
      )
    }
    if (window.App.state.props && window.App.state.props.initialValues) {
      setFormData(
        window.App.state.props.initialValues
      )
    }
    loadLazyData();
  }, [lazyParams])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-5"}>
              <Field name="startAt" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="startAt"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Beosztás kezdete</label>
                  <span className="p-input-icon-right">
                        {formData.startAt && (formData.startAt !== null || formData.startAt !== undefined) ? (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    showTime
                                    hideOnDateTimeSelect="true"
                                    hourFormat="24"
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          startAt: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                                    value={new Date(formData.startAt)}
                                    //mask="9999-99-99"
                                    id={"startAt"}
                                    locale={"hu"}/>
                        ):(
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    hideOnDateTimeSelect="true"
                                    showTime
                                    hourFormat="24"
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          startAt: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                                    viewDate={new Date(new Date().setHours(0,0,0,0))}
                                    id={"startAt"}
                                    locale={"hu"}/>
                        )}
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
        </Panel>
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Kiválasztható feladatok
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          <DataTable
            emptyMessage="Nincs találat."
            value={actions} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
            onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
            onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
            paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
            autoLayout={true}
            filterDisplay="row"
responsiveLayout="scroll"
            stripedRows
            selection={selectedActions}
            onSelectionChange={(e) => {
              setSelectedActions(e.value)
            }}
            dataKey="id"
            selectionMode="checkbox"
            // onAllRowsSelect={e => {
            //   setTotalRecords(actions.length)
            // }}
            // onAllRowsUnselect={e => {
            //   loadLazyData();
            // }}
          >
            {/*<Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>*/}
            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
            <Column field="projectActionName" header="Név" sortable filter filterPlaceholder="Név"></Column>
            <Column field="serial" header="Sorszám"></Column>
            <Column field="unitName" header="Egység"></Column>
            <Column field="quantity" header="Mennyiség"></Column>
            <Column field="unitPrice" header="Egységár"></Column>
            <Column field="materialUnitPrice" header="Anyag egységár"></Column>
          </DataTable>
        </Panel>
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Kiválasztott feladatok és sorrendjük
              </h3>
            </div>
          </div>
        } className={"p-mt-3"}>
          <DataTable
            emptyMessage="Nincs találat."
            value={selectedActions} emptyMessage="Nincs találat."
            autoLayout={true}
            filterDisplay="row"
responsiveLayout="scroll"
            stripedRows
            onRowReorder={onRowReorder}
            reorderableRows
            dataKey="id"
          >
            {/*<Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>*/}
            <Column field="projectActionName" header="Név" sortable filter filterPlaceholder="Név"></Column>
            <Column field="serial" header="Sorszám"></Column>
            <Column field="unitName" header="Egység"></Column>
            <Column field="quantity" header="Mennyiség"></Column>
            <Column field="unitPrice" header="Egységár"></Column>
            <Column field="materialUnitPrice" header="Anyag egységár"></Column>
            <Column rowReorder style={{width: '3em'}} />
          </DataTable>
        </Panel>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            <Button type="submit" label="Beosztás készítése"  icon={"pi pi-check"} loading={loading}
                    className="p-button-success"/>
          </div>
        </div>

      </form>
    )}/>
  )
}
export default withTranslation('common')(ProjectactionFormComponent);
