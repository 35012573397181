import axios from "axios";

export default function EntryzoneWorktypeRemoveService(formData, entryzoneId) {
  return axios({
    method: 'DELETE',
    url: process.env.REACT_APP_API_HOST + "/entry_zone/" + entryzoneId + "/workTypes",
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
