import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Timeline} from "primereact/timeline";
import {Card} from "primereact/card";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";

import EntryterminalShowService from "../../services/entryterminal/EntryterminalShowService";

const EntryterminalShowComponent = (props) => {

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (props && props.initialValues) {
      EntryterminalShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })

    }
  }, [])

  return (
    <div className={"p-grid"}>
      <div className={"p-col-12 p-lg-12 p-xl-12"}>
        <Panel className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-8 p-xl-8"}>
              <div className={"p-grid p-mt-0"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-list"}></i> Alapadatok
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                {formData.name &&
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <label>Megnevezés</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {formData.name}
                  </p>
                </div>
                }
                {formData.identifier &&
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <label>Azonosító</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {formData.identifier}
                  </p>
                </div>
                }
                {formData.entryZoneName &&
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <label>Zóna</label>
                  <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                    {formData.entryZoneName}
                  </p>
                </div>
                }
              </div>
              <div className={"p-grid p-mt-0 p-mr-1 panel-highlight"}>
                {formData.id &&
                <>
                  <div className={"p-col-12 p-lg-4 p-xl-4"}>
                    <label>Hiba státusz</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.status}
                    </p>
                  </div>
                  <div className={"p-col-12 p-lg-4 p-xl-4"}>
                    <label>Üzemmód</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.mode}
                    </p>
                  </div>
                  <div className={"p-col-12 p-lg-4 p-xl-4"}>
                    <label>Utolsó módosítás</label>
                    <p className={"p-mt-1 p-mb-0 p-text-bold"}>
                      {formData.updatedAt !== null ? (
                        <>{moment.utc(formData.updatedAt).local().format('YYYY-MM-DD')}</>
                      ) : (
                        <>Nincs adat</>
                      )}
                    </p>
                  </div>
                </>
                }
              </div>
            </div>
            <div className={"p-col-4 p-lg-4 p-xl-4 panel-highlight"}>
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-image"}></i> Fotó
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  {formData.id &&
                  <img src={process.env.REACT_APP_API_HOST + "/entry_terminal/" + formData.id + "/download?token="
                  + localStorage.getItem("userToken") + "?date=" + new (Date)}
                       className={"w-100 p-d-block"}/>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              {formData.id && localStorage.getItem('roles').toString().includes('role_entry_terminal_write') &&
              <Button type="button" label="Módosítás" icon="pi pi-pencil" className="p-button-warning"
                      onClick={() => {
                        window.App.setState({
                          sidebarEntryterminalShow: false,
                          sidebarEntryterminalUpdate: true,
                          props: formData
                        })
                      }}
              />
              }
            </div>
          </div>

        </Panel>
      </div>
    </div>
  )
}
export default withTranslation('common')(EntryterminalShowComponent);
