import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {PickList} from 'primereact/picklist';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import WorktypeFormService from '../../services/worktype/WorktypeFormService'
import EntrylocationListService from "../../services/entrylocation/EntrylocationListService";
import EntrylocationEmployeeListService from "../../services/entrylocation/EntrylocationEmployeeListService";

import WorktypeEmployeeAddService from "../../services/worktype/WorktypeEmployeeAddService";
import WorktypeEmployeeListService from "../../services/worktype/WorktypeEmployeeListService";
import WorktypeEmployeeRemoveService from "../../services/worktype/WorktypeEmployeeRemoveService";
import WorktypeAttachableEmployeeListService from "../../services/worktype/WorktypeAttachableEmployeeListService";
import {AutoComplete} from "primereact/autocomplete";

const WorktypeFormComponent = (props) => {

  const [formData, setFormData] = useState({
    entryLocation: {id: Number(localStorage.getItem('defaultProjectId'))},
  });
  const [loading, setLoading] = useState(false);
  const [entryloations, setEntryloations] = useState([]);

  const validate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'Az megnevezés nem lehet üres';
    }
    if (!data.entryLocation) {
      errors.entryLocation = 'A projekt nem lehet üres';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if ((props && props.initialValues) || formData.id) {
      WorktypeFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          loadLazyDataEmployeesSource(response.data.id);
          loadLazyDataEmployeesTarget(response.data.id);
          window.App.setState({rerenderWorktypeList: true})
          if (formData.closeForm === true) {
            window.App.setState({
              sidebarWorktypeCreate: false,
              sidebarWorktypeUpdate: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      WorktypeFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          setFormData(response.data)
          loadLazyDataEmployeesSource(response.data.id);
          loadLazyDataEmployeesTarget(response.data.id);
          window.App.setState({rerenderWorktypeList: true})
          if (formData.closeForm === true) {
            window.App.setState({
              sidebarWorktypeCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // employees assign
  const [employeesSource, setEmployeesSource] = useState([]);
  const [employeesToAdd, setEmployeesToAdd] = useState([]);
  const [employeesTarget, setEmployeesTarget] = useState([]);
  const [employeesToDelete, setEmployeesToDelete] = useState([]);

  const [loadingEmployeesSource, setLoadingEmployeesSource] = useState(false);
  const [totalRecordsEmployeesSource, setTotalRecordsEmployeesSource] = useState(0);
  const [rowsEmployeesSource, setRowsEmployeesSource] = useState(10);
  const [lazyParamsEmployeesSource, setLazyParamsEmployeesSource] = useState({
    first: 0,
    rows: rowsEmployeesSource,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutEmployeesSource = null;
  const loadLazyDataEmployeesSource = (worktypeId) => {
    setLoadingEmployeesSource(true);
    if (loadLazyTimeoutEmployeesSource) {
      clearTimeout(loadLazyTimeoutEmployeesSource);
    }
    loadLazyTimeoutEmployeesSource = setTimeout(() => {
      WorktypeAttachableEmployeeListService(JSON.stringify(lazyParamsEmployeesSource), worktypeId)
        .then(response => {
          setTotalRecordsEmployeesSource(response.data.total_item_count);
          setEmployeesSource(response.data.items);
          setLoadingEmployeesSource(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const onSortEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const onFilterEmployeesSource = (event) => {
    let _lazyParams = {...lazyParamsEmployeesSource, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsEmployeesSource(_lazyParams);
  }
  const pagerEmployeesSource = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsEmployeesSource(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const onEmployeesSubmit = () => {
    window.App.setState({loadingWorktypeEmployeeAdd: true})
    let _employeesToAdd = []
    Object.entries(employeesToAdd).map(function (employee, index) {
      _employeesToAdd.push(employee[1].id)
    })
    WorktypeEmployeeAddService(_employeesToAdd, formData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        loadLazyDataEmployeesSource(formData.id);
        loadLazyDataEmployeesTarget(formData.id);
        window.App.setState({loadingWorktypeEmployeeAdd: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        window.App.setState({loadingWorktypeEmployeeAdd: false})
      })
  }

  const [loadingEmployeesTarget, setLoadingEmployeesTarget] = useState(false);
  const [totalRecordsEmployeesTarget, setTotalRecordsEmployeesTarget] = useState(0);
  const [rowsEmployeesTarget, setRowsEmployeesTarget] = useState(10);
  const [lazyParamsEmployeesTarget, setLazyParamsEmployeesTarget] = useState({
    first: 0,
    rows: rowsEmployeesTarget,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutEmployeesTarget = null;
  const loadLazyDataEmployeesTarget = (worktypeId) => {
    setLoadingEmployeesTarget(true);
    if (loadLazyTimeoutEmployeesTarget) {
      clearTimeout(loadLazyTimeoutEmployeesTarget);
    }
    loadLazyTimeoutEmployeesTarget = setTimeout(() => {
      WorktypeEmployeeListService(JSON.stringify(lazyParamsEmployeesTarget), worktypeId)
        .then(response => {
          setTotalRecordsEmployeesTarget(response.data.total_item_count);
          setEmployeesTarget(response.data.items);
          setLoadingEmployeesTarget(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageEmployeesTarget = (event) => {
    let _lazyParams = {...lazyParamsEmployeesTarget, ...event};
    setLazyParamsEmployeesTarget(_lazyParams);
  }
  const onSortEmployeesTarget = (event) => {
    let _lazyParams = {...lazyParamsEmployeesTarget, ...event};
    setLazyParamsEmployeesTarget(_lazyParams);
  }
  const onFilterEmployeesTarget = (event) => {
    let _lazyParams = {...lazyParamsEmployeesTarget, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsEmployeesTarget(_lazyParams);
  }
  const pagerEmployeesTarget = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsEmployeesTarget(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const onEmployeesDelete = () => {
    window.App.setState({loadingWorktypeEmployeeRemove: true})
    let _employeesToDelete = []
    Object.entries(employeesToDelete).map(function (employee, index) {
      _employeesToDelete.push(employee[1].id)
    })
    WorktypeEmployeeRemoveService(_employeesToDelete, formData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        loadLazyDataEmployeesSource(formData.id);
        loadLazyDataEmployeesTarget(formData.id);
        window.App.setState({loadingWorktypeEmployeeRemove: false})
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        window.App.setState({loadingWorktypeEmployeeRemove: false})
      })
  }

  // entryLocation autocomplete
  const [entryLocations, setEntryLocations] = useState([]);
  const [selectedEntryLocation, setSelectedEntryLocation] = useState(localStorage.getItem("defaultProjectName"));

  const searchEntryLocation = (event) => {
    setSelectedEntryLocation(event.query.replace('Nincs találat', '').replace(': ', ''))
    EntrylocationListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setEntryLocations(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedEntryLocation(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    if (formData && formData.id) {
      loadLazyDataEmployeesSource(formData.id);
      loadLazyDataEmployeesTarget(formData.id);
    }
    if (props && props.initialValues) {
      loadLazyDataEmployeesSource(props.initialValues.id);
      loadLazyDataEmployeesTarget(props.initialValues.id);
      setFormData(props.initialValues)
      setSelectedEntryLocation(props.initialValues.entryLocationName)
    }
  }, [lazyParamsEmployeesSource, lazyParamsEmployeesTarget])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit, submitting}) => (
      <form onSubmit={handleSubmit} className="">
        <div className={"p-grid p-d-flex p-flex-wrap"}>
          <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex"}>
            <Panel headerTemplate={
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-list"}></i> Alapadatok
                  </h3>
                </div>
              </div>
            } className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-12 p-lg-12"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                      <span className="p-input-icon-right">
                        <InputText id="name" {...input}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       name: e.target.value
                                     })
                                   }}
                                   value={formData.name}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                {/*<div className={"p-col-12 p-md-4 p-lg-4"}>*/}
                {/*  <Field name="entryLocation" render={({input, meta}) => (*/}
                {/*    <div className="p-field p-fluid">*/}
                {/*      <label htmlFor="entryLocation"*/}
                {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Projekt</label>*/}
                {/*      <span className="p-input-icon-right">*/}
                {/*        <AutoComplete value={selectedEntryLocation}*/}
                {/*                      id={"entryLocation"}*/}
                {/*                      suggestions={entryLocations}*/}
                {/*                      // forceSelection*/}
                {/*                      completeMethod={searchEntryLocation}*/}
                {/*                      field={"name"}*/}
                {/*                      delay="500"*/}
                {/*                      placeholder={"Keresés gépeléssel..."}*/}
                {/*                      className={classNames({'p-invalid': isFormFieldValid(meta)})}*/}
                {/*                      onSelect={(e) => {*/}
                {/*                        setFormData({*/}
                {/*                          ...formData,*/}
                {/*                          entryLocation: {id: e.value.id}*/}
                {/*                        })*/}
                {/*                        setSelectedEntryLocation(e.value.name)*/}
                {/*                        localStorage.setItem("defaultProjectId", e.value.id)*/}
                {/*                        localStorage.setItem("defaultProjectName", e.value.name)*/}
                {/*                      }}*/}
                {/*                      dropdown*/}
                {/*                      onClear={(e) => {*/}
                {/*                        setSelectedEntryLocation('')*/}
                {/*                      }}*/}
                {/*        />*/}
                {/*      </span>*/}
                {/*      {getFormErrorMessage(meta)}*/}
                {/*    </div>*/}
                {/*  )}/>*/}
                {/*</div>*/}
              </div>
              <div className={"p-grid p-mt-3"}>
                <div className={"p-col-12 p-lg-4 p-xl-4"}>

                </div>
                <div className={"p-col-12 p-lg-8 p-xl-8 p-text-right"}>
                  <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                          onClick={() => {
                            setFormData({...formData, closeForm: true})
                          }}
                          className="p-button-success"/>
                  <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                          onClick={() => {
                            setFormData({...formData, closeForm: false})
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
            </Panel>
          </div>
          <div className={"p-col-12 p-lg-8 p-xl-8 p-d-flex"}>
            {localStorage.getItem('roles').toString().includes('role_work_type_employees') ? (
              <>
                <Panel className={"w-100"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      <div className={"p-grid"}>
                        <div className={"p-col-12"}>
                          <h3>
                            <i className={"pi pi-list"}></i> Kiválasztható munkavállalók
                          </h3>
                        </div>
                      </div>
                      {formData.id ? (
                        <>
                          <DataTable
                            emptyMessage="Nincs találat."
                            value={employeesSource} paginator first={lazyParamsEmployeesSource.first}
                            totalRecords={totalRecordsEmployeesSource} onPage={onPageEmployeesSource}
                            onSort={onSortEmployeesSource} sortField={lazyParamsEmployeesSource.sortField}
                            sortOrder={lazyParamsEmployeesSource.sortOrder}
                            onFilter={onFilterEmployeesSource} filters={lazyParamsEmployeesSource.filters}
                            loading={loadingEmployeesSource} lazy
                            paginatorTemplate={pagerEmployeesSource} rows={rowsEmployeesSource}
                            emptyMessage="Nincs találat."
                            autoLayout={true}
                            selection={employeesToAdd} onSelectionChange={e => {
                            setEmployeesToAdd(e.value)
                          }} dataKey="id"
                            selectionMode="checkbox"
                            filterDisplay="row"
responsiveLayout="scroll"
                            stripedRows
                          >
                            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                            <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
                          </DataTable>
                          <div className={"p-grid p-mt-3"}>
                            <div className={"p-col-12 p-lg-6 p-xl-6"}>

                            </div>
                            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                              <Button type="button" label="Kijelöltek hozzáadása" icon={"pi pi-plus"}
                                      loading={window.App.state.loadingWorktypeEmployeeAdd}
                                      onClick={onEmployeesSubmit}
                                      className="p-button-success"/>
                            </div>
                          </div>
                        </>
                      ) : (
                        <><i className={"pi pi-lock"}></i> A munkavállalók kiválasztása csak mentés után lehetséges!</>
                      )}
                    </div>
                    <div className={"p-col-12 p-lg-6 p-xl-6"}>
                      <div className={"p-grid"}>
                        <div className={"p-col-12"}>
                          <h3>
                            <i className={"pi pi-list"}></i> Kiválasztott munkavállalók
                          </h3>
                        </div>
                      </div>
                      {formData.id ? (
                        <>
                          <DataTable
                            emptyMessage="Nincs találat."
                            value={employeesTarget} paginator first={lazyParamsEmployeesTarget.first}
                            totalRecords={totalRecordsEmployeesTarget} onPage={onPageEmployeesTarget}
                            onSort={onSortEmployeesTarget} sortField={lazyParamsEmployeesTarget.sortField}
                            sortOrder={lazyParamsEmployeesTarget.sortOrder}
                            onFilter={onFilterEmployeesTarget} filters={lazyParamsEmployeesTarget.filters}
                            loading={loadingEmployeesTarget} lazy
                            paginatorTemplate={pagerEmployeesTarget} rows={rowsEmployeesTarget}
                            emptyMessage="Nincs találat."
                            autoLayout={true}
                            selection={employeesToDelete} onSelectionChange={e => {
                            setEmployeesToDelete(e.value)
                          }} dataKey="id"
                            selectionMode="checkbox"
                            filterDisplay="row"
responsiveLayout="scroll"
                            stripedRows
                          >
                            <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                            <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
                          </DataTable>
                          <div className={"p-grid p-mt-3"}>
                            <div className={"p-col-12 p-lg-6 p-xl-6"}>

                            </div>
                            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                              <Button type="button" label="Kijelöltek eltávolítása"
                                      onClick={onEmployeesDelete}
                                      icon="pi pi-trash"
                                      loading={window.App.state.loadingWorktypeEmployeeRemove}
                                      className="p-button-danger"/>
                            </div>
                          </div>
                        </>
                      ) : (
                        <><i className={"pi pi-lock"}></i> A munkavállalók megtekintése csak mentés után lehetséges!</>
                      )}
                    </div>
                  </div>
                </Panel>
              </>
            ) : (
              <p>Nincs jogosultság.</p>
            )}
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(WorktypeFormComponent);
