import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import {Dialog} from "primereact/dialog";
import {AutoComplete} from "primereact/autocomplete";
import {BlockUI} from "primereact/blockui";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import moment from "moment";
import {FileUpload} from "primereact/fileupload";
import {InputNumber} from "primereact/inputnumber";
import {Tooltip} from "primereact/tooltip";
import {GMap} from 'primereact/gmap';
//import { loadGoogleMaps, removeGoogleMaps } from './GoogleMaps';

import ProjectFormService from '../../services/project/ProjectFormService'
import CurrencyListService from "../../services/currency/CurrencyListService";
import ProjecttypeListService from "../../services/projecttype/ProjecttypeListService";
import ProjectstatusListService from "../../services/projectstatus/ProjectstatusListService";
import CosttypeListService from "../../services/costtype/CosttypeListService";
import PartnerListService from "../../services/partner/PartnerListService";
import PartnerFormComponent from "../partner/PartnerFormComponent";
import ProjectmilestoneCreateService from "../../services/projectmilestone/ProjectmilestoneCreateService";
import ProjectmilestoneUpdateService from "../../services/projectmilestone/ProjectmilestoneUpdateService";
import ProjectmilestoneListService from "../../services/projectmilestone/ProjectmilestoneListService";
import ProjectworktypeCreateService from "../../services/projectworktype/ProjectworktypeCreateService";
import ProjectworktypeUpdateService from "../../services/projectworktype/ProjectworktypeUpdateService";
import ProjectworktypeListService from "../../services/projectworktype/ProjectworktypeListService";
import VatListService from "../../services/vat/VatListService";
import ProjectShowService from "../../services/project/ProjectShowService";
import EntrylocationListService from "../../services/entrylocation/EntrylocationListService";
import EmployeeImportService from "../../services/employee/EmployeeImportService";
import ProjectMaterialImportService from "../../services/project/ProjectMaterialImportService";
import WarehouseListService from "../../services/warehouse/WarehouseListService";
import WarehouseFormComponent from "../warehouse/WarehouseFormComponent";
import ProjectWarehouseCreateService from "../../services/project/ProjectWarehouseCreateService";

const ProjectFormComponent = (props) => {

  const [formData, setFormData] = useState({
    currency: "HUF"
  });
  const [loading, setLoading] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [costtypes, setCosttypes] = useState([]);

  const validate = (data) => {
    let errors = {};
    if (!data.name) {
      errors.name = 'Az megnevezés nem lehet üres';
    }
    // if (!data.netAmount) {
    //   errors.netAmount = 'Az összeg nem lehet üres';
    // }
    // if (!data.projectType) {
    //   errors.projectType = 'A típus nem lehet üres';
    // }
    // if (!data.projectStatus) {
    //   errors.projectStatus = 'A státusz nem lehet üres';
    // }
    // if (!data.currency) {
    //   errors.currency = 'A pénznem kötelező';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues || formData.id) {
      ProjectFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderProjectList: true,
            newPartnerId: null,
            newPartnerName: null,
          })
          if (formData.closeForm === true) {
            window.App.setState({
              sidebarProjectUpdate: false,
              sidebarProjectCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      ProjectFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          setFormData({...data, id: response.data.id})
          window.App.setState({
            rerenderProjectList: true,
            newPartnerId: null,
            newPartnerName: null,
          })
          if (formData.closeForm === true) {
            window.App.setState({
              sidebarProjectCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // partners autocomplete
  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState('');

  const searchPartners = (event) => {
    setSelectedPartner(event.query.replace('Nincs találat', '').replace(': ', ''))
    PartnerListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {

        if (response.data && response.data.items && response.data.items[0]) {
          setPartners(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedPartner(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString());
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  // worktypes
  const [worktypes, setWorktypes] = useState([]);
  const [worktypesLoading, setWorktypesLoading] = useState(false);
  const [worktypeFormMethod, setWorktypeFormMethod] = useState('');

  const worktypeValidate = (data) => {
    let errors = {};
    if (!data.serial) {
      errors.serial = 'Kötelező mező';
    }
    if (!data.name) {
      errors.name = 'Kötelező mező';
    }
    return errors;
  };

  const worktypeActionBody = (rowData, row) => {
    return (
      <React.Fragment>
        <Button type="button" icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  setWorktypeFormMethod('update')
                  setWorktypeDialog(true)
                  setWorktypeFormData({...rowData, rowIndex: row.rowIndex})
                }}
        />
        {/*<Button type="button" icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Elem tölrése"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            worktypeDelete(rowData, row)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
      </React.Fragment>
    );
  }
  const [worktypeDialog, setWorktypeDialog] = useState(false);
  const [worktypeFormData, setWorktypeFormData] = useState({}); // set on dialog open!

  const worktypeOnSubmit = (data, form) => {
    setWorktypesLoading(true)
    if (worktypeFormMethod === 'create') {
      ProjectworktypeCreateService(data, 'post', formData.id)
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          ProjectworktypeListService(formData.id)
            .then(response => {
              setWorktypes(response.data)
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          setWorktypeDialog(false)
          setWorktypesLoading(false)
          setWorktypeFormData({})
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setWorktypesLoading(false)
        })
    }
    if (worktypeFormMethod === 'update') {
      ProjectworktypeUpdateService(data, 'put', data.id)
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          ProjectworktypeListService(formData.id)
            .then(response => {
              setWorktypes(response.data)
            })
            .catch(error => {
              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
            })
          setWorktypeDialog(false)
          setWorktypesLoading(false)
          setWorktypeFormData({})
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setWorktypesLoading(false)
        })
    }

  }

  const worktypeDelete = (data, row) => {
    let newWorktypes = worktypes.filter((worktype, index) => index !== row.rowIndex)
    setWorktypes(newWorktypes)
  }

  // vat
  const [vats, setVats] = useState([]);

  // projectType autocomplete
  const [projectTypes, setProjectTypes] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState('');

  const searchProjectType = (event) => {
    setSelectedProjectType(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProjecttypeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setProjectTypes(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedProjectType(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // projectStatus autocomplete
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState('');

  const searchProjectStatus = (event) => {
    setSelectedProjectStatus(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProjectstatusListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setProjectStatuses(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query
          } else {
            string = 'Nincs találat'
          }
          setSelectedProjectStatus(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // gmpap
  const [showMap, setShowMap] = useState(false);
  const google = window.google
  const loadGoogleMaps = (callback) => {
    const existingScript = document.getElementById('googleMaps');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://maps.google.com/maps/api/js?key=' + process.env.REACT_APP_GMAP_KEY;
      script.id = 'googleMaps';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  // const removeGoogleMaps = () => {
  //   const mapScript = document.getElementById('googleMaps');
  //
  //   if (mapScript) {
  //     mapScript.parentNode.removeChild(mapScript);
  //
  //     const head = document.getElementsByTagName('head')[0];
  //     const scripts = head.getElementsByTagName('script');
  //     for (let i = 0; i < scripts.length; i++) {
  //       let script = scripts[i];
  //       let src = script.src;
  //
  //       if (src.startsWith('https://maps.google.com/maps')) {
  //         head.removeChild(script);
  //       }
  //     }
  //   }
  // };

  const [googleMapsReady, setGoogleMapsReady] = useState(false);
  const [mapDialogVisible, setMapDialogVisible] = useState(false);
  const [mapOverlays, setMapOverlays] = useState(null);
  const [mapSelectedPosition, setMapSelectedPosition] = useState(null);
  const toast = useRef(null);

  //const toast = useRef(null);
  const infoWindow = useRef(null);

  const onMapClick = (event) => {
    setMapDialogVisible(true);
    setMapSelectedPosition(event.latLng)
  }

  const onOverlayClick = (event) => {
    let isMarker = event.overlay.getTitle !== undefined;

    if (isMarker) {
      let title = event.overlay.getTitle();
      infoWindow.current = infoWindow.current || new google.maps.InfoWindow();
      infoWindow.setContent('<div>' + title + '</div>');
      infoWindow.open(event.map, event.overlay);
      event.map.setCenter(event.overlay.getPosition());

      toast.current.show({severity: 'info', summary: 'Marker Selected', detail: title});
    } else {
      toast.current.show({severity: 'info', summary: 'Shape Selected', detail: ''});
    }
  }

  const addMarker = () => {
    let newMarker = new google.maps.Marker({
      position: {
        lat: mapSelectedPosition.lat(),
        lng: mapSelectedPosition.lng()
      },
    });

    setMapOverlays([newMarker]);
    setMapDialogVisible(false);
    setFormData({
      ...formData,
      lng: mapSelectedPosition.lng(),
      lat: mapSelectedPosition.lat()
    })
  }

  const onMapReady = (event) => {
    if (props && props.initialValues && props.initialValues.lat && props.initialValues.lng) {
      setMapOverlays(
        [
          new google.maps.Marker({position: {lat: props.initialValues.lat, lng: props.initialValues.lng}, title: ''}),
        ]
      );
    } else {
      setMapOverlays(
        []
      );
    }
  }

  const onMapHide = (event) => {
    setMapDialogVisible(false);
  }

  const mapOptions = {
    center: {lat: 47.49982405077617, lng: 19.064711250233742},
    zoom: 10
  };

  const mapFooter = <div>
    <Button label="Mentés" icon="pi pi-check" className={"p-button-success"} onClick={addMarker}/>
    <Button label="Mégse" className={"p-button-secondary"} onClick={onMapHide}/>
  </div>;

  // import
  const [importLoading, setImportLoading] = useState(false);
  const [importFormData, setImportFormData] = useState({});

  const importValidate = (data) => {
    let errors = {};
    return errors;
  };

  const fileUpload = (files) => {
    var reader = new FileReader();
    let name = files.files[0].name
    reader.onloadend = () => {
      let base64data = reader.result;
      setImportFormData({
        ...importFormData,
        file: base64data.toString().replace(/^data:(.*,)?/, ''),
        originalFileName: name
      })
    }
    reader.readAsDataURL(files.files[0])
  }

  const importOnSubmit = (data, form) => {
    setImportLoading(true)
    setImportFormData({
      ...importFormData,
      data
    });
    ProjectMaterialImportService(importFormData, formData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres importálás!', '');
        setImportFormData({...importFormData, file: null})
        setImportLoading(false)
      })
      .catch(function (error) {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    form.restart();
  };

  // warehouse autocomplete
  const [warehousesFrom, setWarehousesFrom] = useState([]);

  const searchWarehouse = (event) => {
    window.App.setState({valueWarehouseName: event.query.replace('Nincs találat', '').replace(': ', '')})
    WarehouseListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setWarehousesFrom(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          window.App.setState({valueWarehouseName: string})
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    if (window.App.state.newPartnerId) {
      setFormData({
        ...formData,
        partner: {id: window.App.state.newPartnerId}
      })
      setSelectedPartner(window.App.state.newPartnerName)
    } else {
      loadGoogleMaps(() => {
        setGoogleMapsReady(true);
      });
      // return () => {
      //   removeGoogleMaps();
      // }
      CurrencyListService()
        .then(response => {
          setCurrencies(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      CosttypeListService()
        .then(response => {
          setCosttypes(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      VatListService()
        .then(response => {
          setVats(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      if (props && props.initialValues) {
        setFormData(
          props.initialValues
        )
        ProjectworktypeListService(props.initialValues.id)
          .then(response => {
            setWorktypes(response.data)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        if (props.initialValues.projectType && props.initialValues.projectTypeName) {
          setSelectedProjectType(props.initialValues.projectTypeName)
        }
        if (props.initialValues.projectStatus && props.initialValues.projectStatusName) {
          setSelectedProjectStatus(props.initialValues.projectStatusName)
        }
        if (props.initialValues.partner && props.initialValues.partnerName) {
          setSelectedPartner(props.initialValues.partnerName)
        }
        if (props.initialValues.warehouse && props.initialValues.warehouseName) {
          window.App.setState({valueWarehouseName: props.initialValues.warehouseName})
        }
      }
    }
  }, [window.App.state.newPartnerId])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3 className={"p-text-secondary p-mt-0"}>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-input-icon-right">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="partner" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="partner"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Vevő
                  </label>
                  <span className="p-inputgroup">
                              <AutoComplete value={selectedPartner}
                                            id={"partner"}
                                            suggestions={partners}
                                            // forceSelection
                                            completeMethod={searchPartners}
                                            field={"name"}
                                            delay="500"
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                partner: {id: e.value.id}
                                              })
                                              setSelectedPartner(e.value.name)
                                            }}
                                            onClear={(e) => {
                                              setSelectedPartner('')
                                            }}
                              />
                              <Button type="button" className={"p-button"}
                                      onClick={() => {
                                        window.App.setState({sidebarPartnerCreate: true})
                                      }}>
                                <i className={"pi pi-window-maximize"}></i>
                              </Button>
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-12 p-lg-12"}>
              <Field name="description" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="description"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Leírás</label>
                  <span className="p-input-icon-right">
                      <InputText id="description" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     description: e.target.value
                                   })
                                 }}
                                 value={formData.description}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-12"}>
                  <h3 className={"p-text-secondary"}>
                    <i className={"pi pi-money-bill"}></i> Pénzügy
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-3 p-lg-3"}>
                  <Field name="currency" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="currency"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Pénznem</label>
                      <span className="p-input-icon-right">
                    <Dropdown options={currencies}
                              optionLabel="code"
                              optionValue="code"
                              id={"currency"}
                              emptyMessage={"Még nincs pénznem..."}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  currency: e.value
                                })
                              }}
                              value={formData.currency}
                    />
                  </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-9 p-lg-9"}>
                  <Field name="netAmount" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="netAmount"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Nettó összeg</label>
                      <span className="p-input-icon-right">
                    {formData && formData.currency ? (
                      <InputNumber {...input} id="netAmount"
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   value={formData.netAmount}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       netAmount: e.value
                                     })
                                   }}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    ) : (
                      <InputNumber {...input} id="netAmount"
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   value={formData.netAmount}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       netAmount: e.value
                                     })
                                   }}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    )}
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                {/*<div className={"p-col-12 p-md-5 p-lg-5"}>*/}
                {/*  <Field name="extraWorkAmount" render={({input, meta}) => (*/}
                {/*    <div className="p-field p-fluid">*/}
                {/*      <label htmlFor="extraWorkAmount"*/}
                {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Pótmunka összege</label>*/}
                {/*      <span className="p-input-icon-right">*/}
                {/*    {formData && formData.currency ? (*/}
                {/*      <InputNumber id="extraWorkAmount" {...input}*/}
                {/*                   // mode="currency"*/}
                {/*                   // currency={formData.currency}*/}
                {/*                   minFractionDigits={0}*/}
                {/*                   maxFractionDigits={9}*/}
                {/*                   locale="hu-HU"*/}
                {/*                   onChange={(e) => {*/}
                {/*                     setFormData({*/}
                {/*                       ...formData,*/}
                {/*                       extraWorkAmount: e.value*/}
                {/*                     })*/}
                {/*                   }}*/}
                {/*                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                {/*    ) : (*/}
                {/*      <InputNumber id="extraWorkAmount" {...input}*/}
                {/*                   locale="hu-HU"*/}
                {/*                   minFractionDigits={0}*/}
                {/*                   maxFractionDigits={9}*/}
                {/*                   onChange={(e) => {*/}
                {/*                     setFormData({*/}
                {/*                       ...formData,*/}
                {/*                       extraWorkAmount: e.value*/}
                {/*                     })*/}
                {/*                   }}*/}
                {/*                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
                {/*    )}*/}
                {/*    </span>*/}
                {/*      {getFormErrorMessage(meta)}*/}
                {/*    </div>*/}
                {/*  )}/>*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <div className={"p-grid p-mt-1"}>
                <div className={"p-col-12"}>
                  <h3 className={"p-text-secondary"}>
                    <i className={"pi pi-info-circle"}></i> Tulajdonságok
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="projectType" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="projectType"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                      <span className="p-input-icon-right">
                        <AutoComplete value={selectedProjectType}
                                      id={"projectType"}
                                      suggestions={projectTypes}
                                      // forceSelection
                                      completeMethod={searchProjectType}
                                      field={"name"}
                                      delay="500"
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          projectType: {id: e.value.id}
                                        })
                                        setSelectedProjectType(e.value.name)
                                      }}
                                      dropdown
                                      onClear={(e) => {
                                        setSelectedProjectType('')
                                      }}
                        />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="projectStatus" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="projectStatus"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Státusz</label>
                      <span className="p-input-icon-right">
                        <AutoComplete value={selectedProjectStatus}
                                      id={"projectType"}
                                      suggestions={projectStatuses}
                                      // forceSelection
                                      completeMethod={searchProjectStatus}
                                      field={"name"}
                                      delay="500"
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          projectStatus: {id: e.value.id}
                                        })
                                        setSelectedProjectStatus(e.value.name)
                                      }}
                                      dropdown
                                      onClear={(e) => {
                                        setSelectedProjectStatus('')
                                      }}
                        />
                  </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3 className={"p-text-secondary"}>
                    <i className={"pi pi-file"}></i> Szerződés
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="contractNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="contractNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Szerződésszám</label>
                      <span className="p-input-icon-right">
                      <InputText id="contractNumber" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     contractNumber: e.target.value
                                   })
                                 }}
                                 value={formData.contractNumber}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="projectNumber" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="projectNumber"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Projektszám</label>
                      <span className="p-input-icon-right">
                      <InputText id="projectNumber" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     projectNumber: e.target.value
                                   })
                                 }}
                                 value={formData.projectNumber}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3 className={"p-text-secondary"}>
                    <i className={"pi pi-calendar"}></i> Határidők
                  </h3>
                </div>
              </div>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="startedAt" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="startedAt"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Munkakezdés</label>
                      <span className="p-input-icon-right">
                    {formData.startedAt && (formData.startedAt !== null || formData.startedAt !== undefined) ? (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value) {
                                    setFormData({
                                      ...formData,
                                      startedAt: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  }
                                }}
                                value={new Date(formData.startedAt)}
                                mask="9999-99-99"
                                id={"startedAt"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    ) : (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value) {
                                    setFormData({
                                      ...formData,
                                      startedAt: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  }
                                }}
                                mask="9999-99-99"
                                id={"startedAt"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    )}
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-md-6 p-lg-6"}>
                  <Field name="guaranteeEndAt" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="guaranteeEndAt"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Garancia lejárat</label>
                      <span className="p-input-icon-right">
                    {formData.guaranteeEndAt && (formData.guaranteeEndAt !== null || formData.guaranteeEndAt !== undefined) ? (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value) {
                                    setFormData({
                                      ...formData,
                                      guaranteeEndAt: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  }
                                }}
                                value={new Date(formData.guaranteeEndAt)}
                                mask="9999-99-99"
                                id={"guaranteeEndAt"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    ) : (
                      <Calendar dateFormat="yy-mm-dd"
                                monthNavigator={true}
                                yearNavigator={true}
                                yearRange={"2022:2099"}
                                keepInvalid="true"
                                onChange={(e) => {
                                  if (e.target.value) {
                                    setFormData({
                                      ...formData,
                                      guaranteeEndAt: moment.utc(e.target.value).local().format('YYYY-MM-DD')
                                    })
                                  }
                                }}
                                mask="9999-99-99"
                                id={"guaranteeEndAt"}
                                locale={"hu"}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}
                      />
                    )}
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <h3 className={"p-text-secondary"}>
                <i className={"pi pi-map"}></i> Lokáció
              </h3>
            </div>
            <div className={"p-col-6 p-lg-6 p-xl-6 p-text-right p-col-align-center"}>
              {showMap === false ? (
                <Button className={"p-button-outlined p-button-sm"}
                        type={"button"}
                        style={{border: 'none'}}
                        label="Térkép nézet bekapcsolása" icon="pi pi-map"
                        onClick={() => {
                          setShowMap(true)
                        }}
                />
              ) : (
                <Button className={"p-button-outlined p-button-sm p-button-danger"}
                        type={"button"}
                        style={{border: 'none'}}
                        label="Térkép nézet kikapcsolása" icon="pi pi-map"
                        onClick={() => {
                          setShowMap(false)
                        }}
                />
              )}
            </div>
          </div>
          <div className={"p-grid"}>
            {
              showMap === true && googleMapsReady && (
                <div className={"p-col-12"}>
                  <div className="card">
                    <GMap overlays={mapOverlays} options={mapOptions} style={{width: '100%', minHeight: '320px'}}
                          onMapReady={onMapReady}
                          onMapClick={onMapClick} onOverlayClick={onOverlayClick}/>
                  </div>
                </div>
              )
            }
            <Dialog header="Lokáció kiválasztása" visible={mapDialogVisible} width="300px" modal footer={mapFooter}
                    onHide={onMapHide}>
              <div className="grid p-fluid">
                <div className="col-2" style={{paddingTop: '.75em'}}>Lat</div>
                <div className="col-10"><InputText readOnly
                                                   value={mapSelectedPosition ? mapSelectedPosition.lat() : ''}/>
                </div>
                <div className="col-2" style={{paddingTop: '.75em'}}>Lng</div>
                <div className="col-10"><InputText readOnly
                                                   value={mapSelectedPosition ? mapSelectedPosition.lng() : ''}/>
                </div>
              </div>
            </Dialog>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="lat" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="lat"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Szélesség</label>
                  <span className="p-input-icon-right">
                      <InputNumber id="lat" {...input}
                                   format={false}
                                   minFractionDigits={0}
                                   maxFractionDigits={20}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       lat: e.value
                                     })
                                   }}
                                   value={formData.lat}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <Field name="lng" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="lng"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Hosszúság</label>
                  <span className="p-input-icon-right">
                      <InputNumber id="lng" {...input}
                                   format={false}
                                   minFractionDigits={0}
                                   maxFractionDigits={20}
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       lng: e.value
                                     })
                                   }}
                                   value={formData.lng}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-1"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              <h3 className={"p-text-secondary"}>
                <i className={"pi pi-map"}></i> Egyéb
              </h3>
            </div>
          </div>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="warehouse" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="warehouse"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Alapértelmezett raktár</label>
                  <span className="p-inputgroup">
                      <AutoComplete value={window.App.state.valueWarehouseName}
                                    id={"warehouse"}
                                    suggestions={warehousesFrom}
                                    // forceSelection
                                    completeMethod={searchWarehouse}
                                    field={"name"}
                                    delay="500"
                                    dropdown
                                    placeholder={"Keresés gépeléssel..."}
                                    className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                    onSelect={(e) => {
                                      setFormData({
                                        ...formData,
                                        warehouse: {id: e.value.id},
                                        warehouseName: e.value.name
                                      })
                                      window.App.setState({valueWarehouseName: e.value.name})
                                    }}
                                    onClear={(e) => {
                                      window.App.setState({valueWarehouseName: ''})
                                    }}
                      />
                    {/*<Button type="button" className={"p-button"}*/}
                    {/*        onClick={() => {*/}
                    {/*          window.App.setState({*/}
                    {/*            dialogWarehouse: true*/}
                    {/*          })*/}
                    {/*        }}>*/}
                    {/*  <i className={"pi pi-window-maximize"}></i>*/}
                    {/*</Button>*/}
                    {formData.id && !formData.warehouseName &&
                    <Button type="button" className={"p-button-success"}
                            onClick={() => {
                              ProjectWarehouseCreateService(formData.id)
                                .then(response => {
                                  window.App.toastShow('success', 'Sikeres raktár létrehozás!');
                                  setFormData({
                                    ...formData,
                                    warehouse: {id: response.id},
                                    warehouseName: response.name
                                  })
                                })
                                .catch(error => {
                                  window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                })
                            }}>
                      <i className={"pi pi-plus"}></i>
                    </Button>
                    }
                    {/*<Dialog visible={window.App.state.dialogWarehouse}*/}
                    {/*        style={{width: '750px'}}*/}
                    {/*        header="Raktár hozzáadása"*/}
                    {/*        modal*/}
                    {/*        onHide={() => {*/}
                    {/*          window.App.setState({*/}
                    {/*            dialogWarehouse: false*/}
                    {/*          })*/}
                    {/*        }}>*/}
                    {/*  <WarehouseFormComponent/>*/}
                    {/*</Dialog>*/}
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              {formData.id &&
              <Button type="button" label="Megtekintés" icon={"pi pi-eye"}
                      onClick={() => {
                        window.App.setState({
                          props: formData,
                          sidebarProjectUpdate: false,
                          sidebarProjectCreate: false,
                          sidebarProjectShow: true,
                        })
                      }}
                      className="p-button"/>
              }
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        setFormData({...formData, closeForm: true})
                      }}
                      className="p-button-success"/>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
        </Panel>
        {/*<BlockUI blocked={Boolean(!formData.id)} template={<i className="pi pi-lock" style={{'fontSize': '3rem'}}/>}>*/}
        {/*<Panel headerTemplate={*/}
        {/*  <div className={"p-grid"}>*/}
        {/*    <div className={"p-col-12"}>*/}
        {/*      <h3 className={"p-text-secondary p-mt-0"}>*/}
        {/*        <i className={"pi pi-list"}></i> Munkatípusok*/}
        {/*      </h3>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*} className={"p-mt-3"}>*/}
        {/*  {formData && formData.id ? (*/}
        {/*    <>*/}
        {/*      {worktypes && worktypes[0] &&*/}
        {/*      <DataTable value={worktypes}*/}
        {/*                 emptyMessage="Nincs találat."*/}
        {/*                 autoLayout={true}*/}
        {/*                 filterDisplay="row"
responsiveLayout="scroll"*/}
        {/*                 stripedRows*/}
        {/*                 className={"p-mb-3"}*/}
        {/*      >*/}
        {/*        <Column body={worktypeActionBody} className={"p-py-0 p-text-nowrap"}></Column>*/}
        {/*        <Column field="name" header="Megnevezés"></Column>*/}
        {/*      </DataTable>*/}
        {/*      }*/}
        {/*      <div className={"p-grid"}>*/}
        {/*        <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>*/}
        {/*          {worktypes && !worktypes[0] &&*/}
        {/*          <>Nincs munkatípus.</>*/}
        {/*          }*/}
        {/*        </div>*/}
        {/*        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>*/}
        {/*          <Button type="button" label="Új típus hozzáadása" icon="pi pi-plus" className="p-button-success"*/}
        {/*                  onClick={() => {*/}
        {/*                    setWorktypeDialog(true)*/}
        {/*                    setWorktypeFormMethod('create')*/}
        {/*                  }}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </>*/}
        {/*  ) : (*/}
        {/*    <><i className={"pi pi-lock"}></i> A munkatípusok megtekintése csak mentés után lehetséges!</>*/}
        {/*  )}*/}
        {/*</Panel>*/}


        {/*<Form onSubmit={importOnSubmit} initialValues={importFormData}*/}
        {/*      validate={importValidate} render={({handleSubmit, submitting}) => (*/}
        {/*  <form onSubmit={handleSubmit} className="">*/}

        {/*    <Panel headerTemplate={*/}
        {/*      <div className={"p-grid"}>*/}
        {/*        <div className={"p-col-12 p-lg-12 p-xl-12  p-col-align-center"}>*/}
        {/*          <h3 className={"p-text-secondary p-mt-0"}>*/}
        {/*            <i className={"pi pi-upload"}></i> Anyagfelhasználási terv import*/}
        {/*          </h3>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    } className={"p-mt-3"}>*/}
        {/*      {formData && formData.id ? (*/}
        {/*        <>*/}
        {/*          <div className={"p-grid"}>*/}
        {/*            <div className={"p-col-12 p-lg-6 p-xl-6"}>*/}
        {/*              <Field name="file" render={({input, meta}) => (*/}
        {/*                <div className="p-field">*/}
        {/*                  {!importFormData.file ? (*/}
        {/*                    <>*/}
        {/*                      <label htmlFor="image"*/}
        {/*                             className={classNames({'p-error': isFormFieldValid(meta)})}>XLS*/}
        {/*                        kiválasztása</label>*/}
        {/*                      <span className="">*/}
        {/*                                                 <FileUpload mode="basic"*/}
        {/*                                                             name="file"*/}
        {/*                                                             id={"file"}*/}
        {/*                                                             accept=""*/}
        {/*                                                             auto="true"*/}
        {/*                                                             maxFileSize={1000000}*/}
        {/*                                                             customUpload*/}
        {/*                                                             chooseLabel={"Fájl kiválasztása"}*/}
        {/*                                                             uploadLabel={"Feltöltés"}*/}
        {/*                                                             uploadHandler={fileUpload}*/}
        {/*                                                 />*/}
        {/*                                              </span>*/}
        {/*                    </>*/}
        {/*                  ) : (*/}
        {/*                    <>*/}
        {/*                      <label htmlFor="image"*/}
        {/*                             className={classNames({'p-error': isFormFieldValid(meta)})}>Feltöltött XLS*/}
        {/*                        törlése</label>*/}
        {/*                      <span className="p-d-block">*/}
        {/*                                                <Button type="button"*/}
        {/*                                                        className="p-button p-button-danger"*/}
        {/*                                                        onClick={() => setImportFormData({...importFormData, file: null})}*/}
        {/*                                                >Törlés</Button>*/}
        {/*                                              </span>*/}
        {/*                    </>*/}
        {/*                  )}*/}
        {/*                  {getFormErrorMessage(meta)}*/}
        {/*                </div>*/}
        {/*              )}/>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*          <div className={"p-col-12 p-text-right"}>*/}
        {/*            {importFormData.file ? (*/}
        {/*              <Button type="submit" label="Import indítása"  icon={"pi pi-check"}*/}
        {/*                      className="p-button-success"/>*/}
        {/*            ) : (*/}
        {/*              <Button type="button" label="Import indítása"*/}
        {/*                      className="p-button-success"*/}
        {/*                      onClick={() => {*/}
        {/*                        alert('Tölts fel egy XLS fájlt!')*/}
        {/*                      }}*/}
        {/*              />*/}
        {/*            )}*/}
        {/*          </div>*/}
        {/*        </>*/}
        {/*      ) : (*/}
        {/*        <><i className={"pi pi-lock"}></i> Az importálás csak mentés után lehetséges!</>*/}
        {/*      )}*/}
        {/*    </Panel>*/}
        {/*  </form>*/}
        {/*)}/>*/}
        {/*</BlockUI>*/}
        <Dialog visible={worktypeDialog}
                style={{width: '750px'}}
                header="Típus hozzáadása"
                modal
                onHide={() => {
                  setWorktypeDialog(false)
                }}>
          <Form onSubmit={worktypeOnSubmit}
                initialValues={worktypeFormData}
                validate={worktypeValidate}
                render={({handleSubmit, submitting}) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className={"p-grid"}>
                      <div className={"p-col-12 p-md-4 p-lg-4"}>
                        <Field name="serial" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="serial"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Sorszám</label>
                            <span className="p-input-icon-right">
                              <InputText id="serial" {...input}
                                         onChange={(e) => {
                                           setWorktypeFormData({
                                             ...worktypeFormData,
                                             serial: e.target.value
                                           })
                                         }}
                                         value={worktypeFormData.serial}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-md-8 p-lg-8"}>
                        <Field name="name" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="name"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Név</label>
                            <span className="p-input-icon-right">
                              <InputText id="name" {...input}
                                         onChange={(e) => {
                                           setWorktypeFormData({
                                             ...worktypeFormData,
                                             name: e.target.value
                                           })
                                         }}
                                         value={worktypeFormData.name}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col-12 p-lg-6 p-xl-6"}>

                      </div>
                      <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                        {worktypeFormMethod === 'create' &&
                        <Button type="submit" label="Típus hozzáadása" icon="pi pi-check"
                                loading={worktypesLoading}/>
                        }
                        {worktypeFormMethod === 'update' &&
                        <Button type="submit" label="Módosítás" icon="pi pi-check"
                                loading={worktypesLoading}/>
                        }
                      </div>
                    </div>
                  </form>
                )}/>
        </Dialog>
        <Dialog visible={window.App.state.sidebarPartnerCreate}
                style={{width: '750px'}}
                header="Partner hozzáadása"
                modal
                onHide={() => {
                  window.App.setState({sidebarPartnerCreate: false})
                }}>
          <PartnerFormComponent/>
        </Dialog>
      </form>
    )}/>
  )
}
export default withTranslation('common')(ProjectFormComponent);
