import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {Panel} from "primereact/panel";
import {TabView, TabPanel} from 'primereact/tabview';

import ProjectListComponent from "../../components/project/ProjectListComponent";
import ProjectFormComponent from "../../components/project/ProjectFormComponent";
import ProjectShowComponent from "../../components/project/ProjectShowComponent";
import WorksheetListComponent from "../../components/worksheet/WorksheetListComponent";
import WorksheetFormComponent from "../../components/worksheet/WorksheetFormComponent";
import WorksheetShowComponent from "../../components/worksheet/WorksheetShowComponent";
import TrackListComponent from "../../components/track/TrackListComponent";

export const ProjectDashboardPage = () => {

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Projektek' + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('project')[0]) {
        document.getElementsByClassName('project')[0].classList.add("activeMenu")
      }
    },1000)
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_project') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_project_read') ? (
                  <TabPanel header="Projektek lista">
                    <div className={"p-p-5"}>
                      {/*<Button className={"p-button-outlined p-button-sm fixed"}*/}
                      {/*        label="Kiterjesztett nézet" icon="pi pi-window-maximize"*/}
                      {/*        onClick={() => {*/}
                      {/*          window.App.setState({sidebarProjectList: true})*/}
                      {/*        }}*/}
                      {/*/>*/}
                      <ProjectListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                <TabPanel header="Munkalapok lista">
                  <div className={"p-p-5"}>
                    {/*<Button className={"p-button-outlined p-button-sm fixed"}*/}
                    {/*        label="Kiterjesztett nézet" icon="pi pi-window-maximize"*/}
                    {/*        onClick={() => {*/}
                    {/*          window.App.setState({sidebarWorksheetList: true})*/}
                    {/*        }}*/}
                    {/*/>*/}
                    <WorksheetListComponent view="simple"/>
                  </div>
                </TabPanel>
                <TabPanel header="Munkanaplók lista">
                  <div className={"p-p-5"}>
                    <TrackListComponent view="simple"/>
                  </div>
                </TabPanel>
              </TabView>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {/*<div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>*/}
                  {/*  <Card className={"p-text-center w-100 shadow-none"}>*/}
                  {/*    <a onClick={() => window.App.setState({sidebarContractCreate: true})}>*/}
                  {/*      <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"*/}
                  {/*         style={{'fontSize': '2em'}}></i>*/}
                  {/*      Új szerződés*/}
                  {/*    </a>*/}
                  {/*  </Card>*/}
                  {/*</div>*/}
                  {localStorage.getItem('roles').toString().includes('role_project_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarProjectCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új projekt
                      </Card>
                    </a>
                  </div>
                  }
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarWorksheetCreate: true})} className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új munkalap
                      </Card>
                    </a>
                  </div>
                </div>
                {/*<Chart type="bar"*/}
                {/*       options={{*/}
                {/*         animation: {*/}
                {/*           duration: 0*/}
                {/*         },*/}
                {/*         plugins: {*/}
                {/*           legend: {*/}
                {/*             labels: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           }*/}
                {/*         },*/}
                {/*         scales: {*/}
                {/*           x: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*           y: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*         }*/}
                {/*       }}*/}
                {/*       data={{*/}
                {/*         labels: ['Január', 'December', 'November', 'Október'],*/}
                {/*         datasets: [*/}
                {/*           {*/}
                {/*             label: '',*/}
                {/*             data: [30, 50, 32, 12],*/}
                {/*             backgroundColor: [*/}
                {/*               "#42A5F5",*/}
                {/*               "#66BB6A",*/}
                {/*               "#FFA726",*/}
                {/*               "#F8f8f8"*/}
                {/*             ]*/}
                {/*           }*/}
                {/*         ]*/}
                {/*       }}/>*/}
                {/*<h3 className={"p-text-center p-text-normal"}>Bevétel</h3>*/}
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                <span className={"p-text-light"}
                      dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          {/*<Sidebar position="left" visible={window.App.state.sidebarContractCreate} closeOnEscape={true}*/}
          {/*         style={{width: '780px'}}*/}
          {/*         onHide={() => {*/}
          {/*           window.App.setState({sidebarContractCreate: false})*/}
          {/*         }}>*/}
          {/*  <h2 className={"text-primary"}>Új szerződés</h2>*/}
          {/*  <ContractFormComponent method="post"/>*/}
          {/*</Sidebar>*/}
          {/*<Sidebar position="left" visible={window.App.state.sidebarContractUpdate} closeOnEscape={true}*/}
          {/*         style={{width: '780px'}}*/}
          {/*         onHide={() => {*/}
          {/*           window.App.setState({sidebarContractUpdate: false})*/}
          {/*         }}>*/}
          {/*  <h2 className={"text-primary"}>Szerződés módosítása</h2>*/}
          {/*  <ContractFormComponent method="put" initialValues={window.App.state.props}/>*/}
          {/*</Sidebar>*/}
          <Sidebar position="left" visible={window.App.state.sidebarProjectList} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => window.App.setState({sidebarProjectList: false})}>
            <h2 className={"text-primary"}>Projektek listája</h2>
            <ProjectListComponent view="advanced"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarProjectCreate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <h2 className={"text-primary"}>Új projekt</h2>
            <ProjectFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectUpdate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarProjectUpdate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <h2 className={"text-primary"}>Projekt módosítása</h2>
            <ProjectFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarProjectShow} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => window.App.setState({sidebarProjectShow: false})}>
            {/*<h2 className={"text-primary"}>Projekt megtekintése</h2>*/}
            <ProjectShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWorksheetList} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => window.App.setState({sidebarWorksheetList: false})}>
            <h2 className={"text-primary"}>Munkalapok listája</h2>
            <WorksheetListComponent view="simple"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWorksheetCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarWorksheetCreate: false,
                     })
                   }}>
            <h2 className={"text-primary"}>Új munkalap</h2>
            <WorksheetFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWorksheetUpdate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({
                       sidebarWorksheetUpdate: false,
                       newPartnerId: null,
                       newPartnerName: null
                     })
                   }}>
            <h2 className={"text-primary"}>Munkalap módosítása</h2>
            <WorksheetFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarWorksheetShow} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => window.App.setState({sidebarWorksheetShow: false})}>
            <h2 className={"text-primary"}>Munkalap megtekintése</h2>
            <WorksheetShowComponent initialValues={window.App.state.props}/>
          </Sidebar>

        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          {/*<i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>*/}
          {/*<span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>*/}
          {/*Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.*/}
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(ProjectDashboardPage);
