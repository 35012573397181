import axios from "axios";

export default function InventorytransferFinishService(transferId) {
  return axios({
    method: 'put',
    url: process.env.REACT_APP_API_HOST + "/inventoryTransfer/" + transferId + "/finish",
    data: {},
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
