import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {Sidebar} from "primereact/sidebar";

import PartnerFormComponent from "../components/partner/PartnerFormComponent";

export const DashboardPage = (props) => {

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Vezérlőpult' + ' - ' + process.env.REACT_APP_TITLE;
    if (document.getElementsByClassName('activeMenu')[0]) {
      document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
    }
  });

  return (
    <div className="DashboardPage p-component p-p-3">
      <div className={"p-grid p-d-flex p-flex-wrap"}>
        <div className={"p-col-12 p-lg-8 p-xl-8 p-p-5 left"}>
          <div className={"p-p-5"}>
            {localStorage.getItem('welcomeCard') === true || !localStorage.getItem('welcomeCard') &&
            <Card title="Üdvözöl a Trackie.io" subTitle={"1.2 Béta verzió!"} id="welcome"
                  className={"p-mb-5 p-p-3 p-pb-0"}
                  footer={
                    <Button icon="pi pi-times" className="p-button" label={"Bezár"}
                            onClick={() => {
                              if (window.confirm('Bezárást követően nem jelenik meg többé ez az üzenet!')) {
                                localStorage.setItem('welcomeCard', false);
                                window.App.forceUpdate()
                              }
                            }}
                    />
                  }
            >
              <p className={"p-m-0"}>
                Igyekeztünk a lehető legalaposabban letesztelni a szoftverünket, de ettől még biztosan
                előfordulnak hibák. Továbbra is várjuk a véleményeket a szoftverrel kapcsolatban, vagy a hibajelzéseket
                screenshottal együtt
                a(z) <a
                href={"mailto:" + process.env.REACT_APP_DEVOPS_CONTACT}>{process.env.REACT_APP_DEVOPS_CONTACT}</a> email
                címre. <strong>
                Köszönjük a türelmet!</strong>
              </p>
            </Card>
            }
            <h2 className={"p-pt-0 p-mb-5 text-primary p-text-center p-text-lg-left"}>
              Folyamatban lévő fejlesztéseink
            </h2>
            <div className={"p-grid p-mt-5"}>
              <div className={"p-col-12 p-lg-2 p-text-center p-col-align-center"}>
                <i className="pi pi-shopping-cart bg-white"
                   style={{'fontSize': '2em', 'padding': '1em', 'borderRadius': '3px',}}></i>
              </div>
              <div className={"p-col-12 p-lg-10 p-text-center p-text-lg-left"}>
                <strong>Webshop integrációk</strong>
                <p className={"p-mb-0"}>
                  Számlázható rendelések összegyűjtése webáruházakból és azok automatikus kiszámlázása, majd
                  kézbesítése a vevő részére. Elérhető integrációk: UNAS webáruház vagy egyedi API haszhálat.
                  Előrendelhető integrációk: Wordpress WooCommerce, Prestashop, Shoprenter
                </p>
              </div>
            </div>
            <hr className={"p-my-5"}/>
            <div className={"p-grid p-mt-5"}>
              <div className={"p-col-12 p-lg-2 p-text-center p-col-align-center"}>
                <i className="pi pi-chart-bar bg-white"
                   style={{'fontSize': '2em', 'padding': '1em', 'borderRadius': '3px',}}></i>
              </div>
              <div className={"p-col-12 p-lg-10 p-text-center p-text-lg-left"}>
                <strong>Pénzügyi kontrolling</strong>
                <p className={"p-mb-0"}>
                  Lejelentett költségszámlák importálása a NAV rendszeréből vagy kézi rögzítése számlakép
                  feltöltéssel. Electra formátum importálása, majd az importált banki tranzakciók párosítása
                  számlákkal és/vagy költségszámlákkal. A számlákat lefedő banki tranzakciók alapján a rendszer
                  automatikusan fizetettre állítja az adott számlákat. Bevételek, kintlévőségek és ÁFA fizetési
                  információk.
                </p>
              </div>
            </div>
            <hr className={"p-my-5"}/>
            <div className={"p-grid p-mt-5"}>
              <div className={"p-col-12 p-lg-2 p-text-center p-col-align-center"}>
                <i className="pi pi-users bg-white"
                   style={{'fontSize': '2em', 'padding': '1em', 'borderRadius': '3px',}}></i>
              </div>
              <div className={"p-col-12 p-lg-10 p-text-center p-text-lg-left"}>
                <strong>CRM funkciók</strong>
                <p className={"p-mb-0"}>
                  Fejlett partner kezelés partner forgalmi adatainak, cégméret és látszám adatainak rögzítésével,
                  értékesítési folyamatok naplózása és tennivalók meghatrozása dolgozók között. Figyelmeztetések és
                  emlékeztetők kezelése.
                </p>
              </div>
            </div>
            <hr className={"p-my-5"}/>
            <div className={"p-mt-5 p-pt-5 p-text-center"}>
              <a href={"mailto:" + process.env.REACT_APP_DEVOPS_CONTACT}
                 className={"p-button p-button-lg p-button-success"}>
                Érdeklődj előfizetési lehetőségekről!
              </a>
            </div>
          </div>
        </div>
        <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
          <div className={"p-p-5 w-100"}>
            <h2 className={"p-mt-0"}>Aktív modulok</h2>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                <Link to={"/partner"} className={"w-100"}>
                  <Card className={"p-text-center w-100 shadow-none"}>
                    <i className="pi pi-briefcase bg-white p-d-block p-mb-2"
                       style={{'fontSize': '2em'}}></i>
                    Partnerek
                  </Card>
                </Link>
              </div>
              {localStorage.getItem("networkId")
              // && window.App.state.companyPermissions
              // && window.App.state.companyPermissions.invoice
              // && window.App.state.companyPermissions.invoice.active == true
              // && window.App.state.companyPermissions.invoice.end > new Date(Date.now()).toISOString()
              &&
              <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                <Link to={"/finance"} className={"w-100"}>
                  <Card className={"p-text-center w-100 shadow-none"}>
                    <i className="pi pi-wallet bg-white p-d-block p-mb-2"
                       style={{'fontSize': '2em'}}></i>
                    Pénzügy
                  </Card>
                </Link>
              </div>
              }
              {localStorage.getItem("networkId")
              // && window.App.state.companyPermissions
              // && window.App.state.companyPermissions.project
              // && window.App.state.companyPermissions.project.active == true
              // && window.App.state.companyPermissions.project.end > new Date(Date.now()).toISOString()
              &&
              <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                <Link to={"/project"} className={"w-100"}>
                  <Card className={"p-text-center w-100 shadow-none"}>
                    <i className="pi pi-clone bg-white p-d-block p-mb-2"
                       style={{'fontSize': '2em'}}></i>
                    Projektek
                  </Card>
                </Link>
              </div>
              }
              {localStorage.getItem("networkId")
              // && window.App.state.companyPermissions
              // && window.App.state.companyPermissions.webshop
              // && window.App.state.companyPermissions.webshop.active == true
              // && window.App.state.companyPermissions.webshop.end > new Date(Date.now()).toISOString()
              &&
              <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                <Link to={"/order"} className={"w-100"}>
                  <Card className={"p-text-center w-100 shadow-none"}>
                    <i className="pi pi-shopping-cart bg-white p-d-block p-mb-2"
                       style={{'fontSize': '2em'}}></i>
                    Rendelések
                  </Card>
                </Link>
              </div>
              }
              {localStorage.getItem("networkId")
              // && window.App.state.companyPermissions
              // && window.App.state.companyPermissions.entry
              // && window.App.state.companyPermissions.entry.active == true
              // && window.App.state.companyPermissions.entry.end > new Date(Date.now()).toISOString()
              &&
              <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                <Link to={"/entry"} className={"w-100"}>
                  <Card className={"p-text-center w-100 shadow-none"}>
                    <i className="pi pi-id-card bg-white p-d-block p-mb-2"
                       style={{'fontSize': '2em'}}></i>
                    Beléptetés
                  </Card>
                </Link>
              </div>
              }
              {localStorage.getItem("networkId")
              // && window.App.state.companyPermissions
              // && window.App.state.companyPermissions.hr
              // && window.App.state.companyPermissions.hr.active == true
              // && window.App.state.companyPermissions.hr.end > new Date(Date.now()).toISOString()
              &&
              <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                <Link to={"/hr"} className={"w-100"}>
                  <Card className={"p-text-center w-100 shadow-none"}>
                    <i className="pi pi-users bg-white p-d-block p-mb-2"
                       style={{'fontSize': '2em'}}></i>
                    HR
                  </Card>
                </Link>
              </div>
              }
            </div>
            <div className={"p-text-center"}>
              <h3 className={"p-text-center p-text-normal"}>Gyorsmenü</h3>
              <Button className={"p-button-outlined p-button-sm p-ml-2 p-mt-2 p-text-light"}
                      onClick={() => window.App.setState({sidebarPartnerCreate: true})}
              >
                <i className="pi pi-plus-circle"></i>&nbsp;Új partner
              </Button>
              {localStorage.getItem("networkId")
              // && window.App.state.companyPermissions
              // && window.App.state.companyPermissions.invoice
              // && window.App.state.companyPermissions.invoice.active == true
              // && window.App.state.companyPermissions.invoice.end > new Date(Date.now()).toISOString()
              &&
              <Button className={"p-button-outlined p-button-sm p-ml-2 p-mt-2 p-text-light"}
                      onClick={() => window.App.setState({sidebarReceiptInvoiceCreate: true})}
              >
                <i className="pi pi-plus-circle"></i>&nbsp;Új számla
              </Button>
              }
              {localStorage.getItem("networkId")
              // && window.App.state.companyPermissions
              // && window.App.state.companyPermissions.controlling
              // && window.App.state.companyPermissions.controlling.active == true
              // && window.App.state.companyPermissions.controlling.end > new Date(Date.now()).toISOString()
              &&
              <Button className={"p-button-outlined p-button-sm p-ml-2 p-mt-2 p-text-light"}
                      onClick={() => window.App.setState({sidebarReceiptCostCreate: true})}
              >
                <i className="pi pi-plus-circle"></i>&nbsp;Új költségszámla
              </Button>
              }
            </div>
            <div className={"p-py-3"}>
              <hr className={"p-my-5"}/>
            </div>
            <img src={require('../assets/img/undraw_set_preferences_kwia.svg')} className="p-d-block p-mx-auto w-50"/>
            <div className={"p-text-center p-mt-3"}>
              {localStorage.getItem("networkId")
              // && window.App.state.companyPermissions
              // && window.App.state.companyPermissions.invoice
              // && window.App.state.companyPermissions.invoice.active == true
              // && window.App.state.companyPermissions.invoice.end > new Date(Date.now()).toISOString()
              &&
              <Button className={"p-button-outlined p-button-sm p-ml-2 p-mt-2 p-text-light"}>
                NAV 3.0 beállítás
              </Button>
              }
              {localStorage.getItem("networkId")
              // && window.App.state.companyPermissions
              // && window.App.state.companyPermissions.webshop
              // && window.App.state.companyPermissions.webshop.active == true
              // && window.App.state.companyPermissions.webshop.end > new Date(Date.now()).toISOString()
              &&
              <Button className={"p-button-outlined p-button-sm p-ml-2 p-mt-2 p-text-light"}>
                UNAS kapcsolat
              </Button>
              }
              <h3 className={"p-text-center p-text-normal p-mt-4"}>Beállítások</h3>
            </div>
            <div className={"p-text-center p-as-end w-100"}>
              <span className={"p-text-light"}
                    dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
            </div>
          </div>
        </div>
      </div>
      <Sidebar position="left" visible={window.App.state.sidebarPartnerCreate} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({sidebarPartnerCreate: false})}>
        <h2 className={"text-primary"}>Új partner</h2>
        <PartnerFormComponent method="post"/>
      </Sidebar>
      <Sidebar position="left" visible={window.App.state.sidebarReceiptInvoiceCreate} closeOnEscape={true}
               style={{width: '95%'}}
               onHide={() => {
                 window.App.setState({sidebarReceiptInvoiceCreate: false})
               }}>
        <h2 className={"text-primary"}>Új számla</h2>
        {/*<ReceiptInvoiceFormComponent method="post"/>*/}
      </Sidebar>
      <Sidebar position="left" visible={window.App.state.sidebarReceiptCostCreate} closeOnEscape={true}
               style={{width: '95%'}}
               onHide={() => window.App.setState({sidebarReceiptCostCreate: false})}>
        <h2 className={"text-primary"}>Új költségszámla</h2>
        {/*<ReceiptCostFormComponent />*/}
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(DashboardPage);
