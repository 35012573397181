import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from "primereact/dropdown";

import ImportqueueListService from "../../services/importqueue/ImportqueueListService";
import {Column} from "primereact/column";
import ImportqueueStatusesService from "../../services/importqueue/ImportqueueStatusesService";
import moment from "moment";
import ProjectmilestoneitemListService from "../../services/projectmilestoneitem/ProjectmilestoneitemListService";
import {Button} from "primereact/button";
import {Field, Form} from "react-final-form";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {AutoComplete} from "primereact/autocomplete";
import {FileUpload} from "primereact/fileupload";
import {Message} from "primereact/message";
import {Dialog} from "primereact/dialog";
import EmployeepositionFormService from "../../services/employeeposition/EmployeepositionFormService";
import ProjectactionListService from "../../services/projectaction/ProjectactionListService";
import {MultiSelect} from "primereact/multiselect";
import {InputNumber} from "primereact/inputnumber";
import ProjectmilestoneitemFormService from "../../services/projectmilestoneitem/ProjectmilestoneitemFormService";
import CompanyListService from "../../services/company/CompanyListService";

const ProjectmilestoneitemListComponent = (props) => {

  const [projectmilestoneitems, setProjectmilestoneitems] = useState([]);
  const [milestoneitemDialog, setMilestoneitemDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProjectmilestoneitemList: false})
      ProjectmilestoneitemListService(JSON.stringify(lazyParams), props.initialValues.id)
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProjectmilestoneitems(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  //form
  const [formData, setFormData] = useState({});
  const validate = (data) => {
    let errors = {};
    if (!data.title) {
      errors.title = 'Az cím nem lehet üres';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    // if (props && props.initialValues) {
    //   ProjectmilestoneitemFormService(data, 'put', props.initialValues.id)
    //     .then(response => {
    //       window.App.toastShow('success', 'Sikeres módosítás!');
    //       // window.App.setState({
    //       //   rerenderEmployeepositionList: true,
    //       //   sidebarEmployeepositionUpdate: false
    //       // })
    //       setLoading(false)
    //     })
    //     .catch(error => {
    //       window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //       setLoading(false)
    //     })
    // } else {
    ProjectmilestoneitemFormService(data, 'post', props.initialValues.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres mentés!');
        // window.App.setState({
        //   rerenderEmployeepositionList: true,
        //   sidebarEmployeepositionCreate: false
        // })
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
    //}
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };


  // projectaction autocomplete
  const [projectactions, setProjectactions] = useState([]);
  const [selectedProjectaction, setSelectedProjectaction] = useState();

  const searchProjectaction = (event) => {
    setSelectedProjectaction(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProjectactionListService(JSON.stringify({filters: {projectActionName: {value: event.query.replace('Nincs találat', '').replace(': ', '')}, project: {value: props.projectId}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setProjectactions(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedProjectaction(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    ProjectactionListService(JSON.stringify({filters: {project: {value: props.projectId}}}))
      .then(response => {
        setProjectactions(response.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderProjectmilestoneitemList === true])

  return (

    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={projectmilestoneitems} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows}
        autoLayout={true}
        ref={dt}
        filterDisplay="row"
responsiveLayout="scroll"
        stripedRows
      >
        <Column field="createdAt" header="Időpont" sortable body={(rowData) => {
          return (
            <>{moment.utc(rowData.createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</>
          )
        }}></Column>
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>

        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          <Button type="button" label="Új hozzáadása"
                  icon={"pi pi-plus"}
                  loading={loading}
                  onClick={() => {
                    setMilestoneitemDialog(true)
                  }}
                  className="p-button-success"/>
          <Dialog visible={milestoneitemDialog}
                  style={{width: '750px'}}
                  header="Új tétel hozzáadása"
                  modal
                  onHide={() => {
                    setMilestoneitemDialog(false)
                  }}>
            <Form onSubmit={onSubmit}
                  initialValues={formData}
                  validate={validate}
                  render={({handleSubmit, submitting}) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className={"p-grid"}>
                        <div className={"p-col-6"}>
                          <Field name="projectAction" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="projectAction"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Projekt tétel</label>
                              <span className="p-input-icon-right">
                                <AutoComplete value={selectedProjectaction}
                                              id={"company"}
                                              suggestions={projectactions}
                                              // forceSelection
                                              completeMethod={searchProjectaction}
                                              field={"projectActionName"}
                                              delay="500"
                                              placeholder={"Keresés gépeléssel..."}
                                              className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                              onSelect={(e) => {
                                                setSelectedProjectaction(e.value.projectActionName)
                                                setFormData({
                                                  ...formData,
                                                  projectAction: {id: e.value.id}
                                                })
                                              }}
                                              dropdown
                                              onClear={(e) => {
                                                setSelectedProjectaction('')
                                              }}
                                />
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-6"}>
                          <Field name="title" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="title"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Cím</label>
                              <span className="p-input-icon-right">
                              <InputText id="title" {...input}
                                         onChange={(e) => {
                                           setFormData({
                                             ...formData,
                                             title: e.target.value
                                           })
                                         }}
                                         value={formData.title}
                                         className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-6"}>
                          <Field name="quantity" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="quantity"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Mennyiség</label>
                              <span className="p-input-icon-right">
                              <InputNumber id="quantity" {...input}
                                           locale="hu-HU"
                                           onChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               quantity: e.value
                                             })
                                           }}
                                           value={formData.quantity}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-6"}>
                          <Field name="totalNetMaterialAmount" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="totalNetMaterialAmount"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Összes anyagköltség</label>
                              <span className="p-input-icon-right">
                              <InputNumber id="totalNetMaterialAmount" {...input}
                                           locale="hu-HU"
                                           onChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               totalNetMaterialAmount: e.value
                                             })
                                           }}
                                           value={formData.totalNetMaterialAmount}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                        <div className={"p-col-6"}>
                          <Field name="totalNetAmount" render={({input, meta}) => (
                            <div className="p-field p-fluid">
                              <label htmlFor="totalNetAmount"
                                     className={classNames({'p-error': isFormFieldValid(meta)})}>Nettó összesen</label>
                              <span className="p-input-icon-right">
                              <InputNumber id="totalNetAmount" {...input}
                                           locale="hu-HU"
                                           onChange={(e) => {
                                             setFormData({
                                               ...formData,
                                               totalNetAmount: e.value
                                             })
                                           }}
                                           value={formData.totalNetAmount}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                            </span>
                              {getFormErrorMessage(meta)}
                            </div>
                          )}/>
                        </div>
                      </div>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>
                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          <Button type="submit" label="Mentés" icon="pi pi-check"
                                  loading={loading}/>
                        </div>
                      </div>
                    </form>
                  )}/>
          </Dialog>
        </div>
      </div>
    </>

  )
}
export default withTranslation('common')(ProjectmilestoneitemListComponent);
