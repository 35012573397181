import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {TabView, TabPanel} from 'primereact/tabview';
import {Panel} from "primereact/panel";

import ProductListComponent from "../../components/product/ProductListComponent";
import ProductFormComponent from "../../components/product/ProductFormComponent";
import ManufacturerListComponent from "../../components/manufacturer/ManufacturerListComponent";
import ManufacturerFormComponent from "../../components/manufacturer/ManufacturerFormComponent";
import PurchaseorderrequestListComponent from "../../components/purchaseorderrequest/PurchaseorderrequestListComponent";
import PurchaseorderrequestFormComponent from "../../components/purchaseorderrequest/PurchaseorderrequestFormComponent";
import PurchaseorderFormComponent from "../../components/purchaseorder/PurchaseorderFormComponent";
import PurchaseorderListComponent from "../../components/purchaseorder/PurchaseorderListComponent";
import WarehouseListComponent from "../../components/warehouse/WarehouseListComponent";
import WarehouseFormComponent from "../../components/warehouse/WarehouseFormComponent";
import PurchaseorderPricingComponent from "../../components/purchaseorder/PurchaseorderPricingComponent";
import PurchaseorderStockComponent from "../../components/purchaseorder/PurchaseorderStockComponent";
import PurchaseorderShowComponent from "../../components/purchaseorder/PurchaseorderShowComponent";
import EmployeeImportComponent from "../../components/employee/EmployeeImportComponent";
import ProductImportComponent from "../../components/product/ProductImportComponent";
import ReceiptShowComponent from "../../components/receipt/ReceiptShowComponent";
import ReceiptFormComponent from "../../components/receipt/ReceiptFormComponent";
import PurchaseorderrequestShowComponent from "../../components/purchaseorderrequest/PurchaseorderrequestShowComponent";

export const PurchaseDashboardPage = () => {

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Beszerzések' + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function () {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('purchase')[0]) {
        document.getElementsByClassName('purchase')[0].classList.add("activeMenu")
      }
    }, 1000)
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_purchase') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                {localStorage.getItem('roles').toString().includes('role_purchase_order_req_read') ? (
                  <TabPanel header="Beszerzési megrendelés igény"
                            toggleable
                            collapsed={localStorage.getItem('panel_purchaseorderrequests')}
                            onExpand={() => {
                              localStorage.removeItem('panel_purchaseorderrequests')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_purchaseorderrequests', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <PurchaseorderrequestListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                {localStorage.getItem('roles').toString().includes('role_purchase_order_read') ? (
                  <TabPanel header="Beszerzési megrendelések"
                            toggleable
                            collapsed={localStorage.getItem('panel_purchaseorders')}
                            onExpand={() => {
                              localStorage.removeItem('panel_purchaseorders')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_purchaseorders', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <PurchaseorderListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
              </TabView>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {localStorage.getItem('roles').toString().includes('role_purchase_order_req_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarPurchaseorderrequestCreate: true})}
                          className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új beszerzési megrendelés igény
                      </Card>
                    </a>
                  </div>
                  }
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarPurchaseorderCreate: true})}
                          className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új beszerzési megrendelés
                      </Card>
                    </a>
                  </div>
                </div>
                {/*<Chart type="bar"*/}
                {/*       options={{*/}
                {/*         animation: {*/}
                {/*           duration: 0*/}
                {/*         },*/}
                {/*         plugins: {*/}
                {/*           legend: {*/}
                {/*             labels: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           }*/}
                {/*         },*/}
                {/*         scales: {*/}
                {/*           x: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*           y: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*         }*/}
                {/*       }}*/}
                {/*       data={{*/}
                {/*         labels: ['Belépett', 'Kilépett', 'Bent tartózkodik', 'Visszautasított'],*/}
                {/*         datasets: [*/}
                {/*           {*/}
                {/*             label: 'Összes azonosítás: 134',*/}
                {/*             data: [30, 50, 32, 12],*/}
                {/*             backgroundColor: [*/}
                {/*               "#42A5F5",*/}
                {/*               "#66BB6A",*/}
                {/*               "#FFA726",*/}
                {/*               "#F8f8f8"*/}
                {/*             ]*/}
                {/*           }*/}
                {/*         ]*/}
                {/*       }}/>*/}
                {/*<h3 className={"p-text-center p-text-normal"}>Riport</h3>*/}
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                <span className={"p-text-light"}
                      dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarPurchaseorderrequestCreate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({sidebarPurchaseorderrequestCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új beszerzési megrendelés igény</h2>
            <PurchaseorderrequestFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPurchaseorderrequestUpdate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({sidebarPurchaseorderrequestUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Beszerzési megrendelés igény módosítása</h2>
            <PurchaseorderrequestFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPurchaseorderrequestShow} closeOnEscape={true}
                   style={{width: '740px'}}
                   onHide={() => {
                     window.App.setState({sidebarPurchaseorderrequestShow: false})
                   }}>
            {/*<h2 className={"text-primary"}>Beszerzési megrendelés igény megtekintése</h2>*/}
            <PurchaseorderrequestShowComponent initialValues={window.App.state.props}/>
          </Sidebar>

          <Sidebar position="left" visible={window.App.state.sidebarPurchaseorderCreate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({sidebarPurchaseorderCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új beszerzési megrendelés</h2>
            <PurchaseorderFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPurchaseorderUpdate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({sidebarPurchaseorderUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Beszerzési megrendelés módosítása</h2>
            <PurchaseorderFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarPurchaseorderShow} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => {
                     window.App.setState({lazyparamsReceiptList: null})
                     window.App.setState({sidebarPurchaseorderShow: false})
                   }}>
            {/*<h2 className={"text-primary"}>Beszerzési megrendelés megtekintése</h2>*/}
            <PurchaseorderShowComponent initialValues={window.App.state.props}/>
          </Sidebar>

          <Sidebar position="left" visible={window.App.state.sidebarReceiptShow} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptShow: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <h2 className={"text-primary"}>Bizonylat megtekintése</h2>
            <ReceiptShowComponent initialValues={window.App.state.props}/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarReceiptUpdate} closeOnEscape={true}
                   style={{width: '95%'}}
                   onHide={() => window.App.setState({
                     rerenderReceiptList: true,
                     sidebarReceiptUpdate: false,
                     newPartnerId: null,
                     newPartnerName: null
                   })}>
            <h2 className={"text-primary"}>Költségszámla módosítása</h2>
            <ReceiptFormComponent method="put" initialValues={window.App.state.props}/>
          </Sidebar>
        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          {/*<i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>*/}
          {/*<span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>*/}
          {/*Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.*/}
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(PurchaseDashboardPage);
