import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Chart} from 'primereact/chart';
import {Sidebar} from "primereact/sidebar";
import {Link} from "react-router-dom";
import {TabView, TabPanel} from 'primereact/tabview';
import {Panel} from "primereact/panel";

import ProductListComponent from "../../components/product/ProductListComponent";
import ProductFormComponent from "../../components/product/ProductFormComponent";
import ManufacturerListComponent from "../../components/manufacturer/ManufacturerListComponent";
import ManufacturerFormComponent from "../../components/manufacturer/ManufacturerFormComponent";
import PurchaseorderrequestListComponent from "../../components/purchaseorderrequest/PurchaseorderrequestListComponent";
import PurchaseorderrequestFormComponent from "../../components/purchaseorderrequest/PurchaseorderrequestFormComponent";
import PurchaseorderFormComponent from "../../components/purchaseorder/PurchaseorderFormComponent";
import PurchaseorderListComponent from "../../components/purchaseorder/PurchaseorderListComponent";
import WarehouseListComponent from "../../components/warehouse/WarehouseListComponent";
import WarehouseFormComponent from "../../components/warehouse/WarehouseFormComponent";
import PurchaseorderPricingComponent from "../../components/purchaseorder/PurchaseorderPricingComponent";
import PurchaseorderStockComponent from "../../components/purchaseorder/PurchaseorderStockComponent";
import PurchaseorderShowComponent from "../../components/purchaseorder/PurchaseorderShowComponent";
import EmployeeImportComponent from "../../components/employee/EmployeeImportComponent";
import ProductImportComponent from "../../components/product/ProductImportComponent";
import MachineListComponent from "../../components/machine/MachineListComponent";
import MachineFormComponent from "../../components/machine/MachineFormComponent";
import ProjectactionListComponent
  from "../../components/projectaction/ProjectactionListComponent";
import currencyFormatter from "currency-formatter";
import ManufacturingsummaryListService from "../../services/manufacturingsummary/ManufacturingsummaryListService";
import ProjectMaterialListService from "../../services/project/ProjectMaterialListService";
import ManufacturingsummaryListComponent from "../../components/manufacturingsummary/ManufacturingsummaryListComponent";
import ProjectactionFormComponent from "../../components/projectaction/ProjectactionFormComponent";

export const ManufacturingDashboardPage = () => {

  useEffect(() => {
    window.App.pageHeight()
    document.title = 'Gyártás' + ' - ' + process.env.REACT_APP_TITLE;
    setTimeout(function() {
      if (document.getElementsByClassName('activeMenu')[0]) {
        document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
      }
      if (document.getElementsByClassName('manufacturing')[0]) {
        document.getElementsByClassName('manufacturing')[0].classList.add("activeMenu")
      }
    },1000)
  }, []);

  return (
    <div className="ModuleDashboardPage DashboardPage p-component p-p-3 ">
      {localStorage.getItem('module_manufacturing') ? (
        <>
          <div className={"p-grid p-d-flex p-flex-wrap"}>
            <div className={"p-col-12 p-lg-8 p-xl-8 p-p-0 left p-position-relative"}>
              <TabView renderActiveOnly="true" className={"nav"} scrollable={window.App.state.tabScroll} id="tabScroll">
                <TabPanel header="Összefoglaló"
                          toggleable
                          collapsed={localStorage.getItem('panel_manufacturing')}
                          onExpand={() => {
                            localStorage.removeItem('panel_manufacturing')
                          }}
                          onCollapse={() => {
                            localStorage.setItem('panel_manufacturing', 'collapse')
                          }}>
                  <div className={"p-p-5"}>
                    <ManufacturingsummaryListComponent />
                  </div>
                </TabPanel>
                {localStorage.getItem('roles').toString().includes('role_machine_read') ? (
                  <TabPanel header="Gépek"
                            toggleable
                            collapsed={localStorage.getItem('panel_machine')}
                            onExpand={() => {
                              localStorage.removeItem('panel_machine')
                            }}
                            onCollapse={() => {
                              localStorage.setItem('panel_machine', 'collapse')
                            }}>
                    <div className={"p-p-5"}>
                      <MachineListComponent view="simple"/>
                    </div>
                  </TabPanel>
                ) : (
                  <TabPanel disabled></TabPanel>
                )}
                <TabPanel header="Naptár"
                          toggleable
                          collapsed={localStorage.getItem('panel_projectaction')}
                          onExpand={() => {
                            localStorage.removeItem('panel_projectaction')
                          }}
                          onCollapse={() => {
                            localStorage.setItem('panel_projectaction', 'collapse')
                          }}>
                  <div className={"p-p-5"}>
                    <ProjectactionListComponent view="simple"/>
                  </div>
                </TabPanel>
              </TabView>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4 p-d-flex p-flex-wrap right p-p-5 p-text-light"}>
              <div className={"p-p-5 w-100"}>
                <h2 className={"p-mt-0"}>Műveletek</h2>
                <div className={"p-grid"}>
                  {localStorage.getItem('roles').toString().includes('role_machine_write') &&
                  <div className={"p-col-12 p-lg-6 p-xl-4 p-d-flex"}>
                    <a onClick={() => window.App.setState({sidebarMachineCreate: true})}
                          className={"w-100"}>
                      <Card className={"p-text-center w-100 shadow-none"}>
                        <i className="pi pi-plus-circle bg-white p-d-block p-mb-2"
                           style={{'fontSize': '2em'}}></i>
                        Új Gép
                      </Card>
                    </a>
                  </div>
                  }
                </div>
                {/*<Chart type="bar"*/}
                {/*       options={{*/}
                {/*         animation: {*/}
                {/*           duration: 0*/}
                {/*         },*/}
                {/*         plugins: {*/}
                {/*           legend: {*/}
                {/*             labels: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           }*/}
                {/*         },*/}
                {/*         scales: {*/}
                {/*           x: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*           y: {*/}
                {/*             ticks: {*/}
                {/*               color: '#FFF'*/}
                {/*             },*/}
                {/*             grid: {*/}
                {/*               color: '#FFF'*/}
                {/*             }*/}
                {/*           },*/}
                {/*         }*/}
                {/*       }}*/}
                {/*       data={{*/}
                {/*         labels: ['Belépett', 'Kilépett', 'Bent tartózkodik', 'Visszautasított'],*/}
                {/*         datasets: [*/}
                {/*           {*/}
                {/*             label: 'Összes azonosítás: 134',*/}
                {/*             data: [30, 50, 32, 12],*/}
                {/*             backgroundColor: [*/}
                {/*               "#42A5F5",*/}
                {/*               "#66BB6A",*/}
                {/*               "#FFA726",*/}
                {/*               "#F8f8f8"*/}
                {/*             ]*/}
                {/*           }*/}
                {/*         ]*/}
                {/*       }}/>*/}
                {/*<h3 className={"p-text-center p-text-normal"}>Riport</h3>*/}
              </div>
              <div className={"p-text-center p-as-end w-100"}>
                <span className={"p-text-light"}
                      dangerouslySetInnerHTML={{__html: process.env.REACT_APP_COPYRIGHT.replace('[[YEAR]]', new Date().getFullYear())}}></span>
              </div>
            </div>
          </div>
          <Sidebar position="left" visible={window.App.state.sidebarMachineCreate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarMachineCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új gép</h2>
            <MachineFormComponent method="post"/>
          </Sidebar>
          <Sidebar position="left" visible={window.App.state.sidebarMachineUpdate} closeOnEscape={true}
                   style={{width: '780px'}}
                   onHide={() => {
                     window.App.setState({sidebarMachineUpdate: false})
                   }}>
            <h2 className={"text-primary"}>Gép módosítása</h2>
            <MachineFormComponent method="post" initialValues={window.App.state.props}/>
          </Sidebar>

          <Sidebar position="left" visible={window.App.state.sidebarProjectactionCreate} closeOnEscape={true}
                   style={{width: '980px'}}
                   onHide={() => {
                     window.App.setState({sidebarProjectactionCreate: false})
                   }}>
            <h2 className={"text-primary"}>Új beosztás</h2>
            <ProjectactionFormComponent method="post"/>
          </Sidebar>

        </>
      ) : (
        <p className={"p-text-center p-my-5 p-py-5"}>
          {/*<i className={"pi pi-exclamation-circle p-d-block p-mb-5"} style={{'fontSize': '5em'}}></i>*/}
          {/*<span className={"p-text-bold p-d-block "}>Ehhez a modulhoz sajnos nem rendelkezel hozzáféréssel.</span>*/}
          {/*Amennyiben további információra van szükséged írj emailt a {process.env.REACT_APP_DEVOPS_CONTACT} címre.*/}
        </p>
      )}
    </div>
  )
}
export default withTranslation('common')(ManufacturingDashboardPage);
