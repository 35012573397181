import axios from "axios";

export default function WorktypeEmployeeAddService(formData, worktypeId) {
  return axios({
    method: 'post',
    url: process.env.REACT_APP_API_HOST + "/worktype/" + worktypeId + "/employees",
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
