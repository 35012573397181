import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {addLocale} from "primereact/api";
import {AutoComplete} from "primereact/autocomplete";

import HrcalendarFormService from "../../services/hrcalendar/HrcalendarFormService";
import EmployeeListService from "../../services/employee/EmployeeListService";
import CalendartypeListService from "../../services/calendartype/CalendartypeListService";
import {InputSwitch} from "primereact/inputswitch";

const HrcalendarFormComponent = (props) => {

  //form
  const [formData, setFormData] = useState({});
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();
  const [loading, setLoading] = useState(false);
  const [allday, setAllday] = useState(false);

  const validate = (data) => {
    let errors = {};
    if (!data.startAt) {
      errors.startAt = 'A dátum nem lehet üres';
    }
    if (!data.endAt) {
      errors.endAt = 'A dátum nem lehet üres';
    }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues) {
      HrcalendarFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({
            rerenderHrcalendarList: true,
            sidebarHrcalendarCreate: false,
            sidebarHrcalendarUpdate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      HrcalendarFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({
            rerenderHrcalendarList: true,
            sidebarHrcalendarCreate: false,
            sidebarHrcalendarUpdate: false,
          })
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const searchEmployee = (event) => {
    setSelectedEmployee(event.query.replace('Nincs találat', '').replace(': ', ''))
    EmployeeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if(response.data && response.data.items && response.data.items[0]) {
          setEmployees(response.data.items)
        } else {
          let string
          if(event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedEmployee(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const searchType = (event) => {
    setSelectedType(event.query.replace('Nincs találat', '').replace(': ', ''))
    CalendartypeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if(response.data && response.data.items && response.data.items[0]) {
          setTypes(response.data.items)
        } else {
          let string
          if(event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedType(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {
    if (props && props.initialValues) {
      setFormData(
        props.initialValues
      )
      setSelectedType(props.initialValues.typeName)
      setSelectedEmployee(props.initialValues.employeeName)
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-2"}>
                <div className="p-field p-fluid">
                  <label htmlFor="isBlockingEntry">Egész napos</label>
                  <span className="p-input-icon-right">
                        <InputSwitch
                          className={"p-mt-1"}
                          checked={allday}
                          value={allday}
                          onChange={(e) => {
                            setAllday(e.target.value)
                            setFormData({})
                          }}/>
                      </span>
                </div>
            </div>
            <div className={"p-col-12 p-lg-5"}>
              <Field name="startAt" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="startAt"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Esemény kezdete</label>
                  <span className="p-input-icon-right">
                    {allday === false ? (
                      <>
                        {formData.startAt && (formData.startAt !== null || formData.startAt !== undefined) ? (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    showTime
                                    hideOnDateTimeSelect="true"
                                    hourFormat="24"
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          startAt: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                                    value={new Date(formData.startAt)}
                            //mask="9999-99-99"
                                    id={"startAt"}
                                    locale={"hu"}/>
                        ):(
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    hideOnDateTimeSelect="true"
                                    showTime
                                    hourFormat="24"
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          startAt: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                                    viewDate={new Date(new Date().setHours(0,0,0,0))}
                                    id={"startAt"}
                                    locale={"hu"}/>
                        )}
                      </>
                    ):(
                      <>
                        {formData.startAt && (formData.startAt !== null || formData.startAt !== undefined) ? (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          startAt: moment.utc(e.target.value).local().format('YYYY-MM-DD 00:00')
                                        })
                                      }
                                    }}
                                    value={new Date(formData.startAt)}
                                    id={"startAt"}
                                    locale={"hu"}/>
                        ):(
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    hideOnDateTimeSelect="true"
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          startAt: moment.utc(e.target.value).local().format('YYYY-MM-DD 00:00')
                                        })
                                      }
                                    }}
                                    id={"startAt"}
                                    locale={"hu"}/>
                        )}
                      </>
                    )}
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-5"}>
              <Field name="endAt" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="endAt"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Esemény vége</label>
                  <span className="p-input-icon-right">
                    {allday === false ? (
                      <>
                        {formData.endAt && (formData.endAt !== null || formData.endAt !== undefined) ? (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    showTime
                                    hideOnDateTimeSelect="true"
                                    hourFormat="24"
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          endAt: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                                    value={new Date(formData.endAt)}
                            //mask="9999-99-99"
                                    id={"endAt"}
                                    locale={"hu"}/>
                        ):(
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    showTime
                                    hideOnDateTimeSelect="true"
                                    hourFormat="24"
                                    viewDate={new Date(new Date().setHours(23,59,59,0))}
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          endAt: moment.utc(e.target.value).local().format('YYYY-MM-DD HH:mm')
                                        })
                                      }
                                    }}
                            //mask="9999-99-99"
                                    id={"endAt"}
                                    locale={"hu"}/>
                        )}
                      </>
                    ):(
                      <>
                        {formData.endAt && (formData.endAt !== null || formData.endAt !== undefined) ? (
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          endAt: moment.utc(e.target.value).local().format('YYYY-MM-DD 23:59')
                                        })
                                      }
                                    }}
                                    value={new Date(formData.endAt)}
                                    id={"endAt"}
                                    locale={"hu"}/>
                        ):(
                          <Calendar dateFormat="yy-mm-dd"
                                    monthNavigator={true}
                                    yearNavigator={true}
                                    yearRange={"2022:2099"}
                                    keepInvalid="true"
                                    onChange={(e) => {
                                      if(e.target.value) {
                                        setFormData({
                                          ...formData,
                                          endAt: moment.utc(e.target.value).local().format('YYYY-MM-DD 23:59')
                                        })
                                      }
                                    }}
                                    id={"endAt"}
                                    locale={"hu"}/>
                        )}
                      </>
                    )}
                      </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="employee" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="employee"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>
                  <span className="p-input-icon-right">
                            <AutoComplete value={selectedEmployee}
                                          id={"employee"}
                                          suggestions={employees}
                                          // forceSelection
                                          completeMethod={searchEmployee}
                                          field={"name"}
                                          delay="500"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              employee: {id: e.value.id}
                                            })
                                            setSelectedEmployee(e.value.name)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedEmployee('')
                                          }}
                            />
                          </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <Field name="type" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="type"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Típus</label>
                  <span className="p-input-icon-right">
                            <AutoComplete value={selectedType}
                                          id={"type"}
                                          suggestions={types}
                                          // forceSelection
                                          completeMethod={searchType}
                                          field={"name"}
                                          delay="500"
                                          placeholder={"Keresés gépeléssel..."}
                                          className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                          onSelect={(e) => {
                                            setFormData({
                                              ...formData,
                                              type: {id: e.value.id}
                                            })
                                            setSelectedType(e.value.name)
                                          }}
                                          dropdown
                                          onClear={(e) => {
                                            setSelectedType('')
                                          }}
                            />
                          </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>


            <div className={"p-col-12 p-lg-12 p-xl-12"}>
              <Field name="summary" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="summary"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Leírás</label>
                  <span className="p-input-icon-right">
                      <InputText id="summary" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     summary: e.target.value
                                   })
                                 }}
                                 value={formData.summary}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés" icon="pi pi-check" loading={loading}
                      className="p-button-success"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(HrcalendarFormComponent);
