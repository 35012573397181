import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import PurchaseorderRequestItemsAddedService from "../../services/purchaseorder/PurchaseorderRequestItemsAddedService";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import PurchaseorderDownloadService from "../../services/purchaseorder/PurchaseorderDownloadService";
import download from "js-file-download";
import moment from "moment";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import PurchaseorderRequestItemsUpdateService
  from "../../services/purchaseorder/PurchaseorderRequestItemsUpdateService";
import PurchaseorderStatusChangeTenderService
  from "../../services/purchaseorder/PurchaseorderStatusChangeTenderService";
import PurchaseorderShowService from "../../services/purchaseorder/PurchaseorderShowService";

const PurchaseorderFeerequestComponent = (props) => {

  const [loadingDownload, setLoadingDownload] = useState(false);
  const dt = useRef(null);

  // requested items
  const [requestItemsTarget, setRequestItemsTarget] = useState([]);
  const [loadingRequestItemsTarget, setLoadingRequestItemsTarget] = useState(false);
  const [totalRecordsRequestItemsTarget, setTotalRecordsRequestItemsTarget] = useState(0);
  const [rowsRequestItemsTarget, setRowsRequestItemsTarget] = useState(10);
  const [lazyParamsRequestItemsTarget, setLazyParamsRequestItemsTarget] = useState({
    first: 0,
    rows: rowsRequestItemsTarget,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutRequestItemsTarget = null;
  const loadLazyDataRequestItemsTarget = () => {
    setLoadingRequestItemsTarget(true);
    if (loadLazyTimeoutRequestItemsTarget) {
      clearTimeout(loadLazyTimeoutRequestItemsTarget);
    }
    loadLazyTimeoutRequestItemsTarget = setTimeout(() => {
      PurchaseorderRequestItemsAddedService(props.orderId, JSON.stringify(lazyParamsRequestItemsTarget))
        .then(response => {
          setTotalRecordsRequestItemsTarget(response.data.total_item_count);
          setRequestItemsTarget(response.data.items);
          setLoadingRequestItemsTarget(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageRequestItemsTarget = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsTarget, ...event};
    setLazyParamsRequestItemsTarget(_lazyParams);
  }
  const onSortRequestItemsTarget = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsTarget, ...event};
    setLazyParamsRequestItemsTarget(_lazyParams);
  }
  const onFilterRequestItemsTarget = (event) => {
    let _lazyParams = {...lazyParamsRequestItemsTarget, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsRequestItemsTarget(_lazyParams);
  }
  const pagerRequestItemsTarget = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsRequestItemsTarget(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  //qunatity filter
  const [quantityFilterSelectedFrom, setQuantityFilterSelectedFrom] = useState(null);
  const [quantityFilterSelectedUntil, setQuantityFilterSelectedUntil] = useState(null);

  useEffect(() => {
    loadLazyDataRequestItemsTarget();

  }, [lazyParamsRequestItemsTarget])

  return (
    <>
      <DataTable
        emptyMessage="Nincs találat."
        value={requestItemsTarget} paginator first={lazyParamsRequestItemsTarget.first}
        totalRecords={totalRecordsRequestItemsTarget} onPage={onPageRequestItemsTarget}
        onSort={onSortRequestItemsTarget} sortField={lazyParamsRequestItemsTarget.sortField}
        sortOrder={lazyParamsRequestItemsTarget.sortOrder}
        onFilter={onFilterRequestItemsTarget} filters={lazyParamsRequestItemsTarget.filters}
        loading={loadingRequestItemsTarget} lazy
        paginatorTemplate={pagerRequestItemsTarget} rows={rowsRequestItemsTarget}
        emptyMessage="Nincs találat."
        autoLayout={true}
        filterDisplay="row"
responsiveLayout="scroll"
        stripedRows
        ref={dt}
      >
        <Column field="projectName" header="Projekt" sortable filter filterPlaceholder="Projekt"></Column>
        <Column field="productName" header="Termék" sortable filter filterPlaceholder="Termék"></Column>
        <Column field="productName" header="Cikkszám" sortable filter filterPlaceholder="Cikkszám"></Column>
        <Column field="quantity" header="Mennyiség" sortable filter filterPlaceholder="Mennyiség" className={"p-text-right"}
                filterElement={
                  <div className={"p-grid p-fluid"}>
                    <div className={"p-col-5"}>
                      <InputNumber value={quantityFilterSelectedFrom}
                                   id={quantityFilterSelectedFrom}
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   onChange={(e) => {
                                     setQuantityFilterSelectedFrom(e.value)
                                     clearTimeout(window.searchTimeout)
                                     window.searchTimeout = setTimeout(function () {
                                       dt.current.filter(e.value, 'quantityFrom', '');
                                     }, 500)
                                   }}
                      />
                    </div>
                    <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                      <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                    </div>
                    <div className={"p-col-5"}>
                      <InputNumber value={quantityFilterSelectedUntil}
                                   id={quantityFilterSelectedUntil}
                                   locale="hu-HU"
                                   minFractionDigits={0}
                                   maxFractionDigits={9}
                                   onChange={(e) => {
                                     setQuantityFilterSelectedUntil(e.value)
                                     clearTimeout(window.searchTimeout)
                                     window.searchTimeout = setTimeout(function () {
                                       dt.current.filter(e.value, 'quantityUntil', '');
                                     }, 500)
                                   }}
                      />
                    </div>
                  </div>
                }
        ></Column>
        <Column field="unitName" header="M.e." sortable className={"p-text-right"}></Column>
      </DataTable>
      <div className={"p-grid p-mt-3"}>
        <div className={"p-col-12 p-lg-6 p-xl-6"}>
        </div>
        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
          {props.initialValues.status !== 'arrived' &&
          <>
            {loadingDownload === true ? (
              <>
                <Button type="button" label="Ajánlatkérő sablon" icon="pi pi-download" disabled loading
                        className="p-button-secondary p-mr-2"/>
              </>
            ) : (
              <>
                <Button type="button" label="Ajánlatkérő sablon" icon="pi pi-download"
                        className="p-button-secondary p-mr-2"
                        onClick={() => {
                          setLoadingDownload(true)
                          PurchaseorderDownloadService(props.orderId)
                            .then(response => {
                              download(response.data, localStorage.getItem('networkName')
                                + '_'
                                + 'ajánlat_sablon'
                                + '_'
                                + props.orderId
                                + '_'
                                + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                                + '.xlsx')
                              setLoadingDownload(false)
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString());
                              setLoadingDownload(false)
                            })
                        }}
                />
              </>
            )}
          </>
          }
          {props.initialValues.status === 'draft' &&
          <Button type="button" label="Összeállítás lezárása" className="p-button-warning p-mt-1" icon={"pi pi-check"}
                  onClick={() => {
                    PurchaseorderStatusChangeTenderService(props.initialValues.id)
                      .then(response => {
                        window.App.toastShow('success', 'Sekeres művelet!', 'Az összeállítás lázárásra került.');
                        PurchaseorderShowService(props.initialValues.id)
                          .then(response => {
                            window.App.setState({
                              props: response.data,
                              rerenderPurchaseorderShow: true
                            })
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString());
                          })
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString());
                      })
                  }}
          />
          }
        </div>
      </div>
    </>
  )
}
export default withTranslation('common')(PurchaseorderFeerequestComponent);
