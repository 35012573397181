import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import moment from "moment";
import {MultiSelect} from "primereact/multiselect";
import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import {Checkbox} from 'primereact/checkbox';
import {Field} from "react-final-form";
import {classNames} from "primereact/utils";
import {AutoComplete} from "primereact/autocomplete";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import download from 'js-file-download';
import currencyFormatter from "currency-formatter";

import ReceiptListService from "../../services/receipt/ReceiptListService";
import PaymentmethodListService from "../../services/paymentmethod/PaymentmethodListService";
import ProjectListService from "../../services/project/ProjectListService";
import CosttypeListService from "../../services/costtype/CosttypeListService";
//import ReceiptDeleteService from "../../services/receipt/ReceiptDeleteService";
import ReceiptBatchService from "../../services/receipt/ReceiptBatchService";
import ReceiptDownloadService from "../../services/receipt/ReceiptDownloadService";
import ReceipttypeListService from "../../services/receipttype/ReceipttypeListService";
import {InputSwitch} from "primereact/inputswitch";
import ProjectitemImportComponent from "../projectitem/ProjectitemImportComponent";
import {Dialog} from "primereact/dialog";
import ReceiptgroupListService from "../../services/receiptgroup/ReceiptgroupListService";
import ReceiptDeliverynoteInvoiceComponent from "./ReceiptDeliverynoteInvoiceComponent";
import ReceiptSendComponent from "./ReceiptSendComponent";

const ReceiptListComponent = (props) => {

  const [receipt, setReceipt] = useState([]);
  const [selectedReceipts, setSelectedReceipts] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedReceiptType, setSelectedReceiptType] = useState();
  const [loading, setLoading] = useState(false);
  const [receipttypes, setReceipttypes] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [sum, setSum] = useState({});

  const [filterSupplierName, setFilterSupplierName] = useState();
  const [filterCustomerName, setFilterCustomerName] = useState();
  const [filterNetAmountMin, setFilterNetAmountMin] = useState();
  const [filterNetAmountMax, setFilterNetAmountMax] = useState();
  const [filterIssueDateFrom, setFilterIssueDateFrom] = useState();
  const [filterIssueDateTo, setFilterIssueDateTo] = useState();
  const [filterDeliveryDateFrom, setFilterDeliveryDateFrom] = useState();
  const [filterDeliveryDateTo, setFilterDeliveryDateTo] = useState();
  const [filterPaymentDateFrom, setFilterPaymentDateFrom] = useState();
  const [filterPaymentDateTo, setFilterPaymentDateTo] = useState();
  const [filterRetentionPaymentDateFrom, setFilterRetentionPaymentDateFrom] = useState();
  const [filterRetentionPaymentDateUntil, setFilterRetentionPaymentDateUntil] = useState();
  const [filterPaidDateFrom, setFilterPaidDateFrom] = useState();
  const [filterPaidDateTo, setFilterPaidDateTo] = useState();
  const [filterReceiptNumber, setFilterReceiptNumber] = useState();
  const [filterPaid, setFilterPaid] = useState();
  const [filterAllowed, setFilterAllowed] = useState();
  const [filterIncome, setFilterIncome] = useState();
  const [searchCollapsed, setSearchCollapsed] = useState();

  let _lazyParams
  if (props.receiptGroup) {
    _lazyParams = {
      filters: {
        "receiptGroup": {
          value: props.receiptGroup
        }
      },
      first: 0,
      rows: rows,
      page: 0,
      sortField: 'id',
      sortOrder: 1,
    }
  } else {
    if (window.App.state.lazyparamsReceiptList) {
      _lazyParams = window.App.state.lazyparamsReceiptList
    } else {
      _lazyParams = {
        first: 0,
        rows: rows,
        page: 0,
        sortField: 'id',
        sortOrder: 1
      }
    }
  }
  const [lazyParams, setLazyParams] = useState(_lazyParams);

  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderReceiptList: false})
      ReceiptListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setReceipt(response.data.items);
          setLoading(false);
          // tot count by currency
          let currencies = [...new Set(response.data.items.map(item => item.currency))];
          let tot = []
          currencies.forEach((currency) => {
            let netSumByCurrency = 0
            let vatSumByCurrency = 0
            let brSumByCurrency = 0
            if (currency) {
              response.data.items.map((receipt) => {
                if (receipt.currency == currency) {
                  netSumByCurrency = netSumByCurrency + receipt.netAmount
                  vatSumByCurrency = vatSumByCurrency + receipt.vatAmount
                  brSumByCurrency = brSumByCurrency + receipt.grossAmount
                }
              })
              tot.push([
                {
                  currency: currency,
                  sumNet: netSumByCurrency,
                  sumVat: vatSumByCurrency,
                  sumBr: brSumByCurrency
                }
              ])
            }
          });
          setSum(tot)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };
  const deleteReceipt = (rowData) => {
    // ReceiptDeleteService(rowData.id)
    //   .then(response => {
    //     window.App.toastShow('success', 'Sikeres törlés!',);
    //     loadLazyData();
    //   })
    //   .catch(error => {
    //     window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
    //   })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_receipt_write') &&
        props.view !== 'purchaseorder' &&
        <>
          {rowData.draft === true ? (
            <>
              <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                      tooltip="Módosítás"
                      onClick={() => {
                        let _type = 'invoice'
                        if (rowData.receiptType === 'invoice' && rowData.outgoing === false) {
                          _type = 'cost'
                        }
                        let _rowData = {
                          ...rowData,
                          type: _type
                        }
                        window.App.setState({
                          sidebarReceiptUpdate: true,
                          props: _rowData
                        })
                      }}/>
            </>
          ) : (
            <>
              <Button icon="pi pi-pencil" className="p-button-sm p-button-warning p-mr-2 disabled"
                      tooltip="Módosítás"/>
            </>
          )}
        </>
        }
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarReceiptShow: true,
                    props: rowData
                  })
                }}/>
        {rowData.receiptType === 'delivery_note' &&
        <Button icon="pi pi-copy" className="p-button-sm  p-mr-2"
                tooltip="Számla készítése"
                onClick={() => {
                  window.App.setState({
                    dialogReceiptDeliverynoteInvoiceCreate: true,
                    props: rowData
                  })
                }}/>
        }
        {(rowData.receiptType === 'invoice' || rowData.receiptType === 'fee_request') && rowData.outgoing === true && rowData.external === false &&
        <Button icon="pi pi-envelope" className="p-button-sm p-mr-2 p-button-info"
                tooltip="Számla újrakézbesítése emailben"
                onClick={() => {
                  window.App.setState({
                    dialogReceiptSend: true,
                    props: rowData
                  })
                }}/>
        }
        {/*{rowData.receiptGroup && rowData.receiptGroup.income && rowData.receiptGroup.income === true*/}
        {/*&& localStorage.getItem('roles').toString().includes('role_receipt_write') &&*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteReceipt(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  //colToggle
  let columns = [{}]
  if (props.view === 'advanced') {
    columns = [
      {
        field: 'deliveryDate',
        header: 'Teljesítés',
        sortable: 'true',
        filter: 'true',
        body: (rowData) => {
          return <>{moment.utc(rowData.deliveryDate).local().format('YYYY-MM-DD')}</>
        },
      },
      {
        field: 'paymentDate',
        header: 'Fizetés',
        sortable: 'true',
        filter: 'true',
        body: (rowData) => {
          return <>{moment.utc(rowData.paymentDate).local().format('YYYY-MM-DD')}</>
        },
      },
      {
        field: 'nextPaymentDate',
        header: 'Következő kifizetés',
        sortable: 'true',
        filter: 'true',
        body: (rowData) => {
          return <>{moment.utc(rowData.nextPaymentDate).local().format('YYYY-MM-DD')}</>
        },
      },
      {
        field: 'vatAmount',
        header: 'ÁFA összeg',
        sortable: 'true',
        filter: 'true',
        body: (rowData) => {
          let currency
          if (rowData.currency === 'HUF') {
            currency = {
              precision: 2,
              symbol: '',
              decimal: ',',
              thousand: ' ',
            }
          } else {
            currency = {
              precision: 2,
              symbol: '',
              decimal: '.',
              thousand: ' ',
            }
          }
          return (
            <>
              {currencyFormatter.format(
                rowData.vatAmount, {
                  precision: currency.precision,
                  symbol: currency.symbol,
                  decimal: currency.decimal,
                  thousand: currency.thousand,
                }
              )}&nbsp;{rowData.currency}
            </>
          )
        },
        className: 'p-text-right p-text-nowrap'
      },
    ];
  }
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [selectedColumnsCut, setSelectedColumnsCut] = useState();
  const [columnsCut, setColumnsCut] = useState([]);
  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
    let orderedSelectedColumnsCut = []
    orderedSelectedColumns.map((item) => {
      orderedSelectedColumnsCut.push({
        field: item.field,
        header: item.header
      })
    })
    setSelectedColumns(orderedSelectedColumns);
    setSelectedColumnsCut(orderedSelectedColumnsCut)
    localStorage.removeItem("colsReceiptList")
    localStorage.setItem("colsReceiptList", JSON.stringify(orderedSelectedColumnsCut))
  }
  const columnComponents = selectedColumns.map(col => {
    return <Column key={col.field}
                   field={col.field}
                   header={col.header}
                   sortable={col.sortable}
      // filter={col.filter}
      // filterElement={col.filterElement}
                   body={col.body}
                   className={col.className}/>;
  });

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  // paymentMethod autocomplete
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const searchPaymentMethod = (event) => {
    setSelectedPaymentMethod(event.query.replace('Nincs találat', '').replace(': ', ''))
    PaymentmethodListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setPaymentMethods(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedPaymentMethod(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // project autocomplete
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();

  const searchProject = (event) => {
    setSelectedProject(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProjectListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setProjects(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedProject(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // costtype autocomplete
  const [costtypes, setCosttypes] = useState([]);
  const [selectedCosttype, setSelectedCosttype] = useState('');

  const searchCosttype = (event) => {
    setSelectedCosttype(event.query.replace('Nincs találat', '').replace(': ', ''))
    CosttypeListService(JSON.stringify({filters: {name: {value: event.query}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCosttypes(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query
          } else {
            string = 'Nincs találat'
          }
          setSelectedCosttype(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const searchHeaderTemplate = (options) => {
    const toggleIcon = options.collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up';
    return (
      <div className={"p-grid"}>
        <div className={"p-col-12 p-lg-6 p-col-align-center"}>
          <h3 className={"p-p-0 p-m-0"}>
            <button className={options.togglerClassName} onClick={options.onTogglerClick}>
              <span className={toggleIcon}></span>
            </button>
            &nbsp; Szűrés
          </h3>
        </div>
        <div className={"p-col-12 p-lg-6 p-text-right p-col-align-center"}>
          <Button className={"p-button-danger p-mr-2 p-text-light"}
                  label="Szűrés törlése" icon="pi pi-times"
                  onClick={() => {
                    setFilterSupplierName('')
                    setFilterCustomerName('')
                    setFilterNetAmountMin('')
                    setFilterNetAmountMax('')
                    setFilterIssueDateFrom('')
                    setFilterIssueDateTo('')
                    setFilterDeliveryDateFrom('')
                    setFilterDeliveryDateTo('')
                    setFilterPaymentDateFrom('')
                    setFilterPaymentDateTo('')
                    setFilterRetentionPaymentDateFrom('')
                    setFilterRetentionPaymentDateUntil('')
                    setFilterPaidDateFrom('')
                    setFilterPaidDateTo('')
                    setFilterReceiptNumber('')
                    setFilterAllowed(null)
                    setFilterPaid(null)
                    setFilterIncome(null)
                    setSelectedPaymentMethod('')
                    setSelectedProject('')
                    setSelectedCosttype('')
                    setSelectedReceiptType('')
                    setLazyParams({
                      first: 0,
                      rows: rows,
                      page: 0,
                      sortField: 'id',
                      sortOrder: 1
                    })
                  }}
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    ReceipttypeListService()
      .then(response => {
        setReceipttypes(response.data)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
    loadLazyData();
    //colToggle
    let columnsCut = []
    columns.map((item) => {
      columnsCut.push({
        field: item.field,
        header: item.header
      })
    })
    setColumnsCut(columnsCut)
    let colsReceiptList = JSON.parse(localStorage.getItem("colsReceiptList"))
    if (colsReceiptList && colsReceiptList[0] && colsReceiptList[0].field) {
      setSelectedColumnsCut(colsReceiptList)
      let orderedSelectedColumns = columns.filter(col => colsReceiptList.some(sCol => sCol.field === col.field));
      setSelectedColumns(orderedSelectedColumns);
    } else {
      let orderedSelectedColumnsCut = []
      columns.map((item) => {
        orderedSelectedColumnsCut.push({
          field: item.field,
          header: item.header
        })
      })
      setSelectedColumnsCut(orderedSelectedColumnsCut)
    }
    if (window.App.state.lazyparamsReceiptList && window.App.state.lazyparamsReceiptList !== null) {
      Object.entries(window.App.state.lazyparamsReceiptList.filters).map(function (filter, index) {
        if (document.getElementById(filter[0])) {
          document.getElementById(filter[0]).value = filter[1].value
        }
        if (filter[0] === 'paid') {
          setFilterPaid(filter[1].value)
        }
        if (filter[0] === 'allowed') {
          setFilterAllowed(filter[1].value)
        }
      })
    }
  }, [lazyParams, window.App.state.rerenderReceiptList === true])

  return (
    <>
      {props && props.view === 'advanced' &&
      <Panel headerTemplate={searchHeaderTemplate} toggleable collapsed={searchCollapsed}
             onToggle={(e) => setSearchCollapsed(e.value)}
             className={"p-pb-2"}
             id={"filters"}
      >
        <div className={"p-grid"}>
          {/*<div className={"p-col-12 p-lg-2 p-xl-2"}>*/}
          {/*  <div className="p-field p-fluid">*/}
          {/*    <label>Megjelenítés</label>*/}
          {/*    <span className="p-input-icon-right">*/}
          {/*      <MultiSelect value={selectedColumnsCut} options={columnsCut} optionLabel="header"*/}
          {/*                   onChange={onColumnToggle}*/}
          {/*                   selectedItemsLabel="{0} oszlop kiválasztva"*/}
          {/*                   placeholder={'Megjelenítés'}*/}
          {/*      />*/}
          {/*    </span>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Vállalkozás</label>
              <span className="p-input-icon-right">
                <InputText id="companyName"
                           onChange={(e) => {
                             setFilterSupplierName(e.target.value)
                             let value = e.target.value
                             clearTimeout(window.searchTimeout)
                             window.searchTimeout = setTimeout(function () {
                               dt.current.filter(value, 'companyName', '');
                             }, 500)
                           }}
                           value={filterSupplierName}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Partner</label>
              <span className="p-input-icon-right">
                <InputText id="partnerName"
                           onChange={(e) => {
                             setFilterCustomerName(e.target.value)
                             let value = e.target.value
                             clearTimeout(window.searchTimeout)
                             window.searchTimeout = setTimeout(function () {
                               dt.current.filter(value, 'partnerName', '');
                             }, 500)
                           }}
                           value={filterCustomerName}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-1"}>
            <div className="p-field p-fluid">
              <label>Min. nettó</label>
              <span className="p-input-icon-right">
                <InputNumber id="netAmountMin"
                             locale="hu-HU"
                             minFractionDigits={0}
                             maxFractionDigits={9}
                             onChange={(e) => {
                               setFilterNetAmountMin(e.value)
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(e.value, 'netAmountMin', '');
                               }, 500)
                             }}
                             value={filterNetAmountMin}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-1"}>
            <div className="p-field p-fluid">
              <label>Max. nettó</label>
              <span className="p-input-icon-right">
                <InputNumber id="netAmountMax"
                             locale="hu-HU"
                             minFractionDigits={0}
                             maxFractionDigits={9}
                             onChange={(e) => {
                               setFilterNetAmountMax(e.value)
                               clearTimeout(window.searchTimeout)
                               window.searchTimeout = setTimeout(function () {
                                 dt.current.filter(e.value, 'netAmountMax', '');
                               }, 500)
                             }}
                             value={filterNetAmountMax}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Kelt</label>
              <div className={"p-grid"}>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterIssueDateFrom(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'issueDateFrom', '');
                                }, 500)
                              }}
                              value={filterIssueDateFrom}
                              mask="9999-99-99"
                              id={"issueDateFrom"}
                              locale={"hu"}
                    />
                  </span>
                </div>
                <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                  <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                </div>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterIssueDateTo(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'issueDateTo', '');
                                }, 500)
                              }}
                              value={filterIssueDateTo}
                              mask="9999-99-99"
                              id={"issueDateTo"}
                              locale={"hu"}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Teljesítés</label>
              <div className={"p-grid"}>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterDeliveryDateFrom(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'deliveryDateFrom', '');
                                }, 500)
                              }}
                              value={filterDeliveryDateFrom}
                              mask="9999-99-99"
                              id={"deliveryDateFrom"}
                              locale={"hu"}
                    />
                  </span>
                </div>
                <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                  <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                </div>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterDeliveryDateTo(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'deliveryDateTo', '');
                                }, 500)
                              }}
                              value={filterDeliveryDateTo}
                              mask="9999-99-99"
                              id={"deliveryDateTo"}
                              locale={"hu"}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Fizetési határidő</label>
              <div className={"p-grid"}>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterPaymentDateFrom(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'paymentDateFrom', '');
                                }, 500)
                              }}
                              value={filterPaymentDateFrom}
                              mask="9999-99-99"
                              id={"paymentDateFrom"}
                              locale={"hu"}
                    />
                  </span>
                </div>
                <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                  <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                </div>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterPaymentDateTo(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'paymentDateTo', '');
                                }, 500)
                              }}
                              value={filterPaymentDateTo}
                              mask="9999-99-99"
                              id={"paymentDateTo"}
                              locale={"hu"}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Visszatartás határideje</label>
              <div className={"p-grid"}>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterRetentionPaymentDateFrom(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'retentionPaymentDateFrom', '');
                                }, 500)
                              }}
                              value={filterRetentionPaymentDateFrom}
                              mask="9999-99-99"
                              id={"retentionPaymentDateFrom"}
                              locale={"hu"}
                    />
                  </span>
                </div>
                <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                  <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                </div>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterRetentionPaymentDateUntil(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'retentionPaymentDateUntil', '');
                                }, 500)
                              }}
                              value={filterRetentionPaymentDateUntil}
                              mask="9999-99-99"
                              id={"nextPaymentDateTo"}
                              locale={"hu"}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>
                Fizetve dátum
              </label>
              <div className={"p-grid"}>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterPaidDateFrom(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'paidDateFrom', '');
                                }, 500)
                              }}
                              value={filterPaidDateFrom}
                              mask="9999-99-99"
                              id={"paidDateFrom"}
                              locale={"hu"}
                    />
                  </span>
                </div>
                <div className={"p-col-2 p-text-center p-col-align-center p-px-0"}>
                  <i className={"pi pi-arrow-left"}></i> <i className={"pi pi-arrow-right"}></i>
                </div>
                <div className={"p-col-5"}>
                  <span className="p-input-icon-right">
                    <Calendar dateFormat="yy-mm-dd"
                              monthNavigator={true}
                              yearNavigator={true}
                              yearRange={"2022:2099"}
                              keepInvalid="true"
                              onChange={(e) => {
                                setFilterPaidDateTo(e.value)
                                clearTimeout(window.searchTimeout)
                                window.searchTimeout = setTimeout(function () {
                                  dt.current.filter(moment.utc(e.value).local().format('YYYY-MM-DD'), 'paidDateTo', '');
                                }, 500)
                              }}
                              value={filterPaidDateTo}
                              mask="9999-99-99"
                              id={"paidDateTo"}
                              locale={"hu"}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Számla sorszám</label>
              <span className="p-input-icon-right">
                <InputText id="receiptNumber"
                           onChange={(e) => {
                             setFilterReceiptNumber(e.target.value)
                             let value = e.target.value
                             clearTimeout(window.searchTimeout)
                             window.searchTimeout = setTimeout(function () {
                               dt.current.filter(value, 'receiptNumber', '');
                             }, 500)
                           }}
                           value={filterReceiptNumber}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Fizetés módja</label>
              <span className="p-input-icon-right">
                <AutoComplete value={selectedPaymentMethod}
                              id={"paymentMethod"}
                              suggestions={paymentMethods}
                              completeMethod={searchPaymentMethod}
                              field={"name"}
                              delay="500"
                              placeholder={"Keresés gépeléssel..."}
                              onSelect={(e) => {
                                dt.current.filter(e.value.name, 'paymentMethod', '');
                                setSelectedPaymentMethod(e.value.name)
                              }}
                              //// forceSelection
                              dropdown
                              onClear={(e) => {
                                setSelectedPaymentMethod('')
                                dt.current.filter('', 'paymentMethod', '');
                              }}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Projekt</label>
              <span className="p-input-icon-right">
                <AutoComplete value={selectedProject}
                              id={"project"}
                              suggestions={projects}
                              completeMethod={searchProject}
                              field={"name"}
                              delay="500"
                              //// forceSelection
                              dropdown
                              placeholder={"Keresés gépeléssel..."}
                              onSelect={(e) => {
                                dt.current.filter(e.value.name, 'projectName', '');
                                setSelectedProject(e.value.name)
                              }}
                              onClear={(e) => {
                                setSelectedProject('')
                                dt.current.filter('', 'projectName', '');
                              }}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Költségnem</label>
              <span className="p-input-icon-right">
                <AutoComplete value={selectedCosttype}
                              id={"costType"}
                              suggestions={costtypes}
                              //// forceSelection
                              completeMethod={searchCosttype}
                              field={"name"}
                              delay="500"
                              placeholder={"Keresés gépeléssel..."}
                              onSelect={(e) => {
                                dt.current.filter(e.value.name, 'costType', '');
                                setSelectedCosttype(e.value.name)
                              }}
                              dropdown
                              onClear={(e) => {
                                setSelectedCosttype('')
                                dt.current.filter('', 'costType', '');
                              }}
                />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2 p-xl-2"}>
            <div className="p-field p-fluid">
              <label>Típus</label>
              <span className="p-input-icon-right">
                  <Dropdown options={receipttypes}
                            optionLabel="name"
                            optionValue="id"
                            id={"receipt_type"}
                            emptyMessage={"Még nincs typus..."}
                            onChange={(e) => {
                              setSelectedReceiptType(e.value)
                              dt.current.filter(e.value, 'type', '');
                            }}
                            showClear
                            value={selectedReceiptType}
                  />
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-10 p-pt-5"}>
            <div className="p-fluid p-py-1 p-mt-2">
              <label className={"p-mr-2"}>
                <TriStateCheckbox inputId="outgoing" id="outgoing" value={filterIncome} onChange={e => {
                  setFilterIncome(e.value)
                  clearTimeout(window.searchTimeout)
                  window.searchTimeout = setTimeout(function () {
                    dt.current.filter(e.value, 'outgoing', '');
                  }, 500)
                }}/>
                &nbsp;
                Kimenő
              </label>
              <label className={"p-mr-2"}>
                <TriStateCheckbox inputId="paid" id="paid" value={filterPaid} onChange={e => {
                  setFilterPaid(e.value)
                  clearTimeout(window.searchTimeout)
                  window.searchTimeout = setTimeout(function () {
                    dt.current.filter(e.value, 'paid', '');
                  }, 500)
                }}/>
                &nbsp;
                Fizetve
              </label>
              <label className={"p-mr-2"}>
                <TriStateCheckbox inputId="allowed" id="allowed" value={filterAllowed} onChange={e => {
                  setFilterAllowed(e.value)
                  clearTimeout(window.searchTimeout)
                  window.searchTimeout = setTimeout(function () {
                    dt.current.filter(e.value, 'allowed', '');
                  }, 500)
                }}/>
                &nbsp;
                Jóváhagyva
              </label>
            </div>
          </div>
        </div>
      </Panel>
      }
      {props.view !== 'advanced' && props.view !== 'purchaseorder' &&
      <>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-12 p-xl-12 p-text-right p-col-align-center p-pt-0"}>
            <Button className={"p-button-outlined p-button-danger p-button-sm p-mr-2  p-text-light"}
                    label="Szűrés törlése" icon="pi pi-times"
                    style={{border: 'none'}}
                    onClick={() => {
                      setLazyParams({
                        first: 0,
                        rows: rows,
                        page: 0,
                        sortField: 'id',
                        sortOrder: 1,
                      })
                    }}
            />
            <Button className={"p-button-outlined  p-button-sm"}
                    style={{border: 'none'}}
                    label="Kiterjesztett nézet" icon="pi pi-window-maximize"
                    onClick={() => {
                      window.App.setState({
                        lazyparamsReceiptList: {
                          first: 0,
                          rows: 10,
                          page: 0,
                          sortField: 'id',
                          sortOrder: 1,
                          filters: {
                            receiptGroup: {value: props.receiptGroup}
                          }
                        },
                        sidebarReceiptList: true
                      })
                    }}
            />
          </div>
        </div>
      </>
      }
      <DataTable
        emptyMessage="Nincs találat."
        value={receipt} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
        autoLayout={true}
        ref={dt}
        filterDisplay="row"
responsiveLayout="scroll"
        stripedRows
        selection={selectedReceipts}
        onSelectionChange={e => {
          setSelectedReceipts(e.value)
        }}
        dataKey="id"
        selectionMode="checkbox"
        // onAllRowsSelect={e => {
        //   setTotalRecords(receipt.length)
        // }}
        // onAllRowsUnselect={e => {
        //   loadLazyData();
        // }}
      >
        {props.view === 'advanced' &&
        <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
        }
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
        {props.view === 'advanced' ? (
          <Column field="receiptNumber" header="Sorszám" sortable></Column>
        ) : (
          <Column field="receiptNumber" header="Sorszám" sortable filter filterPlaceholder="Sorszám"></Column>
        )}
        {props.view === 'advanced' ? (
          <Column field="companyName" header="Vállalkozás" sortable></Column>
        ) : (
          <Column field="companyName" header="Vállalkozás" sortable filter filterPlaceholder="Vállalkozás"></Column>
        )}
        {props.view === 'advanced' ? (
          <Column field="partnerName" header="Partner" sortable></Column>
        ) : (
          <Column field="partnerName" header="Partner" sortable filter filterPlaceholder="Partner"></Column>
        )}
        <Column field="issueDate" header="Kelt" sortable className={"p-text-nowrap"} body={(rowData) => {
          return (
            <>{moment.utc(rowData.issueDate).local().format('YYYY-MM-DD')}</>
          )
        }}></Column>
        {columnComponents}
        {props.receiptType !== 'delivery_note' &&
        <Column field="netAmount" header="Nettó összeg" sortable className={"p-text-right p-text-nowrap"}
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.netAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        }
        {props.receiptType !== 'delivery_note' &&
        <Column field="grossAmount" header="Bruttó összeg" sortable className={"p-text-right p-text-nowrap"}
                body={(rowData) => {
                  let currency
                  if (rowData.currency === 'HUF') {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: ',',
                      thousand: ' ',
                    }
                  } else {
                    currency = {
                      precision: 2,
                      symbol: '',
                      decimal: '.',
                      thousand: ' ',
                    }
                  }
                  return (
                    <>
                      {currencyFormatter.format(
                        rowData.grossAmount, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{rowData.currency}
                    </>
                  )
                }}></Column>
        }
      </DataTable>
      <div className={"p-grid p-mt-1"}>
        <div className={"p-col-12 p-lg-12 p-xl-12 p-text-right p-col-align-center"}>
          {props.view === 'advanced' &&
          <MultiSelect value={selectedColumnsCut} options={columnsCut} optionLabel="header"
                       onChange={onColumnToggle}
                       selectedItemsLabel="{0} oszlop kiválasztva"
                       placeholder={'Megjelenítés'}
                       className={"p-mr-2"}
          />
          }
          {props.view === 'advanced' && selectedReceipts && selectedReceipts[0] &&
          <>
            <Button type="button" label="Letöltés" icon="pi pi-download" className="p-button-secondary p-mr-2"
                    onClick={() => {
                      setLoading(true)
                      ReceiptDownloadService(JSON.stringify(lazyParams))
                        .then(response => {
                          download(response.data, localStorage.getItem('networkName')
                            + '_'
                            + 'bizonylatok'
                            + '_'
                            + moment.utc(new Date()).local().format('YYYY-MM-DD HH:mm:ss')
                            + '.xlsx')
                          setLoading(false)
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString());
                          setLoading(false)
                        })
                    }}
            />
          </>
          }
          {props.view === 'advanced' && selectedReceipts && selectedReceipts[0]
          && JSON.stringify(lazyParams).includes('allowed') &&
          <>
            <Button type="button" label="Jóváhagyás visszavonása" icon="pi pi-times" className="p-button-danger p-mr-2"
                    onClick={() => {
                      setLoading(true)
                      let ids = []
                      Object.entries(selectedReceipts).map(function (receipt, index) {
                        ids.push(receipt[1].id)
                      })
                      let data = {
                        method: 'allowed',
                        value: false,
                        receipts: ids
                      }
                      ReceiptBatchService(data)
                        .then(response => {
                          ReceiptListService(JSON.stringify(lazyParams))
                            .then(response => {
                              setTotalRecords(response.data.total_item_count);
                              setReceipt(response.data.items);
                              setLoading(false);
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              setLoading(false);
                            })
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          setLoading(false);
                        })
                    }}
            />
            <Button type="button" label="Kijelöltek jóváhagyása" icon="pi pi-check" className="p-button-warning p-mr-2"
                    onClick={() => {
                      setLoading(true)
                      let ids = []
                      Object.entries(selectedReceipts).map(function (receipt, index) {
                        ids.push(receipt[1].id)
                      })
                      let data = {
                        method: 'allowed',
                        value: true,
                        receipts: ids
                      }
                      ReceiptBatchService(data)
                        .then(response => {
                          ReceiptListService(JSON.stringify(lazyParams))
                            .then(response => {
                              setTotalRecords(response.data.total_item_count);
                              setReceipt(response.data.items);
                              setLoading(false);
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              setLoading(false);
                            })
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          setLoading(false);
                        })
                    }}
            />
          </>
          }
          {props.view === 'advanced' && selectedReceipts && selectedReceipts[0]
          && JSON.stringify(lazyParams).includes('paid') &&
          <>
            <Button type="button" label="Fizetve visszavonása" icon="pi pi-times" className="p-button-danger p-mr-2"
                    onClick={() => {
                      setLoading(true)
                      let ids = []
                      Object.entries(selectedReceipts).map(function (receipt, index) {
                        ids.push(receipt[1].id)
                      })
                      let data = {
                        method: 'paid',
                        value: false,
                        receipts: ids
                      }
                      ReceiptBatchService(data)
                        .then(response => {
                          ReceiptListService(JSON.stringify(lazyParams))
                            .then(response => {
                              setTotalRecords(response.data.total_item_count);
                              setReceipt(response.data.items);
                              setLoading(false);
                            })
                            .catch(error => {
                              window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                              setLoading(false);
                            })
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          setLoading(false);
                        })
                    }}
            />
            <Calendar dateFormat="yy-mm-dd" locale={"hu"}
                      onChange={(e) => setSelectedDate(moment.utc(e.target.value).local().format('YYYY-MM-DD'))}
                      style={{maxWidth: '160px'}}
                      showIcon
            />
            {selectedDate === undefined ? (
              <Button type="button" label="Kijelöltek fizetve" icon="pi pi-check"
                      className="disabled p-button-warning p-mr-2"
                      onClick={() => alert('Dátum választása kötelező!')}
              />
            ) : (
              <Button type="button" label="Kijelöltek fizetve" icon="pi pi-check" className="p-button-warning p-mr-2"
                      onClick={() => {
                        setLoading(true)
                        let ids = []
                        Object.entries(selectedReceipts).map(function (receipt, index) {
                          ids.push(receipt[1].id)
                        })
                        let data = {
                          method: 'paid',
                          value: selectedDate,
                          receipts: ids
                        }
                        ReceiptBatchService(data)
                          .then(response => {
                            ReceiptListService(JSON.stringify(lazyParams))
                              .then(response => {
                                setTotalRecords(response.data.total_item_count);
                                setReceipt(response.data.items);
                                setLoading(false);
                              })
                              .catch(error => {
                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                setLoading(false);
                              })
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            setLoading(false);
                          })
                      }}
              />
            )}
          </>
          }
        </div>
      </div>
      {props.view === 'advanced' &&
      <div className={"p-grid p-mt-1"}>
        <div className={"p-col-12 p-lg-8"}>

        </div>
        <div className={"p-col-12 p-lg-4"}>
          {sum && sum[0] &&
          <>
            <hr className={"p-mb-3"}/>
            {sum.map((item, index) => {
              let currency
              if (item[0].currency === 'HUF') {
                currency = {
                  precision: 2,
                  symbol: '',
                  decimal: ',',
                  thousand: ' ',
                }
              } else {
                currency = {
                  precision: 2,
                  symbol: '',
                  decimal: '.',
                  thousand: ' ',
                }
              }
              return (
                <>
                  <div className={"p-grid"}>
                    <div className={"p-col p-text-right"}>
                      Nettó összesen:
                    </div>
                    <div className={"p-col p-text-right"}>
                      {currencyFormatter.format(
                        item[0].sumNet, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{item[0].currency}
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col p-text-right"}>
                      ÁFA összesen:
                    </div>
                    <div className={"p-col p-text-right"}>
                      {currencyFormatter.format(
                        item[0].sumVat, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{item[0].currency}
                    </div>
                  </div>
                  <div className={"p-grid"}>
                    <div className={"p-col p-text-right"}>
                      Bruttó összesen:
                    </div>
                    <div className={"p-col p-text-right"}>
                      {currencyFormatter.format(
                        item[0].sumBr, {
                          precision: currency.precision,
                          symbol: currency.symbol,
                          decimal: currency.decimal,
                          thousand: currency.thousand,
                        }
                      )}&nbsp;{item[0].currency}
                    </div>
                  </div>
                  <hr/>
                </>
              )
            })}
          </>
          }
        </div>
      </div>
      }
      <Dialog visible={window.App.state.dialogReceiptDeliverynoteInvoiceCreate}
              style={{width: '750px'}}
              header="Költségszámla készítése"
              modal
              onHide={() => {
                window.App.setState({dialogReceiptDeliverynoteInvoiceCreate: false})
              }}>
        <ReceiptDeliverynoteInvoiceComponent/>
      </Dialog>
      <Dialog visible={window.App.state.dialogReceiptSend}
              style={{width: '450px'}}
              header="Számla újrakézbesítése emailben"
              modal
              onHide={() => {
                window.App.setState({dialogReceiptSend: false})
              }}>
        <ReceiptSendComponent/>
      </Dialog>
    </>
  )
}
export default withTranslation('common')(ReceiptListComponent);
