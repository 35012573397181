import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {BlockUI} from "primereact/blockui";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import moment from "moment";
import {addLocale} from "primereact/api";

import WorksheetFormService from "../../services/worksheet/WorksheetFormService";
import WorksheetShowService from "../../services/worksheet/WorksheetShowService";
import EmployeeListService from "../../services/employee/EmployeeListService";
import ProjectListService from "../../services/project/ProjectListService";

const WorksheetFormComponent = (props) => {

  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (data) => {
    let errors = {};
    // if (!data.firstName) {
    //   errors.firstName = 'Az név nem lehet üres';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if (props && props.initialValues || formData.id) {
      WorksheetFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({rerenderWorksheetList: true})
          if (formData.closeForm === true) {
            window.App.setState({
              sidebarWorksheetUpdate: false,
              sidebarWorksheetCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      WorksheetFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          setFormData(response.data)
          window.App.setState({rerenderWorksheetList: true})
          if (formData.closeForm === true) {
            window.App.setState({
              sidebarWorksheetCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // employees autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const searchEmployees = (event) => {
    setSelectedEmployee(event.query.replace('Nincs találat', '').replace(': ', ''))
    EmployeeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
      if (response.data && response.data.items && response.data.items[0]) {
        setEmployees(response.data.items)
      } else {
        let string
        if (event.query) {
          string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        } else {
          string = 'Nincs találat'
        }
        setSelectedEmployee(string)
      }
    });
  }

  // projects autocomplete
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');

  const searchProjects = (event) => {
    setSelectedProject(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProjectListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}})).then(response => {
      if (response.data && response.data.items && response.data.items[0]) {
        setProjects(response.data.items)
      } else {
        let string
        if (event.query) {
          string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
        } else {
          string = 'Nincs találat'
        }
        setSelectedProject(string)
      }
    });
  }

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  useEffect(() => {

    if (formData.id) {

    }
    if (props && props.initialValues) {
      WorksheetShowService(props.initialValues.id)
        .then(response => {
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      setFormData(props.initialValues)
    }
  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-lg-4 p-xl-4"}>
              <Field name="employee" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="employee"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>
                  <span className="p-inputgroup">
                              <AutoComplete value={selectedEmployee}
                                            id={"employee"}
                                            suggestions={employees}
                                            // forceSelection
                                            completeMethod={searchEmployees}
                                            field={"name"}
                                            delay="500"
                                            dropdown
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                employee: {id: e.value.id},
                                              })
                                              setSelectedEmployee(e.value.name)
                                            }}
                                            onClear={(e) => {
                                              setSelectedEmployee('')
                                            }}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-4 p-xl-4"}>
              <Field name="project" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="project"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Projekt</label>
                  <span className="p-inputgroup">
                              <AutoComplete value={selectedProject}
                                            id={"project"}
                                            suggestions={projects}
                                            // forceSelection
                                            completeMethod={searchProjects}
                                            field={"name"}
                                            delay="500"
                                            dropdown
                                            placeholder={"Keresés gépeléssel..."}
                                            className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                            onSelect={(e) => {
                                              setFormData({
                                                ...formData,
                                                project: {id: e.value.id},
                                              })
                                              setSelectedProject(e.value.name)
                                            }}
                                            onClear={(e) => {
                                              setSelectedProject('')
                                            }}
                              />
                            </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-4 p-lg-4"}>
              <Field name="title" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="title"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Megnevezés</label>
                  <span className="p-input-icon-right">
                      <InputText id="title" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     title: e.target.value
                                   })
                                 }}
                                 value={formData.title}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12"}>
              <Field name="instruction" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="instruction"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Feladat leírása</label>
                  <span className="p-input-icon-right">
                      <InputTextarea id="instruction" {...input}
                                     rows={5}
                                     onChange={(e) => {
                                       setFormData({
                                         ...formData,
                                         instruction: e.target.value
                                       })
                                     }}
                                     value={formData.instruction}
                                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {/*<div className={"p-col-12 p-md-4 p-lg-4"}>*/}
            {/*  <Field name="note" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="note"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Megjegyzés</label>*/}
            {/*      <span className="p-input-icon-right">*/}
            {/*          <InputText id="note" {...input}*/}
            {/*                     onChange={(e) => {*/}
            {/*                       setFormData({*/}
            {/*                         ...formData,*/}
            {/*                         note: e.target.value*/}
            {/*                       })*/}
            {/*                     }}*/}
            {/*                     value={formData.note}*/}
            {/*                     className={classNames({'p-invalid': isFormFieldValid(meta)})}/>*/}
            {/*        </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            {/*<div className={"p-col-12 p-md-4 p-lg-4"}>*/}
            {/*  <Field name="workFrom" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="workFrom"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Munka kezdete</label>*/}
            {/*      <span className="p-input-icon-right">*/}
            {/*        {formData.workFrom && (formData.workFrom !== null || formData.workFrom !== undefined) ? (*/}
            {/*          <Calendar dateFormat="yy-mm-dd"*/}
            {/*                    keepInvalid="true"*/}
            {/*                    onChange={(e) => {*/}
            {/*                      setFormData({*/}
            {/*                        ...formData,*/}
            {/*                        workFrom: moment.utc(e.target.value).local().format('YYYY-MM-DD')*/}
            {/*                      })*/}
            {/*                    }}*/}
            {/*                    value={new Date(formData.workFrom)}*/}
            {/*                    mask="9999-99-99"*/}
            {/*                    id={"workFrom"}*/}
            {/*                    locale={"hu"}*/}
            {/*                    className={classNames({'p-invalid': isFormFieldValid(meta)})}*/}
            {/*          />*/}
            {/*        ):(*/}
            {/*          <Calendar dateFormat="yy-mm-dd"*/}
            {/*                    keepInvalid="true"*/}
            {/*                    onChange={(e) => {*/}
            {/*                      setFormData({*/}
            {/*                        ...formData,*/}
            {/*                        workFrom: moment.utc(e.target.value).local().format('YYYY-MM-DD')*/}
            {/*                      })*/}
            {/*                    }}*/}
            {/*                    mask="9999-99-99"*/}
            {/*                    id={"workFrom"}*/}
            {/*                    locale={"hu"}*/}
            {/*                    className={classNames({'p-invalid': isFormFieldValid(meta)})}*/}
            {/*          />*/}
            {/*        )}*/}
            {/*        </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
            {/*<div className={"p-col-12 p-md-4 p-lg-4"}>*/}
            {/*  <Field name="workUntil" render={({input, meta}) => (*/}
            {/*    <div className="p-field p-fluid">*/}
            {/*      <label htmlFor="workUntil"*/}
            {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Munka vége</label>*/}
            {/*      <span className="p-input-icon-right">*/}
            {/*        {formData.workUntil && (formData.workUntil !== null || formData.workUntil !== undefined) ? (*/}
            {/*          <Calendar dateFormat="yy-mm-dd"*/}
            {/*                    keepInvalid="true"*/}
            {/*                    onChange={(e) => {*/}
            {/*                      setFormData({*/}
            {/*                        ...formData,*/}
            {/*                        workUntil: moment.utc(e.target.value).local().format('YYYY-MM-DD')*/}
            {/*                      })*/}
            {/*                    }}*/}
            {/*                    value={new Date(formData.workUntil)}*/}
            {/*                    mask="9999-99-99"*/}
            {/*                    id={"workUntil"}*/}
            {/*                    locale={"hu"}*/}
            {/*                    className={classNames({'p-invalid': isFormFieldValid(meta)})}*/}
            {/*          />*/}
            {/*        ):(*/}
            {/*          <Calendar dateFormat="yy-mm-dd"*/}
            {/*                    keepInvalid="true"*/}
            {/*                    onChange={(e) => {*/}
            {/*                      setFormData({*/}
            {/*                        ...formData,*/}
            {/*                        workUntil: moment.utc(e.target.value).local().format('YYYY-MM-DD')*/}
            {/*                      })*/}
            {/*                    }}*/}
            {/*                    mask="9999-99-99"*/}
            {/*                    id={"workUntil"}*/}
            {/*                    locale={"hu"}*/}
            {/*                    className={classNames({'p-invalid': isFormFieldValid(meta)})}*/}
            {/*          />*/}
            {/*        )}*/}
            {/*        </span>*/}
            {/*      {getFormErrorMessage(meta)}*/}
            {/*    </div>*/}
            {/*  )}/>*/}
            {/*</div>*/}
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>
              {formData.id &&
              <Button type="button" label="Megtekintés" icon={"pi pi-eye"}
                      onClick={() => {
                        window.App.setState({
                          props: formData,
                          sidebarWorksheetUpdate: false,
                          sidebarWorksheetCreate: false,
                          sidebarWorksheetShow: true,
                        })
                      }}
                      className="p-button"/>
              }
            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"} loading={loading}
                      onClick={() => {
                        setFormData({...formData, closeForm: true})
                      }}
                      className="p-button-success"/>
              <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
        </Panel>
      </form>
    )}/>
  )
}
export default withTranslation('common')(WorksheetFormComponent);
