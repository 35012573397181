import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Tooltip} from "primereact/tooltip";

import ProjectListService from "../../services/project/ProjectListService";
import ProjectDeleteService from "../../services/project/ProjectDeleteService";
import EntrylogStatusListService from "../../services/entrylog/EntrylogStatusListService";
import ProjectstatusListService from "../../services/projectstatus/ProjectstatusListService";
import ProjecttypeListService from "../../services/projecttype/ProjecttypeListService";

const ProjectListComponent = (props) => {

  const [project, setProject] = useState([]);
  const [projectStatuses, setProjectStatuses] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      window.App.setState({rerenderProjectList: false})
      ProjectListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProject(response.data.items);
          setLoading(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString());
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          <span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const dt = useRef(null);

  const deleteProject = (rowData) => {
    ProjectDeleteService(rowData.id)
      .then(response => {
        window.App.toastShow('success', 'Sikeres törlés!',);
        loadLazyData();
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {localStorage.getItem('roles').toString().includes('role_project_write') &&
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                onClick={() => {
                  window.App.setState({
                    sidebarProjectUpdate: true,
                    props: rowData
                  })
                }}/>
        }
        <Button icon="pi pi-eye" className="p-button-sm p-button p-mr-2"
                tooltip="Megtekintés"
                onClick={() => {
                  window.App.setState({
                    sidebarProjectShow: true,
                    props: rowData
                  })
                }}/>
        {/*{localStorage.getItem('roles').toString().includes('role_project_write') &&*/}
        {/*<Button icon="pi pi-trash" className="p-button-sm  p-button-danger"*/}
        {/*        tooltip="Törlés"*/}
        {/*        onClick={() => {*/}
        {/*          if (window.confirm('A törlés megerősítésére van szükség!')) {*/}
        {/*            deleteProject(rowData)*/}
        {/*          }*/}
        {/*        }}*/}
        {/*/>*/}
        {/*}*/}
      </React.Fragment>
    );
  }

  const statusFilterElement = <Dropdown options={projectStatuses}
                                        id={"projectStatuses"}
                                        optionValue={"id"}
                                        optionLabel={"name"}
                                        showClear
                                        emptyMessage={"Még nincs státusz..."}
                                        onChange={(e) => {
                                          dt.current.filter(e.value, 'projectStatus', '');
                                          window.App.setState({
                                            valueProjectStatuses: e.value
                                          })
                                        }}
                                        value={window.App.state.valueProjectStatuses}
  />

  const typeFilterElement = <Dropdown options={projectTypes}
                                        id={"projectTypes"}
                                        optionValue={"id"}
                                        optionLabel={"name"}
                                        showClear
                                        emptyMessage={"Még nincs típus..."}
                                        onChange={(e) => {
                                          dt.current.filter(e.value, 'projectType', '');
                                          window.App.setState({
                                            valueProjectTypes: e.value
                                          })
                                        }}
                                        value={window.App.state.valueProjectTypes}
  />

  useEffect(() => {
    loadLazyData();
    ProjectstatusListService()
      .then(response => {
        setProjectStatuses(response.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString());
      })
    ProjecttypeListService()
      .then(response => {
        setProjectTypes(response.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString());
      })
    // const textFilters = document.querySelectorAll('.p-inputtext.p-column-filter');
    // for (const filter of textFilters) {
    //   filter.outerHTML += '<i class="pi pi-times p-ml-1" onClick="javascript:this.parentElement.children[0].value = \'\'"></i>';
    // }
  }, [lazyParams, window.App.state.rerenderProjectList === true])

  return (

    <DataTable
      emptyMessage="Nincs találat."
      value={project} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
      onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
      autoLayout={true}
      filterDisplay="row"
responsiveLayout="scroll"
      stripedRows
      ref={dt}
    >
      <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
      {props.view === 'advanced' ? (
        <Column field="projectNumber" header="Projektkód" sortable></Column>
      ) : (
        <Column field="projectNumber" header="Projektkód" sortable filter filterPlaceholder="Projektkód"></Column>
      )}
      {props.view === 'advanced' ? (
        <Column field="name" header="Név" sortable></Column>
      ) : (
        <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
      )}
      {props.view === 'advanced' ? (
        <Column field="partnerName" header="Vevő" sortable></Column>
      ) : (
        <Column field="partnerName" header="Vevő" sortable filter filterPlaceholder="Vevő"></Column>
      )}
      {props.view === 'advanced' &&
      <Column field="contractNumber" header="Szerződés" sortable></Column>
      }
      <Column field="projectStatusName" header="Státusz" sortable filter
              filterElement={statusFilterElement}></Column>
      <Column field="projectTypeName" header="Típus" sortable filter
              filterElement={typeFilterElement}></Column>
    </DataTable>

  )
}
export default withTranslation('common')(ProjectListComponent);
