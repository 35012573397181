import axios from "axios";

export default function ReceiptitemFormService(formData, receiptId, method, itemId) {
  let url = ''
  if(method === 'post') {
    url = process.env.REACT_APP_API_HOST + "/receipt/" + receiptId + "/item"
  }
  if(method === 'put') {
    url = process.env.REACT_APP_API_HOST + "/receipt/" + receiptId + "/item/" + itemId
  }
  return axios({
    method: method,
    url: url,
    data: formData,
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken") + '.' + localStorage.getItem("networkId")}
  });
}
